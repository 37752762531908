<template>
  <div>
    <VueSimpleAutocomplete
      :items="municipios"
      :selected-item="municipio"
      @change="buscarMunicipio"
      @value-selected="onMunicipioSelecionada"
      :is-loading="carregando"
      :get-display-value="exibirMunicipio"
      :min-length="2"
      placeholder="Digite o nome do município para pesquisar"
    ></VueSimpleAutocomplete>
  </div>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";

import CodigoMunicipioMapeamentoOportunidadeApi from "../../../services/api/CodigoMunicipioMapeamentoOportunidadeApi";

export default {
  name: "MunicipioAutocomplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "municipio",
    event: "change",
  },
  props: {
    municipio: {
      type: Object,
    },
  },
  data() {
    return {
      municipios: [],
      carregando: false,
    };
  },
  created() {
    this.codigoMunicipioMapeamentoOportunidadeApi =
      new CodigoMunicipioMapeamentoOportunidadeApi();
  },
  methods: {
    async buscarMunicipio(nome) {
      try {
        this.municipios = [];
        this.$emit("change", {});

        if (nome == null || nome.length === 0) {
          return;
        }

        this.carregando = true;
        const data =
          await this.codigoMunicipioMapeamentoOportunidadeApi.getMunicipioAutocomplete(
            nome
          );

        this.municipios = data;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
      }
    },
    onMunicipioSelecionada(municipio) {
      this.$emit("change", municipio);
    },
    exibirMunicipio(municipio) {
      if (municipio && municipio !== "" && municipio.nome) {
        return `${municipio.nome}`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
