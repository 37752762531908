import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class PessoasFisicasApi {
  cancelTokens = {
    getPessoasFisicas: null,
    getPessoaFisica: null,
    salvarPessoaFisica: null,
    excluirPessoaFisica: null,
    getFuncoes: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  // termo: nome ou cpf
  async getPessoasFisicas({ termo, page, perPage, servidorSic }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getPessoasFisicas = source;

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
      termo,
      servidor_sic: servidorSic,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoasFisicas = null;

    return data;
  }

  async getPessoaFisica(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getPessoaFisica = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoaFisica = null;

    return data;
  }

  async salvarPessoaFisica(pessoaFisica) {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.salvarPessoaFisica = source;

      let method;
      let url;
      if (pessoaFisica.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas/${pessoaFisica.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas`;
      }

      const { data } = await method(url, pessoaFisica, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarPessoaFisica = null;

      return data;
    } catch (err) {
      this.cancelTokens.salvarPessoaFisica = null;

      throw err;
    }
  }

  async excluirPessoaFisica(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.excluirPessoaFisica = source;

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.excluirPessoaFisica = null;

    return data;
  }

  async getFuncoes(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getFuncoes = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_fisicas/${id}/funcoes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getFuncoes = null;

    return data;
  }
}

export default PessoasFisicasApi;
