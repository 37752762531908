import ModelPersistido from "./ModelPersistido";

export default class RegiaoPlanejamento extends ModelPersistido {
    static entity = "regioesPlanejamentos"

    static fields() {
        return {
            id: this.attr(null),
            nome: this.string(null).nullable(),
        }
    }
}