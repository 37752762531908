<template>
  <div class="caixa-form">
    <div class="header-caixa">
      <h4>Municípios de Interesse</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Município de Interesse"
      >
        <span>+</span>Adicionar
      </BotaoArredondado>
    </div>
    <div
      class="caixa-item"
      v-for="(municipio, index) in municipiosAlvos"
      :key="index"
    >
      <div v-if="!municipio._destroy">
        <button class="bt-delete" @click="excluir(index)" type="button">
          <DeleteIcon />
        </button>
        <PessoasJuridicasMunicipioAlvo
          :municipios="municipios"
          :tipos-area="tiposArea"
          v-model="municipiosAlvos[index]"
          :index="municipiosAlvos.length - index"
        />
      </div>
      <div class="campo-desfazer" v-else>
        <p>O registro será excluído.</p>
        <button
          class="bt-desfazer"
          @click="desfazerExclusao(index)"
          type="button"
        >
          <IconDesfazer /> Desfazer
        </button>
      </div>
    </div>
    <div class="text-center" v-if="municipiosAlvos.length === 0">
      <p>Nenhum municipío alvo encontrado</p>
    </div>
  </div>
</template>

<script>
import PessoasJuridicasMunicipioAlvo from "./PessoasJuridicasMunicipioAlvo";
import BotaoArredondado from "../../../botoes/BotaoArredondado.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import IconDesfazer from "../../../../assets/images/icon-return.svg";

const cloneDeep = require("lodash.clonedeep");

export default {
  name: "PessoasJuridicasMunicipiosAlvo",
  model: {
    prop: "municipiosAlvos",
    event: "change",
  },
  props: {
    municipios: {
      type: Array,
      required: true,
    },
    municipiosAlvos: {
      type: Array,
      required: true,
    },
    tiposArea: {
      type: Array,
      required: true,
    },
  },
  components: {
    PessoasJuridicasMunicipioAlvo,
    BotaoArredondado,
    DeleteIcon,
    IconDesfazer,
  },
  methods: {
    adicionar() {
      const municipiosAlvos = cloneDeep(this.municipiosAlvos);
      municipiosAlvos.unshift({});

      this.$emit("change", municipiosAlvos);
    },
    excluir(index) {
      const municipiosAlvos = cloneDeep(this.municipiosAlvos);
      if (!municipiosAlvos[index].id) {
        //nao esta salvo no banco
        municipiosAlvos.splice(index, 1);
      } else {
        municipiosAlvos[index]._destroy = true;
      }
      this.$emit("change", municipiosAlvos);
    },
    desfazerExclusao(index) {
      const municipiosAlvos = cloneDeep(this.municipiosAlvos);

      municipiosAlvos[index]._destroy = false;

      this.$emit("change", municipiosAlvos);
    },
    updateValue(index, municipioAlvo) {
      const municipiosAlvos = cloneDeep(this.municipiosAlvos);

      municipiosAlvos[index] = municipioAlvo;

      this.$emit("change", municipioAlvo);
    },
  },
};
</script>

<style lang="scss" scoped>

.header-caixa {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span{
    font-weight: 800;
    font-size: 1.8rem;
  }
}
.caixa-form {
  padding: 0rem .5rem;
  border-radius: 2px;
  margin-bottom: 2rem;
}
.disp-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.caixa-item {
  background-color: var(--cinza-caixa-gaia);
  padding: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
}
.text-center {
  text-align: center;
}
p {
  text-align: center;
}
.bt-desfazer {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: var(--vermelho-gaia);
  margin: 1rem auto;
  padding: 0.3rem 1rem;
  transition: 0.2s;
  svg {
    height: 1.3rem;
    width: 2rem;
    fill: var(--vermelho-gaia);
    margin-right: 1rem;
    transition: 0.2s;
  }
}
.bt-delete {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  padding: 0;
  svg {
    fill: var(--vermelho-gaia);
  }
}
@media screen and(min-width: '50em') {
  .campo-desfazer {
    display: flex;
    align-items: center;
    justify-content: center;
    .bt-desfazer {
      margin: 0;
    }
  }
}
</style>
