<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Contatos</h1>
    </div>

    <form
      class="form-pesquisa"
      @submit="buscar"
      v-if="!errocarregandoFiltro && !carregandoFiltro"
    >
      <h4>Pesquisar</h4>
      <div>
        <div class="row2-2">
          <div class="item">
            <label>Razão Social:</label>
            <input type="text" v-model="filtro.razao_social" />
          </div>
          <div class="item">
            <label> Status do Contato:</label>
            <select v-model="filtro.status_contato">
              <option value="">Todos</option>
              <option
                v-for="contato in statusContato"
                :value="contato.value"
                :key="contato.label"
              >
                {{ contato.label }}
              </option>
            </select>
          </div>
        </div>
        <fieldset>
          <p>Data do Contato:</p>
          <div class="div-flex">
            <div class="item">
              <label>De</label>
              <date-picker
                v-model="filtro.criado_de"
                format="DD/MM/YYYY"
                value-type="format"
              ></date-picker>
            </div>
            <div class="item">
              <label>Até</label>
              <date-picker
                v-model="filtro.criado_ate"
                format="DD/MM/YYYY"
                value-type="format"
              ></date-picker>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" @click="limpar"><IconClear />Limpar campos</button>
        <button class="bt-pesquisar" type="submit">Pesquisar</button>
      </div>
    </form>

    <AppLoading class="carregando1" v-if="!paginaCarregou && !paginaComErro"></AppLoading>
    <AppErro v-if="paginaCarregou && paginaComErro"></AppErro>

    <template v-if="paginaCarregou && !paginaComErro">
      <div class="campo-header-resultados">
      <AppTotalResultadosEncontrados :totalResultados="totalResultados" />
        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>
      <ContatosResultados :contatos="contatos"></ContatosResultados>
      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { DEFAULT_PER_PAGE } from "../../../config";

import ContatosSiteApi from "../../../services/api/ContatosSiteApi";

import { INVESTE_GOIAS_CONTATOS_SITE_INDEX } from "../../../router/routeNames";

import Paginate from "vuejs-paginate";

import filterNullsAndEmptyStrings from "../../../helpers/filterNullsAndEmptyStrings";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados.vue";

import ContatosResultados from "../../../components/investe_goias/contatos_site/ContatosSiteResultados.vue";

import IconClear from "../../../assets/images/icon-clean.svg";

const isEqual = require("lodash.isequal");

export default {
  name: "ContatoSiteIndex",
  components: {
    Paginate,
    AppErro,
    AppLoading,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    ContatosResultados,
    DatePicker,
    IconClear,
  },
  metaInfo() {
    return {
      title: `Contatos Site - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      carregando: true,
      carregandoFiltro: true,
      erroCarregando: false,
      errocarregandoFiltro: false,
      contatos: [],
      totalResultados: 0,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      statusContato: [],
      filtro: {
        status_contato: this.$route.query.status_contato || "",
        razao_social: this.$route.query.razao_social || "",
        criado_de: this.$route.query.criado_de || "",
        criado_ate: this.$route.query.criado_ate || "",
      },
    };
  },
  created() {
    this.contatoSiteApi = new ContatosSiteApi();

    this.carregandoDados();
    this.carregandoFiltros();
  },
  watch: {
    $route: function (to, from) {
      if (
        to.name == INVESTE_GOIAS_CONTATOS_SITE_INDEX &&
        !isEqual(to.query, from.query)
      ) {
        this.carregandoDados();
      }
    },
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        this.filtro = {
          status_contato: this.$route.query.status_contato || "",
          razao_social: this.$route.query.razao_social || "",
          criado_de: this.$route.query.criado_de || "",
          criado_ate: this.$route.query.criado_ate || "",
        };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.contatoSiteApi.getContatosSite({
          ...this.filtro,
          page: this.page,
          per_page: this.perPage,
        });
        this.totalResultados = data.total_resultados;
        this.contatos = data.resultados;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: INVESTE_GOIAS_CONTATOS_SITE_INDEX,
        query: filterNullsAndEmptyStrings({
          razao_social: this.filtro.razao_social,
          criado_de: this.filtro.criado_de,
          criado_ate: this.filtro.criado_ate,
          status_contato: this.filtro.status_contato,
          page: 1,
          per_page: this.perPage,
        }),
      });
    },
    async carregandoFiltros() {
      try {
        this.carregandoFiltro = true;
        this.errocarregandoFiltro = false;

        this.statusContato = await this.contatoSiteApi.getStatusContato();

        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.carregandoFiltro = false;
        this.errocarregandoFiltro = true;
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: INVESTE_GOIAS_CONTATOS_SITE_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    limpar() {
      this.filtro = {
        razao_social: "",
        criado_de: "",
        criado_ate: "",
        status_contato: "",
      };
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    paginaCarregou() {
      return !this.carregandoFiltro && !this.carregando;
    },
    paginaComErro() {
      return this.erroCarregando || this.errocarregandoFiltro;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  h1 {
    margin: 0;
    color:  var(--laranja-gaia);
  }
}
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.6rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }
  fieldset {
    p {
      margin: 0;
      font-family: "Lato-Regular";
    }
  }
  .div-flex {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    justify-content: center;
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
    .mx-datepicker {
      width: auto;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color:  var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: "30em") {
  .campo-header-resultados {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
  fieldset{
    .div-flex{
      .item{
        display: flex;
        align-items: center;
        label{
          margin-right: .5rem;
        }
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .row2-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
