<template>
  <div>
    <!--<div>{{ index }}</div>-->
    <div>
      <div class="row3">
        <p>
          <strong>Município: </strong
          >{{ municipioAlvo ? municipioAlvo.municipio.nome : "" }}
        </p>

        <p>
          <strong>Prefeito: </strong>{{ municipioAlvo.prefeito | naoInformado }}
        </p>
        <p>
          <strong>Precisa de área: </strong
          >{{ municipioAlvo.precisa_de_area ? "Sim" : "Não" }}
        </p>
      </div>
      <div class="row3">
        <template v-if="municipioAlvo.precisa_de_area">
          <p>
            <strong>Tamanho de área(m2): </strong>
            {{ municipioAlvo.tamanho_area }}
          </p>

          <p>
            <strong>Tipo de área: </strong>
            {{
              municipioAlvo.tipo_area
                ? municipioAlvo.tipo_area.nome
                : "Não informado"
            }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasItemMunicipioAlvo",
  props: {
    index: {
      type: Number,
      required: true,
    },
    municipioAlvo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: "60em") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
}
</style>
