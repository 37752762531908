import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";


export default class TiposSolicitacaoEmpresaAtracaoApi {
    cancelTokens = {
        getTiposSolicitacao: null
    };

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown);
    }

    async getTiposSolicitacao({ page = 1, perPage }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.getTiposSolicitacao = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao?page=${page}&per_page=${perPage}`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getTiposSolicitacao = null;

        return data;
    }
}