<template>
  <div class="beneficio-form">
    <div class="title">
      <h4>Benefícios</h4>
      <BotaoArredondado
        v-if="beneficios.length !== 0"
        @click="adicionar"
        type="button"
        title="Adicionar Benefício"
      >
        <span>+</span> Adicionar
      </BotaoArredondado>
    </div>
    <div v-if="beneficios.length === 0">
      <p class="sem-resultado">Nenhum benefício cadastrado no sistema.</p>
    </div>
    <div class="div-flex" v-if="beneficios.length > 0">
      <div
        v-for="(beneficioMunicipio, index) in beneficiosMunicipios"
        :key="index"
      >
        <template class="item-beneficio" v-if="!beneficioMunicipio._destroy">
          <select @change="(value) => selecionarBeneficio(index, value)">
            <option :value="null">Selecione</option>
            <option
              v-for="beneficio in beneficios"
              :key="beneficio.id"
              :value="beneficio.id"
              :selected="beneficio.id == beneficioMunicipio.beneficio_id"
              >{{ beneficio.nome }}</option
            >
          </select>
          <button @click="remover(index)" type="button"><DeleteIcon /></button>
        </template>
        <template v-else>
          <div class="desfazer">
            <span>Registro vai ser removido</span>
            <button type="button" @click="desfazerRemocao(index)">
              <IconReturn />
            </button>
          </div>
        </template>
      </div>
      <p class="sem-resultado" v-if="beneficiosMunicipios.length === 0">
        Nenhum benefício adicionado
      </p>
    </div>
  </div>
</template>

<script>
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import IconReturn from "../../../assets/images/icon-return.svg";
import BotaoArredondado from "../../botoes/BotaoArredondado.vue";
export default {
  name: "MunicipioBeneficiosForm",
  model: {
    prop: "beneficiosMunicipios",
    event: "update",
  },
  components: {
    DeleteIcon,
    IconReturn,
    BotaoArredondado,
  },
  props: {
    beneficiosMunicipios: {
      type: Array,
      default: () => [],
    },
    beneficios: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    adicionar() {
      this.$emit(
        "update",
        this.beneficiosMunicipios.concat({
          id: null,
          municipio_id: null,
          beneficio_id: null,
        })
      );
    },
    remover(index) {
      if (this.beneficiosMunicipios[index].id) {
        //ja esta salvo, entao adicionar _destroy = true
        this.beneficiosMunicipios[index]._destroy = true;
        this.$emit("update", [...this.beneficiosMunicipios]);
      } else {
        //nao esta salvo, entao apenas remove objeto
        this.$emit(
          "update",
          this.beneficiosMunicipios.filter((ele, idx) => idx !== index)
        );
      }
    },
    selecionarBeneficio(index, event) {
      this.beneficiosMunicipios[index].beneficio_id = event.target.value;
      this.$emit("update", this.beneficiosMunicipios);
    },
    desfazerRemocao(index) {
      this.beneficiosMunicipios[index]._destroy = false;
      this.$emit("update", [...this.beneficiosMunicipios]);
    },
  },
};
</script>

<style lang="scss" scoped>
.beneficio-form {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .sem-resultado {
    font-style: italic;
    margin: 0 auto 2rem;
  }
  .div-flex {
    display: flex;
    flex-wrap: wrap;
    div {
      margin: 0 1rem 1rem 0;
      display: flex;
      align-items: center;
    }
    select {
      height: 1.8rem;
      min-width: 14rem;
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 1.8rem;
      svg {
        fill: var(--vermelho-gaia);
      }
    }
  }
}
</style>
