<template>
  <div>
    <TheMenu v-if="exibirMenu"></TheMenu>

    <div :class="{ 'app-conteudo': exibirMenu }">
      <AppBreadcrumbs />
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import VueScrollTo from "vue-scrollto";
import VueSweetalert2 from "vue-sweetalert2";
import VueCurrencyInput from "vue-currency-input";
import VueCurrencyFilter from "vue-currency-filter";

import "sweetalert2/dist/sweetalert2.min.css";

import TheMenu from "./components/menu/TheMenu";
import AppBreadcrumbs from "./components/AppBreadcrumbs";
import * as routeNames from "./router/routeNames";

import store from "./store";
import filters from "./filters";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueSweetalert2);
Vue.use(VueScrollTo);
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: "BRL",
    locale: "pt",
    distractionFree: false,
  },
});

Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

Vue.use(filters);

export default {
  name: "App",
  store,
  data() {
    return {
      routesNoMenu: [routeNames.LOGIN_ROUTE],
    };
  },
  components: {
    TheMenu,
    AppBreadcrumbs,
  },
  computed: {
    exibirMenu() {
      return !this.routesNoMenu.includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-conteudo {
  margin-top: 6rem;
}
.menudesktop {
  display: none;
}
@media screen and (min-width: "55em") {
  .app-conteudo {
    margin-top: 0rem;
  }
  .menuresponsivo {
    display: none;
  }
  .menudesktop {
    display: block;
  }
}
</style>
