<template>
  <div class="tela-form">
    <div class="caixa-form">
      <div>
        <router-link
          title="Fechar"
          :to="{ name: ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX }"
          >&#10005;</router-link
        >
        <h1>
          {{
            $route.name === ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW
              ? "Novo Benefício"
              : "Editar Benefício"
          }}
        </h1>
      </div>
      <div>
        <!--  -->
      </div>
      <AppLoading
        class="carregando1"
        v-if="carregandoDados && !erroCarregandoDados"
      />
      <AppErro v-if="!carregandoDados && erroCarregandoDados" />

      <form
        v-if="!carregandoDados && !erroCarregandoDados"
        @submit="salvarDados"
      >
        <AppErrosFormularios
          v-if="errosSalvando && errosSalvando.length > 0"
          :erros="errosSalvando"
        />
        <div>
          <div class="item">
            <label>Nome:</label>
            <input type="text" v-model="beneficio.nome" />
          </div>
          <div class="botao-salvar">
            <AppLoading v-if="salvando" />
            <BotaoSalvar v-if="!salvando" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BeneficiosApi from "../../../../services/api/BeneficiosApi";
import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";

import {
  ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
  ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
  ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";

import AppLoading from "../../../../components/AppLoading";
import AppErro from "../../../../components/AppErro";
import AppErrosFormularios from "../../../../components/AppErrosFormularios";

export default {
  name: "BeneficiosForm",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    BotaoSalvar,
  },
  metaInfo() {
    return {
      title: `${
        this.$route.name === ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW
          ? "Criar"
          : "Editar"
      } Benefício - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      beneficio: [],
      carregandoDados: true,
      erroCarregandoDados: false,
      salvando: false,
      errosSalvando: [],
      ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
      ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
      ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW,
    };
  },
  created() {
    this.beneficiosApi = new BeneficiosApi();
    this.carregarDados();
  },
  methods: {
    async carregarDados() {
      try {
        this.carregandoDados = true;
        this.erroCarregandoDados = false;

        if (this.beneficiosApi.cancelTokens.getBeneficio) {
          this.beneficiosApi.cancelTokens.getBeneficio.cancel();
        }

        if (this.$route.params.id) {
          this.beneficio = await this.beneficiosApi.getBeneficio(
            this.$route.params.id
          );
        }
        this.carregandoDados = false;
      } catch (err) {
        if (this.beneficiosApi.isCancel(err)) {
          return;
        }

        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        console.log(err);
        this.carregandoDados = false;
        this.erroCarregandoDados = true;
      }
    },
    async salvarDados(event) {
      event.preventDefault();

      try {
        this.salvando = true;
        this.errosSalvando = [];

        await this.beneficiosApi.salvarBeneficio(this.beneficio);

        this.salvando = false;

        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
          query: this.$route.query,
        });
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
        this.salvando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-form {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    min-width: 50%;
    position: relative;
    a {
      text-decoration: none;
      color: silver;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      transition: 0.2s;
    }
    a:hover {
      color: var(--vermelho-gaia);
    }
    h1 {
      color: var(--laranja-gaia);
      margin-top: 2rem;
    }
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
      }
    }
    .botao-salvar {
      text-align: center;
      margin: 1rem 0;
    }
  }
}
</style>
