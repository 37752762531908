<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <router-link
        class="bt-fechar"
        :to="{
          name: PESSOA_JURIDICA_SHOW,
          params: { id: $route.params.id },
          hash: $route.hash || '#atracao',
        }"
        ><IconFechar
      /></router-link>
      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Atração de Investimento</h1>

        <div class="row3">
          <p>
            <b>Data de início: </b
            >{{ atracaoInvestimento.data_inicio | toDate }}
          </p>

          <p>
            <b>Área internacional: </b>
            {{ atracaoInvestimento.area_internacional ? "Sim" : "Não" }}
          </p>

          <p>
            <b>Ja estabelecida em Goiás: </b
            >{{ atracaoInvestimento.ja_estabelecida_em_goias ? "Sim" : "Não" }}
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Tipo de Projeto: </b>
            {{ atracaoInvestimento.tipo_projeto_label | naoInformado }}
          </p>

          <p>
            <b>Quem iniciou o contato: </b>
            {{ atracaoInvestimento.quem_iniciou_contato_label }}
          </p>

          <p v-if="atracaoInvestimento.quem_iniciou_contato == 'outro'">
            <b>Nome de quem iniciou o contato: </b>
            {{ atracaoInvestimento.nome_quem_iniciou_contato }}
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Empregos previstos: </b>
            {{ atracaoInvestimento.empregos_previstos | naoInformado }}
          </p>

          <p>
            <b>Investimentos previstos: </b>
            {{
              atracaoInvestimento.investimentos_previstos
                | currency
                | naoInformado
            }}
          </p>

          <p>
            <b>Empresa precisa de fomento: </b>
            {{ atracaoInvestimento.empresa_precisa_de_fomento ? "Sim" : "Não" }}
          </p>
        </div>
        <p v-if="atracaoInvestimento.observacao">
          <b>Observação: </b>
          {{ atracaoInvestimento.observacao }}
        </p>

        <AppTabs :tabs="tabs" v-model="currentTab">
          <template v-slot:[tabs[0].id]>
            <PessoasJuridicasListaSolicitacoes
              :solicitacoes-empresa="
                atracaoInvestimento.solicitacoes_empresa_atracao_investimento
              "
            />
          </template>
          <template v-slot:[tabs[1].id]>
            <PessoasJuridicasListaMunicipiosAlvos
              :municipios-alvos="atracaoInvestimento.municipios_alvos"
            />
          </template>
        </AppTabs>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import AppTabs from "../../../AppTabs";

import PessoasJuridicasListaSolicitacoes from "./PessoasJuridicasListaSolicitacoes";
import PessoasJuridicasListaMunicipiosAlvos from "./PessoasJuridicasListaMunicipiosAlvos";

import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";
import { modulePessoaJuridica } from "../../../../store/moduleNames";

import { PESSOA_JURIDICA_SHOW } from "../../../../router/routeNames";

import toDate from "../../../../filters/toDate";

import IconFechar from "../../../../assets/images/icon-fechar.svg";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasExibirAtracao",
  components: {
    AppErro,
    AppLoading,
    AppTabs,
    PessoasJuridicasListaSolicitacoes,
    PessoasJuridicasListaMunicipiosAlvos,
    IconFechar,
  },
  filters: {
    toDate,
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: " Solicitações",
        permissoes: [],
      },
      {
        id: 2,
        label: "Municípios de Interesse",
        permissoes: [],
      },
    ];
    return {
      tabs,
      currentTab: tabs[0],
      PESSOA_JURIDICA_SHOW,
    };
  },
  methods: {
    ...mapActions(["carregarAtracaoInvestimento"]),
  },
  created() {
    this.carregarAtracaoInvestimento(this.$route.params.atracaoId);
  },
  computed: {
    carregando() {
      return (
        this.$store.state.entities.atracaoInvestimentos
          .carregandoAtracaoInvestimento ||
        this.$store.state.entities.atracaoInvestimentos.carregandoLista
      );
    },
    erro() {
      return this.$store.state.entities.atracaoInvestimentos
        .erroCarregarAtracaoInvestimento;
    },
    atracaoInvestimento() {
      if (!this.$route.params.atracaoId) return null;

      return AtracaoInvestimento.query()
        .whereId(parseInt(this.$route.params.atracaoId))
        .withAllRecursive()
        .with("solicitacoes_empresa_atracao_investimento", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .with("municipios_alvos", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
}
.modal-caixa {
  background-color: white;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: auto;
  position: relative;
  h1 {
    color: var(--laranja-gaia);
  }
  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
  b {
    font-family: "Lato-bold";
    font-weight: 0;
  }
}
.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: 95vh;
    width: 80vw;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: 0.5rem;
    }
  }
  .botoes {
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
</style>
