<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <router-link
        class="bt-fechar"
        :to="{
          name: PESSOA_JURIDICA_SHOW,
          params: { id: $route.params.id },
          hash: $route.hash || '#contatos',
        }"
        ><IconFechar />
      </router-link>

      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Contato Realizado</h1>

        <div>
          <div class="row2-2">
            <p>
              <strong>Data de contato: </strong
              >{{ contatoRealizado.data_contato | toDate }}
            </p>

            <p>
              <strong>Servidor que entrou em contato: </strong
              >{{
                contatoRealizado.pessoa_entrou_em_contato
                  ? contatoRealizado.pessoa_entrou_em_contato.nome
                  : "Não informado"
              }}
            </p>
          </div>
          <div class="row3">
            <p>
              <strong>Pessoa contactada: </strong
              >{{ contatoRealizado.pessoa_contactada }}
            </p>

            <p>
              <strong>Cargo do contato: </strong
              >{{ contatoRealizado.cargo_do_contato | naoInformado }}
            </p>

            <p>
              <strong>Meio de contato: </strong
              >{{
                contatoRealizado.meio_contato
                  ? contatoRealizado.meio_contato.nome
                  : "Não informado"
              }}
            </p>
          </div>
          <div class="row3">
            <p v-if="contatoRealizado.etapa_type">
              <strong>Etapa: </strong>{{ contatoRealizado.etapa_label }}
            </p>

            <p
              v-if="
                contatoRealizado.etapa_type ==
                  'SicNegocio::Gaia::Model::ProtocoloIntencao' &&
                  protocoloIntencao
              "
            >
              <strong>Protocolo de intenção: </strong
              ><router-link
                :to="{
                  name: PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
                  params: {
                    id: $route.params.id,
                    protocoloId: protocoloIntencao.id,
                  },
                  hash: $route.hash,
                }"
                v-if="
                  usuario.funcionalidades.includes(
                    PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO
                  )
                "
              >
                {{ protocoloIntencao.numero }} -
                {{ protocoloIntencao.data_inicio | toDate }} ({{
                  protocoloIntencao.status_label
                }})
              </router-link>
              <span v-else>
                {{ protocoloIntencao.numero }} -
                {{ protocoloIntencao.data_inicio | toDate }} ({{
                  protocoloIntencao.status_label
                }})
              </span>
            </p>
            <p
              v-if="
                contatoRealizado.etapa_type ==
                  'SicNegocio::Gaia::Model::AtracaoInvestimento' &&
                  atracaoInvestimento
              "
            >
              <strong>Atração de investimento: </strong
              ><router-link
                :to="{
                  name: PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
                  params: {
                    id: $route.params.id,
                    atracaoId: atracaoInvestimento.id,
                  },
                  hash: $route.hash,
                }"
                v-if="
                  usuario.funcionalidades.includes(
                    ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO
                  )
                "
              >
                Iniciada em {{ atracaoInvestimento.data_inicio | toDate }}
              </router-link>
              <span v-else
                >Iniciada em
                {{ atracaoInvestimento.data_inicio | toDate }}</span
              >
            </p>
          </div>
          <p>
            <strong>Assunto: </strong
            >{{ contatoRealizado.assunto | naoInformado }}
          </p>

          <p>
            <strong>Conclusão: </strong
            >{{ contatoRealizado.conclusao | naoInformado }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";

import {
  PESSOA_JURIDICA_SHOW,
  PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
  PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
} from "../../../../router/routeNames";
import { modulePessoaJuridica } from "../../../../store/moduleNames";
import ContatoRealizado from "../../../../store/models/ContatoRealizado";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";

import IconFechar from "../../../../assets/images/icon-fechar.svg";

import {
  ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO,
  PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO,
} from "../../../../permissoes";
import { getUsuario } from "../../../../services/Auth";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasExibirContato",
  components: {
    AppErro,
    AppLoading,
    IconFechar,
  },
  data() {
    return {
      usuario: getUsuario(),
      PESSOA_JURIDICA_SHOW,
      PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
      PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
      ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO,
      PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO,
    };
  },
  created() {
    this.carregarContatoRealizado(this.$route.params.contatoId);
  },
  methods: {
    ...mapActions(["carregarContatoRealizado"]),
  },
  computed: {
    carregando() {
      return (
        this.$store.state.entities.contatosRealizados
          .carregandoContatoRealizado ||
        this.$store.state.entities.contatosRealizados
          .carregandoListaContatosRealizados
      );
    },
    erro() {
      return (
        this.$store.state.entities.contatosRealizados
          .erroCarregarContatoRealizado ||
        this.$store.state.entities.contatosRealizados
          .erroCarregarListaContatosRealizados
      );
    },
    contatoRealizado() {
      const { contatoId } = this.$route.params;
      return ContatoRealizado.query()
        .whereId(isNaN(contatoId) ? contatoId : parseInt(contatoId))
        .withAll()
        .first();
    },
    atracaoInvestimento() {
      if (
        !this.contatoRealizado ||
        this.contatoRealizado.etapa_type !=
          "SicNegocio::Gaia::Model::AtracaoInvestimento"
      )
        return null;

      return AtracaoInvestimento.query()
        .whereId(this.contatoRealizado.etapa_id)
        .first();
    },
    protocoloIntencao() {
      if (
        !this.contatoRealizado ||
        this.contatoRealizado.etapa_type !=
          "SicNegocio::Gaia::Model::ProtocoloIntencao"
      )
        return null;

      return ProtocoloIntencao.query()
        .whereId(this.contatoRealizado.etapa_id)
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
}

.modal-caixa {
  background-color: white;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: auto;
  position: relative;
  h1 {
    color: var(--laranja-gaia);
  }
  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
}

.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: auto;
    max-height: 95vh;
    width: 80vw;
    p {
      display: grid;
      gap: 0.5rem;
    }
  }
  .row2-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
