import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import TiposInvestimentoIndex from "../../../views/investe_goias/tipos_investimento/TiposInvestimentoIndex";
import TiposInvestimentoForm from "../../../views/investe_goias/tipos_investimento/TiposInvestimentoForm";
import TiposInvestimentoShow from "../../../views/investe_goias/tipos_investimento/TiposInvestimentoShow";

const rotasTiposInfvestimento = [
  {
    name: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
    component: TiposInvestimentoIndex,
    path: "/investe-goias/tipos-investimento/",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.TIPOS_INVESTIMENTO_PERMISSAO],
      breadcrumb: {
        label: "Tipos de Investimentos",
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
    component: TiposInvestimentoShow,
    path: "/investe-goias/tipos-investimento/:id(\\d+)",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.TIPOS_INVESTIMENTO_PERMISSAO],
      breadcrumb: {
        label: "Exibir Tipo de Investimento",
        parent: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW,
    component: TiposInvestimentoForm,
    path: "/investe-goias/tipos-investimento/new",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.TIPOS_INVESTIMENTO_PERMISSAO],
      breadcrumb: {
        label: "Novo Tipo de Investimento",
        parent: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
    component: TiposInvestimentoForm,
    path: "/investe-goias/tipos-investimento/:id/edit",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.TIPOS_INVESTIMENTO_PERMISSAO],
      breadcrumb: {
        label: "Editar Tipo de Investimento",
        parent: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
      },
    },
  },
];

export default rotasTiposInfvestimento;
