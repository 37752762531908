<template>
  <div class="centro form-conteudo">
    <div class="form-header">
      <h1>
        {{
          $route.name == CADASTROS_BASICOS_PESSOAS_FISICAS_NEW
            ? "Nova Pessoa Física"
            : "Editar Pessoa Física"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="pessoaFisica.id"
        :to="{
          name: CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
          params: { id: pessoaFisica.id },
        }"
      />
    </div>

    <AppLoading class="carregando1" v-if="carregando && !erro" />
    <AppErro v-if="!carregando && erro" />

    <template v-if="!carregando && !erro">
      <div id="erros-container">
        <AppErrosFormularios
          v-if="!salvando && erroSalvar && erroSalvar.length > 0"
          :erros="erroSalvar"
        />
      </div>

      <form @submit="salvar">
        <div class="caixa-form">
          <div class="row3-3">
            <div class="item">
              <label for="nome">Nome: </label>
              <input type="text" v-model="pessoaFisica.nome" name="nome" />
            </div>

            <div class="item">
              <label for="cpf">CPF: </label>
              <TheMask
                name="cpf"
                type="text"
                mask="###.###.###-##"
                v-model="pessoaFisica.cpf"
              />
            </div>

            <div class="item">
              <label>Servidor SIC? </label>
              <select v-model="pessoaFisica.servidor_sic">
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </div>
          </div>
        </div>
        <div class="botao-salvar">
          <BotaoSalvar v-if="!salvando" />
          <AppLoading v-if="salvando" texto="" size="small" />
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import BotaoVisualizarForm from "../../../components/botoes/BotaoVisualizarForm.vue";
import BotaoSalvar from "../../../components/botoes/BotaoSalvar.vue";

import {
  CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
  CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
  CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import PessoasFisicasApi from "../../../services/api/PessoasFisicasApi";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppErrosFormularios from "../../../components/AppErrosFormularios";

export default {
  name: "PessoasFisicasForm",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    TheMask,
    BotaoVisualizarForm,
    BotaoSalvar,
  },
  metaInfo() {
    return {
      title:
        this.$route.name == CADASTROS_BASICOS_PESSOAS_FISICAS_NEW
          ? "Nova Pessoa Física"
          : "Editar Pessoa Física",
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
      CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
      pessoaFisica: {
        cpf: null,
        nome: null,
        servidor_sic: true,
      },
      carregandoPessoaFisica: true,
      erroCarregarPessoaFisica: false,
      salvando: false,
      erroSalvar: null,
    };
  },
  async created() {
    this.pessoasFisicasApi = new PessoasFisicasApi();

    if (this.$route.name == CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT) {
      await this.carregarPessoaFisica();
    } else {
      this.carregandoPessoaFisica = false;
    }
  },
  methods: {
    async carregarPessoaFisica() {
      try {
        this.carregandoPessoaFisica = true;
        this.erroCarregarPessoaFisica = false;
        this.salvando = false;
        this.erroSalvar = null;

        this.pessoaFisica = await this.pessoasFisicasApi.getPessoaFisica(
          this.$route.params.id
        );

        this.carregandoPessoaFisica = false;
      } catch (err) {
        console.log(err);
        this.carregandoPessoaFisica = false;
        this.erroCarregarPessoaFisica = true;
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
      }
    },
    async salvar(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.erroSalvar = null;

        const pessoaFisica = await this.pessoasFisicasApi.salvarPessoaFisica(
          this.pessoaFisica
        );

        this.salvando = false;

        this.$router.push({
          name: CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
          params: { id: pessoaFisica.id },
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Pessoa jurídica salva com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        this.salvando = false;

        const { response } = err;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            this.erroSalvar = response.data;
          } else {
            this.erroSalvar = [response.data];
          }
        } else {
          this.erroSalvar = ["Houve um erro. Por favor, tente novamente"];
        }

        this.$scrollTo("#erros-container");
      }
    },
  },
  computed: {
    erro() {
      return this.erroCarregarPessoaFisica;
    },
    carregando() {
      return this.carregandoPessoaFisica;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: var(--laranja-gaia);
    }
  }
}

.caixa-form {
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }
}
.botao-salvar {
  text-align: center;
  margin: 2rem 0 3rem;
}

@media screen and(min-width: '50rem') {
  .row3-3 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
