import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import BeneficiosIndex from "../../../views/atracao_investimentos/cadastros/beneficios/BeneficiosIndex";
import BeneficiosForm from "../../../views/atracao_investimentos/cadastros/beneficios/BeneficiosForm";

const rotasBeneficios = [
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
    component: BeneficiosIndex,
    path: "/atracao-investimentos/cadastros/beneficios",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.BENEFICIOS_PERMISSAO],
    },
    children: [
      {
        name: routeNames.ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW,
        component: BeneficiosForm,
        path: "/atracao-investimentos/cadastros/beneficios/new",
        props: true,
        meta: {
          requerPermissao: true,
          raiz: true,
          permissoes: [permissoes.BENEFICIOS_PERMISSAO],
        },
      },

      {
        name: routeNames.ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
        path: "/atracao-investimentos/cadastros/beneficios/:id/edit",
        component: BeneficiosForm,
        props: true,
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.BENEFICIOS_PERMISSAO],
        },
      },
    ],
  },
];
export default rotasBeneficios;
