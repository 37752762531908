import * as routeNames from "../../routeNames";

import * as permissoes from "../../../permissoes";

import MunicipiosIndex from "../../../views/atracao_investimentos/municipios/MunicipiosIndex";
import MunicipioForm from "../../../views/atracao_investimentos/municipios/MunicipioForm";
import MunicipioShow from "../../../views/atracao_investimentos/municipios/MunicipioShow";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

const rotasMunicipios = [
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
    component: MunicipiosIndex,
    path: "/atracao-investimentos/municipios",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.MUNICIPIOS_PERMISSAO],
      breadcrumb: {
        label: "Municípios",
      },
    },
  },
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW,
    component: MunicipioForm,
    path: "/atracao-investimentos/municipios/new",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.MUNICIPIOS_PERMISSAO],
      breadcrumb: {
        label: "Novo Município",
        parent: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX
      },
    },
  },
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
    component: MunicipioForm,
    path: "/atracao-investimentos/municipios/:id/edit",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.MUNICIPIOS_PERMISSAO],
      breadcrumb: {
        label: "Editar Município",
        parent: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX
      },
    },
  },
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
    component: MunicipioShow,
    path: "/atracao-investimentos/municipios/:id(\\d+)",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.MUNICIPIOS_PERMISSAO],
      breadcrumb: {
        label: "Exibir Município",
        parent: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX
      },
    },
  },
];

export default rotasMunicipios;
