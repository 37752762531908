import Vue from "vue";
import Vuex from "vuex";
import VuexORM from '@vuex-orm/core';

import pessoaJuridica from "./modules/pessoa_juridica";
import { modulePessoaJuridica } from "./moduleNames";

import database from './database';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    [modulePessoaJuridica]: pessoaJuridica,
  },
  plugins: [VuexORM.install(database)]
});

export default store;
