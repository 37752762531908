import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class BeneficiosApi {
  cancelTokens = {
    getBeneficios: null,
    getBeneficio: null,
    salvarBeneficio: null,
    removerBeneficio: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getBeneficios({ page = 1, perPage }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getBeneficios = source;

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/beneficios?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getBeneficios = null;

    return data;
  }

  async getBeneficio(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getBeneficio = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/beneficios/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getBeneficio = null;

    return data;
  }

  async salvarBeneficio({ id, nome }) {
    let method;
    let url;
    if (id) {
      (method = axiosAuthenticated.put),
        (url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/beneficios/${id}`);
    } else {
      (method = axiosAuthenticated.post),
        (url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/beneficios`);
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarBeneficio = source;
    const { data } = await method(
      url,
      {
        id,
        nome,
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarBeneficio = null;

    return data;
  }

  async removerBeneficio({ id }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.removerBeneficio = source;

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/beneficios/${id}`,
      {
        cancelToken: source.token
      }
    );

    this.cancelTokens.removerBeneficio = null;

    return data;
  }
}

export default BeneficiosApi;
