<template>
  <router-link class="bt-header" :to="to"> <slot></slot> </router-link>
</template>

<script>
export default {
  name: "BotaoLink",
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-header {
  text-decoration: none;
  color: var(--verde-escuro-gaia);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0rem;
  padding: 0rem 2rem;
  border: var(--verde-escuro-gaia) solid 0.15rem;
  transition: linear 0.2s;
  -moz-transition: linear 0.2s;
  -webkit-transition: linear 0.2s;
  height: 2rem;
}
.bt-header:hover {
  background-color:  var(--verde-escuro-gaia);
  color: white;
}
</style>
