<script>
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";

export default {
  name: "PasswordTextField",
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleLocal: this.visible,
    };
  },
  watch: {
    visible: function(newValue) {
      this.visibleLocal = newValue;
    },
  },
  methods: {
    changeValue(event) {
      this.$emit("change", event.target.value);
    },
    changeVisible() {
      this.visibleLocal = !this.visibleLocal;
      this.$emit("change-visible", this.visibleLocal);
    },
  },
  render() {
    return (
      <div>
        <input
          type={this.visibleLocal ? "text" : "password"}
          value={this.value}
          onInput={this.changeValue}
          id={this.id}
        />
        {this.visibleLocal ? (
          <EyeOffIcon onClick={this.changeVisible} />
        ) : (
          <EyeIcon onClick={this.changeVisible} />
        )}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  align-items: center;
  height: 1.8rem;
  // border: silver 0.1rem solid;
  // border-radius: 2px;
  position: relative;
  input {
    // border: none;
    border: silver 0.1rem solid;
    font-family: "Lato-Regular";
    padding: 0 2.5rem 0 0.5rem;
    height: 2rem;
    background-color: transparent;
    width: calc(100% - 3rem);
  }
  .material-design-icon {
    margin-left: -2rem;
    display: block;
    width: 2rem;
    margin-right: 0;
  }
  svg {
    fill: var(--preto-gaia);
  }
}
</style>
