import actionsPessoaJuridica from "./actionsPessoaJuridica";
import actionsAtracaoInvestimento from "./actionsAtracaoInvestimento";
import actionsProtocoloIntencao from "./actionsProtocoloIntencao";
import actionsContatosRealizados from "./actionsContatosRealizados";

export default {
  namespaced: true,
  actions: {
    ...actionsPessoaJuridica,
    ...actionsAtracaoInvestimento,
    ...actionsProtocoloIntencao,
    ...actionsContatosRealizados,
  },
};
