<template>
  <div class="tela-form">
    <div class="caixa-form">
      <router-link
        title="Fechar"
        :to="{
          name: CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
          query: { ...this.$route.query },
        }"
        >&#10005;</router-link
      >
      <h1>Editar Código do município<br> do mapeamento de oportunidade</h1>
      <AppLoading v-if="carregandoDados && !erroCarregandoDados" />
      <AppErro v-if="!carregandoDados && erroCarregandoDados" />
      <form
        v-if="!carregandoDados && !erroCarregandoDados"
        @submit="salvarDados"
      >
        <AppErrosFormularios
          v-if="errosSalvando && errosSalvando.length > 0"
          :erros="errosSalvando"
        />
        <div class="row2">
          <div class="item">
              <label>Município:</label>
              {{ CodigoMunicipioMapeamentoOportunidade.municipio.nome }}
          </div>
          <div class="item">
              <label>Código:<span>*</span> </label>
              <input
                type="text"
                v-model="CodigoMunicipioMapeamentoOportunidade.codigo"
              />
          </div>
        </div>
        <div class="botao-salvar">
          <AppLoading size="small" v-if="salvando" texto="" />
          <BotaoSalvar v-if="!salvando" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CodigoMunicipioMapeamentoOportunidadeApi from "../../../../services/api/CodigoMunicipioMapeamentoOportunidadeApi";
import {
  CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
  CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";

import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import AppErrosFormularios from "../../../../components/AppErrosFormularios.vue";

export default {
  name: "CodigoMunicipioMapeamentoOportunidadeForm",
  metaInfo() {
    return {
      title: `${
        this.$route.name === CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT
          ? "Editar"
          : "Criar"
      } Mapeamento Oportunidades - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    AppLoading,
    BotaoSalvar,
    AppErro,
    AppErrosFormularios,
  },
  data() {
    return {
      carregandoDados: true,
      erroCarregandoDados: false,
      erroCarregar: false,
      page: this.$route.query.page,
      perPage: this.$route.query.per_page,
      CodigoMunicipioMapeamentoOportunidade: null,
      listaStatus: [],
      salvando: false,
      errosSalvando: [],
      CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
      CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT,
    };
  },
  created() {
    this.codigoMunicipioMapeamentoOportunidadeApi =
      new CodigoMunicipioMapeamentoOportunidadeApi();
    this.carregarDados();
  },
  methods: {
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.errosSalvando = [];

        await this.codigoMunicipioMapeamentoOportunidadeApi.salvarCodigoMunicipioMapeamentoOportunidade(
          this.CodigoMunicipioMapeamentoOportunidade
        );
        this.salvando = false;

        this.$router.push({
          name: CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
          query: this.$route.query,
        });

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }

        this.salvando = false;
      }
    },
    async carregarDados() {
      try {
        this.carregandoDados = true;
        this.erroCarregar = false;

        if (
          this.codigoMunicipioMapeamentoOportunidadeApi.cancelTokens
            .getCodigoMunicipioMapeamentoOportunidade
        ) {
          this.codigoMunicipioMapeamentoOportunidadeApi.cancelTokens.getCodigoMunicipioMapeamentoOportunidade.cancel();
        }

        if (this.$route.params.id) {
          this.CodigoMunicipioMapeamentoOportunidade =
            await this.codigoMunicipioMapeamentoOportunidadeApi.getCodigoMunicipioMapeamentoOportunidade(
              this.$route.params.id
            );
        }
        this.carregandoDados = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregandoDados = false;
        this.erroCarregar = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-form {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    min-width: 50%;
    margin: 0.5rem;
    position: relative;
    a {
      text-decoration: none;
      color: silver;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      transition: 0.2s;
    }
    a:hover {
      color: var(--vermelho-gaia);
    }
    h1 {
      color: var(--laranja-gaia);
      margin-top: .5rem;
    }
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
      }
    }
    .botao-salvar {
      text-align: center;
      margin: 2rem 0;
    }
  }
}
@media screen and (min-width: "50em"){
  .row2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
