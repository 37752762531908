import { parseISO } from "date-fns";

import HistoricoNomeFantasia from "./HistoricoNomeFantasia";
import HistoricoRazaoSocial from "./HistoricoRazaoSocial";
import Endereco from "./Endereco";
import PessoaResponsavel from "./PessoaResponsavel";
import ContatoPessoaJuridica from "./ContatoPessoaJuridica";
import CodigoCnaePessoaJuridica from "./CodigoCnaePessoaJuridica";

import ModelPersistido from "./ModelPersistido";

import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";

export default class PessoaJuridica extends ModelPersistido {
  static entity = "pessoasJuridicas";

  static state() {
    return {
      carregando: false,
      erroCarregar: false,
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      cnpj: this.attr(null),
      nome_fantasia_atual_id: this.number(null).nullable(),
      razao_social_atual_id: this.number(null).nullable(),
      observacao: this.string(null).nullable(),
      data_constituicao: this.attr(null, convertValidDateToString),
      cnpj_empresa_matriz: this.attr(null),
      objetivo_social: this.string(null).nullable(),
      reg_junta_comercial: this.string(null).nullable(),
      atua_area_internacional: this.boolean(false),
      data_inicio_area_internacional: this.attr(null, convertValidDateToString),
      faturamento: this.number(null).nullable(),
      classificacao: this.string(null).nullable(),
      label_classificacao: this.string(null).nullable(),
      num_funcionarios: this.number(null).nullable(),
      site: this.string(null).nullable(),
      email: this.string(null).nullable(),
      tipo: this.string(null).nullable(),
      label_tipo: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      criado_por: this.string(null).nullable(),
      atualizado_por: this.string(null).nullable(),
      historico_nomes_fantasia: this.hasMany(
        HistoricoNomeFantasia,
        "pessoa_juridica_id"
      ),
      historico_razoes_sociais: this.hasMany(
        HistoricoRazaoSocial,
        "pessoa_juridica_id"
      ),
      enderecos: this.hasMany(Endereco, "pessoa_juridica_id"),
      nome_fantasia_atual: this.attr({ nome_fantasia: null }),
      razao_social_atual: this.attr({ razao_social: null }),
      pessoas_responsaveis: this.hasMany(
        PessoaResponsavel,
        "pessoa_juridica_id"
      ),
      contatos_pessoas_juridicas: this.hasMany(
        ContatoPessoaJuridica,
        "pessoa_juridica_id"
      ),
      codigos_cnae_pessoas_juridicas: this.hasMany(
        CodigoCnaePessoaJuridica,
        "pessoa_juridica_id"
      ),
      razaoSocialOriginal: this.attr(null), // variavel para que saibamos se razao social foi alterado depois
      nomeFantasiaOriginal: this.attr(null), // variavel para que saibamos se nome fantasia foi alterado depois
    };
  }

  get dataConstituicaoToDate() {
    if (!this.data_constituicao) return null;

    return parseISO(this.data_constituicao);
  }

  get dataInicioAreaInternacionalToDate() {
    if (!this.data_inicio_area_internacional) return null;

    return parseISO(this.data_inicio_area_internacional);
  }

  // lifecycle hooks

  static beforeCreate(model) {
    if (model.razao_social_atual && model.razao_social_atual.id) {
      model.razaoSocialOriginal = model.razao_social_atual.razao_social;
    }

    if (model.nome_fantasia_atual && model.nome_fantasia_atual.id) {
      model.nomeFantasiaOriginal = model.nome_fantasia_atual.nome_fantasia;
    }else{
      model.nomeFantasiaOriginal = null;
    }
  }
}
