import { render, staticRenderFns } from "./LinguagensForm.vue?vue&type=template&id=f2419b14&scoped=true&"
import script from "./LinguagensForm.vue?vue&type=script&lang=js&"
export * from "./LinguagensForm.vue?vue&type=script&lang=js&"
import style0 from "./LinguagensForm.vue?vue&type=style&index=0&id=f2419b14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2419b14",
  null
  
)

export default component.exports