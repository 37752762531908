import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

class EstadosApi {
  cancelTokens = {
    getEstados: null,
    getMunicipios: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getEstados() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getEstados = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/estados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getEstados = null;

    return data;
  }

  async getMunicipios(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getMunicipios = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/estados/${id}/municipios`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMunicipios = null;

    return data;
  }
}

export default EstadosApi;
