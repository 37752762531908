<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Tipos de Investimento</h1>
      <BotaoLink
        class="bt-novo"
        :to="{ name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading
      class="carregando1"
      v-if="carregando && !erroCarregando"
    ></AppLoading>
    <AppErro v-if="!carregando && erroCarregando"></AppErro>
    <template v-if="!carregando && !erroCarregando">
      <div>
        <div>
          <div class="campo-header-resultados">
          <AppTotalResultadosEncontrados :total-resultados="totalResultados" />
            <AppResultadosPorPagina
              :per-page="perPage"
              @per-page-changed="perPageChanged"
            />
            <!--<h4>{{ totalResultados }} resultados encontrados</h4>-->
          </div>

          <TiposInvestimentoResultados
            :tiposInvestimento="tiposInvestimento"
            @removerTipoInvestimento="removerTipoInvestimento"
          />
        </div>
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          >
          </Paginate>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TiposInvestimentoApi from "../../../services/api/TiposInvestimentoApi";
import { DEFAULT_PER_PAGE } from "../../../config";
import Paginate from "vuejs-paginate";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina";

import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados";

import TiposInvestimentoResultados from "../../../components/investe_goias/tipos_investimento/TiposInvestimentoResultados.vue";

import BotaoLink from "../../../components/botoes/BotaoLink.vue";
import {
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW,
} from "../../../router/routeNames";

export default {
  name: "TiposInvestimentoIndex",
  components: {
    Paginate,
    AppLoading,
    AppErro,
    AppResultadosPorPagina,
    BotaoLink,
    TiposInvestimentoResultados,
    AppTotalResultadosEncontrados,
  },
  metaInfo: {
    title: "Tipos de Investimento - Investe Goiás",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      carregando: false,
      erroCarregando: false,
      tiposInvestimento: [],
      totalResultados: 0,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW,
    };
  },
  created() {
    this.tiposInvestimentoApi = new TiposInvestimentoApi();

    this.carregandoDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregandoDados();
      }
    },
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const res = await this.tiposInvestimentoApi.getTiposInvestimento({
          page: this.page,
          perPage: this.perPage,
        });
        this.totalResultados = res.total_resultados;
        this.tiposInvestimento = res.resultados;

        this.carregando = false;
      } catch (err) {
        console.log(err);

        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    async removerTipoInvestimento(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }
        this.carregando = true;

        await this.tiposInvestimentoApi.removerTipoInvestimento(id);

        if (
          this.tiposInvestimento &&
          this.tiposInvestimento.length == 1 &&
          this.page > 1
        ) {
          this.page--;
          this.$router.push({
            name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregandoDados();
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.carregando = false;
      } catch (err) {
        console.log(err, err.response);
        this.$swal({
          position: "center",
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
        query: {
          page: this.page,
          per_page: this.perPage,
        },
      });
    },

    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  gap: 1.5rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}

.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
