<template>
  <div class="caixa-dados">
    <header class="header-caixa">
      <b>{{ index }}</b> - {{ protocoloIntencao.numero }} (
      {{ protocoloIntencao.status_label }})
    </header>

    <div>
      <div class="row3">
        <p class="item" v-if="protocoloIntencao.data_inicio">
          <strong>Data de inicio: </strong
          >{{ protocoloIntencao.data_inicio | toDate | naoInformado }}
        </p>
        <p class="item">
          <strong>Tipo de Projeto: </strong>{{ protocoloIntencao.tipo_projeto }}
        </p>
        <p class="item">
          <strong>Processo SEI: </strong
          >{{ protocoloIntencao.processo_sei | naoInformado }}
        </p>
      </div>
    </div>
    <p class="item">
      <strong>Descrição do Projeto: </strong
      >{{ protocoloIntencao.descricao_projeto }}
    </p>

    <router-link
      class="bt-show-modal"
      :to="{
        name: PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
        params: {
          id: $route.params.id,
          protocoloId: protocoloIntencao.id,
        },
        hash: '#protocolo',
      }"
      ><EyeIcon /> Visualizar</router-link
    >
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";

import { PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO } from "../../../../router/routeNames";

import EyeIcon from "vue-material-design-icons/Eye.vue";

export default {
  name: "PessoasJuridicasItemProtocoloIntencao",
  components: {
    EyeIcon,
  },
  props: {
    protocoloIntencao: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
    };
  },
};
</script>

<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: 'Lato-Bold';
  }
  .item {
    padding: 0 1rem;
  }
  .bt-show-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color:  var(--verde-escuro-gaia);
    border-radius: 2px;
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem 2rem;
    width: 7rem;
    margin: 0 auto;
    font-family: 'Lato-Regular';
    svg {
      fill: white;
      margin-right: 0.5rem;
    }
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
    gap: .5rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      margin-bottom: 0;
    }
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
}
</style>
