import * as routeNames from '../../routeNames'

import * as permissoes from '../../../permissoes';

import ContatosSiteIndex from '../../../views/investe_goias/contatos_site/ContatosSiteIndex';
import ContatosSiteShow from '../../../views/investe_goias/contatos_site/ContatosSiteShow';
import ContatosSiteForm from '../../../views/investe_goias/contatos_site/ContatosSiteForm';

const rotasContatosSite = [
  {
    name: routeNames.INVESTE_GOIAS_CONTATOS_SITE_INDEX,
    component: ContatosSiteIndex,
    path: '/investe-goias/contatos',
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.CONTATOS_SITE_PERMISSAO],
      breadcrumb: { 
        label: 'Contatos',
      }
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_CONTATOS_SITE_SHOW,
    component: ContatosSiteShow,
    path: '/investe-goias/contatos/:id(\\d+)',
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.CONTATOS_SITE_PERMISSAO],
      breadcrumb: { 
        label: 'Exibir Contato',
        parent: routeNames.INVESTE_GOIAS_CONTATOS_SITE_INDEX
      }
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_CONTATOS_SITE_EDIT,
    component: ContatosSiteForm,
    path: '/investe-goias/contatos/:id/edit',
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.CONTATOS_SITE_PERMISSAO],
      breadcrumb: { 
        label: 'Editar Contato',
        parent: routeNames.INVESTE_GOIAS_CONTATOS_SITE_INDEX
      }
    },
  },
]

export default rotasContatosSite
