import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class ContatoSiteApi {
  cancelTokens = {
    getContatosSite: null,
    getStatusContato: null,
    getContato: null,
    salvarContato: null,
    removerContato: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getContatosSite({
    page = 1,
    per_page,
    razao_social,
    cnpj,
    criado_de,
    criado_ate,
    status_contato,
  }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getContatosSite = source;

    const searchString = queryString.stringify({
      page,
      per_page,
      razao_social,
      cnpj,
      criado_de,
      criado_ate,
      status_contato,
    });
    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/contatos_site?${searchString}`,
      {
        cancelToken: source.token,
      }
    );
    this.cancelTokens.getContatosSite = null;
    return data;
  }

  async getStatusContato() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getStatusContato = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/contatos_site/status_contatos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatusContato = null;
    return data;
  }

  async getContato(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getContato = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/contatos_site/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getContato = null;

    return data;
  }

  async salvarContato({ id, observacao, status_contato }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarContato = source;

    const { data } = await axiosAuthenticated.put(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/contatos_site/${id}`,
      {
        status_contato,
        observacao,
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarContato = null;

    return data;
  }

  async removerContato(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.removerContato = source;

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/contatos_site/${id}`
    );

    this.cancelTokens.removerContato = null;

    return data;
  }
}

export default ContatoSiteApi;
