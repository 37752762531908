var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-conteudo centro"},[_c('div',{staticClass:"form-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$route.name === _vm.INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW ? "Adicionar Tipo de investimento" : "Editar Tipo de investimento")+" ")]),(
        _vm.$route.name === _vm.INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT &&
          !_vm.carregando &&
          !_vm.erroCarregando &&
          !_vm.carregandoFiltro &&
          !_vm.erroCarregandoFiltro
      )?_c('BotaoVisualizarForm',{attrs:{"to":{
        name: _vm.INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
        params: { id: _vm.$route.params.id },
      }}}):_vm._e()],1),(
      _vm.carregando &&
        !_vm.erroCarregando &&
        _vm.carregandoFiltro &&
        !_vm.erroCarregandoFiltro
    )?_c('AppLoading',{staticClass:"carregando1"}):_vm._e(),(
      !_vm.carregando &&
        !_vm.carregandoFiltro &&
        (_vm.erroCarregando || _vm.erroCarregandoFiltro)
    )?_c('AppErro'):_vm._e(),(_vm.errosSalvando && _vm.errosSalvando.length > 0)?_c('AppErrosFormularios',{attrs:{"erros":_vm.errosSalvando}}):_vm._e(),(
      !_vm.carregando &&
        !_vm.erroCarregando &&
        !_vm.carregandoFiltro &&
        !_vm.erroCarregandoFiltro
    )?_c('TraducoesTipoInvestimento',{attrs:{"filtro":_vm.filtro,"salvando":_vm.salvando},on:{"submit":_vm.salvarDados},model:{value:(_vm.tipoInvestimento.tipos_investimento_traducao),callback:function ($$v) {_vm.$set(_vm.tipoInvestimento, "tipos_investimento_traducao", $$v)},expression:"tipoInvestimento.tipos_investimento_traducao"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }