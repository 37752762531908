<script>
import KeepableSlot from "./KeepableSlot";
import { getUsuario } from "../services/Auth";

// tabs é um array de objetos no formato:
// {
//     id: 1,
//     label: 'Tab 1',
//     permissoes: ['permissao1', 'permissao2'],
//     disabled: false,
//     async onChange, // funcao asincrona que é executada quando a tab atual vai ser trocada. Retorna true ou false. Caso retorne false, a troca é cancelada
// }
export default {
  name: "AppTabs",
  components: {
    KeepableSlot,
  },
  model: {
    prop: "currentTab",
    event: "change-tab",
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: Object,
    },
    keepAlive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usuario: getUsuario(),
    };
  },
  methods: {
    async onTabSelected(tab) {
      if (this.currentTab.onChange) {
        //se funcao on change foi fornecida para tab atual
        const result = await this.currentTab.onChange();
        if (!result) return;
      }
      this.$emit("change-tab", tab);
    },
    renderTabsMobile() {
      return (
        <div class="select">
          <select
            onChange={(event) =>
              this.onTabSelected(
                this.tabs.find((tab) => tab.id == event.target.value)
              )
            }
          >
            {this.tabsComPermissao.map((tab) => (
              <option value={tab.id} key={tab.id} disabled={tab.disabled}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>
      );
    },
    renderTabsDesktop() {
      return (
        <div class="grupo-tabs">
          {this.tabsComPermissao.map((tab) => (
            <button
              key={tab.id}
              onClick={() => this.onTabSelected(tab)}
              class={{ "tab-ativa": this.currentTab.id == tab.id }}
              disabled={tab.disabled}
              type="button"
            >
              <p>{tab.label}</p>
              <span></span>
            </button>
          ))}
        </div>
      );
    },
    renderContent(h) {
      if (this.currentTab.disabled) return null;

      const child = this.$scopedSlots[this.currentTab.id] && this.$scopedSlots[this.currentTab.id]();
      if(!child) return null;

      if (this.keepAlive) {
        return (
          <keep-alive>
            {h(
              "KeepableSlot",
              { name: child[0].tag, key: child[0].tag },
              child
            )}
          </keep-alive>
        );
      } else {
        return h("div", { key: this.currentTab.id }, child);
      }
    },
  },
  computed: {
    tabsComPermissao() {
      if (this.tabs.length == 0) {
        return this.tabs;
      }

      //por precaucao, se nao houver objeto usuario, nao vamos exibir tab nenhuma
      if (!this.usuario) {
        return [];
      }

      return this.tabs.filter((tab) => {
        return tab.permissoes.every((t) =>
          this.usuario.funcionalidades.includes(t)
        );
      });
    },
    tabLocal() {
      if (
        !this.currentTab &&
        this.tabsComPermissao &&
        this.tabsComPermissao.length > 0
      ) {
        return this.tabsComPermissao[0];
      }
      return this.currentTab;
    },
  },
  render(h) {
    return (
      <div>
        {this.renderTabsMobile()}
        {this.renderTabsDesktop()}
        {this.renderContent(h)}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.select {
  margin: 1rem 0 1rem;
  select {
    height: 2rem;
    width: 100%;
    background-color: white;
    font-size: 1rem;
    color: black;
    padding-left: 0.5rem;
    border-radius: 2px;
  }
}
.grupo-tabs {
  display: none;
}
@media screen and (min-width: "50em") {
  .select {
    display: none;
  }
  .grupo-tabs {
    display: flex;
    background-color: var(--verde-escuro-gaia);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    max-width: 51rem;
    button {
      background-color: transparent;
      font-size: 1rem;
      border: none;
      color: white;
      position: relative;
      cursor: pointer;
      padding: 0.5rem 1rem;
      p {
        color: white;
      }
    }
    button:disabled {
      cursor: default;
      p {
        color: rgb(148, 148, 148);
      }
    }
    p {
      margin: 0;
      position: relative;
      z-index: 100;
      font-family: "Lato-Regular";
    }
    span {
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      display: block;
      background-color: white;
      border-radius: 2px;
      height: 100%;
      width: 0;
    }
    .tab-ativa {
      p {
        color: var(--verde-escuro-gaia);
        font-family: "Lato-Bold";
      }
      span {
        width: 100%;
      }
    }
  }
}
</style>
