<template>
  <div>
    <MenuResponsive
      class="menuresponsivo"
      :menu="menu"
      :nome="nome"
      :version="version"
      @logout="logout"
    ></MenuResponsive>
    <MenuDesk
      class="menudesk"
      :menu="menu"
      :nome="nome"
      :version="version"
      @logout="logout"
    ></MenuDesk>
  </div>
</template>

<script>
import { LOGIN_ROUTE } from "../../router/routeNames";

import MenuResponsive from "./TheMenuResponsive.vue";
import MenuDesk from "./TheMenuDesk.vue";

import menu from "../../router/menu";
import * as Auth from "../../services/Auth";

const get = require("lodash.get");

export default {
  name: "TheMenu",
  components: {
    MenuDesk,
    MenuResponsive,
  },
  data() {
    const usuario = Auth.getUsuario();

    const filtrarRotas = (item) => {
      if (item.routeName) {
        const route = this.$router.options.routes.find(
          (route) => route.name == item.routeName
        );
        const requerPermissao = get(route, "meta.requerPermissao", false);
        const permissoes = get(route, "meta.permissoes", []);

        if (
          route &&
          (!requerPermissao ||
            permissoes.every((permissao) =>
              usuario.funcionalidades.includes(permissao)
            ))
        ) {
          return item;
        }
        return null;
      } else if (item.routeList) {
        const itemFiltrado = {
          ...item,
          routeList: item.routeList.reduce((acc, route) => {
            const rotaFiltrada = filtrarRotas(route);

            if (rotaFiltrada) return acc.concat(rotaFiltrada);

            return acc;
          }, []),
        };

        if (
          itemFiltrado &&
          itemFiltrado.routeList &&
          itemFiltrado.routeList.length > 0
        )
          return itemFiltrado;

        return null;
      }
      return null;
    };

    let menuPermitido = menu.reduce((accumulator, element) => {
      const rotaFiltrada = filtrarRotas(element);

      if (rotaFiltrada && (rotaFiltrada.routeName || rotaFiltrada.routeList))
        return accumulator.concat(rotaFiltrada);

      return accumulator;
    }, []);

    return {
      menu: menuPermitido,
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    logout() {
      Auth.logout();

      this.$router.replace({ name: LOGIN_ROUTE });
    },
  },
  computed: {
    nome() {
      if (Auth.getUsuario()) {
        return Auth.getUsuario().nome;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.menudesk {
  display: none;
}
@media screen and (min-width: "55em") {
  .menuresponsivo {
    display: none;
  }
  .menudesk {
    display: block;
  }
}
</style>
