import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class TiposAreaApi {

    cancelTokens = {
        getTiposArea: null,
        getTipoArea: null,
        salvarTipoArea: null,
        removerTipoArea: null,
    };

    async getTiposArea({ page, perPage }) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getTiposArea = source;

        const searchString = queryString.stringify({
            page: page,
            per_page: perPage
        });

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_area?${searchString}`,
            {
                cancelToken: source.token
            }
        );

        this.cancelTokens.getTiposArea = null;
        return data;
    }

    async getTipoArea(id) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getTipoArea = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_area/${id}`,
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.getTipoArea = null;
        return data;
    }

    async salvarTipoArea({ id, nome }) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.salvarTipoArea = source;
        let method;
        let url;

        if (id) {
            method = axiosAuthenticated.put;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_area/${id}`
        } else {
            method = axiosAuthenticated.post;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_area`
        }

        const { data } = await method(url, { id, nome },
            {
                cancelToken: source.token
            }
        );

        this.cancelTokens.salvarTipoArea = null;
        return data;
    }

    async removerTipoArea(id) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.removerTipoArea = source;

        const { data } = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_area/${id}`
        );

        this.cancelTokens.removerTipoArea = null;
        return data;
    }
}

export default TiposAreaApi;
