<template>
  <div>
    <form @submit="salvar">
      <div>
        <div class="caixa-form">
          <AppErrosFormularios
            v-if="!salvando && errosSalvar && errosSalvar.length > 0"
            :erros="errosSalvar"
          />
          <h4>Dados básicos</h4>
          <div class="row2-1">
            <div class="item">
              <label>CNPJ: <span>*</span></label>
              <TheMask
                type="text"
                mask="##.###.###/####-##"
                :value="pessoaJuridica.cnpj"
                @input="(value) => updateValuePessoaJuridica('cnpj', value)"
              />
            </div>
            <div class="item">
              <label>Razão social: <span>*</span></label>
              <input
                type="text"
                :value="pessoaJuridica.razao_social_atual.razao_social"
                @input="(event) => updateRazaoSocial(event.target.value)"
              />
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>Nome fantasia:</label>
              <input
                type="text"
                :value="pessoaJuridica.nome_fantasia_atual.nome_fantasia"
                @input="(event) => updateNomeFantasia(event.target.value)"
              />
            </div>
            <div class="item">
              <label>Tipo: <span>*</span></label>
              <select
                @change="
                  (event) =>
                    updateValuePessoaJuridica('tipo', event.target.value)
                "
              >
                <option :selected="!pessoaJuridica.tipo" disabled>
                  Selecione
                </option>
                <option
                  v-for="tipo in tipos"
                  :key="tipo.value"
                  :value="tipo.value"
                  :selected="tipo.value === pessoaJuridica.tipo"
                >
                  {{ tipo.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>Site: </label>
              <input
                type="text"
                :value="pessoaJuridica.site"
                @input="
                  (event) =>
                    updateValuePessoaJuridica('site', event.target.value)
                "
              />
            </div>

            <div class="item">
              <label>Email: </label>
              <input
                type="text"
                :value="pessoaJuridica.email"
                @input="
                  (event) =>
                    updateValuePessoaJuridica('email', event.target.value)
                "
              />
            </div>
          </div>
          <div class="item">
            <label>Observação: </label>
            <textarea
              :value="pessoaJuridica.observacao"
              @input="
                (event) =>
                  updateValuePessoaJuridica('observacao', event.target.value)
              "
            ></textarea>
          </div>
          <template v-if="pessoaJuridica.tipo === 'empresa'">
            <div class="caixa-form-bco">
              <div class="row4">
                <div class="item">
                  <label>CNPJ empresa matriz:</label>
                  <TheMask
                    type="text"
                    mask="##.###.###/####-##"
                    :value="pessoaJuridica.cnpj_empresa_matriz"
                    @input="
                      (value) =>
                        updateValuePessoaJuridica('cnpj_empresa_matriz', value)
                    "
                  />
                </div>

                <div class="item">
                  <label>Data de constituição:</label>
                  <DatePicker
                    v-model="dataConstituicao"
                    @change="
                      (value) =>
                        updateValuePessoaJuridica('data_constituicao', value)
                    "
                    format="DD/MM/YYYY"
                  />
                </div>

                <div class="item">
                  <label>Classificação: </label>
                  <select
                    @change="
                      (event) =>
                        updateValuePessoaJuridica(
                          'classificacao',
                          event.target.value
                        )
                    "
                  >
                    <option :selected="!pessoaJuridica.classificacao" disabled>
                      Selecione
                    </option>
                    <option
                      v-for="classificacao in classificacoes"
                      :key="classificacao.value"
                      :value="classificacao.value"
                      :selected="
                        classificacao.value === pessoaJuridica.classificacao
                      "
                    >
                      {{ classificacao.label }}
                    </option>
                  </select>
                </div>
                <div class="item">
                  <label>Registro de junta comércial: </label>
                  <input
                    type="text"
                    :value="pessoaJuridica.reg_junta_comercial"
                    @input="
                      (event) =>
                        updateValuePessoaJuridica(
                          'reg_junta_comercial',
                          event.target.value
                        )
                    "
                  />
                </div>
              </div>
              <div class="item">
                <label>Objetivo social: </label>
                <input
                  type="text"
                  :value="pessoaJuridica.objetivo_social"
                  @input="
                    (event) =>
                      updateValuePessoaJuridica(
                        'objetivo_social',
                        event.target.value
                      )
                  "
                />
              </div>
              <br />
              <PessoasJuridicasFormularioCodigoCnae
                :pessoa-juridica-id="pessoaJuridica.id"
              />
            </div>
          </template>
          <div v-if="pessoaJuridica.tipo === 'empresa'">
            <br />
            <h4>Dados adicionais:</h4>
            <div class="item">
              <div class="checkbox">
                <label class="check path">
                  <input
                    type="checkbox"
                    name="atua_area_internacional"
                    :value="pessoaJuridica.atua_area_internacional"
                    @change="
                      (event) => {
                        updateValueCheckbox(event);
                      }
                    "
                    :checked="pessoaJuridica.atua_area_internacional"
                    :true-value="true"
                    :false-value="false"
                  />
                  <svg viewBox="0 0 21 21">
                    <path
                      d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                    ></path>
                  </svg>
                  <span> Atua na área internacional?</span>
                </label>
              </div>
            </div>

            <div class="row3">
              <div class="item" v-if="pessoaJuridica.atua_area_internacional">
                <label> Data de início na área internacional:</label>
                <DatePicker
                  v-model="dataInicioAreaInternacional"
                  @change="
                    (value) =>
                      updateValuePessoaJuridica(
                        'data_inicio_area_internacional',
                        value
                      )
                  "
                  format="DD/MM/YYYY"
                />
              </div>

              <div class="item">
                <label>Faturamento: </label>
                <CurrencyInput
                  :value="pessoaJuridica.faturamento"
                  @input="
                    (value) => updateValuePessoaJuridica('faturamento', value)
                  "
                />
              </div>

              <div class="item">
                <label>Número de funcionários: </label>
                <input
                  type="number"
                  :value="pessoaJuridica.num_funcionarios"
                  @input="
                    (event) =>
                      updateValuePessoaJuridica(
                        'num_funcionarios',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PessoasJuridicasFormularioResponsaveis
        :pessoa-juridica-id="pessoaJuridica.id"
      />
      <PessoasJuridicasFormularioContatos
        :pessoa-juridica-id="pessoaJuridica.id"
      />
      <PessoasJuridicasFormularioEnderecos
        :pessoa-juridica-id="pessoaJuridica.id"
        :tipos="tiposEnderecos"
        :estados="estados"
        :paises="paises"
      />
      <div class="botao-salvar">
        <BotaoSalvar v-if="!salvando" />
        <AppLoading v-if="salvando" texto="" size="medium" />
      </div>
    </form>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import PessoasJuridicasFormularioCodigoCnae from "./PessoasJuridicasFormularioCodigoCnae";
import PessoasJuridicasFormularioContatos from "./PessoasJuridicasFormularioContatos";
import PessoasJuridicasFormularioEnderecos from "./PessoasJuridicasFormularioEnderecos";
import PessoasJuridicasFormularioResponsaveis from "./PessoasJuridicasFormularioResponsaveis";

import AppErrosFormularios from "../../../AppErrosFormularios";
import AppLoading from "../../../AppLoading";

import PessoaJuridica from "../../../../store/models/PessoaJuridica";

import BotaoSalvar from "../../../botoes/BotaoSalvar";

import {
  PESSOA_JURIDICA_NEW,
  PESSOA_JURIDICA_EDIT,
} from "../../../../router/routeNames";

import {
  removeIdsGerados,
  preencherNomeFantasiaAtual,
  preencherRazaoSocialAtual,
} from "../../../../store/modules/pessoa_juridica/helpers";

import PessoasJuridicasApi from "../../../../services/api/PessoasJuridicasApi";
import processarMensagemErro from "../../../../helpers/processarMensagemErro";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import Endereco from "../../../../store/models/Endereco";
import ContatoPessoaJuridica from "../../../../store/models/ContatoPessoaJuridica";
import PessoaResponsavel from "../../../../store/models/PessoaResponsavel";
import CodigoCnaePessoaJuridica from "../../../../store/models/CodigoCnaePessoaJuridica";

export default {
  name: "PessoasJuridicasFormularioDadosGerais",
  props: {
    pessoaJuridica: {
      type: Object,
      required: true,
    },
    tipos: {
      type: Array,
      required: true,
    },
    classificacoes: {
      type: Array,
      required: true,
    },
    tiposEnderecos: {
      type: Array,
      required: true,
    },
    estados: {
      type: Array,
      required: true,
    },
    paises: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppErrosFormularios,
    AppLoading,
    BotaoSalvar,
    DatePicker,
    PessoasJuridicasFormularioCodigoCnae,
    PessoasJuridicasFormularioContatos,
    PessoasJuridicasFormularioEnderecos,
    PessoasJuridicasFormularioResponsaveis,
    TheMask,
  },
  data() {
    return {
      dataConstituicao: this.pessoaJuridica.dataConstituicaoToDate,
      dataInicioAreaInternacional: this.pessoaJuridica
        .dataInicioAreaInternacionalToDate,
      salvando: false,
      errosSalvar: null,
      tipoOriginal: null,
    };
  },
  created() {
    this.pessoasJuridicasApi = new PessoasJuridicasApi();
    this.tipoOriginal = this.pessoaJuridica ? this.pessoaJuridica.tipo : null;
  },
  watch: {
    "pessoaJuridica.data_constituicao": function() {
      //quando o props muda, alteramos o v-model local de data
      this.dataConstituicao = this.pessoaJuridica.dataConstituicaoToDate;
    },
    "pessoaJuridica.data_inicio_area_internacional": function() {
      //quando o props muda, alteramos o v-model local de data
      this.dataInicioAreaInternacional = this.pessoaJuridica.dataInicioAreaInternacionalToDate;
    },
  },
  methods: {
    async salvar(event) {
      event.preventDefault();

      let deveDeletarAssociacoes = false;
      try {
        if (
          this.tipoOriginal == "empresa" &&
          this.pessoaJuridica.tipo != this.tipoOriginal
        ) {
          const result = await this.$swal.fire({
            title:
              "Você tem certeza que deseja alterar o tipo de Pessoa Jurídica?",
            text:
              "Os registros de atração de investimentos e protocolos de Intenção associados serão excluidos",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#054700",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            allowOutsideClick: false,
          });

          if (!result.isConfirmed) {
            return;
          }

          deveDeletarAssociacoes = true;
        }

        if (
          this.pessoaJuridica.razaoSocialOriginal &&
          this.pessoaJuridica.razao_social_atual.razao_social !=
            this.pessoaJuridica.razaoSocialOriginal
        ) {
          const result = await this.$swal.fire({
            title:
              "Você deseja cadastrar uma nova Razão social ou atualizar a atual?",
            text:
              "Ao criar uma nova razão social, a antiga será armazenada como histórico",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#054700",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Criar nova",
            cancelButtonText: "Atualizar atual",
            allowOutsideClick: false,
          });

          if (result.isConfirmed) {
            //criar nova
            await PessoaJuridica.update({
              where: this.pessoaJuridica.id,
              data: {
                razao_social_atual: {
                  ...this.pessoaJuridica.razao_social_atual,
                  id: null,
                },
              },
            });
          }
        }

        if (
          this.pessoaJuridica.nomeFantasiaOriginal &&
          this.pessoaJuridica.nome_fantasia_atual.nome_fantasia !=
            this.pessoaJuridica.nomeFantasiaOriginal &&
          this.pessoaJuridica.nome_fantasia_atual.nome_fantasia.trim().length >
            0
        ) {
          const result = await this.$swal.fire({
            title:
              "Você deseja cadastrar um novo Nome Fantasia ou atualizar o atual?",
            text:
              "Ao criar um novo Nome Fantasia, o antigo será armazenado como histórico",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#054700",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Criar nova",
            cancelButtonText: "Atualizar atual",
            allowOutsideClick: false,
          });

          if (result.isConfirmed) {
            //criar nova
            await PessoaJuridica.update({
              where: this.pessoaJuridica.id,
              data: {
                nome_fantasia_atual: {
                  ...this.pessoaJuridica.nome_fantasia_atual,
                  id: null,
                },
              },
            });
          }
        }

        this.salvando = true;
        this.errosSalvar = null;

        const pessoaJuridicaAttributes = {
          ...this.pessoaJuridica,
          id: this.pessoaJuridica._persistido ? this.pessoaJuridica.id : null,
          enderecos_attributes: this.pessoaJuridica.enderecos.map((endereco) =>
            removeIdsGerados(endereco)
          ),
          pessoas_responsaveis_attributes: this.pessoaJuridica.pessoas_responsaveis.map(
            (pessoa) => {
              const pessoaFisicaSemIdsGerados = pessoa.pessoa_fisica
                ? removeIdsGerados(pessoa.pessoa_fisica)
                : null;
              return {
                ...removeIdsGerados(pessoa),
                pessoa_fisica_id: pessoaFisicaSemIdsGerados?.id,
                pessoa_fisica_attributes: pessoaFisicaSemIdsGerados,
              };
            }
          ),
          codigos_cnae_pessoas_juridicas_attributes: this.pessoaJuridica.codigos_cnae_pessoas_juridicas.map(
            (codigoPessoa) => removeIdsGerados(codigoPessoa)
          ),
          contatos_pessoas_juridicas_attributes: this.pessoaJuridica.contatos_pessoas_juridicas.map(
            (contatoPessoa) => removeIdsGerados(contatoPessoa)
          ),
        };

        const pessoaJuridicaAtualizado = await this.pessoasJuridicasApi.salvarPessoaJuridica(
          pessoaJuridicaAttributes
        );

        preencherNomeFantasiaAtual(pessoaJuridicaAtualizado);
        preencherRazaoSocialAtual(pessoaJuridicaAtualizado);

        await PessoaJuridica.insertOrUpdate({
          data: pessoaJuridicaAtualizado,
          create: [
            "contatosPessoasJuridicas",
            "pessoasResponsaveis",
            "codigosCnaePessoasJuridicas",
            "enderecos",
          ],
        });

        if (deveDeletarAssociacoes) {
          await AtracaoInvestimento.deleteAll();
          await ProtocoloIntencao.deleteAll();
        }

        // o vuex não apaga as vezes os elementos removidos quando a lista é esvaziada
        // entao faço isso
        await Endereco.delete((endereco) => endereco._destroy);
        await ContatoPessoaJuridica.delete((contato) => contato._destroy);
        await PessoaResponsavel.delete((pessoa) => pessoa._destroy);
        await CodigoCnaePessoaJuridica.delete((codigo) => codigo._destroy);

        const oldId = this.pessoaJuridica.id;

        this.salvando = false;
        this.tipoOriginal = this.pessoaJuridica
          ? this.pessoaJuridica.tipo
          : null;

        this.$swal({
          icon: "success",
          title: "Pessoa jurídica salva com sucesso!",
          timer: 3000,
          showConfirmButton: true,
        });
        if (this.$route.name == PESSOA_JURIDICA_NEW) {
          this.$router.push({
            name: PESSOA_JURIDICA_EDIT,
            params: { id: pessoaJuridicaAtualizado.id },
          });
          await PessoaJuridica.delete(oldId);
        }
        this.$emit("salvou");
      } catch (err) {
        console.log(err);

        this.$scrollTo("#erro-container");
        this.errosSalvar = processarMensagemErro(err.response);
        this.salvando = false;
      }
    },
    updateValuePessoaJuridica(key, value) {
      PessoaJuridica.update({
        where: this.pessoaJuridica.id,
        data: {
          [key]: value,
        },
      });
    },
    updateValueCheckbox(event) {
      const { checked, name } = event.srcElement;
      this.updateValuePessoaJuridica(name, checked);
    },
    updateRazaoSocial(value) {
      PessoaJuridica.update({
        where: this.pessoaJuridica.id,
        data: {
          razao_social_atual: {
            ...this.pessoaJuridica.razao_social_atual,
            razao_social: value,
          },
        },
      });
    },
    updateNomeFantasia(value) {
      PessoaJuridica.update({
        where: this.pessoaJuridica.id,
        data: {
          nome_fantasia_atual: {
            ...this.pessoaJuridica.nome_fantasia_atual,
            nome_fantasia: value,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 130%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  padding: 0.5rem;
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    textarea {
      height: 5rem;
      font-size: 1rem;
      padding-left: 0.5rem;
    }
    .mx-datepicker {
      width: 100%;
    }
    .checkbox {
      input {
        width: auto;
      }
      span {
        color: #313131;
      }
      .check {
        --background: #fff;
        --border: #d1d6ee;
        --border-hover: #bbc1e1;
        --border-active: #1b5138;
        --tick: #fff;
        position: relative;
        cursor: pointer;
        input,
        svg {
          width: 21px;
          height: 21px;
          display: inline-block;
        }
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0;
          transform: translateY(5px);
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow 0.3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
          &:hover {
            --s: 2px;
            --b: var(--border-hover);
          }
          &:checked {
            --b: var(--border-active);
          }
        }
        svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 21px;
          height: 21px;
          transform: scale(var(--scale, 1)) translateZ(1);
          margin-top: 0rem;
        }
        span {
          transform: translateY(-5px);
          display: inline;
        }
        &.path {
          input {
            &:checked {
              --s: 2px;
              transition-delay: 0.4s;
              & + svg {
                --a: 16.1 86.12;
                --o: 102.22;
              }
            }
          }
          svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
          }
        }
      }
    }
  }
  .caixa-form-bco {
    background-color: white;
    // padding: 1rem;
    border-radius: 2px;
    margin-top: 0.5rem;
  }
}
.botao-salvar {
  text-align: center;
  margin: 2rem 0 3rem;
}
@media screen and(min-width: '50em') {
  .caixa-form {
    padding: 0 0.5rem;
  }
  .row2-1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}

@media screen and(min-width: '62em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
