export const LOGIN_ROUTE = "LOGIN_ROUTE";
export const NO_PERMISSION = "NO_PERMISSION";
export const NAO_ENCONTRADO = "NAO_ENCONTRADO";

//Atracao de investimentos - Municipios

export const ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX =
  "ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX";
export const ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW =
  "ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW";
export const ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW =
  "ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW";
export const ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT =
  "ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT";

//Atracao de investimentos - Beneficios
export const ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX =
  "ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX";
export const ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW =
  "ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW";
export const ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT =
  "ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT";

//Atracao de investimentos - Código Municipio no mapeamento de oportunidades
export const CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX =
  "CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX";
export const CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT =
  "CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT";

//Atracao de investimentos - Tipo de solicitação de empresas

export const ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX =
  "ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX"
export const ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW =
  "ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW"
export const ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT =
  "ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT"


//Atração de investimentos - Meios de Contato
export const ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX =
  "ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX";
export const ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW =
  "ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW";
export const ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_EDIT =
  "ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_EDIT";

//Atração de investimentos - Tipos de area
export const ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX =
  "ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX";
export const ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW =
  "ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW";
export const ATRACAO_INVESTIMENTOS_TIPOS_AREA_EDIT =
  "ATRACAO_INVESTIMENTOS_TIPOS_AREA_EDIT";

//Pessoas juridicas
export const PESSOAS_JURIDICAS_INDEX = "PESSOAS_JURIDICAS_INDEX";
export const PESSOA_JURIDICA_NEW = "PESSOA_JURIDICA_NEW";

export const PESSOA_JURIDICA_SHOW = "PESSOA_JURIDICA_SHOW";
export const PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO = "PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO";
export const PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO = "PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO";
export const PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO = "PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO";

export const PESSOA_JURIDICA_EDIT = "PESSOA_JURIDICA_EDIT";
export const PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO = "PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO";
export const PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO = "PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO";
export const PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO = "PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO";

//Consultas JUCEG - Pessoas juridicas JUCEG
export const CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX = "CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX";
export const CONSULTAS_JUCEG_PESSOAS_JURIDICAS_SHOW = "CONSULTAS_JUCEG_PESSOAS_JURIDICAS_SHOW";

//investe goias - Linguagens

export const INVESTE_GOIAS_LINGUAGENS_INDEX = "INVESTE_GOIAS_LINGUAGENS_INDEX";
export const INVESTE_GOIAS_LINGUAGENS_NEW = "INVESTE_GOIAS_LINGUAGENS_NEW";
export const INVESTE_GOIAS_LINGUAGENS_EDIT = "INVESTE_GOIAS_LINGUAGENS_EDIT";
export const INVESTE_GOIAS_LINGUAGENS_SHOW = "INVESTE_GOIAS_LINGUAGENS_SHOW";

//investe goias - Contatos_Site

export const INVESTE_GOIAS_CONTATOS_SITE_INDEX =
  "INVESTE_GOIAS_CONTATOS_SITE_INDEX";
export const INVESTE_GOIAS_CONTATOS_SITE_SHOW =
  "INVESTE_GOIAS_CONTATOS_SITE_SHOW";
export const INVESTE_GOIAS_CONTATOS_SITE_EDIT =
  "INVESTE_GOIAS_CONTATOS_SITE_EDIT";

//investe goias - Tipos_Investimento

export const INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX = "INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX"
export const INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW = "INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW"
export const INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW = "INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW"
export const INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT = "INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT"

//investe goias - polos_industriais

export const INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX =
  "INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX";
export const INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT =
  "INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT";
export const INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW =
  "INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW";
export const INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW =
  "INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW";

//pessoas fisicas

export const CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX =
  "CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX";
export const CADASTROS_BASICOS_PESSOAS_FISICAS_NEW =
  "CADASTROS_BASICOS_PESSOAS_FISICAS_NEW";
export const CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT =
  "CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT";
export const CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW =
  "CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW";


//Atracao de investimentos - Mapeamento oportunidades
export const ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX =
  "ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX";
export const ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW =
  "ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW";
export const ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT =
  "ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT";