var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-tela"},[_c('div',{staticClass:"modal-caixa"},[_c('router-link',{staticClass:"bt-fechar",attrs:{"to":{
        name: _vm.PESSOA_JURIDICA_SHOW,
        params: { id: _vm.$route.params.id },
        hash: _vm.$route.hash || '#protocolo',
      }}},[_c('IconFechar')],1),(_vm.carregando && !_vm.erro)?_c('div',[_c('AppLoading')],1):_vm._e(),(!_vm.carregando && _vm.erro)?_c('div',[_c('AppErro')],1):_vm._e(),(!_vm.carregando && !_vm.erro)?[_c('h1',[_vm._v("Protocolo de Intenção")]),_c('div',[_c('p',[_c('strong',[_vm._v("Descrição do Projeto: ")]),_vm._v(_vm._s(_vm.protocoloIntencao.descricao_projeto)+" ")]),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("Número: ")]),_vm._v(" "+_vm._s(_vm.protocoloIntencao.numero)+" ")]),_c('p',[_c('strong',[_vm._v("Tipo de Projeto: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.tipo_projeto_label))+" ")]),_c('p',[_c('strong',[_vm._v("Status: ")]),_vm._v(" "+_vm._s(_vm.protocoloIntencao.status_label)+" ")])]),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("Data de início: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm._f("toDate")(_vm.protocoloIntencao.data_inicio)))+" ")]),_c('p',[_c('strong',[_vm._v("Resultado do processo de atração? ")]),_vm._v(" "+_vm._s(_vm.atracaoInvestimento ? "Sim" : "Não")+" ")]),(_vm.atracaoInvestimento)?[_c('p',[_c('strong',[_vm._v("Atração: ")]),_c('router-link',{attrs:{"to":{
                  name: _vm.PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
                  params: {
                    id: _vm.$route.params.id,
                    atracaoId: _vm.atracaoInvestimento.atracao_investimento_id,
                  },
                }}},[_vm._v("Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.atracao_investimento.data_inicio)))])],1)]:_vm._e()],2),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("(Previsão) Empregos diretos gerados: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.empregos_diretos_gerados))+" ")]),_c('p',[_c('strong',[_vm._v("(Previsão) Empregos indiretos gerados: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.empregos_indiretos_gerados))+" ")]),_c('p',[_c('strong',[_vm._v("(Previsão) Investimentos realizados: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm._f("currency")(_vm.protocoloIntencao.investimento_fixo_estimado)))+" ")])]),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("Prazo estimado: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.prazo_estimado_meses))+" ")]),_c('p',[_c('strong',[_vm._v("Linha de produtos: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.linhas_produtos))+" ")]),_c('p',[_c('strong',[_vm._v("Processo SEI: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.processo_sei))+" ")])]),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("Minuta: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.minuta))+" ")]),_c('p',[_c('strong',[_vm._v("Situação da minuta: ")]),_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.situacao_minuta))+" ")])])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"grafico"},[(
              _vm.historicoComDadosEmpregos &&
                _vm.historicoComDadosEmpregos.length > 0
            )?_c('PessoasJuridicasGraficoEmpregos',{attrs:{"empregos-diretos-gerados":_vm.protocoloIntencao.empregos_diretos_gerados,"empregos-indiretos-gerados":_vm.protocoloIntencao.empregos_indiretos_gerados,"historico-protocolo":_vm.historicoComDadosEmpregos}}):_vm._e()],1),_c('div',{staticClass:"grafico"},[(
              _vm.historicoComDadosInvestimento &&
                _vm.historicoComDadosInvestimento.length > 0
            )?_c('PessoasJuridicasGraficoInvestimento',{attrs:{"historico-protocolo":_vm.historicoComDadosInvestimento,"investimento-fixo-estimado":_vm.protocoloIntencao.investimento_fixo_estimado}}):_vm._e()],1)]),_c('AppTabs',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:_vm.tabs[0].id,fn:function(){return [_c('PessoasJuridicasProtocoloListaOrgaosEnvolvidos',{attrs:{"orgaos-envolvidos":_vm.protocoloIntencao.orgaos_envolvidos}})]},proxy:true},{key:_vm.tabs[1].id,fn:function(){return [_c('PessoasJuridicasProtocoloListaArquivos',{attrs:{"arquivos":_vm.arquivos}})]},proxy:true},{key:_vm.tabs[2].id,fn:function(){return [_c('PessoasJuridicasListaHistorico',{attrs:{"historico-protocolos-intencao":_vm.protocoloIntencao.historico_protocolos_intencao}})]},proxy:true}],null,true),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}}),_c('AppAutores',{attrs:{"criado-por":_vm.protocoloIntencao.criado_por,"created-at":_vm.protocoloIntencao.created_at}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }