import ModelPersistido from "./ModelPersistido";
import PessoaJuridica from "./PessoaJuridica";

export default class HistoricoNomeFantasia extends ModelPersistido {
  static entity = "historicoNomesFantasia";

  static fields() {
    return {
      id: this.number(null).nullable(),
      nome_fantasia: this.string(null).nullable(),
      data_inicio: this.attr(null),
      data_fim: this.attr(null),
      pessoa_juridica_id: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
    };
  }
}
