<template>
  <div>
    <div class="row3">
      <div class="item">
        <label>Tipo de Solicitação:<span>*</span> </label>
        <select
          @change="
            (evt) =>
              updateValue(
                'tipo_solicitacao_empresa_atracao_id',
                evt.target.value
              )
          "
          required
        >
          <option
            :value="null"
            disabled
            :selected="!solicitacaoEmpresa.tipo_solicitacao_empresa_atracao_id"
          >
            Selecione
          </option>
          <option
            v-for="tipo in tiposSolicitacao"
            :key="tipo.id"
            :selected="
              solicitacaoEmpresa.tipo_solicitacao_empresa_atracao_id == tipo.id
            "
            :value="tipo.id"
          >
            {{ tipo.nome }}
          </option>
        </select>
      </div>
      <div class="item">
        <label>Concluída: <span>*</span> </label>
        <div class="disp-flex">
          <label>
            <input
              type="radio"
              :value="true"
              :checked="solicitacaoEmpresa.concluida"
              :name="nameConcluida"
              @change="updateValue('concluida', true)"
            />
            <span>Sim</span>
          </label>

          <label>
            <input
              type="radio"
              :value="false"
              :checked="!solicitacaoEmpresa.concluida"
              :name="nameConcluida"
              @change="updateValue('concluida', false)"
            />
            <span>Não</span>
          </label>
        </div>
      </div>

      <div class="item" v-if="solicitacaoEmpresa.concluida">
        <label>Data de conclusão: </label>
        <DatePicker
          v-model="dataConclusao"
          @input="(value) => updateValue('data_conclusao', value)"
          format="DD/MM/YYYY"
        />
      </div>
    </div>
    <div class="item">
      <label>Observação: </label>
      <textarea
        @input="(evt) => updateValue('observacao', evt.target.value)"
        :value="solicitacaoEmpresa.observacao"
      ></textarea>
    </div>
    <div class="item" v-if="solicitacaoEmpresa.concluida">
      <label>Detalhes da conclusão: </label>
      <textarea
        @input="(evt) => updateValue('descricao_conclusao', evt.target.value)"
        :value="solicitacaoEmpresa.descricao_conclusao"
      ></textarea>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { parseISO } from "date-fns";

export default {
  name: "PessoasJuridicasSolicitacao",
  model: {
    prop: "solicitacaoEmpresa",
    event: "change",
  },
  props: {
    solicitacaoEmpresa: {
      type: Object,
      required: true,
    },
    tiposSolicitacao: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      nameConcluida: `concluida_${Math.random()}`,
      dataConclusao: null,
    };
  },
  watch: {
    "solicitacaoEmpresa.data_conclusao": {
      immediate: true,
      handler: function (newDate) {
        if (newDate && typeof newDate == "string") {
          this.dataConclusao = parseISO(newDate);
        } else {
          this.dataConclusao = newDate;
        }
      },
    },
    "solicitacaoEmpresa.concluida": {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.updateValue("data_conclusao", null);
        }
      },
    },
  },
  methods: {
    updateValue(key, value) {
      this.$emit("change", {
        ...this.solicitacaoEmpresa,
        [key]: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 0rem 0 1rem;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  textarea{
    height: 5rem;
  }
  select {
    height: 1.9rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    width: 100%;
  }
  .disp-flex {
    display: flex;
    flex-wrap: wrap;
    label {
      display: flex;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-bottom: 0.375em;
      margin: 0 1rem 0 0;
      input {
        position: absolute;
        left: -9999px;
        &:checked + span {
          background-color: #efefef;
          &:before {
            box-shadow: inset 0 0 0 0.4375em var(--verde-gaia);
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 99em;
        transition: 0.25s ease;
        color: black;
        &:hover {
          background-color: #efefef;
        }
        &:before {
          display: flex;
          flex-shrink: 0;
          content: "";
          background-color: #fff;
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          margin-right: 0.375em;
          transition: 0.25s ease;
          box-shadow: inset 0 0 0 0.125em #efefef;
        }
      }
    }
  }
}
@media screen and (min-width: "60rem") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
