<template>
  <div>
    <VueSimpleAutocomplete
      :items="atividadesEconomicas"
      :selected-item="atividadeEconomica"
      @change="buscarAtividadeEconomica"
      @value-selected="onAtividadeEconomicaSelecionada"
      :is-loading="carregando"
      :get-display-value="exibirAtividadeEconomica"
      :min-length="2"
      placeholder="Digite o codigo cnae ou descrição "
    ></VueSimpleAutocomplete>
  </div>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";

import PessoasJuridicasJucegApi from "../../../services/api/PessoasJuridicasJucegApi";

export default {
  name: "PessoasJuridicasJucegAtividadeEconomicaAutocomplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "atividadeEconomica",
    event: "change",
  },
  props: {
    atividadeEconomica: {
      type: Object,
    },
  },
  data() {
    return {
      atividadesEconomicas: [],
      carregando: false,
    };
  },
  created() {
    this.pessoasJuridicasJucegApi = new PessoasJuridicasJucegApi();
  },
  methods: {
    async buscarAtividadeEconomica(termo) {
      try {
        this.atividadesEconomicas = [];
        this.$emit("change", {});

        if (termo == null || termo.length === 0) {
          return;
        }
        this.carregando = true;
        const data =
          await this.pessoasJuridicasJucegApi.getAtividadeEconomicaAutocomplete(
            termo
          );
        this.atividadesEconomicas = data;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
      }
    },
    onAtividadeEconomicaSelecionada(atividadeEconomica) {
      this.$emit("change", atividadeEconomica);
    },
    exibirAtividadeEconomica(atividadeEconomica) {
      if (
        atividadeEconomica &&
        atividadeEconomica !== "" &&
        atividadeEconomica.cnae
      ) {
        return `${atividadeEconomica.cnae} - ${atividadeEconomica.descricao}`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
