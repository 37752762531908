import {
  format,
  parseISO
} from "date-fns";

export default function toDate(value) {
  if (value && value instanceof Date) {
    return format(value, "dd/MM/yyyy");
  } else if (value && (value instanceof String || typeof value == "string")) {
    return format(parseISO(value), "dd/MM/yyyy");
  }
  return "";
}