import ModelPersistido from './ModelPersistido';
import PessoaResponsavel from './PessoaResponsavel';

export default class PessoaFisica extends ModelPersistido {
    static entity = 'pessoasFisicas';

    static fields() {
        return {
            id: this.attr(null).nullable(),
            nome: this.attr(null).nullable(),
            cpf: this.attr(null).nullable(),
            servidor_sic: this.boolean(false),
            pessoas_responsaveis: this.hasMany(PessoaResponsavel, "pessoa_fisica_id")
        }
    }
}