<template>
  <div class="tela-form">
    <div class="caixa-form">
      <div>
        <router-link
          title="Fechar"
          :to="{
            name: ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
            query: { ...$route.query },
          }"
          >&#10005;</router-link
        >
        <h1>
          {{
            $route.name === ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW
              ? "Novo Tipo de Solicitação de Empresa"
              : "Editar Tipo de Solicitação de Empresa"
          }}
        </h1>
      </div>
      <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
      <AppErro v-if="!carregando && erroCarregando" />
      <form v-if="!carregando && !erroCarregando" @submit="salvarDados">
        <AppErrosFormularios
          v-if="!salvando && errosSalvando.length > 0"
          :erros="errosSalvando"
        />
        <div>
          <div class="row2">
            <div class="item">
              <label for="name">Nome: <span>*</span> </label>
              <input
                type="text"
                id="name"
                v-model="tipoSolicitacaoEmpresa.nome"
              />
            </div>
            <div class="item">
              <label>Ativo: <span>*</span> </label>
              <div class="disp-flex">
                <label for="sim">
                  <input
                    type="radio"
                    id="sim"
                    :value="true"
                    v-model="tipoSolicitacaoEmpresa.ativo"
                  />
                  <span>Sim</span>
                </label>
                <label for="nao">
                  <input
                    type="radio"
                    id="nao"
                    :value="false"
                    v-model="tipoSolicitacaoEmpresa.ativo"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
          <div class="botao-salvar">
            <AppLoading v-if="salvando" texto="" size="small" />
            <BotaoSalvar v-if="!salvando" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TiposSolicitacaoEmpresaApi from "../../../../services/api/TiposSolicitacaoEmpresaApi";

import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import AppErrosFormularios from "../../../../components/AppErrosFormularios.vue";

import {
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT,
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";

export default {
  name: "tiposSolicitacaoEmpresaForm",
  components: {
    AppErrosFormularios,
    AppLoading,
    AppErro,
    BotaoSalvar,
  },
  metaInfo() {
    return {
      title: `${
        this.$route.name ===
        ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW
          ? "Novo "
          : "Editar"
      } Tipo de Solicitação Empresa- Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      tipoSolicitacaoEmpresa: null,
      carregando: false,
      erroCarregando: false,
      salvando: false,
      errosSalvando: [],
      ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
      ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT,
      ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
    };
  },
  created() {
    this.tiposSolicitacaoEmpresaApi = new TiposSolicitacaoEmpresaApi();
    if (this.$route.params.id) {
      this.carregarDados();
    } else {
      this.tipoSolicitacaoEmpresa = {
        nome: "",
        ativo: true,
      };
    }
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        this.tipoSolicitacaoEmpresa =
          await this.tiposSolicitacaoEmpresaApi.getTipoSolicitacaoEmpresa(
            this.$route.params.id
          );

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.errosSalvando = [];
        this.tipoSolicitacaoEmpresa =
          await this.tiposSolicitacaoEmpresaApi.salvarDados(
            this.tipoSolicitacaoEmpresa
          );

        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
          query: this.$route.query,
        });
        this.$swal({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Registro Salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
        });
        this.salvando = false;
      } catch (err) {
        console.log(err);
        const { response } = err;

        if (response.data.length > 0) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
        this.salvando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-form {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    min-width: 50%;
    position: relative;
    margin: 0.5rem;
    a {
      text-decoration: none;
      color: silver;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      transition: 0.2s;
    }
    a:hover {
      color: var(--vermelho-gaia);
    }
    h1 {
      color: var(--laranja-gaia);
      margin-top: 2.5rem;
    }
    .item {
      display: grid;
      margin: 1rem 0;
      input {
        height: 1.5rem;
      }
      .disp-flex {
        display: flex;
        label {
          display: flex;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          margin-bottom: 0.375em;
          margin: 0 1rem 0 0;
          input {
            position: absolute;
            left: -9999px;
            &:checked + span {
              background-color: #efefef;
              &:before {
                box-shadow: inset 0 0 0 0.4375em var(--verde-gaia);
              }
            }
          }
          span {
            display: flex;
            color: black;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 99em;
            transition: 0.25s ease;
            &:hover {
              background-color: #efefef;
            }
            &:before {
              display: flex;
              flex-shrink: 0;
              content: "";
              background-color: #fff;
              width: 1.5em;
              height: 1.5em;
              border-radius: 50%;
              margin-right: 0.375em;
              transition: 0.25s ease;
              box-shadow: inset 0 0 0 0.125em #efefef;
            }
          }
        }
      }
    }
    .botao-salvar {
      text-align: center;
      margin: 2rem 0;
    }
  }
}
@media screen and(min-width: '45em') {
  .row2 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
  }
}
</style>
