import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

export default class PessoasJuridicasApi {
  cancelTokens = {
    getPessoasJuridicas: null,
    getPessoaJuridica: null,
    salvarPessoaJuridica: null,
    excluirPessoaJuridica: null,
    getTipos: null,
    getClassificacoes: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getPessoasJuridicas({ page, perPage, ...filtro }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoasJuridicas = source;

    const query = queryString.stringify({ page, per_page: perPage, ...filtro });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoasJuridicas = null;

    return data;
  }

  async getPessoaJuridica(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoaJuridica = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoaJuridica = null;

    return data;
  }

  async salvarPessoaJuridica(pessoaJuridica) {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.salvarPessoaJuridica = source;

      let method;
      let url;
      if (pessoaJuridica.id) {
        method = axiosAuthenticated.put;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas/${pessoaJuridica.id}`;
      } else {
        method = axiosAuthenticated.post;
        url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas`;
      }

      const { data } = await method(url, pessoaJuridica, {
        cancelToken: source.token,
      });

      this.cancelTokens.salvarPessoaJuridica = null;

      return data;
    } catch (err) {
      this.cancelTokens.salvarPessoaJuridica = null;

      throw err;
    }
  }

  async excluirPessoaJuridica(id) {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.excluirPessoaJuridica = source;

      const { data } = await axiosAuthenticated.delete(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas/${id}`,
        {
          cancelToken: source.token,
        }
      );

      this.cancelTokens.excluirPessoaJuridica = null;

      return data;
    } catch (err) {
      this.cancelTokens.excluirPessoaJuridica = null;

      throw err;
    }
  }

  async getTipos() {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.getTipos = source;

      const { data } = await axiosAuthenticated.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas/tipos`,
        {
          cancelToken: source.token,
        }
      );

      this.cancelTokens.getTipos = null;

      return data;
    } catch (err) {
      this.cancelTokens.getTipos = null;

      throw err;
    }
  }

  async getClassificacoes() {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.getClassificacoes = source;

      const { data } = await axiosAuthenticated.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/pessoas_juridicas/classificacoes`,
        {
          cancelToken: source.token,
        }
      );

      this.cancelTokens.getClassificacoes = null;

      return data;
    } catch (err) {
      this.cancelTokens.getClassificacoes = null;

      throw err;
    }
  }
}
