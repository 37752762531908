import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import MapeamentoOportunidadesIndex from "../../../views/atracao_investimentos/cadastros/mapeamento_oportunidades/MapeamentoOportunidadesIndex";
import MapeamentoOportunidadesForm from "../../../views/atracao_investimentos/cadastros/mapeamento_oportunidades/MapeamentoOportunidadesForm";
import MapeamentoOportunidadesShow from "../../../views/atracao_investimentos/cadastros/mapeamento_oportunidades/MapeamentoOportunidadesShow";

const rotasMapeamentoOportunidades = [
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
    component: MapeamentoOportunidadesIndex,
    path: "/atracao-investimentos/cadastros/mapeamento-oportunidades",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.MAPEAMENTO_OPORTUNIDADES_PERMISSOES],
      breadcrumb: {
        label: "Mapeamentos de Oportunidades",
      },
    },
  },
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
    component: MapeamentoOportunidadesForm,
    path: "/atracao-investimentos/cadastros/mapeamento-oportunidades/:id/edit",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.MAPEAMENTO_OPORTUNIDADES_PERMISSOES],
      breadcrumb: {
        label: "Realizar Mapeamento de Oportunidade",
        parent: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
      },
    },
  },
  {
    name: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
    component: MapeamentoOportunidadesShow,
    path: "/atracao-investimentos/cadastros/mapeamento-oportunidades/:id(\\d+)",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.MAPEAMENTO_OPORTUNIDADES_PERMISSOES],
      breadcrumb: {
        label: "Exibir Mapeamento de Oportunidade",
        parent: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
      },
    },
  },
];

export default rotasMapeamentoOportunidades;
