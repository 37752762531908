import { parseISO } from "date-fns";

import ModelPersistido from "./ModelPersistido";
import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";
import PessoaFisica from "./PessoaFisica";
import PessoaJuridica from "./PessoaJuridica";
import MeioContato from "./MeioContato";

export default class ContatoRealizado extends ModelPersistido {
  static entity = "contatosRealizados";

  static state() {
    return {
      carregandoListaContatosRealizados: true,
      erroCarregarListaContatosRealizados: false,
      excluindoContatoRealizado: false,
      errosExcluirContatoRealizado: null,
      carregandoContatoRealizado: false,
      erroCarregarContatoRealizado: false,
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      pessoa_juridica_id: this.attr(null),
      data_contato: this.string(null, convertValidDateToString).nullable(),
      assunto: this.string(null).nullable(),
      etapa_label: this.string(null).nullable(),
      pessoa_entrou_em_contato_id: this.attr(null),
      pessoa_contactada: this.string(null).nullable(),
      conclusao: this.string(null).nullable(),
      meio_contato_id: this.attr(null),
      cargo_do_contato: this.string(null).nullable(),
      etapa_id: this.attr(null),
      etapa_type: this.string(null).nullable(),
      pessoa_entrou_em_contato: this.belongsTo(
        PessoaFisica,
        "pessoa_entrou_em_contato_id"
      ),
      pessoa_juridica: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
      meio_contato: this.belongsTo(MeioContato, "meio_contato_id"),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      criado_por: this.string(null).nullable(),
      atualizado_por: this.string(null).nullable(),
      _destroy: this.boolean(false),
      _completo: this.boolean(false), //indica se o registro foi carregado por completo
    };
  }

  get dataContatoToDate() {
    if (!this.data_contato) return null;

    return parseISO(this.data_contato);
  }
}
