import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class MeiosContatoApi {
    cancelTokens = {
        getMeiosContato: null,
        getMeioContato: null,
        salvarMeioContato: null,
        removerMeioContato: null
    };

    async getMeiosContato({ page, perPage, nome }) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();
        this.cancelTokens.getMeiosContato = source;

        const searchString = queryString.stringify({
            page: page,
            per_page: perPage,
            nome: nome
        });

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/meios_contato?${searchString}`,
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.getMeiosContato = null;
        return data;
    }

    async getMeioContato( id ) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.getMeioContato = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/meios_contato/${id}`,
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.getMeioContato = null;
        return data;
    }

    async salvarMeioContato({ id, nome }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.salvarMeioContato = source;

        let method;
        let url;

        if (id) {
            method = axiosAuthenticated.put;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/meios_contato/${id}`;
        }
        else {
            method = axiosAuthenticated.post;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/meios_contato`
        }

        const { data } = await method(url, { id, nome },
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.salvarMeioContato = null;
        return data;
    }

    async removerMeioContato(id){
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.removerMeioContato = source;

        const { data } = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/meios_contato/${id}`
        );

        this.cancelTokens.removerMeioContato = null;
        return data;
    }
}

export default MeiosContatoApi;
