<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <div class="left">
        <h1>Mapeamento Oportunidades</h1>
      </div>
      <div
        v-if="
          mapeamentoOportunidade
            ? mapeamentoOportunidade.status != 'validado'
            : false
        "
      >
        <BotaoEditarShow
          v-if="mapeamentoOportunidade"
          :to="{
            name: ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
            params: { id: mapeamentoOportunidade.id },
          }"
        />
      </div>
    </div>
    <AppLoading v-if="carregando && !erro" />
    <AppErro v-if="!carregando && erro" />
    <template v-if="!carregando && !erro">
      <div class="caixa-show">
        <div class="grupos grupo1">
          <h4>Dados município</h4>

          <div class="caixa-dados">
            <div class="row3">
              <div class="item">
                <strong>Município: </strong>
                <span>{{ mapeamentoOportunidade.municipio.nome }}</span>
              </div>

              <div class="item">
                <strong>Possui distrito industrial? </strong>
                <span>{{
                  mapeamentoOportunidade.tipo_distrito_industrial
                    ? "Sim"
                    : "Não"
                }}</span>
              </div>
              <div class="item">
                <strong>Tipo distrito industrial: </strong>
                <span>
                  {{
                    mapeamentoOportunidade.tipo_distrito_industrial
                      | naoInformado
                  }}
                </span>
              </div>
            </div>
            <div class="row3">
              <div class="item">
                <strong>Emite Licença ambiental? </strong>
                <span>{{
                  mapeamentoOportunidade.emite_licenca_ambiental ? "Sim" : "Não"
                }}</span>
              </div>

              <div class="item">
                <strong>Status: </strong>
                <span>{{ mapeamentoOportunidade.status }}</span>
              </div>
            </div>
            <div class="item">
              <strong>Potencial turistico: </strong>
              <span>{{
                mapeamentoOportunidade.potencial_turistico | naoInformado
              }}</span>
            </div>
            <div class="item">
              <strong>Informações adicionais: </strong>
              <span>{{
                mapeamentoOportunidade.informacoes_adicionais | naoInformado
              }}</span>
            </div>
            <div class="row4">
              <div class="item">
                <strong>Nome de quem respondeu: </strong>
                <span>{{ mapeamentoOportunidade.nome_pessoa_resposta }}</span>
              </div>

              <div class="item">
                <strong>Email de quem respondeu: </strong>
                <span>{{ mapeamentoOportunidade.email_pessoa_resposta }}</span>
              </div>
              <div class="item">
                <strong>Data da resposta: </strong>
                <span>{{ mapeamentoOportunidade.created_at | toDate }}</span>
              </div>

              <div class="item">
                <strong>Data da atualização: </strong>
                <span>{{ mapeamentoOportunidade.updated_at | toDate }}</span>
              </div>
            </div>
          </div>
        </div>

        <MapeamentoOportunidadesAreasInfo
          :areas_disponiveis="mapeamentoOportunidade.areas_disponiveis"
        />
        <MapeamentoOportunidadesIncentivosInfo
          :incentivos_municipios="mapeamentoOportunidade.incentivos_municipios"
        />
        <MapeamentoOportunidadesInstituicoesCapacitacaoInfo
          :formulario_instituicoes_capacitacao_municipios="
            mapeamentoOportunidade.formulario_instituicoes_capacitacao_municipios
          "
        />
        <MapeamentoOportunidadesPotenciaisEconomicosInfo
          :formulario_municipios_potenciais_economicos="
            mapeamentoOportunidade.formulario_municipios_potenciais_economicos
          "
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";
import MapeamentoOportunidadesApi from "../../../../services/api/MapeamentoOportunidadesApi";

import AppLoading from "../../../../components/AppLoading";
import AppErro from "../../../../components/AppErro";
import BotaoEditarShow from "../../../../components/botoes/BotaoEditarShow.vue";
import MapeamentoOportunidadesAreasInfo from "../../../../components/atracao_investimentos/mapeamento_oportunidades/show/MapeamentoOportunidadesAreasInfo";
import MapeamentoOportunidadesIncentivosInfo from "../../../../components/atracao_investimentos/mapeamento_oportunidades/show/MapeamentoOportunidadesIncentivosInfo";
import MapeamentoOportunidadesInstituicoesCapacitacaoInfo from "../../../../components/atracao_investimentos/mapeamento_oportunidades/show/MapeamentoOportunidadesInstituicoesCapacitacaoInfo";
import MapeamentoOportunidadesPotenciaisEconomicosInfo from "../../../../components/atracao_investimentos/mapeamento_oportunidades/show/MapeamentoOportunidadesPotenciaisEconomicosInfo";

export default {
  name: "MapeamentoOportunidadesShow",
  metaInfo() {
    return {
      title: `Exibir Mapeamento Oportunidade - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      mapeamentoOportunidade: null,
      carregando: true,
      erro: false,
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
    };
  },
  components: {
    AppLoading,
    AppErro,
    BotaoEditarShow,
    MapeamentoOportunidadesAreasInfo,
    MapeamentoOportunidadesIncentivosInfo,
    MapeamentoOportunidadesInstituicoesCapacitacaoInfo,
    MapeamentoOportunidadesPotenciaisEconomicosInfo,
  },
  created() {
    this.mapeamentoOportunidadesApi = new MapeamentoOportunidadesApi();

    this.carregarDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erro = false;

        this.mapeamentoOportunidade = await this.mapeamentoOportunidadesApi.getMapeamentoOportunidade(
          this.$route.params.id
        );

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregando = false;
        this.erro = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  .caixa-show {
    h4 {
      margin: 1.5rem 0 0;
      display: table;
      font-size: 1.4rem;
      color: var(--verde-escuro-gaia);
    }
    h4::after {
      content: "";
      display: block;
      height: 2px;
      min-width: 130%;
      width: 30vw;
      max-width: 80vw;
      background-color: var(--verde-escuro-gaia);
      margin: 0.5rem 0 0rem;
    }
    p {
      margin: 1rem 0;
    }
    .caixa-dados {
      .item {
        display: grid;
        gap: 0.5rem;
        margin: 1.5rem 0;
      }
    }
  }
}
@media screen and(min-width: '50em') {
  .row2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
