<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>
        {{
          $route.name === INVESTE_GOIAS_LINGUAGENS_NEW
            ? "Adicionar Linguagem"
            : "Editar Linguagem"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="$route.name === INVESTE_GOIAS_LINGUAGENS_EDIT"
        :to="{
          name: INVESTE_GOIAS_LINGUAGENS_SHOW,
          params: { id: $route.params.id },
        }"
      />
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />

    <form v-if="!carregando && !erroCarregando" @submit="salvarDados">
      <AppErrosFormularios
        v-if="errosSalvando && errosSalvando.length > 0"
        :erros="errosSalvando"
      />
      <div class="caixa-form">
        <div class="row2">
          <div class="item">
            <label>Nome:</label>
            <input type="text" v-model="linguagem.nome" />
          </div>
          <div class="row2">
            <div class="item">
              <label>Código:</label>
              <input type="text" v-model="linguagem.codigo" />
            </div>
            <div class="item">
              <label> Linguagem Padrão</label>
              <div class="disp-flex">
                <label for="verdadeiro">
                  <input
                    type="radio"
                    id="verdadeiro"
                    value="true"
                    v-model="linguagem.linguagem_padrao"
                  />
                  <span class="text">Sim</span>
                </label>

                <label for="falso">
                  <input
                    type="radio"
                    id="falso"
                    value="false"
                    v-model="linguagem.linguagem_padrao"
                  />
                  <span class="text"> Não </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="botao-salvar">
        <AppLoading v-if="salvando" texto="" />
        <BotaoSalvar v-if="!salvando" />
      </div>
    </form>
  </div>
</template>

<script>
import LinguagensApi from "../../../services/api/LinguagensApi";

import {
  INVESTE_GOIAS_LINGUAGENS_INDEX,
  INVESTE_GOIAS_LINGUAGENS_SHOW,
  INVESTE_GOIAS_LINGUAGENS_EDIT,
  INVESTE_GOIAS_LINGUAGENS_NEW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppErrosFormularios from "../../../components/AppErrosFormularios";
import BotaoSalvar from "../../../components/botoes/BotaoSalvar.vue";
import BotaoVisualizarForm from "../../../components/botoes/BotaoVisualizarForm.vue";

export default {
  name: "LinguagensForm",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    BotaoSalvar,
    BotaoVisualizarForm,
  },
  metaInfo: {
    title: "Editar Linguagem - Investe Goiás",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      linguagem: {
        nome: "",
        codigo: "",
        linguagem_padrao: false,
      },
      carregando: true,
      erroCarregando: false,
      salvando: false,
      errosSalvando: [],
      INVESTE_GOIAS_LINGUAGENS_INDEX,
      INVESTE_GOIAS_LINGUAGENS_SHOW,
      INVESTE_GOIAS_LINGUAGENS_EDIT,
      INVESTE_GOIAS_LINGUAGENS_NEW,
    };
  },
  created() {
    this.linguagensApi = new LinguagensApi();

    if (this.$route.params.id) {
      this.carregandoDados();
    } else {
      this.linguagem = {
        nome: "",
        codigo: "",
        linguagem_padrao: false,
      };
      this.carregando = false;
    }
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.linguagensApi.getLinguagem(
          this.$route.params.id
        );
        this.linguagem = {
          id: data.data.id,
          nome: data.data.nome,
          codigo: data.data.codigo,
          linguagem_padrao: data.data.linguagem_padrao,
        };

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    async salvarDados(event) {
      event.preventDefault();

      try {
        this.salvando = true;
        this.errosSalvando = [];

        this.linguagem = await this.linguagensApi.salvarLinguagem(
          this.linguagem
        );

        this.salvando = false;

        this.$router.push({
          name: INVESTE_GOIAS_LINGUAGENS_SHOW,
          params: { id: this.linguagem.id },
        });
        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Linguagem salva com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);
        this.salvando = false;
        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    gap: 1.5rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  .item {
    display: grid;
    margin: 1rem 0;
    input {
      height: 1.5rem;
    }
    .disp-flex {
      display: flex;
      flex-wrap: wrap;
      .text{
        color: black;
      }
      label {
        display: flex;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;
        margin: 0 1rem 0 0;
        input {
          position: absolute;
          left: -9999px;
          &:checked + span {
            background-color: #efefef;
            &:before {
              box-shadow: inset 0 0 0 0.4375em var(--verde-gaia);
            }
          }
        }
        span {
          display: flex;
          align-items: center;
          padding: 0.375em 0.75em 0.375em 0.375em;
          border-radius: 99em;
          transition: 0.25s ease;
          #text{
            color: black;
          }
          &:hover {
            background-color: #efefef;
          }
          &:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            background-color: #fff;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #efefef;
          }
        }
      }
    }
  }

  .botao-salvar {
    text-align: center;
    margin: 2rem 0 1rem 1rem;
  }
}
@media screen and (min-width: "64em") {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
