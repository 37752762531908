<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <h1>Tipo de Investimento</h1>
      <BotaoEditarShow
        v-if="tipoInvestimento"
        :to="{
          name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
          params: tipoInvestimento.tipo_investimento_id,
        }"
      />
    </div>

    <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />
    <template v-if="!carregando && !erroCarregando">
      <div
        class="caixa-dados"
        v-for="traducao in tipoInvestimento.tipos_investimento_traducao"
        :key="traducao.id"
      >
      <h4>{{ traducao.nome }}</h4>
        <div class="row4">
          <!--<p><b>Nome da Tradução:</b> {{ traducao.nome }}</p>-->
          <p><b>Nome da Linguagem:</b> {{ traducao.linguagem.nome }}</p>
          <p><b>Código da Linguagem:</b> {{ traducao.linguagem.codigo }}</p>
          <p>
            <b>Linguagem Padrão:</b>
            {{ traducao.linguagem.linguagem_padrao ? "Sim" : "Não" }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";

import TiposInvestimentoApi from "../../../services/api/TiposInvestimentoApi";

import {
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";

export default {
  name: "TiposInvestimentoShow",
  metaInfo() {
    return {
      title: `Exibir Tipo de Investimento - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    BotaoEditarShow,
    AppErro,
    AppLoading,
  },
  watch: {
    $route: function(to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW
      ) {
        this.carregarDados();
      }
    },
  },
  data() {
    return {
      carregando: false,
      erroCarregando: false,
      tipoInvestimento: null,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
    };
  },
  created() {
    this.tiposInvestimentoApi = new TiposInvestimentoApi();

    this.carregandoDados();
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;
        const data = await this.tiposInvestimentoApi.getTipoInvestimento(
          this.$route.params.id
        );
        this.tipoInvestimento = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0rem 1.5rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  h4 {
    margin: 2rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 0rem;
  }
}
@media screen and(min-width: '40rem') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p{
      display: grid;
      gap: .5rem;
    }
  }
}

// @media screen and(min-width: '60rem') {
//   .row4 {
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     p {
//       display: grid;
//       gap: .5rem;
//     }
//   }
// }
</style>
