import ModelPersistido from "./ModelPersistido";

export default class Estado extends ModelPersistido {
    static entity = "estados"

    static fields() {
        return {
            id: this.attr(null),
            nome: this.string(null).nullable(),
            sigla: this.string(null).nullable(),
        }
    }
}