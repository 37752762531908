<template>
    <div class="resultados">
        <table>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th colspan="2"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for=" beneficio in beneficios" :key="beneficio.id">
                    <td class="topo-card">{{ beneficio.nome }}</td>

                    <td class="acoes">

                      <BotaoEditarTabela
                        :to="{
                        name: ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
                        params: { id: beneficio.id },
                        query: { ...$route.query },
                        }"
                      />
                      <BotaoDeletarTabela 
                        @click="remover(beneficio.id)"
                      />
                    </td>
                </tr>
                <tr v-if="beneficios.length == 0">
                <td class="text-center">Nenhum resultado encontrado</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BotaoEditarTabela from "../../botoes/botoes_tabelas_resultados/BotaoEditarTabela.vue";
import BotaoDeletarTabela from "../../botoes/botoes_tabelas_resultados/BotaoDeletarTabela.vue";

import {
    ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
} from "../../../router/routeNames";
export default {
    name: "BeneficiosResultados",
    components: {
      BotaoEditarTabela,
      BotaoDeletarTabela,        
    },
    props: {
        beneficios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
            removendo: false,
        };
    },
    methods: {
        remover(id) {
            this.$emit("remover-beneficio", id)
        }
    }

}
</script>

<style lang="scss" scoped>

.resultados {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
    thead {
      background-color: var(--verde-gaia);
      color: white;

      th {
        text-align: left;
        padding: 1.5rem 1rem;
      }
      .th-maior {
        min-width: 10rem;
      }
      .text-center {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 1rem 1rem;
      }
      tr:nth-child(even) {
        background-color: var(--cinza-caixa-gaia);
      }
      .text-center {
        text-align: center;
      }
      .acoes {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          svg {
            fill: var(--vermelho-gaia);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: var(--cinza-caixa-gaia);
        width: 100% !important;
        border-radius: 2px;
        overflow: hidden;
        padding: 0 0 1rem;
        td {
          padding: 0rem;
          min-height: 1.94rem;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acoes{
          display: flex;
          justify-content: space-evenly;
          svg{
            height: 1.3rem;
            width: 1.3rem;
          }
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
          display: none;
      }
      .acoes::before{
        display: none;
      }
      .topo-card {
        background-color:  var(--verde-gaia);
        font-family: 'lato-bold';
        text-transform: uppercase;
        margin: 0rem 0rem 1rem 0rem;
        padding: .5rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 50rem){
  tbody{
    grid-template-columns: 1fr !important;
  }
}
</style>