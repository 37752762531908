<template>
  <div class="centro conteudo-show">
    <div class="header-show" v-if="!carregando && !erroCarregando">
      <h1>{{ contato.razao_social }}</h1>
      <BotaoEditarShow
        :to="{
          name: INVESTE_GOIAS_CONTATOS_SITE_EDIT,
          params: contato.id,
        }"
      />
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />
    <template v-if="!carregando && !erroCarregando">
      <div class="caixa-show">
        <h4>Contato</h4>
        <!--<p>
        <b>Razão Social: </b>
        {{ contato.razao_social ? contato.razao_social : "Não Informado" }}
      </p>-->
        <div class="caixa-dados">
          <div class="row2">
            <div class="item">
              <b>Nome: </b>
              {{
                contato.nome_contato ? contato.nome_contato : "Não Informado"
              }}
            </div>
            <div class="item">
              <b>E-mail para contato:</b>
              {{
                contato.email_contato ? contato.email_contato : "Não Informado"
              }}
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <b>Nº para contato:</b>
              {{
                contato.numero_contato
                  ? contato.numero_contato
                  : "Não Informado"
              }}
            </div>
            <div class="item">
              <b>Data do contato</b>
              {{ contato.created_at ? contato.created_at : "Não Informado" }}
            </div>
            <div class="item">
              <b>Status do contato: </b>
              {{
                contato.status_contato
                  ? contato.label_status_contato
                  : "Não Informado"
              }}
            </div>
          </div>
          <div class="item">
            <b>Mensagem: </b>
            {{
              contato.outras_informacoes
                ? contato.outras_informacoes
                : "Não Informado"
            }}
          </div>

          <div class="item">
            <b>Observação: </b>
            {{ contato.observacao ? contato.observacao : "Não Informado" }}
          </div>
        </div>
        <AppAutores
          :criado-por="contato.criado_por"
          :created-at="contato.created_at"
          :atualizado-por="contato.atualizado_por"
          :updated-at="contato.updated_at"
        />
      </div>
    </template>
  </div>
</template>

<script>
import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppAutores from "../../../components/AppAutores.vue";
import ContatosSiteApi from "../../../services/api/ContatosSiteApi";
import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";
import {
  INVESTE_GOIAS_CONTATOS_SITE_INDEX,
  INVESTE_GOIAS_CONTATOS_SITE_EDIT,
  INVESTE_GOIAS_CONTATOS_SITE_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

export default {
  name: "ContatosSiteShow",
  components: {
    AppLoading,
    AppErro,
    BotaoEditarShow,
    AppAutores,
  },
  metaInfo() {
    return {
      title: `Exibir Contato - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  created() {
    this.contatoSiteApi = new ContatosSiteApi();
    this.carregarDados();
  },
  watch: {
    $route: function (to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === INVESTE_GOIAS_CONTATOS_SITE_SHOW
      ) {
        this.carregarDados();
      }
    },
  },
  data() {
    return {
      INVESTE_GOIAS_CONTATOS_SITE_INDEX,
      INVESTE_GOIAS_CONTATOS_SITE_EDIT,
      INVESTE_GOIAS_CONTATOS_SITE_SHOW,
      carregando: true,
      erroCarregando: false,
      contato: [],
    };
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.contatoSiteApi.getContato(
          this.$route.params.id
        );
        this.contato = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  .caixa-show {
    h4 {
      margin: 1.5rem 0 0;
      display: table;
      font-size: 1.4rem;
      color: var(--verde-escuro-gaia);
    }
    h4::after {
      content: "";
      display: block;
      height: 2px;
      min-width: 130%;
      width: 30vw;
      max-width: 80vw;
      background-color: var(--verde-escuro-gaia);
      margin: 0.5rem 0 1rem;
    }
    p {
      margin: 1rem 0;
    }
    .caixa-dados {
      .item {
        display: grid;
        gap: 0.5rem;
        margin: 1.5rem 0;
      }
    }
  }
}
@media screen and(min-width: '50em') {
  .row2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
