<template>
  <div>
    <VueSimpleAutocomplete
      :items="pessoasFisicas"
      :selected-item="pessoaFisica"
      @change="buscarPessoaFisica"
      @value-selected="onPessoaFisicaSelecionada"
      :is-loading="carregandoPessoasFisicas"
      :get-display-value="exibirPessoaFisica"
      :min-length="2"
      placeholder="Digite CPF ou Nome para pesquisar"
    ></VueSimpleAutocomplete>
  </div>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";

import PessoasFisicasApi from "../../../services/api/PessoasFisicasApi";

export default {
  name: "PessoaFisicaAutocomplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "pessoaFisica",
    event: "change",
  },
  props: {
    pessoaFisica: {
      type: Object,
    },
    servidorSic: {
      type: Boolean,
    },
  },
  data() {
    return {
      pessoasFisicas: [],
      carregandoPessoasFisicas: false,
    };
  },
  created() {
    this.pessoasFisicasApi = new PessoasFisicasApi();
  },
  methods: {
    async buscarPessoaFisica(termo) {
      try {
        this.pessoasFisicas = [];
        this.$emit("change", {});

        if (termo == null || termo.length === 0) {
          return;
        }
        this.carregandoPessoasFisicas = true;
        const data = await this.pessoasFisicasApi.getPessoasFisicas({
          termo,
          page: 1,
          perPage: 10,
          servidor_sic: this.servidorSic,
        });
        this.pessoasFisicas = data.resultados;
        this.carregandoPessoasFisicas = false;
      } catch (err) {
        this.carregandoPessoasFisicas = false;
      }
    },
    onPessoaFisicaSelecionada(pessoaFisica) {
      this.$emit("change", pessoaFisica);
    },
    exibirPessoaFisica(pessoaFisica) {
      if (
        pessoaFisica &&
        pessoaFisica !== "" &&
        pessoaFisica.nome &&
        pessoaFisica.cpf
      ) {
        return `${this.$options.filters.cpfCnpj(pessoaFisica.cpf)} - ${
          pessoaFisica.nome
        }`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
