<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Tipos de Solicitação de Empresas</h1>
      <BotaoLink class="bt-novo" 
        :to="{
          name: ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
          query: { ...$route.query },
        }">
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading class="carregando1" v-if="!paginaCarregou && !paginaComErro"></AppLoading>
    <AppErro v-if="paginaCarregou && paginaComErro"></AppErro>
    <template v-if="paginaCarregou && !paginaComErro">
      <div>
        <div class="campo-header-resultados">
        <AppTotalResultadosEncontrados :totalResultados="totalResultados" />
          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <TiposSolicitacaoEmpresaResultados
          :tiposSolicitacaoEmpresa="tiposSolicitacaoEmpresa"
          @remover-TipoSolicitacaoEmpresa="removerTipoSolicitacaoEmpresa"
        />
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          />
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import TiposSolicitacaoEmpresaResultados from "../../../../components/atracao_investimentos/tipos_solicitacao_empresa/TiposSolicitacaoEmpresaResultados.vue";

import Paginate from "vuejs-paginate";

import { DEFAULT_PER_PAGE } from "../../../../config";
import {
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT,
  ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
} from "../../../../router/routeNames";

import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";

import BotaoLink from "../../../../components/botoes/BotaoLink.vue";
import AppTotalResultadosEncontrados from "../../../../components/AppTotalResultadosEncontrados";

import AppResultadosPorPagina from "../../../../components/AppResultadosPorPagina.vue";
import TiposSolicitacaoEmpresaApi from "../../../../services/api/TiposSolicitacaoEmpresaApi";
export default {
  name: "TiposSolicitacaoEmpresaIndex",
  metaInfo: {
    title: "Tipo de Solicitação de Empresas - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  components: {
    BotaoLink,
    AppLoading,
    AppErro,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    Paginate,
    TiposSolicitacaoEmpresaResultados,
  },
  data() {
    return {
      tiposSolicitacaoEmpresa: [],
      carregando: true,
      erroCarregando: false,
      totalResultados: 0,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      page: this.$route.query.pagBotaoNovoIndexe
        ? parseInt(this.$route.query.page)
        : 1,
      ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
      ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT,
    };
  },
  created() {
    this.tiposSolicitacaoEmpresaApi = new TiposSolicitacaoEmpresaApi();
    this.carregarDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;
        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;

        const data = await this.tiposSolicitacaoEmpresaApi.getTiposSolicitacaoEmpresa(
          {
            page: this.page,
            perPage: this.perPage,
          }
        );
        this.totalResultados = data.total_resultados;
        this.tiposSolicitacaoEmpresa = data.resultados;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
        query: {
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    async removerTipoSolicitacaoEmpresa(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.carregando = true;

        await this.tiposSolicitacaoEmpresaApi.removerTipoSolicitacaoEmpresa(id);

        if (
          this.tiposSolicitacaoEmpresa &&
          this.tiposSolicitacaoEmpresa.length == 1 &&
          this.page > 1
        ) {
          this.page--;
          this.$router.push({
            name: ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregarDados();
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
        });
        this.carregando = false;
      } catch (err) {
        console.log(err, err.response);
        const errosMsg = err.response.data.length
          ? err.response.data
          : "Não foi possível excluir o registro. Por favor tente novamente.";

        this.$swal({
          position: "center",
          icon: "error",
          title: errosMsg,
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    paginaCarregou() {
      return !this.carregando;
    },
    paginaComErro() {
      return this.erroCarregando;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  h1 {
    margin: 0;
    color:  var(--laranja-gaia);
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
