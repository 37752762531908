import CodigoCnae from "./CodigoCnae";
import ModelPersistido from "./ModelPersistido";
import PessoaJuridica from "./PessoaJuridica";

export default class CodigoCnaePessoaJuridica extends ModelPersistido {
  static entity = "codigosCnaePessoasJuridicas";

  static fields() {
    return {
      id: this.attr(null),
      codigo_cnae_id: this.attr(null),
      pessoa_juridica_id: this.attr(null),
      codigo_cnae: this.belongsTo(CodigoCnae, "codigo_cnae_id"),
      pessoa_juridica: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
      _destroy: this.boolean(false),
    };
  }

}
