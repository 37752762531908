import { parseISO } from "date-fns";

import ModelPersistido from "./ModelPersistido";
import PessoaJuridica from "./PessoaJuridica";
import AtracaoInvestimentoProtocoloIntencao from "./AtracaoInvestimentoProtocoloIntencao";
import OrgaoEnvolvido from "./OrgaoEnvolvido";
// import Arquivo from "./Arquivo";


import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";
import HistoricoProtocoloIntencao from "./HistoricoProtocoloIntencao";

export default class ProtocoloIntencao extends ModelPersistido {
    static entity = "protocolosIntencao";

    static state() {
        return {
            carregandoListaProtocolosIntencao: true,
            erroCarregarListaProtocolosIntencao: false,
            excluindoProtocolosIntencao: false,
            errosExcluirProtocolosIntencao: null,
            carregandoProtocoloIntencao: false,
            erroCarregarProtocoloIntencao: false,
        }
    }

    static fields() {
        return {
            id: this.attr(null),
            numero: this.string(null).nullable(),
            tipo_projeto: this.string(null).nullable(),
            tipo_projeto_label: this.string(null).nullable(),
            empregos_diretos_gerados: this.number(null).nullable(),
            empregos_indiretos_gerados: this.number(null).nullable(),
            investimento_fixo_estimado: this.number(null).nullable(),
            linhas_produtos: this.string(null).nullable(),
            prazo_estimado_meses: this.number(null).nullable(),
            status: this.string(null).nullable(),
            status_label: this.string(null).nullable(),
            descricao_projeto: this.string(null).nullable(),
            data_inicio: this.string(null, convertValidDateToString).nullable(),
            pessoa_juridica_id: this.attr(null),
            processo_sei: this.string(null).nullable(),
            pessoa_juridica: this.belongsTo(PessoaJuridica, 'pessoa_juridica_id'),
            atracao_investimentos_protocolos_intencao: this.hasMany(AtracaoInvestimentoProtocoloIntencao, 'protocolo_intencao_id'),
            orgaos_envolvidos: this.hasMany(OrgaoEnvolvido, 'protocolo_intencao_id'),
            historico_protocolos_intencao: this.hasMany(HistoricoProtocoloIntencao, "protocolo_intencao_id"),
            minuta: this.string(null).nullable(),
            situacao_minuta: this.string(null).nullable(),
            // arquivos: this.morphMany(Arquivo, 'arquivavel_id', 'arquivavel_type'),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            criado_por: this.string(null).nullable(),
            atualizado_por: this.string(null).nullable(),
            _destroy: this.boolean(false),
            _completo: this.boolean(false), //indica se o registro foi carregado por completo
        }
    }

    get dataInicioToDate() {
        if (!this.data_inicio) return null;

        return parseISO(this.data_inicio);
    }
}