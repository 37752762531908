<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>
        {{
          $route.name === INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW
            ? "Adicionar Tipo de investimento"
            : "Editar Tipo de investimento"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="
          $route.name === INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT &&
            !carregando &&
            !erroCarregando &&
            !carregandoFiltro &&
            !erroCarregandoFiltro
        "
        :to="{
          name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
          params: { id: $route.params.id },
        }"
      />
    </div>
    <AppLoading
      class="carregando1"
      v-if="
        carregando &&
          !erroCarregando &&
          carregandoFiltro &&
          !erroCarregandoFiltro
      "
    />
    <AppErro
      v-if="
        !carregando &&
          !carregandoFiltro &&
          (erroCarregando || erroCarregandoFiltro)
      "
    />

    <AppErrosFormularios
      v-if="errosSalvando && errosSalvando.length > 0"
      :erros="errosSalvando"
    />
    <TraducoesTipoInvestimento
      @submit="salvarDados"
      v-if="
        !carregando &&
          !erroCarregando &&
          !carregandoFiltro &&
          !erroCarregandoFiltro
      "
      v-model="tipoInvestimento.tipos_investimento_traducao"
      :filtro="filtro"
      :salvando="salvando"
    ></TraducoesTipoInvestimento>
  </div>
</template>

<script>
import TiposInvestimentoApi from "../../../services/api/TiposInvestimentoApi";

import {
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppErrosFormularios from "../../../components/AppErrosFormularios";
import BotaoVisualizarForm from "../../../components/botoes/BotaoVisualizarForm.vue";
import TraducoesTipoInvestimento from "../../../components/investe_goias/tipos_investimento/form/TraducoesTipoInvestimento.vue";
export default {
  name: "TiposInvestimentoForm",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    BotaoVisualizarForm,
    TraducoesTipoInvestimento,
  },
  metaInfo: {
    title: "Editar Tipo de Investimento - Investe Goiás",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      tipoInvestimento: [],
      filtro: {
        linguagens: [],
      },
      carregando: true,
      erroCarregando: false,
      salvando: false,
      errosSalvando: [],
      carregandoFiltro: true,
      erroCarregandoFiltro: false,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_NEW,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
    };
  },
  async created() {
    this.tiposInvestimentoApi = new TiposInvestimentoApi();
    await this.carregarFiltro();
    if (this.$route.params.id) {
      this.carregandoDados();
    } else {
      let results = this.filtro.linguagens;
      let aux = results.find((e) => e.linguagem_padrao);

      this.tipoInvestimento = {
        id: null,
        tipos_investimento_traducao: [
          {
            nome: "",
            linguagem_id: aux.id,
          },
        ],
      };
      this.carregando = false;
    }
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;
        const data = await this.tiposInvestimentoApi.getTipoInvestimento(
          this.$route.params.id
        );

        this.tipoInvestimento = data;

        this.totalTraducoes = this.tipoInvestimento.tipos_investimento_traducao.length;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    async carregarFiltro() {
      try {
        this.carregandoFiltro = true;
        this.erroCarregandoFiltro = false;

        const data = await this.tiposInvestimentoApi.getLinguagens();
        this.filtro = {
          linguagens: data.resultados,
        };

        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.carregandoFiltro = false;
        this.erroCarregandoFiltro = true;
      }
    },
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.errosSalvando = [];

        let tipoInvestimento = {
          id: this.tipoInvestimento.id,
          traducoes: this.tipoInvestimento.tipos_investimento_traducao,
        };

        const data = await this.tiposInvestimentoApi.salvarTipoInvestimento(
          tipoInvestimento
        );

        this.$router.push({
          name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
          params: { id: data.id },
        });
        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Tipo de Investimento salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.salvando = false;
      } catch (err) {
        this.salvando = false;
        console.log(err);
        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else if (typeof response.data == "string") {
            this.errosSalvando = [response.data];
          } else if (
            response.data.mensagem &&
            Array.isArray(response.data.mensagem)
          ) {
            this.errosSalvando = response.data.mensagem;
          } else {
            this.errosSalvando = [response.data.mensagem];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: var(--laranja-gaia);
      margin: 1rem 0 1rem;
    }
  }
}
</style>
