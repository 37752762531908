import * as routeNames from "./routeNames";

import IconTeste from "../assets/images/icon-teste.svg";

const menu = [{
  label: "Pessoa Jurídica",
  routeName: routeNames.PESSOAS_JURIDICAS_INDEX,
},
{
  label: "Investe Goiás",
  routeList: [{
    label: "Cadastros",
    routeList: [{
      label: "Linguagens",
      routeName: routeNames.INVESTE_GOIAS_LINGUAGENS_INDEX,
    },
    {
      label: "Tipo de Investimento",
      routeName: routeNames.INVESTE_GOIAS_TIPOS_INVESTIMENTO_INDEX,
      icon: IconTeste,
    },
    {
      label: "Polos Industriais",
      routeName: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX
    }
    ],
    icon: IconTeste,
  },
  {
    label: "Contatos",
    routeName: routeNames.INVESTE_GOIAS_CONTATOS_SITE_INDEX,
    icon: IconTeste,
  },
  ],
},
{
  label: "Mapeamento de oportunidades",
  routeName: routeNames.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
},
{
  label: "Cadastros Gerais",
  routeList: [{
    label: "Pessoas Físicas",
    routeName: routeNames.CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
    icon: IconTeste,
  },
  {
    label: "Atração de Investimentos",
    routeList: [{
      label: "Benefícios",
      routeName: routeNames.ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
    },
    {
      label: "Tipo de Solicitação Empresa",
      routeName: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
    },
    {
      label: "Meios de contato",
      routeName: routeNames.ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
    },
    {
      label: "Código do município do Mapeamento de Oportunidade",
      routeName: routeNames.CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
    },
    {
      label: "Municípios",
      routeName: routeNames.ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
      icon: IconTeste,
    },
    {
      label: "Tipos de área",
      routeName: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
    },
    ],
    icon: IconTeste,
  },
  ],
},
{
  label: "Consultas JUCEG",
  routeList: [{
    label: "Pessoas Jurídicas",
    routeName: routeNames.CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX,
    icon: IconTeste,
  },],
},

];

export default menu;