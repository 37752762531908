<template>
  <div>
    <h4>Potenciais econômicos</h4>

    <div class="caixa-dados">
      <div
        class="grupo-dados"
        v-for="potenciais_economicos in formulario_municipios_potenciais_economicos"
        :key="potenciais_economicos.id"
      >
        <div class="row2-2">
          <div class="item">
            <strong>Categoria Indústrial: </strong>
            <span v-if="potenciais_economicos.categoria_industrial">{{
              potenciais_economicos.categoria_industrial.nome
            }}</span>
            <span v-else>{{ potenciais_economicos.outros }}</span>
          </div>
        </div>
      </div>
      <p
        class="nenhum-resultado"
        v-if="formulario_municipios_potenciais_economicos.length === 0"
      >
        Nenhum Potencial econômico selecionado
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapeamentoOportunidadesPotenciaisEconomicosInfo",
  props: {
    formulario_municipios_potenciais_economicos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 1.5rem 0 0;
  display: table;
  font-size: 1.4rem;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 130%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 0rem;
}
.grupos {
  margin-bottom: 1.5rem;
}
.caixa-dados {
  background-color: white;
  padding: 0.5rem 0.5rem 0;
  .grupo-dados {
    margin-top: 0.5rem;
    border-bottom: solid 0.1rem #e6e6e6;
  }
  .item {
    display: grid;
    margin: 0.5rem 0 1.5rem;
    p {
      margin-top: 0;
    }
  }
}
.nenhum-resultado {
  text-align: center;
  font-style: italic;
  padding-bottom: 1.5rem;
}
@media screen and(min-width: '50em') {
  .row2-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
}
</style>
