var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.protocoloIntencao._destroy)?_c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - "+_vm._s(_vm.protocoloIntencao.numero)+" ( "+_vm._s(_vm.protocoloIntencao.status_label)+") ")]),_c('div',[_c('div',{staticClass:"row3"},[(_vm.protocoloIntencao.data_inicio)?_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Data de inicio: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm._f("toDate")(_vm.protocoloIntencao.data_inicio)))+" ")]):_vm._e(),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Tipo de Projeto: ")]),_vm._v(_vm._s(_vm.protocoloIntencao.tipo_projeto)+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Processo SEI: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.processo_sei))+" ")])]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Descrição do Projeto: ")]),_vm._v(_vm._s(_vm.protocoloIntencao.descricao_projeto)+" ")])]),_c('div',{staticClass:"botoes"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.excluir}},[_c('DeleteIcon'),_vm._v(" Excluir")],1),_c('router-link',{attrs:{"to":{
          name: _vm.PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
          query: {
            protocolo_intencao_id: _vm.protocoloIntencao.id,
            action: 'edit',
          },
          hash: '#protocolo',
        }}},[_c('PencilIcon'),_vm._v(" Editar")],1)],1)]):_c('div',{staticClass:"caixa-dados-2"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - "+_vm._s(_vm.protocoloIntencao.numero)+" ( "+_vm._s(_vm.protocoloIntencao.status_label)+") ")]),_c('div',{staticClass:"desfazer-exclusao"},[_c('p',[_vm._v("Protocolo de Intenção será excluido.")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.desfazerExclusao}},[_vm._v(" Desfazer exclusão ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }