<template>
  <div class="header">
    <div class="barra-sup">
      <div class="nav centro">
        <div>
          <span>v{{ version }}</span>
        </div>
      </div>
    </div>
    <div class="barra-menu">
      <input id="atv-menu" type="checkbox" @click="removeClassMenuAberto" />
      <label for="atv-menu">
        <div class="menu">
          <span class="hamburger"></span>
        </div>
      </label>
      <a href="/" class="link-gaia">
        <img
          src="../../assets/images/logo-gaia-branco.png"
          class="logo-gaia"
          alt="Logo do Sistema Gaia"
        />
      </a>
      <div class="lista-menu">
        <div class="centro">
          <div class="identificacao">
            <p>{{ nome }}</p>
            <a class="lk-sair" @click="logout">Sair<IconSair /></a>
          </div>
          <ul>
            <li
              class="li-no-hover"
              v-for="menuItem in menu"
              :key="menuItem.label"
            >
              <template v-if="menuItem.routeName">
                <router-link
                  :to="{ name: menuItem.routeName }"
                  @click.native="toggleClass"
                >
                  {{ menuItem.label }}
                </router-link>
              </template>
              <template v-else>
                <a class="a-menu" @click="toggleClass">
                  {{ menuItem.label }}
                  <SetaMenu class="seta-menu" />
                </a>
                <ol class="submenu">
                  <li
                    class="li-no-hover"
                    v-for="subItem in menuItem.routeList"
                    :key="subItem.label"
                  >
                    <template v-if="subItem.routeName">
                      <router-link
                        :to="{ name: subItem.routeName }"
                        @click.native="toggleClass"
                      >
                        <component :is="subItem.icon" v-if="subItem.icon" />
                        {{ subItem.label }}</router-link
                      >
                    </template>
                    <template v-else>
                      <a @click="toggleClass"
                        ><component :is="subItem.icon" v-if="subItem.icon" />{{
                          subItem.label
                        }}
                        <SetaMenu class="seta-menu" />
                      </a>
                      <ol class="sub-submenu">
                        <li v-for="item in subItem.routeList" :key="item.label">
                          <router-link
                            :to="{ name: item.routeName }"
                            @click.native="toggleClass"
                            >{{ item.label }}</router-link
                          >
                        </li>
                      </ol>
                    </template>
                  </li>
                </ol>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSair from "../../assets/images/icon-exit.svg";
import SetaMenu from "../../assets/images/icon-seta-menu.svg";

export default {
  name: "TheMenuResponsive",
  components: {
    IconSair,
    SetaMenu,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    nome: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
  },
  watch: {
    "$route.name": function() {
      //se rota muda, tenho que remover a classe menu-aberto dos links e fechar o menu
      this.fecharMenu();
    },
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
    toggleClass(event) {
      if (event.target.nodeName.toUpperCase() === "A") {
        event.srcElement.classList.toggle("menu-aberto");
        if (event.target.href && event.target.href != "") {
          this.fecharMenu();
        }
      } else if (
        event.target.parentNode &&
        event.target.parentNode.nodeName.toUpperCase() === "A"
      ) {
        event.target.parentElement.classList.toggle("menu-aberto");
      }
    },
    removeClassMenuAberto() {
      const linksAbertos = document.getElementsByClassName("menu-aberto");
      while (linksAbertos && linksAbertos.length > 0) {
        linksAbertos[0].classList.remove("menu-aberto");
      }
    },
    fecharMenu() {
      const menu = document.getElementById("atv-menu");
      if (menu) {
        menu.checked = false;
        this.removeClassMenuAberto();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.barra-sup {
  background-color: #00773d;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.5rem;
    background-color: #00773d;
    color: white;
    font-size: 0.7rem;
  }
}
.barra-menu {
  box-shadow: 0rem 0.2rem 0.5rem rgba(82, 82, 82, 0.295);
  background-color: #00773d;
  padding: 0.5rem;
  position: fixed;
  width: 100%;
  z-index: 900;
  top: 1.4rem;
  .lista-menu {
    position: fixed;
    background-color: white;
    left: -100%;
    bottom: 0;
    width: 100%;
    transition: left 0.2s linear;
    box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0, 0, 0.151);
    height: calc(100vh - 4.8rem);
    overflow-y: auto;
    .centro {
      padding: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      display: grid;
      margin-top: 0;
      li {
        position: relative;
        a {
          cursor: pointer;
          font-family: "lato-bold";
          display: flex;
          padding: 1rem;
          align-items: center;
          justify-content: space-between;
          .seta-menu {
            display: block;
            height: 0.8rem;
            margin-right: 0.5rem;
            fill: var(--verde-escuro-gaia);
            transform: rotate(-95deg);
            transition: 0.2s;
          }
        }
        .menu-aberto {
          border-left: solid 0.2rem #ef9b0f;
          .seta-menu {
            transform: rotate(0deg);
          }
        }
        .menu-aberto ~ .submenu {
          display: grid;
          height: auto;
          opacity: 1;
          background-color: white;
          width: 100%;
          li {
            a {
              font-family: "lato-regular";
            }
            span {
              display: none;
            }
            .menu-aberto ~ .sub-submenu {
              display: grid;
              width: 100%;
              opacity: 1;
              height: auto;
              background-color: white;
              li {
                a {
                  color: var(-verde-escuro-gaia);
                }
              }
            }
          }
        }
        svg {
          display: none;
        }
        ol {
          list-style: none;
          padding: 0;
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: 0.2s linear;
          li {
            a {
            }
          }
        }
      }
      a {
        text-decoration: none;
        color: var(--verde-escuro-gaia);
      }
    }
    .identificacao {
      background-color: var(--verde-gaia);
      padding: 2rem 0 1rem;
      p {
        text-align: center;
        font-family: "Lato-Regular";
        color: white;
        margin: 0 0 1rem;
      }
      .lk-sair {
        display: block;
        color: white;
        text-align: center;
        svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: #ef9b0f;
          margin-left: 0.5rem;
          text-align: center;
          margin-bottom: -0.3rem;
        }
      }
    }
  }
  .menu {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0.5rem;
    top: 0.8rem;
    z-index: 3;
    cursor: pointer;
    .hamburger {
      display: block;
      background-color: white;
      width: 1.4rem;
      height: 3px;
      position: relative;
      border-radius: 1.5px;
      z-index: 12;
      margin: 1rem auto;
      transition: 0.2s linear;
    }

    .hamburger::before,
    .hamburger::after {
      background-color: white;
      content: "";
      display: block;
      border-radius: 1.5px;
      height: 100%;
      position: absolute;
      transition: 0.5s ease-in-out;
    }

    .hamburger::before {
      top: 0.4em;
      width: 70%;
    }

    .hamburger::after {
      bottom: 0.45em;
      width: 130%;
    }
  }

  input {
    display: none;
  }
  input:checked ~ nav {
    top: 3rem;
  }
  input:checked ~ label {
    .hamburger {
      transform: rotate(45deg);
    }

    .hamburger::before,
    .hamburger::after {
      transform: rotate(90deg);
      top: 0;
      width: 100%;
    }
  }
  input:checked ~ .lista-menu {
    left: 0;
  }
  .link-gaia {
    display: table;
    margin-left: auto;
    margin-right: 1rem;
  }
  .logo-gaia {
    height: 2.5rem;
    margin: 0rem;
    display: block;
  }
}
</style>
