import ModelPersistido from "./ModelPersistido";

export default class TipoArea extends ModelPersistido {
  static entity = "tipos_area";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.string(null).nullable(),
    };
  }
}
