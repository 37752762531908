import ModelPersistido from "./ModelPersistido";

export default class TipoSolicitacaoEmpresaAtracao extends ModelPersistido {
  static entity = "tiposSolicitacaoEmpresaAtracao";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.string(null).nullable(),
      ativo: this.boolean(true),
    };
  }
}
