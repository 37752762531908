import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import PessoasJuridicasJucegIndex from "../../../views/consultas_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegIndex";
import PessoasJuridicasJucegShow from "../../../views/consultas_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegShow";

const pessoasJuridicas = [{
    name: routeNames.CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX,
    component: PessoasJuridicasJucegIndex,
    path: "/pessoas-juridicas-juceg",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.PESSOA_JURIDICA_JUCEG],
      menu: {
        label: "Pessoas Jurídicas JUCEG",
        exibir: true,
        breadcrumb: {
          label: "Pessoas Jurídicas JUCEG",
        },
      },
    },
  },
  {
    name: routeNames.CONSULTAS_JUCEG_PESSOAS_JURIDICAS_SHOW,
    component: PessoasJuridicasJucegShow,
    path: "/pessoas-juridicas-juceg/:id(\\d+)",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.PESSOA_JURIDICA_JUCEG],
      breadcrumb: {
        label: "Pessoas Jurídicas JUCEG",
        parent: routeNames.CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX,
      },
    },
  },
];

export default pessoasJuridicas;