<template>
  <div class="container">
    <div class="div-flex-header">
      <h4>Contatos</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Contato"
      >
        <span>+</span>Adicionar
      </BotaoArredondado>
    </div>
    <template v-for="(contato, index) in contatos">
      <div
        class="caixa-form"
        :key="`${contato.id}_${index}`"
        v-if="!contato._destroy"
      >
        <button type="button" @click="excluir(index)" title="Excluir Contato">
          <DeleteIcon />
        </button>
        <div class="row2">
          <div class="item">
            <label>Nome: <span>*</span></label>
            <input
              type="text"
              :value="contato.nome"
              @input="(event) => updateValue(index, 'nome', event.target.value)"
            />
          </div>

          <div class="item">
            <label>Cargo:</label>
            <input
              type="text"
              :value="contato.cargo"
              @input="
                (event) => updateValue(index, 'cargo', event.target.value)
              "
            />
          </div>
        </div>
        <div class="row2">
          <div class="item">
            <label>Departamento:</label>
            <input
              type="text"
              :value="contato.departamento"
              @input="
                (event) =>
                  updateValue(index, 'departamento', event.target.value)
              "
            />
          </div>

          <div class="item">
            <label>CPF / CNPJ:</label>
            <TheMask
              name="cpf"
              type="text"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :value="contato.cpf_cnpj"
              @input="(value) => updateValue(index, 'cpf_cnpj', value)"
            />
          </div>

          <div class="item">
            <label>E-mail:</label>
            <input
              type="email"
              :value="contato.email"
              @input="
                (event) => updateValue(index, 'email', event.target.value)
              "
            />
          </div>

          <div class="item">
            <label>Telefone:</label>
            <input
              type="text"
              :value="contato.telefone"
              @input="
                (event) => updateValue(index, 'telefone', event.target.value)
              "
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import BotaoArredondado from "../../../botoes/BotaoArredondado";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import ContatoPessoaJuridica from "../../../../store/models/ContatoPessoaJuridica";

export default {
  name: "PessoasJuridicasFormularioContatos",
  components: {
    BotaoArredondado,
    DeleteIcon,
    TheMask,
  },
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    adicionar() {
      ContatoPessoaJuridica.insert({
        data: {
          pessoa_juridica_id: this.pessoaJuridicaId,
        },
      });
    },
    excluir(index) {
      let contato = this.contatos[index];
      if (contato.id) {
        ContatoPessoaJuridica.update({
          ...contato,
          _destroy: true,
        });
      } else {
        ContatoPessoaJuridica.delete(contato.id);
      }
    },
    updateValue(index, key, value) {
      const contato = this.contatos[index];

      ContatoPessoaJuridica.update({
        ...contato,
        [key]: value,
      });
    },
  },
  computed: {
    contatos() {
      return ContatoPessoaJuridica.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0 0.5rem;
}
.div-flex-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span {
    font-weight: 800;
    font-size: 1.8rem;
  }
}
.caixa-form {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
  position: relative;
  button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }
}

@media screen and(min-width: '50em') {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
