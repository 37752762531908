import { format } from "date-fns";

export function preencherNomeFantasiaAtual(pessoaJuridica) {
  if (
    pessoaJuridica.historico_nomes_fantasia.length > 0 &&
    pessoaJuridica.nome_fantasia_atual_id
  ) {
    const nomeFantasiaAtual = pessoaJuridica.historico_nomes_fantasia.find(
      (nomeFantasia) =>
        nomeFantasia.id === pessoaJuridica.nome_fantasia_atual_id
    );

    if (nomeFantasiaAtual) {
      pessoaJuridica.nome_fantasia_atual = nomeFantasiaAtual;
    }
  } else {
    pessoaJuridica.nome_fantasia_atual = {
      nome_fantasia: null,
    };
  }

  return pessoaJuridica;
}

export function preencherRazaoSocialAtual(pessoaJuridica) {
  if (
    pessoaJuridica.historico_razoes_sociais.length > 0 &&
    pessoaJuridica.razao_social_atual_id
  ) {
    const razaoSocialAtual = pessoaJuridica.historico_razoes_sociais.find(
      (razaoSocial) => razaoSocial.id === pessoaJuridica.razao_social_atual_id
    );

    if (razaoSocialAtual) {
      pessoaJuridica.razao_social_atual = razaoSocialAtual;
    }
  } else {
    pessoaJuridica.razao_social_atual = {
      razao_social: null,
    };
  }

  return pessoaJuridica;
}

export function removeIdsGerados(object) {
  return {
    ...object,
    id: object._persistido ? object.id : null,
  };
}

//infelizmente há um bug no Vuex ORM, nao esta sendo possivel salvar objetos data,
//entao estou convertendo para string.
export function convertValidDateToString(value) {
  if (value && value instanceof Date && !isNaN(value)) {
    return format(value, "yyyy-MM-dd");
  }
  return value;
}
