<template>
  <div class="areas-disp">
    <h4>Áreas disponíveis:</h4>
    <div>
      <div class="grupo-caixa" v-if="areasDisponiveis.length > 0">
        <div  v-for="area in areasDisponiveis" :key="area.id">
          <div class="caixadados">
            <p><b>Área (m²):</b> {{ area.area_m2 }}</p>
            <p class="descricao"><b>Descrição:</b> {{ area.descricao }}</p>
          </div>
        </div>
      </div>
      <p class="sem-conteudo" v-if="areasDisponiveis.length === 0">
        Nenhuma área disponível cadastrada
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MunicipioAreasDisponiveis",
  props: {
    areasDisponiveis: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.areas-disp{
  h4 {
    margin: 1.5rem 0  0;
    display: table;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 0;
  }
  .sem-conteudo{
    font-style: italic;
  }
  .grupo-caixa{
    display: grid;
    gap: 1rem;
    margin-top: .5rem;
    padding-bottom: 1rem;
  }
  .caixadados{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    height: calc(100% - 1.5rem);
    background-color: var(--cinza-caixa-gaia);
    border-radius: 2px;
    p{
      margin: 0.2rem 0;
    }
    .descricao{
      display: grid;
    }
  }
  hr{
    margin: 0;
  }
}
@media screen and (min-width: '50rem') {
  .row2{
    grid-template-columns: 1fr 1fr;
  }
  .grupo-caixa{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: .5rem !important;
  }
}
</style>
