<template>
  <div>
    <h4>Áreas disponíveis</h4>

    <div class="caixa-dados">
      <div class="grupo-dados" v-for="area in areas_disponiveis" :key="area.id">
        <div class="row2-2">
          <div class="item">
            <strong>Área m²: </strong>
            <span>{{ area.area_m2 }}</span>
          </div>

          <div class="item">
            <strong>Descrição: </strong>
            <span>{{ area.descricao }}</span>
          </div>
        </div>
      </div>
      <p class="nenhum-resultado" v-if="areas_disponiveis.length === 0">
        Nenhuma área disponível cadastrada
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapeamentoOportunidadesIncentivosInfo",
  props: {
    areas_disponiveis: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 1.5rem 0 0;
  display: table;
  font-size: 1.4rem;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 130%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 0rem;
}
.grupos {
  margin-bottom: 1.5rem;
}
.caixa-dados {
  background-color: white;
  padding: 0.5rem 0.5rem 0;
  .grupo-dados {
    margin-top: 0.5rem;
    border-bottom: solid 0.1rem #e6e6e6;
  }
  .item {
    display: grid;
    margin: 0.5rem 0 1.5rem;
    p {
      margin-top: 0;
    }
  }
}
.nenhum-resultado {
  text-align: center;
  font-style: italic;
  padding-bottom: 1.5rem;
}
@media screen and(min-width: '50em') {
  .row2-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
}
</style>
