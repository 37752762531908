var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index))]),_vm._v(" - Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]),_c('div',[_c('div',{staticClass:"row3"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Data de inicio: ")]),_vm._v(_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Área internacional: ")]),_vm._v(_vm._s(_vm.atracaoInvestimento.area_internacional ? "Sim" : "Não")+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Já estabelecida em Goiás: ")]),_vm._v(_vm._s(_vm.atracaoInvestimento.ja_estabelecida_em_goias ? "Sim" : "Não")+" ")])]),_c('div',{staticClass:"row3"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Quem iniciou o contato: ")]),_vm._v(_vm._s(_vm.atracaoInvestimento.quem_iniciou_contato_label)+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Empregos previstos: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.atracaoInvestimento.empregos_previstos))+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Investimentos previstos: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.atracaoInvestimento.empregos_previstos))+" ")])])]),_c('router-link',{staticClass:"bt-show-modal",attrs:{"to":{
      name: _vm.PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
      params: {
        id: this.$route.params.id,
        atracaoId: this.atracaoInvestimento.id,
      },
      hash: '#atracao',
    }}},[_c('EyeIcon'),_vm._v(" Visualizar")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }