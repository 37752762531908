<template>
  <div class="caixa-form">
    <div class="header-caixa">
      <h4>Histórico de andamento</h4>
        <BotaoArredondado
          @click="adicionar"
          type="button"
          title="Adicionar Protocolo de Intenção "
        >
          <span>+</span> Adicionar
        </BotaoArredondado>
    </div>
    <div
      class="caixa-item"
      v-for="(historicoProtocoloIntencao, index) in historicoProtocolosIntencao"
      :key="`orgao_envolvido_${index}`"
    >
      <div v-if="!historicoProtocoloIntencao._destroy">
        <button class="bt-delete" @click="excluir(index)" type="button">
          <DeleteIcon />
        </button>
        <PessoasJuridicasProtocoloHistoricoItem
          v-model="historicoProtocolosIntencao[index]"
          :index="historicoProtocolosIntencao.length - index"
        />
      </div>
      <div class="campo-desfazer" v-else>
        <button
          class="bt-desfazer"
          @click="desfazerExclusao(index)"
          type="button"
        >
          <IconDesfazer />
        </button>

        <p>O registro será excluído.</p>
      </div>
    </div>
    <div class="text-center" v-if="historicoProtocolosIntencao.length === 0">
      Nenhum andamento encontrado
    </div>
  </div>
</template>

<script>
import PessoasJuridicasProtocoloHistoricoItem from "./PessoasJuridicasProtocoloHistoricoItem";
import BotaoArredondado from "../../../botoes/BotaoArredondado.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import IconDesfazer from "../../../../assets/images/icon-return.svg";

const cloneDeep = require("lodash.clonedeep");

export default {
  name: "PessoasJuridicasProtocoloHistorico",
  model: {
    prop: "historicoProtocolosIntencao",
    event: "change",
  },
  props: {
    historicoProtocolosIntencao: {
      type: Array,
    },
  },
  components: {
    PessoasJuridicasProtocoloHistoricoItem,
    BotaoArredondado,
    DeleteIcon,
    IconDesfazer,
  },
  methods: {
    adicionar() {
      const historicoProtocolosIntencao = cloneDeep(
        this.historicoProtocolosIntencao
      );
      historicoProtocolosIntencao.unshift({});

      this.$emit("change", historicoProtocolosIntencao);
    },
    excluir(index) {
      const historicoProtocolosIntencao = cloneDeep(
        this.historicoProtocolosIntencao
      );
      if (!historicoProtocolosIntencao[index].id) {
        //nao esta salvo no banco
        historicoProtocolosIntencao.splice(index, 1);
      } else {
        historicoProtocolosIntencao[index]._destroy = true;
      }
      this.$emit("change", historicoProtocolosIntencao);
    },
    desfazerExclusao(index) {
      const historicoProtocolosIntencao = cloneDeep(
        this.historicoProtocolosIntencao
      );

      historicoProtocolosIntencao[index]._destroy = false;

      this.$emit("change", historicoProtocolosIntencao);
    },
    updateValue(index, historicoProtocoloIntencao) {
      const historicoProtocolosIntencao = cloneDeep(
        this.historicoProtocolosIntencao
      );

      historicoProtocolosIntencao[index] = historicoProtocoloIntencao;

      this.$emit("change", historicoProtocolosIntencao);
    },
  },
};
</script>

<style lang="scss" scoped>

.header-caixa {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span{
    font-weight: 800;
    font-size: 1.8rem;
  }
}
.caixa-form {
  padding: 0rem .5rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
}
.caixa-item{
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
}
.bt-delete {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  margin-left: auto;
  display: block;
  background-color: transparent;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  svg {
    fill: var(--vermelho-gaia);
  }
}
p {
  text-align: center;
}
.bt-desfazer {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: var(--vermelho-gaia);
  margin: 1rem auto;
  padding: 0.3rem 1rem;
  transition: 0.2s;
  svg {
    height: 1.3rem;
    width: 2rem;
    fill: var(--vermelho-gaia);
    margin-right: 1rem;
    transition: 0.2s;
  }
}
.text-center {
  text-align: center;
}
@media screen and (min-width: "60em") {
  .campo-desfazer {
    display: flex;
    align-items: center;
    justify-content: center;
    .bt-desfazer {
      margin: 0;
    }
  }
}
</style>
