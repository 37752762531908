<template>
  <div>
    <form class="form-pesquisa" @submit="submit"> 
      <h4>Pesquisar</h4>
      <div class="row2-2">
        <div class="item">
          <label>Nome ou CPF: </label>
          <input
            type="text"
            :value="filtro.termo"
            @input="(event) => valueChanged('termo', event.target.value)"
          />
        </div>

        <div class="item">
          <label>Servidor SIC? </label>
          <select
            @change="(event) => valueChanged('servidor_sic', event.target.value)"
          >
            <option
              :selected="
                filtro.servidor_sic == null || filtro.servidor_sic == undefined
              "
              :value="null"
              >Todos</option
            >
            <option value="true" :selected="filtro.servidor_sic === 'true'"
              >Sim</option
            >
            <option value="false" :selected="filtro.servidor_sic === 'false'"
              >Não</option
            >
          </select>
        </div>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar"> <IconClear />Limpar campos</button>
        <button class="bt-pesquisar" type="submit">Pesquisar</button>
      </div>
    </form>
  </div>
</template>

<script>
import IconClear from "../../../assets/images/icon-clean.svg";
export default {
  name: "PessoasFisicasPesquisaForm",
  model: {
    prop: "filtro",
    event: "value-changed",
  },
  props: {
    filtro: {
      type: Object,
    },
  },
  components: {
    IconClear,
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    valueChanged(key, newValue) {
      this.$emit("value-changed", {
        ...this.filtro,
        [key]: newValue,
      });
    },
    limpar() {
      this.$emit("value-changed", {
        termo: null,
        servidor_sic: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
    }
  }
  .form-pesquisa {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem;
    border-radius: 2px;
    h4 {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }
  p {
    margin: 0;
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
        margin-right: .5rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color: var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
@media screen and(min-width: '50rem') {
  .row2-2 {
    grid-template-columns: 4fr 1fr;
    display: grid;
    gap: 1rem;
  }
}
</style>
