import ModelPersistido from "./ModelPersistido";

export default class CodigoCnae extends ModelPersistido {
  static entity = "codigosCnae";

  static fields() {
    return {
      id: this.attr(null),
      secao: this.string(null).nullable(),
      divisao: this.string(null).nullable(),
      grupo: this.string(null).nullable(),
      classe: this.string(null).nullable(),
      subclasse: this.string(null).nullable(),
      denominacao: this.string(null).nullable(),
    };
  }
}