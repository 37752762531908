<template>
  <div class="conteudo-show centro">
    <div class="header-show">
      <h1>{{ poloIndustrial.nome }}</h1>
      <BotaoEditarShow
        :to="{
          name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
          params: poloIndustrial.id,
        }"
      />
    </div>
    <AppErro v-if="!carregando && erroCarregar" />
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />

    <template v-if="!carregando && !erroCarregar">
      <div>
        <div class="caixa-show">
          <div class="row3">
            <p class="item">
              <b>Categoria:</b>
              {{
                poloIndustrial.categoria
                  ? poloIndustrial.categoria
                  : "Não informado"
              }}
            </p>
            <p class="item">
              <b>Município:</b>
              {{
                poloIndustrial.municipio
                  ? poloIndustrial.municipio.nome
                  : "Não informado"
              }}
            </p>
            <p class="item">
              <b>Raio de influência:</b>
              {{
                poloIndustrial.raio_influencia
                  ? poloIndustrial.raio_influencia
                  : "Não informado"
              }}
            </p>
          </div>
          <p class="item">
            <b>Principais segmentos:</b>
            {{
              poloIndustrial.principais_segmentos
                ? poloIndustrial.principais_segmentos
                : "Não informado"
            }}
          </p>
        </div>
        <div>
          <h4>Pontos:</h4>
          <div class="row4">
            <div
              class="caixa-pontos"
              v-for="ponto in poloIndustrial.pontos"
              :key="ponto.id"
            >
              <p><b>Latitude:</b> {{ ponto.lat }}</p>
              <p><b>Longitude:</b> {{ ponto.long }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="caixa-historico">
        <p><b>Data de Criação: </b> {{ poloIndustrial.created_at }}</p>
        <p><b>Data de Atualização: </b> {{ poloIndustrial.updated_at }}</p>
        <p><b>Criado Por: </b> {{ poloIndustrial.criado_por }}</p>
        <p><b>Atualizado Por: </b> {{ poloIndustrial.atualizado_por }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import PolosIndustriaisApi from "../../../services/api/PolosIndustriaisApi";
import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";
import { format, parseISO } from "date-fns";
import {
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";
export default {
  name: "PolosIndustriaisShow",
  metaInfo() {
    return {
      title: `Exibir Polo Industrial - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    AppErro,
    AppLoading,
    BotaoEditarShow,
  },
  data() {
    return {
      poloIndustrial: [],
      carregando: true,
      erroCarregar: false,
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
    };
  },
  created() {
    this.polosIndustriaisApi = new PolosIndustriaisApi();

    this.carregarDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const { data } = await this.polosIndustriaisApi.getPoloIndustrial(
          this.$route.params.id
        );
        this.poloIndustrial = data;

        var dataCriacao = format(parseISO(data.created_at), "dd/MM/yyyy HH:mm");
        var dataAtualizacao = format(
          parseISO(data.updated_at),
          "dd/MM/yyyy HH:mm"
        );

        this.poloIndustrial.created_at = dataCriacao;
        this.poloIndustrial.updated_at = dataAtualizacao;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conteudo-show {
  .caixa-show {
    p {
      margin: 0.5rem 0;
    }
  }
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0rem 1rem;
    h1 {
    color:  var(--laranja-gaia);
      margin: 0;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .caixa-pontos {
    background-color: #efefef;
    border-radius: 2px;
    padding: 0.1rem 1rem;
    p {
      display: grid;
    }
  }
  .caixa-historico {
    background-color: #efefef;
    border-radius: 2px;
    padding: 0.5rem 1rem;
    display: grid;
    margin: 2rem 0 3rem;
    p {
      margin: 0.5rem 0;
      font-size: 0.8rem;
      margin-right: 1rem;
    }
  }
  h4 {
    margin: 1.5rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
}
@media screen and(min-width: '50rem') {
  .caixa-historico {
    grid-template-columns: 1fr 1fr;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .caixa-show {
    p {
      display: grid;
    }
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    gap: 1rem;
  }
}
</style>
