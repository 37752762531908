<template>
  <div class="caixa-show">
    <h4>Funções</h4>

    <AppLoading v-if="carregandoFuncao" />
    <AppErro v-if="!carregandoFuncao && erroCarregarFuncao" />
    <div v-if="!carregandoFuncao && !erroCarregarFuncao" class="caixa-dados">
      <p v-if="funcoes.length == 0">Nenhuma função encontrada</p>
      <p v-else>Lista de funções/cargos por pessoa jurídica</p>
      <div v-for="(funcao, index) in funcoes" :key="index">
        <div class="row3-2">
          <div class="item">
            <b>Razão Social: </b>
            <router-link
              v-if="possuiPermissaoShowPessoaJuridica"
              :to="{
                name: PESSOA_JURIDICA_SHOW,
                params: { id: funcao.pessoa_juridica.id },
              }"
              target="_blank"
              >{{ funcao.pessoa_juridica.razao_social_atual }}</router-link
            >
            <span v-else>{{ funcao.pessoa_juridica.razao_social_atual }}</span>
          </div>
          <div class="item">
            <b>Cnpj: </b>
            {{ funcao.pessoa_juridica.cnpj | cpfCnpj }}
          </div>
          <div class="item">
            <b>Função: </b> {{ funcao.funcao | naoInformado }}
          </div>
        </div>
        <hr v-if="index + 1 < funcoes.length" />
      </div>
    </div>
  </div>
</template>

<script>
import PessoaFisicaApi from "../../../services/api/PessoasFisicasApi";
import AppLoading from "../../AppLoading";
import AppErro from "../../AppErro";

import { PESSOA_JURIDICA_SHOW } from "../../../router/routeNames";
import podeAcessarRota from "../../../helpers/podeAcessarRota";

export default {
  name: "PessoasFisicasShowTabFuncoes",
  components: {
    AppLoading,
    AppErro,
  },
  data() {
    return {
      funcoes: [],
      carregandoFuncao: true,
      erroCarregarFuncao: false,
      possuiPermissaoShowPessoaJuridica: podeAcessarRota(PESSOA_JURIDICA_SHOW),
      PESSOA_JURIDICA_SHOW,
    };
  },
  created() {
    this.pessoaFisicaApi = new PessoaFisicaApi();
    this.carregarFuncoes();
  },
  methods: {
    async carregarFuncoes() {
      try {
        this.carregandoFuncao = true;
        this.erroCarregarFuncao = false;

        this.funcoes = await this.pessoaFisicaApi.getFuncoes(
          this.$route.params.id
        );

        this.carregandoFuncao = false;
      } catch (err) {
        console.log(err);
        this.carregandoFuncao = false;
        this.erroCarregarFuncao = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-show {
  h4 {
    margin: 2rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 0rem;
  }
  .grupos {
    padding-bottom: 0.5rem;
    ol {
      list-style-type: decimal;
      padding-left: 1.5rem;
    }
  }
  .caixa-dados {
    .item {
      display: grid;
      margin: 0.5rem 0 1.5rem;
      gap: 0.5rem;
    }
  }
}
@media screen and(min-width: '50em') {
  .row3-2 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }
}
</style>
