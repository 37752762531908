export default function buildFormData(object) {
    const formData = new FormData();

    function parseObject(keys, subObj) {
        if (
            subObj instanceof Object &&
            !(subObj instanceof File) &&
            !(subObj instanceof Date)
        ) {
            Object.keys(subObj).forEach((key) =>
                parseObject(keys.concat(key), subObj[key])
            );
        } else {
            formData.append(generateKey(keys), subObj);
        }
    }

    parseObject([], object);
    return formData;
}

function generateKey(keys) {
    if (keys.length == 0) {
        return "";
    }
    if (keys.length == 1) {
        return keys[0];
    }

    return keys.reduce(
        (accumulator, currentValue) => `${accumulator}[${currentValue}]`
    );
}
