import { render, staticRenderFns } from "./PessoasJuridicasAtracaoInvestimentoForm.vue?vue&type=template&id=02a154b0&scoped=true&"
import script from "./PessoasJuridicasAtracaoInvestimentoForm.vue?vue&type=script&lang=js&"
export * from "./PessoasJuridicasAtracaoInvestimentoForm.vue?vue&type=script&lang=js&"
import style0 from "./PessoasJuridicasAtracaoInvestimentoForm.vue?vue&type=style&index=0&id=02a154b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a154b0",
  null
  
)

export default component.exports