import { render, staticRenderFns } from "./MunicipioShow.vue?vue&type=template&id=5863821e&scoped=true&"
import script from "./MunicipioShow.vue?vue&type=script&lang=js&"
export * from "./MunicipioShow.vue?vue&type=script&lang=js&"
import style0 from "./MunicipioShow.vue?vue&type=style&index=0&id=5863821e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5863821e",
  null
  
)

export default component.exports