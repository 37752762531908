export default function processarMensagemErro(response) {
    let errosSalvar = null;
    if (response && response.data) {
        if (Array.isArray(response.data)) {
            errosSalvar = response.data;
        } else if (typeof response.data == "string") {
            errosSalvar = [response.data];
        } else if (
            response.data.mensagem &&
            Array.isArray(response.data.mensagem)
        ) {
            errosSalvar = response.data.mensagem;
        } else if(response.data.mensagem) {
            errosSalvar = [ response.data.mensagem ];
        }
        else {
            errosSalvar = ["Houve um erro. Por favor, tente novamente"];
        }
    } else {
        errosSalvar = ["Houve um erro. Por favor, tente novamente"];
    }
    return errosSalvar;
}