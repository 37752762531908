<script>
import { Line } from "vue-chartjs";
import { format } from "date-fns";

export default {
  extends: Line,
  props: {
    empregosDiretosGerados: {
      type: Number,
    },
    empregosIndiretosGerados: {
      type: Number,
    },
    historicoProtocolo: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const options = {
      title: {
        display: true,
        text: "Evolução de Empregos diretos e indiretos gerados",
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].name}: ${
              tooltipItem.value
            }`;
          },
        },
      },
    };
    this.renderChart(this.chartData, { ...options, ...this.options });
  },
  computed: {
    chartData() {
      return {
        labels: this.historicoProtocolo.map((historico) =>
          format(historico.dataToDate, "dd/MM/yyyy")
        ),
        datasets: [
          {
            name: "Total de empregos indiretos gerados",
            label: `Total de empregos indiretos gerados ${
              this.empregosIndiretosGerados
                ? "(Meta de " + this.empregosIndiretosGerados.toString() + ")"
                : ""
            }`,
            data: this.historicoProtocolo.map(
              (historico) => historico.empregos_indiretos_gerados_atual
            ),
            color: "#FBB03B",
            fill: false,
            backgroundColor: "#FBB03B",
            borderColor: "#FBB03B",
          },
          {
            name: "Total de empregos diretos gerados",
            label: `Total de empregos diretos gerados ${
              this.empregosDiretosGerados
                ? "(Meta de " + this.empregosDiretosGerados.toString() + ")"
                : ""
            }`,
            data: this.historicoProtocolo.map(
              (historico) => historico.empregos_diretos_gerados_atual
            ),
            color: "#D56F24",
            fill: false,
            backgroundColor: "#D56F24",
            borderColor: "#D56F24",
          },
        ],
      };
    },
  },
};
</script>
