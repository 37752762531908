<template>
  <div class="areas-form">
    <div class="title">
    <h4>Áreas disponíveis</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Áreas Disponíveis"
      >
        <span>+</span> Adicionar
      </BotaoArredondado>
    </div>
      <p class="sem-resultado" v-if="areasDisponiveis.length === 0">Nenhuma área cadastrada</p>
    <div class="div-grid-pai">
      <div  v-for="(area, index) in areasDisponiveis" :key="index">
        <template v-if="!area._destroy">
          <div class="div-flex">
            <div class="disp-flex">
              <div class="item">
                <label>Área (m²):<span>*</span></label>
                <input
                  type="text"
                  :value="area.area_m2"
                  @input="(event) => updateArea(index, event)"
                />
              </div>
              <div class="item">
                <label>Descrição:</label>
                <input
                  type="text"
                  :value="area.descricao"
                  @input="(event) => updateDescricao(index, event)"
                />
              </div>
            </div>
            <button type="button" @click="remover(index)"><IconDelete/></button>
          </div>
        </template>
        <template v-else>
          <div class="desfazer">
            <span>Registro vai ser removido</span>
            <button type="button" @click="desfazerRemocao(index)">
              <IconReturn/>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconDelete from "../../../assets/images/icon-delete.svg";
import IconReturn from "../../../assets/images/icon-return.svg";
import BotaoArredondado from "../../botoes/BotaoArredondado.vue"
export default {
  name: "MunicipioAreasDisponiveisForm",
  model: {
    prop: "areasDisponiveis",
    event: "update",
  },
  props: {
    areasDisponiveis: {
      type: Array,
      default: () => [],
    },
  },
  components:{
    IconDelete,
    IconReturn,
    BotaoArredondado,
  },
  methods: {
    adicionar() {
      this.$emit("update", this.areasDisponiveis.concat({}));
    },
    remover(index) {
      if (this.areasDisponiveis[index].id) {
        //esta salvo, entao seta _destroy = true
        this.areasDisponiveis[index]._destroy = true;

        this.$emit("update", [...this.areasDisponiveis]);
      } else {
        //nao esta salvo, entao remove
        this.$emit(
          "update",
          this.areasDisponiveis.filter((area, idx) => idx !== index)
        );
      }
    },
    updateArea(index, event) {
      this.areasDisponiveis[index].area_m2 = event.target.value;
      this.$emit("update", this.areasDisponiveis);
    },
    updateDescricao(index, event) {
      this.areasDisponiveis[index].descricao = event.target.value;
      this.$emit("update", this.areasDisponiveis);
    },
    desfazerRemocao(index) {
      this.areasDisponiveis[index]._destroy = false;
      this.$emit("update", this.areasDisponiveis);
    },
  },
};
</script>
<style lang="scss" scoped>

.areas-form{
  .title{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .sem-resultado{
    font-style: italic;
    margin: 0 auto 2rem;
    text-align: center;
  }
  .desfazer{
    button{
      background-color: transparent;
      border: none;
      margin-right: 1rem;
      svg{
        height: 1rem;
        width: 1rem;
        fill: var(--vermelho-gaia);
      }
    }
  }
  .div-grid-pai{
    display: grid;
    gap: 1rem;
    justify-content: center;
    .div-flex{
      display: flex;
    flex-wrap: wrap;
      border: solid .1rem rgb(75, 75, 75);
      padding: .5rem;
      border-radius: 2px;
      button{
        background-color: transparent;
        border: none;
        justify-items: center;
    margin-left: auto;

        svg{
          height: 1.8rem;
          width: 1.2rem;
          fill: var(--vermelho-gaia);
        }
      }
    }
    .item{
      margin: .5rem 0;
      label{
        margin-bottom: .3rem;
        display: block;
        color: #313131;
      }
      input{
        width: 95%;
        height: 1.5rem;
      }
    }
      button{
        cursor: pointer;
      }
  }
}
@media screen and(min-width: '34em') {
  .div-grid-pai{
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and(min-width: '45em') {
  .div-grid-pai{
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and(min-width: '60em') {
  .div-grid-pai{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>