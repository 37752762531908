var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.mapeamentoOportunidades),function(mapeamentoOportunidade){return _c('tr',{key:mapeamentoOportunidade.id},[_c('td',{staticClass:"topo-card"},[_vm._v(_vm._s(mapeamentoOportunidade.municipio.nome))]),_c('td',{attrs:{"data-label":"Status:"}},[_vm._v(_vm._s(mapeamentoOportunidade.status))]),_c('td',{attrs:{"data-label":"Nome:"}},[_vm._v(_vm._s(mapeamentoOportunidade.nome_pessoa_resposta))]),_c('td',{attrs:{"data-label":"Data resposta:"}},[_vm._v(" "+_vm._s(_vm.dataResposta(mapeamentoOportunidade.created_at))+" ")]),_c('td',{staticClass:"acoes"},[_c('BotaoExibirTabela',{attrs:{"to":{
              name: _vm.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
              params: { id: mapeamentoOportunidade.id },
            }}}),(mapeamentoOportunidade.status!='validado')?_c('div',[_c('BotaoEditarTabela',{attrs:{"to":{
                name: _vm.ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
                params: { id: mapeamentoOportunidade.id },
                query: Object.assign({}, _vm.$route.query),
              }}})],1):_vm._e()],1)])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Municipio")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Nome pessoa resposta")]),_c('th',[_vm._v("Data da resposta")]),_c('th')])])}]

export { render, staticRenderFns }