<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>Editar contato<!-- - {{ contato.razao_social }}--></h1>
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />

    <form v-if="!carregando && !erroCarregando" @submit="salvarDados">
      <AppErrosFormularios
        v-if="errosSalvando && errosSalvando.length > 0"
        :erros="errosSalvando"
      />
      <div>
        <div class="caixa-form">
          <div class="row2">
            <div class="item">
              <label>Razão Social:</label>
              <input type="text" v-model="contato.razao_social" disabled />
            </div>
            <div class="item">
              <label>Nome:</label>
              <input type="text" v-model="contato.nome_contato" disabled />
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>E-mail para contato:</label>
              <input type="text" v-model="contato.email_contato" disabled />
            </div>
            <div class="item">
              <label>Número para contato:</label>
              <input type="text" v-model="contato.numero_contato" disabled />
            </div>
          </div>
          <div class="item">
            <label>Mensagem:</label>
            <textarea
              v-model="contato.outras_informacoes"
              cols="30"
              rows="10"
              disabled
            ></textarea>
          </div>
          <div class="disp-flex">
            <div class="item">
              <p><b>Data do contato:</b> {{ getData(contato.created_at) }}</p>
            </div>
            <div class="item">
              <label>Editar Status</label>
              <select v-model="contato.status_contato">
                <option
                  v-for="status in statusContato"
                  :value="status.value"
                  :key="status.label"
                >
                  {{ status.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="item">
            <label>Observações</label>
            <textarea
              id=""
              cols="30"
              rows="10"
              v-model="contato.observacao"
            ></textarea>
          </div>
        </div>
        <div>
          <div class="botao-salvar">
            <AppLoading v-if="salvando" texto="" />
            <BotaoSalvar v-if="!salvando" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppErrosFormularios from "../../../components/AppErrosFormularios";

import ContatosSiteApi from "../../../services/api/ContatosSiteApi";

import BotaoSalvar from "../../../components/botoes/BotaoSalvar.vue";

import {
  INVESTE_GOIAS_CONTATOS_SITE_INDEX,
  INVESTE_GOIAS_CONTATOS_SITE_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import { format, parseISO } from "date-fns";
export default {
  name: "ContatosSiteEdit",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    BotaoSalvar,
  },
  metaInfo() {
    return {
      title: `Editar Contato - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  created() {
    this.contatoSiteApi = new ContatosSiteApi();
    this.carregarDados();
    this.carregandoCombos();
  },
  data() {
    return {
      INVESTE_GOIAS_CONTATOS_SITE_INDEX,
      INVESTE_GOIAS_CONTATOS_SITE_SHOW,
      carregando: true,
      erroCarregando: false,
      salvando: false,
      errosSalvando: [],
      contato: [],
      carregandoCombo: true,
      erroCarregandoCombo: false,
      statusContato: [],
    };
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        this.contato = await this.contatoSiteApi.getContato(
          this.$route.params.id
        );

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
    getData(data) {
      var dataCriacao = format(parseISO(data), "dd/MM/yyyy HH:mm");
      return dataCriacao;
    },

    async carregandoCombos() {
      try {
        this.carregandoCombo = true;
        this.erroCarregandoCombo = false;

        this.statusContato = await this.contatoSiteApi.getStatusContato();

        this.carregandoCombo = false;
      } catch (err) {
        console.log(err);
        this.carregandoCombo = false;
        this.erroCarregandoCombo = true;
      }
    },

    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.errosSalvando = [];

        await this.contatoSiteApi.salvarContato({
          id: this.contato.id,
          observacao: this.contato.observacao,
          status_contato: this.contato.status_contato,
        });

        this.salvando = false;
        this.$router.push({
          name: INVESTE_GOIAS_CONTATOS_SITE_SHOW,
          params: { id: this.contato.id },
        });
        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Contato salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err, this.salvando);
        this.salvando = false;
        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    gap: 1.5rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  .caixa-form {
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
      select {
        height: 2rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
      textarea {
        padding-left: 0.5rem;
        height: 5rem;
      }
    }
  }
  .botao-salvar {
    text-align: center;
    margin: 2rem 0 3rem;
  }
}
@media screen and (min-width: 50rem) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .disp-flex {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: flex-end;
    p {
      margin: 0 1rem 0 0;
      display: grid;
    }
  }
}
</style>
