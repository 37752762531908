<template>
  <div>
    <div class="row2">
      <div class="item">
        <label>Município: <span>*</span></label>
        <select
          @change="(evt) => updateValue('municipio_id', evt.target.value)"
          required
        >
          <option
            disabled
            :selected="!municipioAlvo.municipio_id"
            :value="null"
          >
            Selecione
          </option>
          <option
            v-for="municipio in municipios"
            :key="municipio.id"
            :value="municipio.id"
            :selected="municipioAlvo.municipio_id == municipio.id"
          >
            {{ municipio.nome }}
          </option>
        </select>
      </div>

      <div class="item">
        <label>Prefeito: </label>
        <input
          type="text"
          @input="(evt) => updateValue('prefeito', evt.target.value)"
          :value="municipioAlvo.prefeito"
        />
      </div>
    </div>
    <div class="row3">
      <div class="item">
        <label>Precisa de área: </label>
        <div class="disp-flex">
          <label>
            <input
              type="radio"
              :value="true"
              :checked="municipioAlvo.precisa_de_area"
              :name="precisaAreaName"
              @change="updateValue('precisa_de_area', true)"
            />
            <span>Sim</span>
          </label>

          <label>
            <input
              type="radio"
              :value="false"
              :checked="!municipioAlvo.precisa_de_area"
              :name="precisaAreaName"
              @change="updateValue('precisa_de_area', false)"
            />
            <span>Não</span>
          </label>
        </div>
      </div>

      <template v-if="municipioAlvo.precisa_de_area">
        <div class="item">
          <label>Tamanho de área(m2): </label>
          <input
            type="number"
            @input="(evt) => updateValue('tamanho_area', evt.target.value)"
            :value="municipioAlvo.tamanho_area"
          />
        </div>

        <div class="item">
          <label>Tipo de área: </label>
          <select
            @change="(evt) => updateValue('tipo_area_id', evt.target.value)"
          >
            <option
              disabled
              :selected="!municipioAlvo.tipo_area_id"
              :value="null"
            >
              Selecione
            </option>
            <option
              v-for="tipo in tiposArea"
              :key="tipo.id"
              :value="tipo.id"
              :selected="municipioAlvo.tipo_area_id == tipo.id"
            >
              {{ tipo.nome }}
            </option>
          </select>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasMunicipioAlvo",
  model: {
    prop: "municipioAlvo",
    event: "change",
  },
  props: {
    municipios: {
      type: Array,
      required: true,
    },
    tiposArea: {
      type: Array,
      required: true,
    },
    municipioAlvo: {
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      precisaAreaName: `precisaArea_${Math.random()}`,
    };
  },
  methods: {
    updateValue(key, value) {
      this.$emit("change", {
        ...this.municipioAlvo,
        [key]: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  select {
    height: 1.9rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    width: 100%;
  }
  .disp-flex {
    display: flex;
    flex-wrap: wrap;
    label {
      display: flex;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-bottom: 0.375em;
      margin: 0 1rem 0 0;
      input {
        position: absolute;
        left: -9999px;
        &:checked + span {
          background-color: #efefef;
          &:before {
            box-shadow: inset 0 0 0 0.4375em  var(--verde-gaia);
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 99em;
        transition: 0.25s ease;
        color: black;
        &:hover {
          background-color: #efefef;
        }
        &:before {
          display: flex;
          flex-shrink: 0;
          content: "";
          background-color: #fff;
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          margin-right: 0.375em;
          transition: 0.25s ease;
          box-shadow: inset 0 0 0 0.125em #efefef;
        }
      }
    }
  }
}
@media screen and (min-width: "60em") {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
