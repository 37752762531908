import RegiaoPlanejamento from "./RegiaoPlanejamento";
import Estado from "./Estado";
import Microrregiao from "./Microrregiao";
import ModelPersistido from "./ModelPersistido";

export default class Municipio extends ModelPersistido {
  static entity = "municipios";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.string(null).nullable(),
      regiao_planejamento_id: this.attr(null),
      estado_id: this.attr(null),
      microrregiao_id: this.attr(null),
      regiao_planejamento: this.belongsTo(
        RegiaoPlanejamento,
        "regiao_planejamento_id"
      ),
      estado: this.belongsTo(Estado, "estado_id"),
      microrregiao: this.belongsTo(Microrregiao, "microrregiao_id"),
    };
  }
}
