<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <h1 v-if="linguagem">{{ linguagem.nome }}</h1>
      <BotaoEditarShow
        v-if="linguagem"
        :to="{
          name: INVESTE_GOIAS_LINGUAGENS_EDIT,
          params: linguagem.id,
        }"
      />
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
    <AppErro v-if="!carregando && erroCarregando" />
    <template v-if="!carregando && !erroCarregando">
      <div>
        <div class="row2">
          <p><b>Código: </b> {{ linguagem.codigo }}</p>
          <p>
            <b>Linguagem Padrão: </b>
            {{ linguagem.linguagem_padrao ? "sim" : "não" }}
          </p>
        </div>
        <!--<div class="caixa-historico">
          <p><b>Data de Criação: </b> {{ linguagem.created_at }}</p>
          <p><b>Data de Atualização: </b> {{ linguagem.updated_at }}</p>
          <p><b>Criado Por: </b> {{ linguagem.criado_por }}</p>
          <p><b>Atualizado Por: </b> {{ linguagem.atualizado_por }}</p>
        </div>-->
      </div>
    </template>
  </div>
</template>

<script>
import LinguagensApi from "../../../services/api/LinguagensApi";
import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";

import {
  INVESTE_GOIAS_LINGUAGENS_INDEX,
  INVESTE_GOIAS_LINGUAGENS_EDIT,
  INVESTE_GOIAS_LINGUAGENS_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import { format, parseISO } from "date-fns";

export default {
  name: "LinguagensShow",
  metaInfo() {
    return {
      title: `Exibir Linguagem - Investe Goiás`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    AppErro,
    AppLoading,
    BotaoEditarShow,
  },
  data() {
    return {
      INVESTE_GOIAS_LINGUAGENS_INDEX,
      INVESTE_GOIAS_LINGUAGENS_EDIT,
      carregando: true,
      erroCarregando: false,
      linguagem: null,
    };
  },
  created() {
    this.linguagensApi = new LinguagensApi();

    this.carregarDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === INVESTE_GOIAS_LINGUAGENS_SHOW
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.linguagensApi.getLinguagem(
          this.$route.params.id
        );
        this.linguagem = data.data;

        var dataCriacao = format(
          parseISO(data.data.created_at),
          "dd/MM/yyyy HH:mm"
        );
        this.linguagem.created_at = dataCriacao;

        var dataAtualizacao = format(
          parseISO(data.data.updated_at),
          "dd/MM/yyyy HH:mm"
        );
        this.linguagem.updated_at = dataAtualizacao;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0rem 2rem;
    h1 {
    color:  var(--laranja-gaia);
      margin: 0;
    }
  }
  .row2 {
p {
  display: grid;
  gap: 0.5rem;
}
  }
}

@media screen and(min-width: '50rem') {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
