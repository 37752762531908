import rotasMunicipios from "./atracao_investimentos/municipios";
import rotasBeneficios from "./atracao_investimentos/beneficios";
import pessoasJuridicas from "./pessoasJuridicas";
import pessoasJuridicasJuceg from "./consultas_juceg/pessoasJuridicasJuceg";
import rotasLinguagens from "./investe_goias/linguagens";
import rotasContatosSite from "./investe_goias/contatos_site";
import rotasPessoasFisicas from "./cadastros_basicos/pessoasFisicas";
import rotasTiposInvestimento from "./investe_goias/tipos_investimento"
import rotasMeiosContato from "./atracao_investimentos/meios_contato";
import rotasPolosIndustriais from "./investe_goias/polos_industriais";
import rotasTiposSolicitacaoEmpresa from './atracao_investimentos/tiposSolicitacaoEmpresa'
import rotasTiposArea from "./atracao_investimentos/tipos_area";
import rotasMapeamentoOportunidades from "./atracao_investimentos/mapeamento_oportunidades";
import rotasCodigoMunicipioMapeamentoOportunidade from "./atracao_investimentos/codigo_municipio_mapeamento_oportunidade";

// a ordem no array indica a ordem de prioridade da rota para redirecionamento ao logar
const rotasPrivadas = [
  ...pessoasJuridicas,
  ...pessoasJuridicasJuceg,
  ...rotasCodigoMunicipioMapeamentoOportunidade,
  ...rotasPessoasFisicas,
  ...rotasMunicipios,
  ...rotasBeneficios,
  ...rotasLinguagens,
  ...rotasContatosSite,
  ...rotasTiposInvestimento,
  ...rotasMeiosContato,
  ...rotasPolosIndustriais,
  ...rotasTiposSolicitacaoEmpresa,
  ...rotasTiposArea,
  ...rotasMapeamentoOportunidades,
];

export default rotasPrivadas;