import { parseISO } from 'date-fns';
import ModelPersistido from "./ModelPersistido";

import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";
import ProtocoloIntencao from "./ProtocoloIntencao";

export default class HistoricoProtocoloIntencao extends ModelPersistido {
    static entity = "historicoProtocolosIntencao";

    static fields() {
        return {
            id: this.attr(null),
            empregos_diretos_gerados_atual: this.number(null).nullable(),
            empregos_indiretos_gerados_atual: this.number(null).nullable(),
            investimento_fixo_atual: this.number(null).nullable(),
            data: this.string(null, convertValidDateToString).nullable(),
            protocolo_intencao_id: this.attr(null),
            observacao: this.string(null).nullable(),
            protocolo_intencao: this.belongsTo(ProtocoloIntencao, 'protocolo_intencao_id')
        }
    }


    get dataToDate() {
        if (!this.data) return null;

        return parseISO(this.data);
    }
}