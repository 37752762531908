<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <a class="bt-fechar" @click="cancelar">
        <IconFechar />
      </a>

      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Protocolo de Intenção</h1>

        <div v-if="!salvando && errosSalvar">
          <AppErroFormulario :erros="errosSalvar" />
        </div>
        <form @submit="salvar" v-if="!carregando && !erro">
          <div class="item">
            <label>Descrição do Projeto: <span>*</span></label>
            <input
              type="text"
              v-model="protocoloIntencao.descricao_projeto"
              required
            />
          </div>
          <div class="row4">
            <div class="item">
              <label>Número: <span>*</span> </label>
              <input type="text" v-model="protocoloIntencao.numero" required />
            </div>

            <div class="item">
              <label>Tipo de Projeto: <span>*</span></label>
              <select v-model="protocoloIntencao.tipo_projeto" required>
                <option :value="undefined" disabled>Selecione</option>
                <option
                  v-for="tipo in tiposProjeto"
                  :key="tipo.value"
                  :value="tipo.value"
                >
                  {{ tipo.label }}
                </option>
              </select>
            </div>

            <div class="item">
              <label>Status: <span>*</span></label>
              <select v-model="protocoloIntencao.status" required>
                <option :value="undefined" disabled>Selecione</option>
                <option
                  v-for="stat in statuses"
                  :key="stat.value"
                  :value="stat.value"
                >
                  {{ stat.label }}
                </option>
              </select>
            </div>
            <div class="item">
              <label>Data de inicio: </label>
              <DatePicker
                v-model="protocoloIntencao.data_inicio"
                format="DD/MM/YYYY"
              />
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>Resultado do processo de atração?: </label>
              <div class="disp-flex">
                <label>
                  <input
                    type="radio"
                    :value="true"
                    name="resultado_processo_atracao"
                    v-model="protocoloIntencao.resultado_processo_atracao"
                  />
                  <span>Sim</span>
                </label>

                <label>
                  <input
                    type="radio"
                    :value="false"
                    name="resultado_processo_atracao"
                    v-model="protocoloIntencao.resultado_processo_atracao"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>

            <template v-if="protocoloIntencao.resultado_processo_atracao">
              <div
                class="item"
                v-for="(atracaoProtocolo,
                idx) in protocoloIntencao.atracao_investimentos_protocolos_intencao"
                :key="idx"
              >
                <label>Atração: <span>*</span></label>
                <select
                  v-model="atracaoProtocolo.atracao_investimento_id"
                  required
                >
                  <option :value="undefined" disabled>Selecione</option>
                  <option
                    v-for="(atracao, index) in atracaoInvestimentos"
                    :key="atracao.id"
                    :value="atracao.id"
                  >
                    {{ index + 1 }} - {{ atracao.data_inicio | toDate }}
                  </option>
                </select>
              </div>
            </template>
          </div>
          <div class="row3">
            <div class="item">
              <label>(Previsão) Empregos diretos gerados: </label>
              <input
                type="number"
                v-model="protocoloIntencao.empregos_diretos_gerados"
                @blur="alterouEmpregosDiretos"
              />
            </div>

            <div class="item">
              <label>(Previsão) Empregos indiretos gerados: </label>
              <input
                type="number"
                v-model="protocoloIntencao.empregos_indiretos_gerados"
              />
            </div>

            <div class="item">
              <label>Investimento estimado: </label>
              <CurrencyInput
                v-model="protocoloIntencao.investimento_fixo_estimado"
              />
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Prazo estimado (meses): </label>
              <input
                type="number"
                v-model="protocoloIntencao.prazo_estimado_meses"
              />
            </div>

            <div class="item">
              <label>Linhas de produtos: </label>
              <input type="text" v-model="protocoloIntencao.linhas_produtos" />
            </div>

            <div class="item">
              <label>Processo SEI: </label>
              <input type="text" v-model="protocoloIntencao.processo_sei" />
            </div>
          </div>

          <div class="row3">
            <div class="item">
              <label>Minuta: </label>
              <input type="text" v-model="protocoloIntencao.minuta" />
            </div>

            <div class="item">
              <label>Situação da minuta: </label>
              <input type="text" v-model="protocoloIntencao.situacao_minuta" />
            </div>
          </div>

          <AppTabs :tabs="tabs" v-model="currentTab">
            <template v-slot:[tabs[0].id]>
              <PessoasJuridicasProtocoloOrgaosEnvolvidos
                v-model="protocoloIntencao.orgaos_envolvidos"
              />
            </template>

            <template v-slot:[tabs[1].id]>
              <PessoasJuridicasProtocoloArquivos
                v-model="protocoloIntencao.arquivos"
              />
            </template>

            <template v-slot:[tabs[2].id]>
              <PessoasJuridicasProtocoloHistorico
                v-model="protocoloIntencao.historico_protocolos_intencao"
              />
            </template>
          </AppTabs>

          <div class="botoes" v-if="!salvando">
            <button class="bt-cancelar" type="button" @click="cancelar">
              Cancelar
            </button>
            <button class="bt-salvar" type="submit">
              <IconSalvar /> Salvar
            </button>
          </div>
          <AppLoading v-if="salvando" size="small" texto="" />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import AppErroFormulario from "../../../AppErrosFormularios";
import AppTabs from "../../../AppTabs";

import ProtocolosIntencaoApi from "../../../../services/api/ProtocolosIntencaoApi";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";
import toDate from "../../../../filters/toDate";
import { PESSOA_JURIDICA_EDIT } from "../../../../router/routeNames";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import { modulePessoaJuridica } from "../../../../store/moduleNames";
import processarMensagemErro from "../../../../helpers/processarMensagemErro";
import AtracaoInvestimentoProtocoloIntencao from "../../../../store/models/AtracaoInvestimentoProtocoloIntencao";
import PessoasJuridicasProtocoloOrgaosEnvolvidos from "./PessoasJuridicasProtocoloOrgaosEnvolvidos";
import OrgaoEnvolvido from "../../../../store/models/OrgaoEnvolvido";
import PessoasJuridicasProtocoloArquivos from "./PessoasJuridicasProtocoloArquivos";
import Arquivo from "../../../../store/models/Arquivo";
import PessoasJuridicasProtocoloHistorico from "./PessoasJuridicasProtocoloHistorico";
import HistoricoProtocoloIntencao from "../../../../store/models/HistoricoProtocoloIntencao";

import IconFechar from "../../../../assets/images/icon-fechar.svg";
import IconSalvar from "../../../../assets/images/icon-save.svg";

const cloneDeep = require("lodash.clonedeep");

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasProtocoloIntencaoForm",
  components: {
    AppErro,
    AppErroFormulario,
    AppLoading,
    AppTabs,
    DatePicker,
    PessoasJuridicasProtocoloOrgaosEnvolvidos,
    PessoasJuridicasProtocoloArquivos,
    PessoasJuridicasProtocoloHistorico,
    IconFechar,
    IconSalvar,
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: "Órgãos envolvidos",
        permissoes: [],
      },
      {
        id: 2,
        label: "Arquivos",
        permissoes: [],
      },
      {
        id: 3,
        label: "Histórico de andamento",
        permissoes: [],
      },
    ];
    return {
      tiposProjeto: [],
      statuses: [],
      carregandoValores: true,
      erroCarregandoValores: false,
      protocoloIntencao: {
        empregos_indiretos_gerados: null,
        empregos_diretos_gerados: null,
        pessoa_juridica_id: this.$route.params.id,
        resultado_processo_atracao: false,
        atracao_investimentos_protocolos_intencao: [{}],
        historico_protocolos_intencao: [],
        orgaos_envolvidos: [],
        arquivos: [],
      },
      salvando: false,
      errosSalvar: null,
      tabs,
      currentTab: tabs[0],
    };
  },
  async created() {
    this.protocolosIntencaoApi = new ProtocolosIntencaoApi();

    this.carregarValores();
    if (this.$route.query.action == "edit") {
      if (
        (this.protocoloIntencaoPersistido &&
          this.protocoloIntencaoPersistido._persistido &&
          !this.protocoloIntencaoPersistido._completo) ||
        !this.protocoloIntencaoPersistido
      ) {
        await this.carregarProtocoloIntencao(
          this.$route.query.protocolo_intencao_id
        );
      } else if (this.protocoloIntencaoPersistido) {
        this.protocoloIntencao = this.protocoloToLocalObject(
          this.protocoloIntencaoPersistido
        );
      }
    }
  },
  watch: {
    protocoloIntencaoPersistido: function(newValue) {
      if (newValue.$toJson()) {
        this.protocoloIntencao = this.protocoloToLocalObject(newValue);
      }
    },
  },
  filters: {
    toDate,
  },
  methods: {
    async carregarValores() {
      try {
        this.carregandoValores = true;
        this.erroCarregandoValores = false;

        const [tiposProjeto, statuses] = await Promise.all([
          this.protocolosIntencaoApi.getTiposProjeto(),
          this.protocolosIntencaoApi.getStatuses(),
        ]);

        this.tiposProjeto = tiposProjeto;
        this.statuses = statuses;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);

        this.carregandoValores = false;
        this.erroCarregandoValores = true;
      }
    },
    async salvar(evt) {
      evt.preventDefault();

      try {
        this.salvando = true;
        this.errosSalvar = null;

        let atracaoProtocolos = cloneDeep(
          this.protocoloIntencao.atracao_investimentos_protocolos_intencao
        );
        if (!this.protocoloIntencao.resultado_processo_atracao) {
          atracaoProtocolos = atracaoProtocolos.reduce((acc, current) => {
            if (current.id) {
              return acc.concat({ ...current, _destroy: true });
            }
            return acc;
          }, []);
        } else {
          atracaoProtocolos = atracaoProtocolos.map((atracao) => ({
            ...atracao,
            _destroy: false,
          }));
        }

        const protocolo = await this.protocolosIntencaoApi.salvarProtocoloIntencao(
          {
            ...this.protocoloIntencao,
            atracao_investimentos_protocolos_intencao_attributes: atracaoProtocolos,
            orgaos_envolvidos_attributes: this.protocoloIntencao
              .orgaos_envolvidos,
            arquivos_attributes: this.protocoloIntencao.arquivos,
            historico_protocolos_intencao_attributes: this.protocoloIntencao
              .historico_protocolos_intencao,
          }
        );

        //apagamos os relacionamentos, para reconstruirmos
        const idsAtracaoProtocolo = AtracaoInvestimentoProtocoloIntencao.query()
          .where("protocolo_intencao_id", protocolo.id)
          .get()
          .map((atracaoProtocolo) => atracaoProtocolo.id);
        await AtracaoInvestimentoProtocoloIntencao.delete((atracaoProtocolo) =>
          idsAtracaoProtocolo.includes(atracaoProtocolo.id)
        );

        const idsOrgaosEnvolvidos = OrgaoEnvolvido.query()
          .where("protocolo_intencao_id", protocolo.id)
          .get()
          .map((orgao) => orgao.id);
        await OrgaoEnvolvido.delete((orgaoEnvolvido) =>
          idsOrgaosEnvolvidos.includes(orgaoEnvolvido.id)
        );

        const idsHistorico = HistoricoProtocoloIntencao.query()
          .where("protocolo_intencao_id", protocolo.id)
          .get()
          .map((historico) => historico.id);

        await HistoricoProtocoloIntencao.delete((historico) =>
          idsHistorico.includes(historico.id)
        );

        //arquivo é polimorfico. Não consegui fazer a ligação funcionar com o rails
        //entao eu faço a insercao e atualizacao manualmente
        const idsArquivos = Arquivo.query()
          .where(
            "arquivavel_type",
            "SicNegocio::Gaia::Model::ProtocoloIntencao"
          )
          .where("arquivavel_id", protocolo.id)
          .get()
          .map((arquivo) => arquivo.id);
        await Arquivo.delete((arquivo) => idsArquivos.includes(arquivo.id));

        await ProtocoloIntencao.insertOrUpdate({ data: protocolo });
        if (protocolo.arquivos && protocolo.arquivos.length > 0) {
          await Arquivo.insertOrUpdate({ data: protocolo.arquivos });
        }

        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#protocolo",
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Protocolo de Intenção salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.salvando = false;
      } catch (err) {
        console.log(err);

        this.salvando = false;
        this.errosSalvar = processarMensagemErro(err.response);
      }
    },
    async cancelar() {
      const result = await this.$swal.fire({
        title: "Você deseja fechar essa tela? ",
        text: "As alterações feitas serão descartadas",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#054700",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#protocolo",
        });
      }
    },
    alterouEmpregosDiretos() {
      if (
        (this.protocoloIntencao.empregos_indiretos_gerados === null ||
          this.protocoloIntencao.empregos_indiretos_gerados === undefined ||
          this.protocoloIntencao.empregos_indiretos_gerados === "") &&
        this.protocoloIntencao.empregos_diretos_gerados
      ) {
        this.protocoloIntencao.empregos_indiretos_gerados =
          parseInt(this.protocoloIntencao.empregos_diretos_gerados) * 4;
      }
    },
    protocoloToLocalObject(protocolo) {
      return {
        ...protocolo.$toJson(),
        data_inicio: protocolo.dataInicioToDate,
        resultado_processo_atracao: protocolo.atracao_investimentos_protocolos_intencao.some(
          (atracaoProtocolo) => !atracaoProtocolo._destroy
        ),
        atracao_investimentos_protocolos_intencao:
          protocolo.atracao_investimentos_protocolos_intencao &&
          protocolo.atracao_investimentos_protocolos_intencao.length > 0
            ? protocolo.atracao_investimentos_protocolos_intencao
            : [{}],
        arquivos:
          this.arquivosPersistidos && this.arquivosPersistidos.length > 0
            ? this.arquivosPersistidos.map((arquivo) => arquivo.$toJson())
            : [],
      };
    },
    ...mapActions(["carregarProtocoloIntencao"]),
  },
  computed: {
    carregando() {
      //preciso dos valores de atracaoInvestimento para o select
      return (
        this.$store.state.entities.protocolosIntencao
          .carregandoProtocoloIntencao ||
        this.carregandoValores ||
        this.$store.state.entities.protocolosIntencao
          .carregandoListaProtocolosIntencao ||
        this.$store.state.entities.atracaoInvestimentos.carregandoLista
      );
    },
    erro() {
      return (
        this.$store.state.entities.protocolosIntencao
          .erroCarregarProtocoloIntencao ||
        this.erroCarregandoValores ||
        this.$store.state.entities.atracaoInvestimentos.erroCarregarLista
      );
    },
    atracaoInvestimentos() {
      return AtracaoInvestimento.query()
        .where("pessoa_juridica_id", parseInt(this.$route.params.id))
        .orderBy("data_inicio", "desc")
        .get();
    },
    protocoloIntencaoPersistido() {
      if (!this.$route.query.protocolo_intencao_id) return null;

      return ProtocoloIntencao.query()
        .whereId(parseInt(this.$route.query.protocolo_intencao_id))
        .withAll()
        .with("orgaos_envolvidos", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .with("historico_protocolos_intencao", (query) => {
          query.orderBy("data", "desc");
        })
        .first();
    },
    arquivosPersistidos() {
      if (!this.protocoloIntencaoPersistido) return null;

      return Arquivo.query()
        .where("arquivavel_type", "SicNegocio::Gaia::Model::ProtocoloIntencao")
        .where("arquivavel_id", this.protocoloIntencaoPersistido.id)
        .orderBy("updated_at", "desc")
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
}

.modal-caixa {
  background-color: white;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: scroll;
  position: relative;
  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
  h1 {
    color: var(--laranja-gaia);
  }
  .item {
    display: grid;
    margin: 1rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    .disp-flex {
      display: flex;
      flex-wrap: wrap;
      label {
        display: flex;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;
        margin: 0 1rem 0 0;
        input {
          position: absolute;
          left: -9999px;
          &:checked + span {
            background-color: #efefef;
            &:before {
              box-shadow: inset 0 0 0 0.4375em var(--verde-gaia);
            }
          }
        }
        span {
          display: flex;
          align-items: center;
          padding: 0.375em 0.75em 0.375em 0.375em;
          border-radius: 99em;
          transition: 0.25s ease;
          color: black;
          &:hover {
            background-color: #efefef;
          }
          &:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            background-color: #fff;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #efefef;
          }
        }
      }
    }
  }
  .botoes {
    margin: 2rem 0 2rem;
    display: grid;
    gap: 1rem;
    button {
      font-size: 1em;
      height: 2.5rem;
      border-radius: 2px;
      min-width: 15rem;
      cursor: pointer;
    }
    .bt-cancelar {
      background-color: transparent;
      border: var(--vermelho-gaia) solid 0.15rem;
      color: var(--vermelho-gaia);
    }

    .bt-cancelar:hover {
      background-color: var(--vermelho-gaia);
      border: var(--vermelho-gaia) solid 0.15rem;
      color: white;
    }
    .bt-salvar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--verde-escuro-gaia);
      color: white;
      border: none;
      svg {
        height: 1rem;
        width: 2rem;
      }
    }
  }
}
.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: 95vh;
    width: 80vw;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
  .botoes {
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
</style>
