<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Municípios</h1>
      <BotaoLink
        class="bt-novo"
        :to="{ name: ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>

    <form
      class="form-pesquisa"
      @submit="buscar"
      v-if="!erroCarregandoFiltro && !carregandoFiltro"
    >
      <h4>Pesquisar</h4>
      <div class="row4">
        <div class="item">
          <label>Nome:</label>
          <input type="text" v-model="filtro.nome" />
        </div>
        <div class="item">
          <label>Estado:</label>
          <select v-model="filtro.estadoId">
            <option
              v-for="estado in estados"
              :key="estado.id"
              :value="estado.id"
            >
              {{ estado.nome }}
            </option>
          </select>
        </div>
        <div class="item">
          <label>Microrregião:</label>
          <select v-model="filtro.microrregiaoId">
            <option :value="null">Todos</option>
            <option
              v-for="microrregiao in microrregioes"
              :key="microrregiao.id"
              :value="microrregiao.id"
            >
              {{ microrregiao.nome }}
            </option>
          </select>
        </div>
        <div class="item">
          <label>Incentivos?</label>
          <select v-model="filtro.possuiIncentivo">
            <option :value="null">Todos</option>
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <fieldset>
        <p>Área disponível (m2)</p>
        <div class="div-flex">
          <div class="area-de">
            <label for="area_minima">De: </label>
            <input type="number" v-model="filtro.areaMinima" id="area_minima" />
          </div>
          <div>
            <label for="area_maxima">Até: </label>
            <input type="number" v-model="filtro.areaMaxima" id="area_maxima" />
          </div>
        </div>
      </fieldset>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar">
          <IconClear /> Limpar campos
        </button>
        <button class="bt-pesquisar" type="submit">Pesquisar</button>
      </div>
    </form>

    <AppLoading
      class="carregando1"
      v-if="!paginaCarregou && !paginaComErro"
    ></AppLoading>
    <AppErro v-if="paginaCarregou && paginaComErro"></AppErro>
    <template v-if="paginaCarregou && !paginaComErro">
      <div class="campo-header-resultados">
        <AppTotalResultadosEncontrados :total-resultados="totalResultados" />
        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>
      <MunicipiosResultados :municipios="municipios"></MunicipiosResultados>
      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </template>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import MunicipiosApi from "../../../services/api/MunicipiosApi";
import EstadosApi from "../../../services/api/EstadosApi";
import MicrorregioesApi from "../../../services/api/MicrorregioesApi";
import IconClear from "../../../assets/images/icon-clean.svg";
import { DEFAULT_PER_PAGE } from "../../../config";
import {
  ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW,
} from "../../../router/routeNames";
import filterNullsAndEmptyStrings from "../../../helpers/filterNullsAndEmptyStrings";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados.vue";
import BotaoLink from "../../../components/botoes/BotaoLink.vue";
import MunicipiosResultados from "../../../components/atracao_investimentos/municipios/MunicipiosResultados";
import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina";

const isEqual = require("lodash.isequal");

export default {
  name: "MunicipiosIndex",
  components: {
    AppLoading,
    AppErro,
    MunicipiosResultados,
    Paginate,
    AppResultadosPorPagina,
    IconClear,
    AppTotalResultadosEncontrados,
    BotaoLink,
  },
  metaInfo: {
    title: "Municípios - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      carregando: true,
      municipios: [],
      totalResultados: 0,
      erroCarregando: false,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      filtro: {
        nome: this.$route.query.nome || "",
        estadoId: this.$route.query.estado_id || null,
        microrregiaoId: this.$route.query.microrregiao_id || null,
        mesorregiaoId: this.$route.query.mesorregiao_id || null,
        possuiIncentivo: this.$route.query.possui_incentivo == undefined ? null : this.$route.query.possui_incentivo ,
        areaMinima: this.$route.query.area_minima || null,
        areaMaxima: this.$route.query.area_maxima || null,
      },
      carregandoFiltro: true,
      erroCarregandoFiltro: false,
      estados: [],
      ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW,
    };
  },
  async created() {
    this.estadosApi = new EstadosApi();
    this.municipiosApi = new MunicipiosApi();
    this.microrregioesApi = new MicrorregioesApi();
    await this.carregarFiltros();
    this.carregarDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX &&
        !isEqual(to.query, from.query)
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        let estadoPadrao;

        if (this.estados) {
          estadoPadrao = this.estados.find((estado) => estado.padrao);
        }

        let estadoId = this.$route.query.estado_id || null;

        if (!estadoId && estadoPadrao) {
          estadoId = estadoPadrao.id;
        }

        this.filtro = {
          nome: this.$route.query.nome || "",
          estadoId,
          microrregiaoId: this.$route.query.microrregiao_id || null,
          mesorregiaoId: this.$route.query.mesorregiao_id || null,
          possuiIncentivo: this.$route.query.possui_incentivo == undefined ? null : this.$route.query.possui_incentivo ,
          areaMinima: this.$route.query.area_minima || null,
          areaMaxima: this.$route.query.area_maxima || null,
        };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.municipiosApi.getMunicipios({
          ...this.filtro,
          page: this.page,
          perPage: this.perPage,
        });

        this.totalResultados = data.total_resultados;
        this.municipios = data.resultados;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.erroCarregando = true;
        this.carregando = false;
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
        query: filterNullsAndEmptyStrings({
          nome: this.filtro.nome,
          estado_id: this.filtro.estadoId,
          microrregiao_id: this.filtro.microrregiaoId,
          mesorregiao_id: this.filtro.mesorregiaoId,
          possui_incentivo: this.filtro.possuiIncentivo,
          area_minima: this.filtro.areaMinima,
          area_maxima: this.filtro.areaMaxima,
          page: 1,
          per_page: this.perPage,
        }),
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    limpar() {
      this.filtro = {
        nome: "",
        estadoId: null,
        microrregiaoId: null,
        mesorregiaoId: null,
        possuiIncentivo: null,
        areaMinima: null,
        areaMaxima: null,
      };
    },
    async carregarFiltros() {
      try {
        this.carregandoFiltro = true;
        this.erroCarregandoFiltro = false;

        const [estados, microrregioes] = await Promise.all([
          this.estadosApi.getEstados(),
          this.microrregioesApi.getMicrorregioes(),
        ]);

        this.estados = estados;
        this.microrregioes = microrregioes;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.carregandoFiltro = false;
        this.erroCarregandoFiltro = true;
      }
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    paginaCarregou() {
      return !this.carregandoFiltro && !this.carregando;
    },
    paginaComErro() {
      return this.erroCarregando || this.erroCarregandoFiltro;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  gap: 1.5rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
    }
  }
  .form-pesquisa {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem;
    border-radius: 2px;
    h4 {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }
  p {
    margin: 0;
    font-family: "Lato-Regular";
  }
  .div-flex {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color: var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color: var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  gap: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and(min-width: '30rem') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
@media screen and(min-width: '50rem') {
  .row4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .div-flex {
    display: flex;
    justify-content: center !important;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: auto !important;
    }
  }
}
</style>
