import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");
const fileDownload = require("js-file-download");

export default class PessoasJuridicasJucegApi {
  cancelTokens = {
    getPessoasJuridicasJuceg: null,
    getPessoaJuridicaJuceg: null,
    getPessoasJuridicasJucegCsv: null,
    getPessoaJuridicaPdf: null,
    getAtividadeEconomicaAutocomplete: null,
    atualizaPessoaJuridicaPorCnpj: null,
    atualizaPessoasJuridica24hrs: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async atualizaPessoaJuridicaPorCnpj(cnpj) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.atualizaPessoaJuridicaPorCnpj = source;

    const {
      data
    } = await axiosAuthenticated.get(`${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/atualizar_empresa_juceg_cnpj?cnpj=${cnpj}`, {
      cancelToken: source.token,
    })

    this.cancelTokens.atualizaPessoaJuridicaPorCnpj = null;

    return data;
  }

  async atualizaPessoasJuridica24hrs() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.atualizaPessoasJuridica24hrs = source;

    const {
      data
    } = await axiosAuthenticated.get(`${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/atualizar_empresas_juceg_ultimas_24hrs`, {
      cancelToken: source.token,
    })

    this.cancelTokens.atualizaPessoasJuridica24hrs = null;

    return data;
  }

  async getAtividadeEconomicaAutocomplete(termo) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAtividadeEconomicaAutocomplete = source;
    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/atividade_economica_autocomplete?termo=${termo}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtividadeEconomicaAutocomplete = null;

    return data;
  }

  async getMunicipioJucegAutocomplete(nome) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMunicipioJucegAutocomplete = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/municipio_juceg_autocomplete?nome=${nome}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMunicipioJucegAutocomplete = null;

    return data;
  }

  async getPessoasJuridicasJucegCsv({
    ...filtro
  }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoasJuridicasJucegCsv = source;

    const query = queryString.stringify({
      ...filtro,
    });

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/exportar_csv.csv?${query}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoasJuridicasJucegCsv = null;

    return data;
  }

  async getPessoasJuridicasJuceg({
    page,
    perPage,
    ...filtro
  }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoasJuridicasJuceg = source;

    const query = queryString.stringify({
      page,
      per_page: perPage,
      ...filtro,
    });

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg?${query}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoasJuridicasJuceg = null;

    return data;
  }

  async getPessoaJuridicaJuceg(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoaJuridicaJuceg = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/${id}`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPessoaJuridicaJuceg = null;

    return data;
  }

  async getPessoaJuridicaPdf(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPessoaJuridicaPdf = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/juceg/pessoas_juridicas_juceg/gerar_pdf.pdf?id=${id}`, {
        cancelToken: source.token,
        responseType: 'blob'
      }
    );

    fileDownload(data, `pessoa-juridica-juceg-${new Date().getTime()}.pdf`);

    this.cancelTokens.getPessoaJuridicaPdf = null;

    return data;
  }
}