import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

export default class AtracaoInvestimentosApi {
  cancelTokens = {
    getAtracaoInvestimentos: null,
    getAtracaoInvestimento: null,
    excluirAtracaoInvestimento: null,
    getQuemIniciouContato: null,
    getTiposProjeto: null,
    salvarAtracaoInvestimento: null,
    excluirBatchAtracaoInvestimentos: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getAtracaoInvestimentos({
    page = 1,
    perPage,
    pessoaJuridicaId,
    periodoDataInicio,
    periodoDataFim,
  }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getAtracaoInvestimentos = source;

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
      pessoa_juridica_id: pessoaJuridicaId,
      periodo_data_inicio: periodoDataInicio,
      periodo_data_fim: periodoDataFim,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtracaoInvestimentos = null;

    return data;
  }

  async getAtracaoInvestimento(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAtracaoInvestimento = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtracaoInvestimento = null;

    return { ...data, _completo: true };
  }

  async salvarAtracaoInvestimento(atracaoInvestimento) {
    let method;
    let url;
    if (atracaoInvestimento.id) {
      method = axiosAuthenticated.put;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/${atracaoInvestimento.id}`;
    } else {
      method = axiosAuthenticated.post;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos`;
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarAtracaoInvestimento = source;
    const { data } = await method(
      url,
      {
        ...atracaoInvestimento
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarAtracaoInvestimento = null;

    return data;
  }

  async excluirAtracaoInvestimento(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.excluirAtracaoInvestimento = source;

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/${id}`
    );

    this.cancelTokens.excluirAtracaoInvestimento = null;

    return data;
  }

  async excluirBatchAtracaoInvestimentos(ids) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.excluirBatchAtracaoInvestimentos = source;

    const searchString = ids.map((id) => `ids[]=${id}`).join('&')

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/destroy_batch?${searchString}`
    );

    this.cancelTokens.excluirBatchAtracaoInvestimentos = null;

    return data;
  }

  async getQuemIniciouContato() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getQuemIniciouContato = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/quem_iniciou_contato`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getQuemIniciouContato = null;

    return data;
  }

  async getTiposProjeto() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getTiposProjeto = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/atracao_investimentos/tipos_projeto`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposProjeto = null;

    return data;
  }
}
