import * as routeNames from "../routeNames";
import * as permissoes from "../../permissoes";

import PessoasJuridicasIndex from "../../views/pessoas_juridicas/PessoasJuridicasIndex";
import PessoaJuridicaForm from "../../views/pessoas_juridicas/PessoaJuridicaForm";
import PessoaJuridicaShow from "../../views/pessoas_juridicas/PessoaJuridicaShow";
import PessoasJuridicasAtracaoInvestimentoForm from "../../components/pessoas_juridicas/form/atracao_investimentos/PessoasJuridicasAtracaoInvestimentoForm"
import PessoasJuridicasProtocoloIntencaoForm from "../../components/pessoas_juridicas/form/protocolos_intencao/PessoasJuridicasProtocoloIntencaoForm";
import PessoasJuridicasContatoForm from "../../components/pessoas_juridicas/form/contatos_realizados/PessoasJuridicasContatoForm";

import PessoasJuridicasExibirAtracao from "../../components/pessoas_juridicas/show/atracao_investimentos/PessoasJuridicasExibirAtracao";
import PessoasJuridicasExibirProtocolo from "../../components/pessoas_juridicas/show/protocolos_intencao/PessoasJuridicasExibirProtocolo";
import PessoasJuridicasExibirContato from "../../components/pessoas_juridicas/show/contatos_realizados/PessoasJuridicasExibirContato";

const pessoasJuridicas = [{
    name: routeNames.PESSOAS_JURIDICAS_INDEX,
    component: PessoasJuridicasIndex,
    path: "/pessoas-juridicas",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO],
      menu: {
        label: "Pessoas Juridicas",
        exibir: true,
      },
      breadcrumb: { 
        label: 'Pessoas Jurídicas'
      }
    },
  },
  {
    name: routeNames.PESSOA_JURIDICA_NEW,
    component: PessoaJuridicaForm,
    path: "/pessoas-juridicas/new",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [
        permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO,
        permissoes.PESSOA_JURIDICA_EDICAO_PERMISSAO,
      ],
      exigirTodasPermissoes: true,
      breadcrumb: { 
        label: 'Nova Pessoa Jurídica',
        parent: routeNames.PESSOAS_JURIDICAS_INDEX
      }
    },
  },
  {
    name: routeNames.PESSOA_JURIDICA_EDIT,
    component: PessoaJuridicaForm,
    path: "/pessoas-juridicas/:id(\\d+)/edit",
    children: [
      {
        name: routeNames.PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
        component: PessoasJuridicasAtracaoInvestimentoForm,
        path: "/pessoas-juridicas/:id(\\d+)/edit/atracao-investimento",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_EDICAO_PERMISSAO, permissoes.ATRACAO_INVESTIMENTO_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
          exigirTodasPermissoes: true //exija que o usuário tenha todas as permissoes do array
        }
      },
      {
        name: routeNames.PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
        component: PessoasJuridicasProtocoloIntencaoForm,
        path: "/pessoas-juridicas/:id(\\d+)/edit/protocolo-intencao",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_EDICAO_PERMISSAO, permissoes.PROTOCOLO_INTENCAO_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
          exigirTodasPermissoes: true //exija que o usuário tenha todas as permissoes do array
        }
      },
      {
        name: routeNames.PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
        component: PessoasJuridicasContatoForm,
        path: "/pessoas-juridicas/:id(\\d+)/edit/contato-realizado",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_EDICAO_PERMISSAO, permissoes.CONTATOS_REALIZADOS_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
          exigirTodasPermissoes: true //exija que o usuário tenha todas as permissoes do array
        }
      }
    ],
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [
        permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO,
        permissoes.PESSOA_JURIDICA_EDICAO_PERMISSAO,
      ],
      exigirTodasPermissoes: true,
      breadcrumb: { 
        label: 'Editar Pessoa Jurídica',
        parent: routeNames.PESSOAS_JURIDICAS_INDEX
      }
    },
  },
  {
    name: routeNames.PESSOA_JURIDICA_SHOW,
    component: PessoaJuridicaShow,
    path: "/pessoas-juridicas/:id(\\d+)",
    children: [
      {
        name: routeNames.PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
        component: PessoasJuridicasExibirAtracao,
        path: "/pessoas-juridicas/:id(\\d+)/atracao-investimento/:atracaoId(\\d+)",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO, permissoes.ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO],
          exigirTodasPermissoes: true, //exija que o usuário tenha todas as permissoes do array
        }
      },
      {
        name: routeNames.PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
        component: PessoasJuridicasExibirProtocolo,
        path: "/pessoas-juridicas/:id(\\d+)/protocolo-intencao/:protocoloId(\\d+)",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO, permissoes.PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO],
          exigirTodasPermissoes: true //exija que o usuário tenha todas as permissoes do array
        }
      },
      {
        name: routeNames.PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
        component: PessoasJuridicasExibirContato,
        path: "/pessoas-juridicas/:id(\\d+)/contato-realizado/:contatoId(\\d+)",
        meta: {
          requerPermissao: true,
          raiz: false,
          permissoes: [permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO, permissoes.CONTATOS_REALIZADOS_EXIBIR_PERMISSAO],
          exigirTodasPermissoes: true //exija que o usuário tenha todas as permissoes do array
        }
      }
    ],
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.PESSOA_JURIDICA_BASICO_PERMISSAO],
      breadcrumb: { 
        label: 'Exibir Pessoa Jurídica',
        parent: routeNames.PESSOAS_JURIDICAS_INDEX
      }
    },
  },
];

export default pessoasJuridicas;