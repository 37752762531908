<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Razão Social</th>
          <th class="th-maior">CNPJ</th>
          <th>Nome Fantasia</th>
          <th class="th-maior">Classificação CNAE</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="pessoaJuridica in pessoasJuridicas" :key="pessoaJuridica.id">
          <td data-label="Razão Social:" class="topo-card">
            {{ pessoaJuridica.razao_social }}
          </td>

          <td data-label="CNPJ:">{{ pessoaJuridica.cnpj | cpfCnpj }}</td>
          <td data-label="Nome Fantasia:">
            {{ pessoaJuridica.nome_fantasia }}
          </td>
          <td data-label="Classificação CNAE:" class="codigo-cnae">
            {{ formarLabelCodigoCnae(pessoaJuridica.codigos_cnae) }}
          </td>
          <td class="acoes">
            <BotaoExibirTabela
              :to="{
                name: PESSOA_JURIDICA_SHOW,
                params: { id: pessoaJuridica.id },
              }"
            />

            <BotaoEditarTabela
              :to="{
                name: PESSOA_JURIDICA_EDIT,
                params: { id: pessoaJuridica.id },
              }"
              v-if="
                usuario.funcionalidades.includes(
                  PESSOA_JURIDICA_EDICAO_PERMISSAO
                )
              "
            />

            <BotaoDeletarTabela
              @click="excluir(pessoaJuridica.id)"
              v-if="usuarioPodeExcluir"
            />
          </td>
        </tr>
        <tr v-if="pessoasJuridicas.length == 0">
          <td class="text-center" colspan="5">Nenhum registro encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BotaoExibirTabela from "../botoes/botoes_tabelas_resultados/BotaoExibirTabela.vue";
import BotaoEditarTabela from "../botoes/botoes_tabelas_resultados/BotaoEditarTabela.vue";
import BotaoDeletarTabela from "../botoes/botoes_tabelas_resultados/BotaoDeletarTabela.vue";

import {
  PESSOA_JURIDICA_EXCLUSAO_PERMISSAO,
  PESSOA_JURIDICA_EDICAO_PERMISSAO,
} from "../../permissoes";

import {
  PESSOA_JURIDICA_SHOW,
  PESSOA_JURIDICA_EDIT,
} from "../../router/routeNames";

import * as Auth from "../../services/Auth";

export default {
  name: "PessoasJuridicasResultados",
  components: {
    BotaoEditarTabela,
    BotaoDeletarTabela,
    BotaoExibirTabela,
  },
  props: {
    pessoasJuridicas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const usuario = Auth.getUsuario();

    return {
      PESSOA_JURIDICA_SHOW,
      PESSOA_JURIDICA_EDIT,
      PESSOA_JURIDICA_EDICAO_PERMISSAO,
      usuario,
      usuarioPodeExcluir:
        usuario &&
        usuario.funcionalidades.includes(PESSOA_JURIDICA_EXCLUSAO_PERMISSAO),
    };
  },
  methods: {
    formarLabelCodigoCnae(codigosCnae) {
      return codigosCnae.map((cod) => cod.denominacao).join("\n");
    },
    excluir(id) {
      this.$emit("excluir-pessoa-juridica", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.codigo-cnae {
  white-space: pre-line;
}
.resultados {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
    thead {
      background-color: var(--verde-gaia);
      color: white;

      th {
        text-align: left;
        padding: 1.5rem 1rem;
      }
      .th-maior {
        min-width: 10rem;
      }
      .text-center {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 1rem 1rem;
      }
      tr:nth-child(even) {
        background-color: var(--cinza-caixa-gaia);
      }
      .text-center {
        text-align: center;
      }
      .acoes {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 2rem;
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          svg {
            fill: var(--vermelho-gaia);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: var(--cinza-caixa-gaia);
        width: 100% !important;
        padding: 0 0 1rem;
        border-radius: 2px;
        overflow: hidden;
        td {
          padding: 0rem;
          min-height: 1.94rem;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acoes {
          display: flex;
          margin-top: 2rem;
          justify-content: space-evenly;
          align-items: center;
          svg {
            height: 1.3rem;
            width: 1.3rem;
          }
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        color: var(--verde-escuro-gaia);
        margin-right: 0.5rem;
      }
      .acoes::before {
        display: none;
      }
      .topo-card {
        background-color: var(--verde-gaia);
        font-family: "lato-bold";
        text-transform: uppercase;
        margin: 0rem 0rem 1rem 0rem;
        padding: 0.5rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 50rem) {
  tbody {
    grid-template-columns: 1fr !important;
  }
}
</style>
