<template>
  <div class="caixa-form">
    <div class="header-caixa">
      <h4>Solicitações</h4>
    </div>
    <div
      class="caixa-item"
      v-for="(solicitacao, index) in solicitacoesEmpresa"
      :key="index"
    >
      <template>
        <PessoasJuridicasItemSolicitacao
          :solicitacao="solicitacoesEmpresa[index]"
          :index="solicitacoesEmpresa.length - index"
        />
      </template>
    </div>

    <div class="text-center" v-if="solicitacoesEmpresa.length === 0">
      <p>Nenhuma solicitação encontrada</p>
    </div>
  </div>
</template>

<script>
import PessoasJuridicasItemSolicitacao from "./PessoasJuridicasItemSolicitacao";

export default {
  name: "PessoasJuridicasListaSolicitacoes",
  props: {
    solicitacoesEmpresa: {
      type: Array,
      required: true,
    },
  },
  components: {
    PessoasJuridicasItemSolicitacao,
  },
};
</script>
<style lang="scss" scoped>
.caixa-form {
  .header-caixa {
    display: flex;
    align-items: center;
    h4 {
      margin: 0;
      font-size: 1.4rem;
      display: table;
      color: var(--verde-escuro-gaia);
    }
    h4::after {
      content: "";
      display: block;
      height: 2px;
      min-width: 100%;
      width: 30vw;
      max-width: 80vw;
      background-color: var(--verde-escuro-gaia);
      margin: 0.5rem 0 1rem;
    }
  }
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    position: relative;
  }
}
p {
  text-align: center;
  gap: 0.5rem;
}
.text-center {
  text-align: center;
}
</style>
