<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Pessoas Físicas</h1>
      <BotaoLink
        class="bt-novo"
        :to="{ name: CADASTROS_BASICOS_PESSOAS_FISICAS_NEW }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>

    <PessoasFisicasPesquisaForm @submit="buscar" v-model="filtro" />

    <AppLoading
      class="carregando1"
      v-if="carregandoPessoasFisicas && !erroCarregarPessoas"
    />
    <AppErro v-if="erroCarregarPessoas && !carregandoPessoasFisicas" />

    <div v-if="!carregandoPessoasFisicas && !erroCarregarPessoas">
      <div class="campo-header-resultados">
      <AppTotalResultadosEncontrados :total-resultados="totalResultados" />

        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>

      <PessoasFisicasResultados
        :pessoas-fisicas="pessoasFisicas"
        @excluir-pessoa-fisica="excluirPessoaFisica"
      />

      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import PessoasFisicasApi from "../../../services/api/PessoasFisicasApi";
import { DEFAULT_PER_PAGE } from "../../../config";
import {
  CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
  CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
} from "../../../router/routeNames";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import PessoasFisicasResultados from "../../../components/cadastros_basicos/pessoas_fisicas/PessoasFisicasResultados";
import PessoasFisicasPesquisaForm from "../../../components/cadastros_basicos/pessoas_fisicas/PessoasFisicasPesquisaForm";
import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados";
import BotaoLink from "../../../components/botoes/BotaoLink.vue";

const isEqual = require("lodash.isequal");

export default {
  name: "PessoasFisicasIndex",
  components: {
    AppLoading,
    AppErro,
    Paginate,
    PessoasFisicasResultados,
    PessoasFisicasPesquisaForm,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    BotaoLink,
  },
  metaInfo: {
    title: "Pessoas Físicas",
    titleTemplate: "%s - Gaia",
  },
  created() {
    this.pessoasFisicasApi = new PessoasFisicasApi();

    this.carregarPessoasFisicas();
  },
  data() {
    return {
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.perPage
        ? parseInt(this.$route.query.perPage)
        : DEFAULT_PER_PAGE,
      pessoasFisicas: [],
      totalResultados: null,
      carregandoPessoasFisicas: true,
      erroCarregarPessoas: false,
      filtro: {
        termo: this.$route.query.termo,
        servidor_sic: this.$route.query.servidor_sic,
      },
      CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
    };
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX &&
        !isEqual(to.query, from.query)
      ) {
        this.carregarPessoasFisicas();
      }
    },
  },
  methods: {
    async carregarPessoasFisicas() {
      try {
        this.carregandoPessoasFisicas = true;
        this.erroCarregarPessoas = false;

        this.filtro = {
          termo: this.$route.query.termo,
          servidor_sic: this.$route.query.servidor_sic,
        };

        const data = await this.pessoasFisicasApi.getPessoasFisicas({
          page: this.page,
          perPage: this.perPage,
          termo: this.filtro.termo,
          servidorSic: this.filtro.servidor_sic,
        });

        this.pessoasFisicas = data.resultados;
        this.totalResultados = data.total_resultados;

        this.carregandoPessoasFisicas = false;
      } catch (err) {
        console.log(err);

        this.carregandoPessoasFisicas = false;
        this.erroCarregarPessoas = true;
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    async excluirPessoaFisica(id) {
      let dialogo;

      dialogo = await this.$swal.fire({
        icon: "question",
        title: "Você tem certeza?",
        text: "Não será possivel reverter essa ação",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          //tenho que tratar o erro aqui, porque o sweetalert engole e nao fecha
          return this.pessoasFisicasApi
            .excluirPessoaFisica(id)
            .catch((error) => {
              console.log(error, error.response);
              const { data } = error.response;
              let mensagem =
                "Houve um erro ao excluir o registro. Por favor, tente novamente";

              if (data && Array.isArray(data)) {
                mensagem = data.join(",");
              } else if (data && data.mensagem) {
                mensagem = data.mensagem;
              }
              this.$swal({
                title: mensagem,
                icon: "error",
                showConfirmButton: true,
              });
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });

      if (dialogo.isConfirmed) {
        //excluido
        if (this.page > 1 && this.pessoasJuridicas.length === 1) {
          this.mudarPagina(this.page - 1);
        } else {
          this.carregarPessoasFisicas();
        }

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      }
    },
    async buscar(event) {
      event.preventDefault();

      this.page = 1;
      this.$router.push({
        name: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
        query: {
          ...this.filtro,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}

.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
