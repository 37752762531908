var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.atracaoInvestimento._destroy)?_c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]),_c('div',[_c('div',{staticClass:"row3"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Data de inicio: ")]),_vm._v(_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Área internacional: ")]),_vm._v(_vm._s(_vm.atracaoInvestimento.area_internacional ? "Sim" : "Não")+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Já estabelecida em Goiás: ")]),_vm._v(_vm._s(_vm.atracaoInvestimento.ja_estabelecida_em_goias ? "Sim" : "Não")+" ")])]),_c('div',{staticClass:"row3"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Quem iniciou o contato: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.atracaoInvestimento.quem_iniciou_contato_label))+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Empregos previstos: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.atracaoInvestimento.empregos_previstos))+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Investimentos previstos: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.atracaoInvestimento.empregos_previstos))+" ")])])]),_c('div',{staticClass:"botoes"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.excluir}},[_c('DeleteIcon'),_vm._v(" Excluir")],1),_c('router-link',{attrs:{"to":{
        name: _vm.PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
        query: {
          atracao_investimento_id: _vm.atracaoInvestimento.id,
          action: 'edit',
        },
        hash: '#atracao',
      }}},[_c('PencilIcon'),_vm._v(" Editar")],1)],1)]):_c('div',{staticClass:"caixa-dados-2"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]),_c('div',{staticClass:"desfazer-exclusao"},[_c('p',[_vm._v("Atração de investimento será excluida.")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.desfazerExclusao}},[_vm._v(" Desfazer exclusão ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }