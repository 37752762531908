import PessoasFisicasIndex from "../../../views/cadastros_basicos/pessoas_fisicas/PessoasFisicasIndex";
import PessoasFisicasShow from "../../../views/cadastros_basicos/pessoas_fisicas/PessoasFisicasShow";
import PessoasFisicasForm from "../../../views/cadastros_basicos/pessoas_fisicas/PessoasFisicasForm";

import {
  CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
  CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
  CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
  CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
} from "../../routeNames";

import { PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO } from "../../../permissoes";

export default [
  {
    name: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
    path: "/cadastros-basicos/pessoas-fisicas",
    component: PessoasFisicasIndex,
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO],
      breadcrumb: {
        label: "Pessoas Físicas",
      },
    },
  },
  {
    name: CADASTROS_BASICOS_PESSOAS_FISICAS_NEW,
    path: "/cadastros-basicos/pessoas-fisicas/new",
    component: PessoasFisicasForm,
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO],
      breadcrumb: {
        label: "Nova Pessoa Física",
        parent: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
      },
    },
  },
  {
    name: CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
    path: "/cadastros-basicos/pessoas-fisicas/:id/edit",
    component: PessoasFisicasForm,
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO],
      breadcrumb: {
        label: "Editar Pessoa Física",
        parent: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
      },
    },
  },
  {
    name: CADASTROS_BASICOS_PESSOAS_FISICAS_SHOW,
    path: "/cadastros-basicos/pessoas-fisicas/:id(\\d+)",
    component: PessoasFisicasShow,
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO],
      breadcrumb: {
        label: "Exibir Pessoa Física",
        parent: CADASTROS_BASICOS_PESSOAS_FISICAS_INDEX,
      },
    },
  },
];
