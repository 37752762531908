<template>
  <div class="caixa-show">
    <h4>Atividades Econômicas</h4>

    <div
      v-for="atividade_economica in pessoaJuridicaJuceg.atividades_economicas_juceg"
      :key="atividade_economica.id"
    >
      <div class="row3 
      caixa-dados">
        <p>
          <strong>CNAE: </strong>
          <span>{{ atividade_economica.cnae | naoInformado }}</span>
        </p>
        <p>
          <strong>Atividade: </strong>
          <span>{{ atividade_economica.atividade | naoInformado }}</span>
        </p>
        <p>
          <strong>Descrição: </strong>
          <span>{{ atividade_economica.descricao | naoInformado }}</span>
        </p>
      </div>
      <hr>
    </div>
    <div v-if="pessoaJuridicaJuceg.atividades_economicas_juceg.length < 1">
      <p class="nehum-resultado">Nenhuma atividade econômica encontrada</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasJucegShowListaAtividadesEconomicas",
  components: {},
  props: {
    pessoaJuridicaJuceg: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-show {
  h4 {
    margin: 1.5rem 0 0;
    display: table;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .grupos {
    margin-bottom: 1.5rem;
  }
  .caixa-dados {
    margin-bottom: 0rem;
    .grupo-dados {
      margin: 0.5rem 0;
      background-color: var(--cinza-caixa-gaia);
      padding: 0.5rem;
      p {
        margin: 0.5rem 0;
      }
    }
    .item {
      display: grid;
      margin: 0.5rem 0 1.5rem;
      p {
        margin-top: 0;
      }
    }
  }
}
.nenhum-resultado {
  text-align: center;
  padding-bottom: 1.5rem;
  font-style: italic;
}

@media screen and(min-width: '50em'){
  .row3{
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  p{
    display: grid;
    gap: .5rem;
  }
}
</style>
