<template>
  <div class="mensagem">
    <img
      src="../assets/images/alert-error.png"
      class="erro-img"
      alt=" alerta de erro "
    />
    <p>
      {{ mensagem }}
      <br />Por favor, recarregue a página.
    </p>
  </div>
</template>

<script>
export default {
  name: "AppErro",
  props: {
    mensagem: {
      type: String,
      default: "Houve um erro ao carregar os resultados.",
    },
  },
};
</script>

<style lang="scss" scoped>
.mensagem {
  margin-top: 4rem;
  .erro-img {
    width: 5rem;
  }
  text-align: center;
  margin-bottom: 4rem;
  p {
    color: var(--vermelho-gaia);
  }
}
</style>
