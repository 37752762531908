<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Pessoas Jurídicas JUCEG</h1>
    </div>
    <AppLoading class="carregando1" v-if="carregando" />
    <AppOverlayLoading class="carregando1" v-if="atualizando && !erroCarregar">
      <span>Aguarde... Isso pode demorar um pouco</span>
    </AppOverlayLoading>
    <AppErro v-if="erroCarregar && !carregando" />

    <PessoasJuridicasJucegPesquisaForm
      v-model="filtro"
      @submit.prevent="buscar"
      v-if="!carregando && !erroCarregar"
    />

    <div v-if="!carregando && !erroCarregar">
      <div class="campo-botoes-resultados">
        <button class="bt-exportar" type="button" @click="atualizarMuitos">
          <IconRecarregar /> Atualizar o Dia
        </button>
        <button class="bt-exportar" type="button" @click="exportarDadosCsv">
          <IconExport /> Exportar CSV
        </button>
      </div>
      <div class="campo-header-resultados">
        <AppTotalResultadosEncontrados :total-resultados="totalResultados" />
        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>
      <PessoasJuridicasJucegResultados
        :pessoas-juridicas-juceg="pessoasJuridicasJuceg"
      />
      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import { CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX } from "../../../router/routeNames";
import { DEFAULT_PER_PAGE } from "../../../config";

import PessoasJuridicasJucegApi from "../../../services/api/PessoasJuridicasJucegApi";

import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados";

import AppLoading from "../../../components/AppLoading.vue";
import AppOverlayLoading from "../../../components/AppOverlayLoading.vue";
import AppErro from "../../../components/AppErro.vue";

import PessoasJuridicasJucegResultados from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegResultados";
import PessoasJuridicasJucegPesquisaForm from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegPesquisaForm";

import filterNullsAndEmptyStrings from "../../../helpers/filterNullsAndEmptyStrings";

import IconExport from "../../../assets/images/icon-export.svg";
import IconRecarregar from "../../../assets/images/icon-recarregar.svg";

const isEqual = require("lodash.isequal");
const fileDownload = require("js-file-download");
// const get = require("lodash.get");

export default {
  name: "PessoasJuridicasJucegIndex",
  metaInfo: {
    title: "Pessoas Jurídicas JUCEG",
    titleTemplate: "%s - Gaia",
  },
  components: {
    AppLoading,
    AppResultadosPorPagina,
    AppErro,
    AppOverlayLoading,
    AppTotalResultadosEncontrados,
    Paginate,
    PessoasJuridicasJucegPesquisaForm,
    PessoasJuridicasJucegResultados,
    IconExport,
    IconRecarregar,
  },
  data() {
    return {
      pessoasJuridicasJuceg: [],
      totalResultados: 0,
      carregandoFiltro: true,
      carregando: true,
      erroCarregar: false,
      excluindo: false,
      atualizando: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      filtro: {
        cnpj: this.$route.query.cnpj,
        municipioFiltro: {},
        atividadeEconomica: {},
        nome_empresarial: this.$route.query.nome_empresarial,
        nire: this.$route.query.nire,
        situacao: this.$route.query.situacao,
        inicio_atividade: this.$route.query.inicio_atividade,
        termino_atividade: this.$route.query.termino_atividade,
        porte: this.$route.query.porte,
      },
    };
  },
  created() {
    this.pessoasJuridicasJucegApi = new PessoasJuridicasJucegApi();

    this.carregarPessoasJuridicasJuceg();
  },
  watch: {
    $route: function (to, from) {
      if (
        to.name == CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX &&
        !isEqual(to.query, from.query)
      ) {
        this.carregarPessoasJuridicasJuceg();
      }
    },
  },
  methods: {
    async atualizarMuitos() {
      try {
        const result = await this.$swal.fire({
          title: "Este processo vai demorar. Você tem certeza?",
          text: "Esta ação irá atualizar as pessoas jurídicas das ultimas 24hrs. Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.atualizando = true;
        this.erroCarregar = false;

        await this.pessoasJuridicasJucegApi.atualizaPessoasJuridica24hrs();

        this.atualizando = false;
      } catch (err) {
        console.log(err);
        this.atualizando = false;
      }
    },
    async exportarDadosCsv() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data =
          await this.pessoasJuridicasJucegApi.getPessoasJuridicasJucegCsv({
            ...this.$route.query,
          });

        fileDownload(
          data,
          `pessoas-juridicas-juceg-${new Date().getTime()}.csv`
        );

        this.carregando = false;
        this.carregandoFiltro = false;
      } catch (err) {
        if (err.response) {
          this.$swal({
            position: "center",
            icon: "error",
            title: err.response.data.mensagem,
            showConfirmButton: true,
          });
        } else {
          this.$swal({
            position: "center",
            icon: "error",
            title:
              "Não foi possível gerar o arquivo. Por favor, tente novamente.",
            showConfirmButton: true,
          });
        }
        console.log(err);
        this.carregando = false;
      }
    },

    async carregarPessoasJuridicasJuceg() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data =
          await this.pessoasJuridicasJucegApi.getPessoasJuridicasJuceg({
            ...this.$route.query,
            page: this.$route.query.page || this.page,
            perPage: this.$route.query.perPage || this.perPage,
          });

        this.pessoasJuridicasJuceg = data.resultados;
        this.totalResultados = data.total_resultados;

        this.carregando = false;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.erroCarregar = true;
        this.carregando = false;
        this.carregandoFiltro = false;
      }
    },
    buscar() {
      this.page = 1;
      // const codigoCnaeId = get(this.filtro, "codigo_cnae.id", null);

      //removendo municipioFiltro do objeto que será passado à busca
      const { municipioFiltro, atividadeEconomica, ...atributos } = this.filtro;
      const municipioId = municipioFiltro ? municipioFiltro.id : null;
      const atividadeEconomicaCnae = atividadeEconomica
        ? atividadeEconomica.cnae
        : null;

      this.$router.push({
        name: CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX,
        query: filterNullsAndEmptyStrings({
          ...atributos,
          atividade_economica_cnae: atividadeEconomicaCnae,
          municipio_id: municipioId,
          page: this.page,
          per_page: this.perPage,
        }),
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: CONSULTAS_JUCEG_PESSOAS_JURIDICAS_INDEX,
        query: filterNullsAndEmptyStrings({
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        }),
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}

.campo-header-resultados {
  margin-top: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
}
.campo-botoes-resultados {
  margin: 2rem 0 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.bt-exportar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  background-color: rgb(223, 223, 223);
  border: none;
  color: var(--verde-escuro-gaia);
  height: 2rem;
  border-radius: 1.75rem;
  cursor: pointer;
  transition: 0.2s;
  padding: 0 1rem;
  svg {
    fill: var(--verde-escuro-gaia);
    margin-right: 0.5rem;
    transition: 0.2s;
  }
}
.bt-exportar:hover {
  background-color: var(--verde-escuro-gaia);
  color: white;
  svg {
    fill: white;
  }
}
@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
