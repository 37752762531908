var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.pessoasJuridicas),function(pessoaJuridica){return _c('tr',{key:pessoaJuridica.id},[_c('td',{staticClass:"topo-card",attrs:{"data-label":"Razão Social:"}},[_vm._v(" "+_vm._s(pessoaJuridica.razao_social)+" ")]),_c('td',{attrs:{"data-label":"CNPJ:"}},[_vm._v(_vm._s(_vm._f("cpfCnpj")(pessoaJuridica.cnpj)))]),_c('td',{attrs:{"data-label":"Nome Fantasia:"}},[_vm._v(" "+_vm._s(pessoaJuridica.nome_fantasia)+" ")]),_c('td',{staticClass:"codigo-cnae",attrs:{"data-label":"Classificação CNAE:"}},[_vm._v(" "+_vm._s(_vm.formarLabelCodigoCnae(pessoaJuridica.codigos_cnae))+" ")]),_c('td',{staticClass:"acoes"},[_c('BotaoExibirTabela',{attrs:{"to":{
              name: _vm.PESSOA_JURIDICA_SHOW,
              params: { id: pessoaJuridica.id },
            }}}),(
              _vm.usuario.funcionalidades.includes(
                _vm.PESSOA_JURIDICA_EDICAO_PERMISSAO
              )
            )?_c('BotaoEditarTabela',{attrs:{"to":{
              name: _vm.PESSOA_JURIDICA_EDIT,
              params: { id: pessoaJuridica.id },
            }}}):_vm._e(),(_vm.usuarioPodeExcluir)?_c('BotaoDeletarTabela',{on:{"click":function($event){return _vm.excluir(pessoaJuridica.id)}}}):_vm._e()],1)])}),(_vm.pessoasJuridicas.length == 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("Nenhum registro encontrado")])]):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Razão Social")]),_c('th',{staticClass:"th-maior"},[_vm._v("CNPJ")]),_c('th',[_vm._v("Nome Fantasia")]),_c('th',{staticClass:"th-maior"},[_vm._v("Classificação CNAE")]),_c('th')])])}]

export { render, staticRenderFns }