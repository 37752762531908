var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"centro form-conteudo"},[_c('div',{staticClass:"form-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$route.name == _vm.PESSOA_JURIDICA_EDIT ? "Editar Pessoa Jurídica" : "Nova Pessoa Jurídica")+" ")]),(
        !_vm.carregandoPessoaJuridica &&
        !_vm.erroCarregamento &&
        _vm.$route.name == _vm.PESSOA_JURIDICA_EDIT
      )?_c('BotaoVisualizarForm',{attrs:{"to":{
        name: _vm.PESSOA_JURIDICA_SHOW,
        params: { id: _vm.$route.params.id },
        hash: _vm.$route.hash,
      }}}):_vm._e()],1),_c('div',[(_vm.carregandoPessoaJuridica && !_vm.erroCarregamento)?_c('AppLoading',{staticClass:"carregando1"}):_vm._e(),_c('div',{attrs:{"id":"erro-container"}},[(_vm.erroCarregamento && !_vm.carregandoPessoaJuridica)?_c('AppErro'):_vm._e()],1),(!_vm.carregandoPessoaJuridica && !_vm.erroCarregamento)?_c('AppTabs',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([(_vm.pessoaJuridica && _vm.pessoaJuridica.id)?{key:_vm.tabs[0].id,fn:function(){return [_c('PessoasJuridicasFormularioDadosGerais',{attrs:{"pessoa-juridica":_vm.pessoaJuridica,"tipos":_vm.tipos,"classificacoes":_vm.classificacoes,"tipos-enderecos":_vm.tiposEnderecos,"estados":_vm.estados,"paises":_vm.paises},on:{"salvou":_vm.fazerCopiaPessoaJuridica}})]},proxy:true}:null,(_vm.pessoaJuridica && _vm.pessoaJuridica.id)?{key:_vm.tabs[1].id,fn:function(){return [_c('PessoasJuridicasAtracaoInvestimentos',{attrs:{"pessoa-juridica-id":_vm.pessoaJuridica.id}})]},proxy:true}:null,(_vm.pessoaJuridica && _vm.pessoaJuridica.id)?{key:_vm.tabs[2].id,fn:function(){return [_c('PessoasJuridicasProtocolosIntencao',{attrs:{"pessoa-juridica-id":_vm.pessoaJuridica.id}})]},proxy:true}:null,(_vm.pessoaJuridica && _vm.pessoaJuridica.id)?{key:_vm.tabs[3].id,fn:function(){return [_c('PessoasJuridicasContatosRealizados',{attrs:{"pessoa-juridica-id":_vm.pessoaJuridica.id}})]},proxy:true}:null],null,true),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}}):_vm._e(),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }