<template>
  <div>
    <div class="caixa-dados" v-if="!contatoRealizado._destroy">
      <header class="header-caixa">
        <b>{{ index + 1 }}</b> - {{ contatoRealizado.data_contato | toDate }}
      </header>
      <div>
        <p class="item">
          <strong>Assunto:</strong> {{ contatoRealizado.assunto }}
        </p>
        <div class="row2">
          <p class="item">
            <strong>Pessoa que entrou em contato:</strong>
            {{
              contatoRealizado.pessoa_entrou_em_contato
                ? contatoRealizado.pessoa_entrou_em_contato.nome
                : "Não informado"
            }}
          </p>
          <p
            class="item"
            v-if="
              contatoRealizado.etapa_label && pessoaJuridica.tipo == 'empresa'
            "
          >
            <strong>Etapa: </strong>{{ contatoRealizado.etapa_label }}
          </p>
        </div>
      </div>
      <div class="botoes">
        <button type="button" @click="excluir"><DeleteIcon /> Excluir</button>
        <router-link
          :to="{
            name: PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
            query: {
              contato_realizado_id: contatoRealizado.id,
              action: 'edit',
            },
            hash: '#contatos',
          }"
          ><PencilIcon /> Editar</router-link
        >
      </div>
    </div>
    <div class="caixa-dados-2" v-else>
      <header class="header-caixa">
        <b>{{ index + 1 }}</b> - {{ contatoRealizado.data_contato | toDate }}
      </header>
      <div class="desfazer-exclusao">
      <p>Contato realizado será excluido.</p>
      <button @click="desfazerExclusao" type="button">Desfazer exclusão</button>
      </div>

    </div>
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";
import ContatoRealizado from "../../../../store/models/ContatoRealizado";
import { PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO } from "../../../../router/routeNames";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";

export default {
  name: "PessoasJuridicasContatosRealizadosItem",
  components: {
    PencilIcon,
    DeleteIcon,
  },
  props: {
    contatoRealizado: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    pessoaJuridica: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
    };
  },
  methods: {
    excluir() {
      if (this.contatoRealizado._persistido) {
        ContatoRealizado.update({
          ...this.contatoRealizado,
          _destroy: true,
        });
      } else {
        ContatoRealizado.delete(this.contatoRealizado.id);
      }
    },
    desfazerExclusao() {
      ContatoRealizado.update({
        ...this.contatoRealizado,
        _destroy: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: "Lato-Bold";
  }
  .item {
    padding: 0 1rem;
  }
  .botoes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      font-size: 1rem;
      border: none;
      color: var(--vermelho-gaia);
      padding: 0.5rem;
      cursor: pointer;
      svg {
        fill: var(--vermelho-gaia);
        margin-right: 0.5rem;
      }
    }
    a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: var(--verde-escuro-gaia);
    color: white;
    padding: 0.5rem;
    font-family: 'Lato-Bold';
      svg {
        fill: white;
        margin-right: 0.5rem;
      }
    }
  }
}
.caixa-dados-2 {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: "Lato-Bold";
  }

  p {
    text-align: center;
  }
  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    background-color: transparent;
    border:  var(--vermelho-gaia) solid 0.1rem;
    border-radius: 2px;
    color:  var(--vermelho-gaia);
    margin: 1rem auto;
    padding: 0.3rem 1rem;
    transition: 0.2s;
    svg {
      height: 1.3rem;
      width: 2rem;
      fill:  var(--vermelho-gaia);
      margin-right: 1rem;
      transition: 0.2s;
    }
  }
  button:hover {
    background-color: var(--vermelho-gaia);
    color: white;
    svg {
      fill: white;
    }
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
  .desfazer-exclusao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    button {
      margin: 0;
    }
  }
}
</style>
