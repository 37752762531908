<template>
  <span
    class="grupo-aviso"
    :class="{ 'is-mobile': mobile }"
    @click="exibirToaster"
  >
    <span class="info-icon"><InformationIcon /></span>
    <p><slot></slot></p>
  </span>
</template>

<script>
import InformationIcon from "vue-material-design-icons/Information.vue";

export default {
  name: "Tooltip",
  components: {
    InformationIcon,
  },
  data() {
    return {
      hover: false,
      mobile: false,
    };
  },
  mounted() {
    this.mediaQueryList = window.matchMedia("screen and (min-width: 70rem)");

    //executa a primeira vez
    this.screenSizeChanged(this.mediaQueryList);
    this.mediaQueryList.addEventListener("change", this.screenSizeChanged);
  },
  beforeDestroy() {
    this.mediaQueryList.removeEventListener("change", this.screenSizeChanged);
  },
  methods: {
    screenSizeChanged(event) {
      if (event.matches) {
        this.mobile = false;
      } else {
        this.mobile = true;
      }
    },
    exibirToaster() {
      if (
        !this.mobile ||
        !this.$slots.default ||
        this.$slots.default.length == 0
      )
        return;

      const { text } = this.$slots.default[0];
      this.$swal.fire({
        html: `<span style="display: block; text-align: center; color: white; font-family: 'Lato-Regular'">${text}</span>`,
        toast: true,
        padding: "0.5rem .5rem 1rem",
        width: "90%",
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        background: "#131313b9",
        animation: false,
        backdrop: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  display: none;
}

@media screen and (min-width: "70rem") {
  .grupo-aviso {
    position: relative;
  }
  p {
    display: block;
    position: absolute;
    bottom: 2.5rem;
    right: -0.6rem;
    width: 22rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    color: white;
    font-family: "Lato-Regular";
    background-color: #131313b9;
    border-radius: 0.5rem;
    opacity: 0;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }
  p::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-top: 0.75rem solid #131313b9;
    position: absolute;
    bottom: -0.75rem;
    right: 1rem;
  }
  .info-icon:hover ~ p {
    opacity: 1;
    overflow: visible;
  }
  .info-icon {
    svg {
      fill: var(--preto-gaia);
      margin-right: 0.4rem;
    }
  }
}
</style>
