<template>
  <div>
<img src="./../assets/images/sem-permissao.jpg" alt=" ">
    <h1>Usuário sem permissão</h1> 
  </div>
</template>

<script>
export default {
  name: "SemPermissao",
  metaInfo: {
    title: "Sem permissão",
    titleTemplate: "%s - Gaia",
  },
};
</script>

<style lang="scss" scoped>
div{
  text-align: center;
  h1{
    color: var(--preto-gaia);
    margin-top: 0;
  }
}
img{
  max-width: 20rem;
  width: 80%;
}

</style>
