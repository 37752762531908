import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import PolosIndustriaisIndex from "../../../views/investe_goias/polos_industriais/PolosIndustriaisIndex";
import PolosIndustriaisForm from "../../../views/investe_goias/polos_industriais/PolosIndustriaisForm";
import PolosIndustriaisShow from "../../../views/investe_goias/polos_industriais/PolosIndustriaisShow";

const rotasPolosIndustriais = [
  {
    name: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
    component: PolosIndustriaisIndex,
    path: "/investe-goias/polos-industriais",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.POLOS_INDUSTRIAIS_PERMISSAO],
      breadcrumb: {
        label: "Polos Industriais",
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW,
    component: PolosIndustriaisForm,
    path: "/investe-goias/polos-industriais/new",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.POLOS_INDUSTRIAIS_PERMISSAO],
      breadcrumb: {
        label: "Novo Polo Industrial",
        parent: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
    component: PolosIndustriaisShow,
    path: "/investe-goias/polos-industriais/:id",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.POLOS_INDUSTRIAIS_PERMISSAO],
      breadcrumb: {
        label: "Exibir Polo Industrial",
        parent: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
      },
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
    component: PolosIndustriaisForm,
    path: "/investe-goias/polos-industriais/:id/edit",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.POLOS_INDUSTRIAIS_PERMISSAO],
      breadcrumb: {
        label: "Editar Polo Industrial",
        parent: routeNames.INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
      },
    },
  },
];

export default rotasPolosIndustriais;
