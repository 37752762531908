import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import LinguagensIndex from "../../../views/investe_goias/linguagens/LinguagensIndex";
import LinguagensForm from "../../../views/investe_goias/linguagens/LinguagensForm";
import LinguagensShow from "../../../views/investe_goias/linguagens/LinguagensShow";

const rotasLinguagens = [
  {
    name: routeNames.INVESTE_GOIAS_LINGUAGENS_INDEX,
    component: LinguagensIndex,
    path: "/investe-goias/linguagens",
    meta: {
      requerPermissao: true,
      raiz: true,
      permissoes: [permissoes.LINGUAGENS_PERMISSAO],
      breadcrumb: { 
        label: 'Linguagens',
      }
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_LINGUAGENS_NEW,
    component: LinguagensForm,
    path: "/investe-goias/linguagens/new",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.LINGUAGENS_PERMISSAO],
      breadcrumb: { 
        label: 'Nova Linguagem',
        parent: routeNames.INVESTE_GOIAS_LINGUAGENS_INDEX
      }
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_LINGUAGENS_EDIT,
    component: LinguagensForm,
    path: "/investe-goias/linguagens/:id/edit",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.LINGUAGENS_PERMISSAO],
      breadcrumb: { 
        label: 'Editar Linguagem',
        parent: routeNames.INVESTE_GOIAS_LINGUAGENS_INDEX
      }
    },
  },
  {
    name: routeNames.INVESTE_GOIAS_LINGUAGENS_SHOW,
    component: LinguagensShow,
    path: "/investe-goias/linguagens/:id(\\d+)",
    meta: {
      requerPermissao: true,
      raiz: false,
      permissoes: [permissoes.LINGUAGENS_PERMISSAO],
      breadcrumb: { 
        label: 'Exibir Linguagem',
        parent: routeNames.INVESTE_GOIAS_LINGUAGENS_INDEX
      }
    },
  },
];

export default rotasLinguagens;
