import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

export default class CodigoMunicipioMapeamentoOportunidadeApi {
  cancelTokens = {
    getCodigoMunicipioMapeamentoOportunidade: null,
    getCodigosMunicipioMapeamentoOportunidade: null,
    salvarCodigoMunicipioMapeamentoOportunidade: null,
    removerCodigoMunicipioMapeamentoOportunidade: null,
    getMunicipioAutocomplete: null,
  };

  async getCodigosMunicipioMapeamentoOportunidade({
    page,
    perPage,
    municipio_id
  }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();
    this.cancelTokens.getCodigosMunicipioMapeamentoOportunidade = source;

    const query = queryString.stringify({
      page,
      per_page: perPage,
      municipio_id
    });

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/codigos_municipio_mapeamento_oportunidade?${query}`, {
      cancelToken: source.token
    }
    );
    this.cancelTokens.getCodigosMunicipioMapeamentoOportunidade = null;
    return data;
  }

  async getCodigoMunicipioMapeamentoOportunidade(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancelTokens.getCodigoMunicipioMapeamentoOportunidade = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/codigos_municipio_mapeamento_oportunidade/${id}`, {
      cancelToken: source.token
    }
    );
    this.cancelTokens.getCodigoMunicipioMapeamentoOportunidade = null;
    return data;
  }

  async salvarCodigoMunicipioMapeamentoOportunidade({
    id,
    codigo,
  }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancelTokens.salvarCodigoMunicipioMapeamentoOportunidade = source;

    let method;
    let url;

    method = axiosAuthenticated.put;
    url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/codigos_municipio_mapeamento_oportunidade/${id}`;

    const {
      data
    } = await method(url, {
      id,
      codigo,
    }, {
      cancelToken: source.token
    });
    this.cancelTokens.salvarCodigoMunicipioMapeamentoOportunidade = null;
    return data;
  }

  async removerCodigoMunicipioMapeamentoOportunidade(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.removerCodigoMunicipioMapeamentoOportunidade = source;

    const data = await axiosAuthenticated.delete(

      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/codigos_municipio_mapeamento_oportunidade/${id}/excluir_formulario`, {
      cancelToken: source.token,
    }

    )

    this.cancelTokens.removerCodigoMunicipioMapeamentoOportunidade = null

    return data

  }

  async getMunicipioAutocomplete(nome) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMunicipioAutocomplete = source;

    const {
      data
    } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/codigos_municipio_mapeamento_oportunidade/municipio_autocomplete?nome=${nome}`, {
      cancelToken: source.token,
    }
    );

    this.cancelTokens.getMunicipioAutocomplete = null;

    return data;
  }
}