//pessoas juridicas
export const PESSOA_JURIDICA_BASICO_PERMISSAO = "pessoas_juridicas#basico";
export const PESSOA_JURIDICA_EDICAO_PERMISSAO = "pessoas_juridicas#edicao";
export const PESSOA_JURIDICA_EXCLUSAO_PERMISSAO = "pessoas_juridicas#exclusao";

export const PESSOA_JURIDICA_JUCEG = "pessoas_juridicas_juceg";
//atracao_investimentos

export const ATRACAO_INVESTIMENTO_CRIAR_EDITAR_EXCLUIR_PERMISSAO = "atracao_investimentos#criar_editar_excluir";
export const ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO = "atracao_investimentos";

//protocolos_intencao

export const PROTOCOLO_INTENCAO_CRIAR_EDITAR_EXCLUIR_PERMISSAO = "protocolos_intencao#criar_editar_excluir"
export const PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO = "protocolos_intencao";

//contatos_realizados
export const CONTATOS_REALIZADOS_CRIAR_EDITAR_EXCLUIR_PERMISSAO = "contatos_realizados#criar_editar_excluir";
export const CONTATOS_REALIZADOS_EXIBIR_PERMISSAO = "contatos_realizados";

//municipios
export const MUNICIPIOS_PERMISSAO = "municipios";

//beneficios
export const BENEFICIOS_PERMISSAO = "beneficios";

//linguagens
export const LINGUAGENS_PERMISSAO = "linguagens";

//CONTATOS SITE
export const CONTATOS_SITE_PERMISSAO = "contatos_site";

//TIPOS INVESTIMENTO
export const TIPOS_INVESTIMENTO_PERMISSAO = "tipos_investimento";

// //pessoas fisicas CADASTROS BÁSICOS
export const PESSOAS_FISICAS_CADASTROS_BASICOS_PERMISSAO = "pessoas_fisicas";
export const PESSOAS_FISICAS_EXCLUIR_CADASTROS_BASICOS_PERMISSAO = "pessoas_fisicas#destroy"

// Meios de contato
export const MEIOS_CONTATO_PERMISSOES = "meios_contato"

// // Tipos Solicitacao Empresa
export const TIPOS_SOLICITACAO_EMPRESA_PERMISSAO = "tipos_solicitacao_empresa_atracao"

//polos industriais
export const POLOS_INDUSTRIAIS_PERMISSAO = "polos_industriais"

//tipos de area
export const TIPOS_AREA_PERMISSAO = "tipos_area";

// Mapeamento Oportunidades
export const MAPEAMENTO_OPORTUNIDADES_PERMISSOES = "mapeamento_oportunidades"

// Codigo de municipio do mapeamento de oportunidade
export const CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE = "codigo_municipio_mapeamento_oportunidade"