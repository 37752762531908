<template>
  <div>
    <div class="row3">
      <div class="item">
        <label>País: <span>*</span></label>
        <select @change="(event) => selecionarPais(event.target.value)">
          <option
            v-for="pais in paises"
            :key="pais.id"
            :value="pais.id"
            :selected="endereco.pais_id == pais.id"
          >
            {{ pais.nome_pt }}
          </option>
        </select>
      </div>

      <template v-if="paisSelecionado && paisSelecionado.padrao">
        <div class="item">
          <label>Estado: <span>*</span> </label>
          <select @change="(event) => selecionarEstado(event.target.value)">
            <option
              disabled
              :selected="
                estadoIdSelecionado == null || estadoIdSelecionado == undefined
              "
              >Selecione</option
            >
            <option
              v-for="estado in estados"
              :key="estado.id"
              :value="estado.id"
              :selected="
                estadoIdSelecionado && estadoIdSelecionado == estado.id
              "
              >{{ estado.nome }}</option
            >
          </select>
        </div>

        <div
          class="item"
          v-if="
            !carregandoMunicipios &&
              estadoSelecionado &&
              municipios &&
              municipios.length > 0
          "
        >
          <label>Município: <span>*</span> </label>
          <select @change="(event) => selecionarMunicipio(event.target.value)">
            <option disabled :selected="!endereco.municipio">Selecione</option>
            <option
              v-for="municipio in municipios"
              :key="municipio.id"
              :value="municipio.id"
              :selected="
                endereco.municipio && municipio.id == endereco.municipio.id
              "
              >{{ municipio.nome }}</option
            >
          </select>
        </div>
        <div
          class="item"
          v-if="carregandoMunicipios && !erroCarregarMunicipios"
        >
          <AppLoading size="small" />
        </div>
        <div
          class="item"
          v-if="!carregandoMunicipios && erroCarregarMunicipios"
        >
          Houve um erro ao carregar os municípios. Recarregue a página.
        </div>
      </template>
      <template v-if="paisSelecionado && !paisSelecionado.padrao">
        <div class="item">
          <label>Cidade / Estado: <span>*</span> </label>
          <input
            type="text"
            :value="endereco.cidade_estado"
            @input="(event) => updateValue('cidade_estado', event.target.value)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AppLoading from "../../../AppLoading";
import Endereco from "../../../../store/models/Endereco";
import EstadosApi from "../../../../services/api/EstadosApi";

export default {
  name: "PessoasJuridicasFormularioMunicipioSelect",
  props: {
    estados: {
      type: Array,
      required: true,
    },
    endereco: {
      type: Object,
    },
    paises: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppLoading,
  },
  data() {
    return {
      carregandoMunicipios: false,
      erroCarregarMunicipios: false,
      municipios: [],
      estadoIdSelecionado:
        this.endereco.municipio &&
        this.endereco.municipio.estado &&
        parseInt(this.endereco.municipio.estado.id),
    };
  },
  created() {
    this.estadosApi = new EstadosApi();
    if (this.endereco.municipio_id) {
      this.carregarMunicipios();
    }
  },
  mounted() {
    if (!this.endereco.pais_id) {
      const paisPadrao = this.paises.find((pais) => pais.padrao);

      if (paisPadrao) {
        this.selecionarPais(paisPadrao.id);
      }
    }
  },
  watch: {
    "endereco.municipio": function(newValue) {
      if (newValue && newValue.estado)
        this.estadoIdSelecionado = newValue.estado.id;
    },
    estadoIdSelecionado: {
      immediate: false,
      handler: function(newValue) {
        if (newValue) {
          this.carregarMunicipios();
        } else {
          this.municipios = [];
        }
      },
    },
  },
  methods: {
    selecionarPais(paisId) {
      this.estadoIdSelecionado = null;

      Endereco.update({
        ...this.endereco,
        municipio: null,
        municipio_id: null,
        pais_id: paisId,
      });
    },
    selecionarEstado(estadoId) {
      this.estadoIdSelecionado =
        estadoId == null || estadoId == undefined
          ? parseInt(estadoId)
          : estadoId;

      Endereco.update({
        ...this.endereco,
        municipio: null,
        municipio_id: null,
      });
    },
    async carregarMunicipios() {
      try {
        this.carregandoMunicipios = true;
        this.erroCarregarMunicipios = false;

        this.municipios = await this.estadosApi.getMunicipios(
          this.estadoIdSelecionado
        );

        this.carregandoMunicipios = false;
      } catch (err) {
        console.log(err);

        this.carregandoMunicipios = false;
        this.erroCarregarMunicipios = true;
      }
    },
    selecionarMunicipio(municipioId) {
      const municipio = this.municipios.find(
        (munic) => munic.id == this.endereco.municipio_id
      );

      Endereco.update({
        ...this.endereco,
        municipio,
        municipio_id: municipioId,
      });
    },
    async updateValue(key, value) {
      await Endereco.update({
        ...this.endereco,
        [key]: value,
      });
    },
  },
  computed: {
    estadoSelecionado() {
      if (this.estadoIdSelecionado) {
        const estado = this.estados.find(
          (est) => est.id == this.estadoIdSelecionado
        );
        return estado;
      }
      return null;
    },
    paisSelecionado() {
      if (this.endereco.pais_id) {
        return this.paises.find((pais) => pais.id == this.endereco.pais_id);
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: grid;
  margin: 0.5rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  select {
    height: 1.9rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    width: 100%;
  }
}

@media screen and (min-width: "50rem") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
