<template>
  <div>
    <h3>Os seguintes erros foram encontrados:</h3>
    <ul>
      <li v-for="erro in erros" :key="erro">{{ erro }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AppErrosFormularios",
  props: {
    erros: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
h3{
  margin: 0;
  color: var(--vermelho-gaia);
}
ul{
  color: var(--vermelho-gaia);
  margin-top: 0;
  margin-bottom: 1.5rem;
}
</style>
