<template>
  <div class="app-autores">
    <p v-if="criadoPor"><b>Criado por: </b>{{ criadoPor }}</p>
    <p v-if="createdAtNormalized">
      <b>Data de criação: </b>{{ createdAtNormalized }}
    </p>
    <p v-if="atualizadoPor">
      <b>Atualizado por: </b>{{ atualizadoPor }}
    </p>
    <p v-if="updatedAtNormalized">
      <b>Data de atualização: </b>{{ updatedAtNormalized }}
    </p>
  </div>
</template>

<script>
import { parseISO, format } from "date-fns";

export default {
  name: "AppAutores",
  props: {
    criadoPor: {
      type: String,
    },
    atualizadoPor: {
      type: String,
    },
    createdAt: {
      type: [Date, String],
    },
    updatedAt: {
      type: [Date, String],
    },
  },
  computed: {
    createdAtNormalized() {
      if (!this.createdAt) return "";

      const data =
        typeof this.createdAt == "string"
          ? format(parseISO(this.createdAt), "dd/MM/yyyy")
          : data;

      return data;
    },
    updatedAtNormalized() {
      if (!this.updatedAt) return "";

      const data =
        typeof this.updatedAt == "string"
          ? format(parseISO(this.updatedAt), "dd/MM/yyyy")
          : data;

      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
p{
  margin: .2rem;
  font-size: .8rem;
}
.app-autores{
  margin-top: 1rem;
  border-radius: 2px;
  padding: 1rem;
  background-color: var(--cinza-caixa-gaia);
}
</style>
