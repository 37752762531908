import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";


export default class MunicipiosGoiasApi {
    cancelTokens = {
        getMunicipiosGoias: null
    };

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown);
    }

    async getMunicipiosGoias() {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.getMunicipiosGoias = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/municipios_goias`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getMunicipiosGoias = null;

        return data;
    }
}
