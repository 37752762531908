<template>
  <button @click="click" title="Deletar">
    <DeleteIcon /><span class="nome">Excluir</span>
  </button>
</template>
<script>
import DeleteIcon from "vue-material-design-icons/Delete.vue";

export default {
  name: "BotaoDeletarTabela",
  methods: {
    click() {
      this.$emit("click");
    },
  },
  components: {
    DeleteIcon,
  },
};
</script>
<style lang="scss" scoped>
button {
  display: grid;
  color: var(--vermelho-gaia);
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  svg {
    fill: var(--vermelho-gaia);
  }
  .nome {
    display: none;
    font-family: "lato-bold";
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 64rem) {
  .nome {
    display: inline !important;
  }
}
</style>