<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Benefícios</h1>
      <BotaoLink
        class="bt-novo"
        :to="{ name: ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading
      class="carregando1"
      v-if="!paginaCarregou && !paginaComErro"
    ></AppLoading>
    <AppErro v-if="paginaCarregou && paginaComErro"></AppErro>
    <template v-if="paginaCarregou && !paginaComErro">
      <div>
        <div class="campo-header-resultados">
          <AppTotalResultadosEncontrados :totalResultados="totalResultados" />
          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <BeneficiosResultados
          :beneficios="beneficios"
          @remover-beneficio="removerBeneficio"
        />
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          >
          </Paginate>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import BeneficiosApi from "../../../../services/api/BeneficiosApi";
import BotaoLink from "../../../../components/botoes/BotaoLink.vue";
import { DEFAULT_PER_PAGE } from "../../../../config";

import {
  ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
  ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW,
  ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
} from "../../../../router/routeNames";
import filterNullsAndEmptyStrings from "../../../../helpers/filterNullsAndEmptyStrings";

// const isEqual = require("lodash.isequal");

import AppLoading from "../../../../components/AppLoading";
import AppErro from "../../../../components/AppErro";
import BeneficiosResultados from "../../../../components/atracao_investimentos/beneficios/BeneficiosResultados.vue";
import AppResultadosPorPagina from "../../../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../../../components/AppTotalResultadosEncontrados";

export default {
  name: "BeneficiosIndex",
  components: {
    AppLoading,
    AppErro,
    BotaoLink,
    Paginate,
    BeneficiosResultados,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
  },
  metaInfo: {
    title: "Benefícios - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      carregando: true,
      beneficios: [],
      totalResultados: 0,
      erroCarregando: false,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      filtro: {
        nome: this.$route.query.nome || "",
        beneficioId: this.$route.query.id || "",
      },
      ATRACAO_INVESTIMENTOS_BENEFICIOS_NEW,
      ATRACAO_INVESTIMENTOS_BENEFICIOS_EDIT,
      //
    };
  },
  created() {
    this.beneficiosApi = new BeneficiosApi();
    this.carregandoDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregandoDados();
      }
    },
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        // this.filtro = {
        //     nome: this.$route.query.nome || "",
        //     id: this.$route.query.id || null,
        // };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.beneficiosApi.getBeneficios({
          page: this.page,
          perPage: this.perPage,
        });

        this.totalResultados = data.total_resultados;
        this.beneficios = data.resultados;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.erroCarregando = true;
        this.carregando = false;
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
        query: filterNullsAndEmptyStrings({
          nome: this.filtro.nome,
          id: this.filtro.id,
          page: 1,
          per_page: this.perPage,
        }),
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
        query: {
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    limpar() {
      this.filtro = {
        nome: "",
        id: null,
      };
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    async removerBeneficio(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }
        this.carregando = true;

        await this.beneficiosApi.removerBeneficio({ id: id });

        if (this.beneficios && this.beneficios.length == 1 && this.page > 1) {
          this.page--;
          this.$router.push({
            name: ATRACAO_INVESTIMENTOS_BENEFICIOS_INDEX,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregandoDados();
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.carregando = false;
      } catch (err) {
        console.log(err, err.response);
        this.$swal({
          position: "center",
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    paginaCarregou() {
      return !this.carregando;
    },
    paginaComErro() {
      return this.erroCarregando;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  gap: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
