<template>
  <div class="caixa-form">
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
    <AppErro v-if="erroCarregar && !carregando" />
    <template v-if="!carregando && !erroCarregar">
      <div>
        <h4>Contatos Realizados</h4>
      </div>
      <PessoasJuridicasContatoRealizadoShowItem
        v-for="(contato, index) in contatosRealizados"
        :index="contatosRealizados.length - index"
        :contato-realizado="contato"
        :key="contato.id"
      />
      <div class="text-center" v-if="contatosRealizados.length === 0">
        <p>Nenhum registro encontrado</p>
      </div>
    </template>
  </div>
</template>

<script>
import AppErro from "../../../AppErro";
import AppLoading from "../../../AppLoading";

import ContatoRealizado from "../../../../store/models/ContatoRealizado";
import PessoasJuridicasContatoRealizadoShowItem from "./PessoasJuridicasContatoRealizadoShowItem";

export default {
  name: "PessoasJuridicasContatosRealizadosShowLista",
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    AppErro,
    AppLoading,
    PessoasJuridicasContatoRealizadoShowItem,
  },
  computed: {
    carregando() {
      return this.$store.state.entities.contatosRealizados
        .carregandoListaContatosRealizados;
    },
    erroCarregar() {
      return this.$store.state.entities.contatosRealizados
        .erroCarregarListaContatosRealizados;
    },
    contatosRealizados() {
      return ContatoRealizado.query()
        .where("pessoa_juridica_id", parseInt(this.pessoaJuridicaId))
        .withAll()
        .orderBy("data_contato", "desc")
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.text-center {
  text-align: center;
}
</style>
