<template>
  <div>
    <div class="row2-2">
      <div class="item">
        <label>Nome do órgão: <span>*</span> </label>
        <input
          type="text"
          :value="orgaoEnvolvido.nome_orgao"
          @input="(evt) => updateValue('nome_orgao', evt.target.value)"
          required
        />
      </div>

      <div class="item">
        <label>Data prevista de termino: </label>
        <DatePicker
          v-model="dataPrevista"
          @input="(value) => updateValue('data_prevista_termino', value)"
          format="DD/MM/YYYY"
        />
      </div>
    </div>
    <div class="item">
      <label>Atribuições: </label>
      <input
        type="text"
        :value="orgaoEnvolvido.atribuicoes"
        @input="(evt) => updateValue('atribuicoes', evt.target.value)"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { parseISO } from "date-fns";

export default {
  name: "PessoasJuridicasProtocoloOrgaoEnvolvido",
  model: {
    prop: "orgaoEnvolvido",
    event: "change",
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    orgaoEnvolvido: {
      type: Object,
      required: true,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      dataPrevista: null,
    };
  },
  watch: {
    "orgaoEnvolvido.data_prevista_termino": {
      immediate: true,
      handler: function(newDate) {
        if (newDate && typeof newDate == "string") {
          this.dataPrevista = parseISO(newDate);
        } else {
          this.dataPrevista = newDate;
        }
      },
    },
  },
  methods: {
    updateValue(key, value) {
      this.$emit("change", {
        ...this.orgaoEnvolvido,
        [key]: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  .mx-datepicker {
    width: 100%;
  }
}
@media screen and (min-width: "60rem") {
  .row2-2 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    .item {
      margin: 0;
    }
  }
}
</style>
