<template>
  <div>
    <!--<div>{{ index }}</div>-->
    <div class="row2-2">
      <div class="item">
        <label>Nome: <span>*</span></label>
        <input
          type="text"
          @input="(evt) => updateValue('nome', evt.target.value)"
          :value="arquivo.nome"
          required
        />
      </div>
      <div class="item" v-if="!arquivo.arquivo_url">
        <label>Arquivo: <span>*</span></label>
        <input
          type="file"
          :id="idFileInput"
          ref="file"
          @change="fileSelected"
          required
        />
      </div>
      <div class="item" v-if="arquivo.arquivo_url">
        <label> Arquivo atual:</label>
        <a :href="apiUrl + arquivo.arquivo_url" target="_blank"
          >Baixar arquivo</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasProtocoloArquivo",
  model: {
    prop: "arquivo",
    event: "change",
  },
  props: {
    arquivo: {
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      idFileInput: "file_" + Math.random().toString(),
      apiUrl: process.env.VUE_APP_BASE_API_URL,
    };
  },
  methods: {
    updateValue(key, value) {
      this.$emit("change", {
        ...this.arquivo,
        [key]: value,
      });
    },
    fileSelected() {
      this.$emit("change", {
        ...this.arquivo,
        arquivo: this.$refs.file.files[0],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    color: var(--verde-escuro-gaia);
    border: 1px solid var(--verde-escuro-gaia);
    text-align: center;
    width: 10rem;
    padding: 0.3rem 1rem;
    transition: 0.2s;
    font-family: 'Lato-Regular';
  }
  a:hover {
    background-color: var(--verde-escuro-gaia);
    color: white;
  }
}
@media screen and (min-width: "60rem") {
  .row2-2 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    .item {
      margin: 0;
    }
  }
}
</style>
