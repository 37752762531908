<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <a class="bt-fechar" @click="cancelar">
        <IconFechar />
      </a>

      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Contato Realizado</h1>

        <div v-if="!salvando && errosSalvar">
          <AppErroFormulario :erros="errosSalvar" />
        </div>

        <form @submit="salvar">
          <div class="row2-1">
            <div class="item">
              <label>Data de contato:<span>*</span></label>
              <DatePicker
                v-model="contatoRealizado.data_contato"
                format="DD/MM/YYYY"
                :input-attr="{ required: true }"
              />
            </div>

            <div class="item">
              <label>Servidor que entrou em contato: <span>*</span></label>
              <PessoaFisicaAutocomplete
                :servidor-sic="true"
                v-model="contatoRealizado.pessoa_entrou_em_contato"
              />
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>Pessoa contactada:<span>*</span></label>
              <input
                type="text"
                v-model="contatoRealizado.pessoa_contactada"
                required
              />
            </div>

            <div class="item">
              <label>Cargo do contato: </label>
              <input type="text" v-model="contatoRealizado.cargo_do_contato" />
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Meio de contato: <span>*</span></label>
              <select v-model="contatoRealizado.meio_contato_id">
                <option disabled :value="undefined">Selecione</option>
                <option
                  :value="meioContato.id"
                  v-for="meioContato in meiosContato"
                  :key="meioContato.id"
                >
                  {{ meioContato.nome }}
                </option>
              </select>
            </div>

            <template v-if="pessoaJuridica.tipo == 'empresa'">
              <div class="item">
                <label>Etapa: </label>
                <select v-model="contatoRealizado.etapa_type">
                  <option :value="null"> Outra </option>
                  <option
                    :value="etapa.value"
                    v-for="etapa in etapas"
                    :key="etapa.value"
                  >
                    {{ etapa.label }}
                  </option>
                </select>
              </div>
              <!-- quando etapa é protocolo de intencao -->
              <div
                class="item"
                v-if="
                  contatoRealizado.etapa_type ==
                    'SicNegocio::Gaia::Model::ProtocoloIntencao'
                "
              >
                <label>Protocolo de Intenção: <span>*</span> </label>
                <select v-model="contatoRealizado.etapa_id" required>
                  <option disabled :value="null">Selecione</option>
                  <option
                    v-for="protocolo in protocolosIntencao"
                    :key="protocolo.id"
                    :value="protocolo.id"
                  >
                    {{ protocolo.numero }} -
                    {{ protocolo.data_inicio | toDate }} ({{
                      protocolo.status_label
                    }})
                  </option>
                </select>
              </div>

              <!-- quando etapa é atracao investimento -->
              <div
                class="item"
                v-if="
                  contatoRealizado.etapa_type ==
                    'SicNegocio::Gaia::Model::AtracaoInvestimento'
                "
              >
                <label>Atração de Investimento:<span>*</span></label>
                <select v-model="contatoRealizado.etapa_id" required>
                  <option disabled :value="null">Selecione</option>
                  <option
                    v-for="atracao in atracaoInvestimentos"
                    :key="atracao.id"
                    :value="atracao.id"
                  >
                    Iniciada em {{ atracao.data_inicio | toDate }}
                  </option>
                </select>
              </div>
            </template>
          </div>
          <div class="item">
            <label>Assunto: <span>*</span></label>
            <input type="text" required v-model="contatoRealizado.assunto" />
          </div>

          <div class="item">
            <label>Conclusão: <span>*</span></label>
            <textarea required v-model="contatoRealizado.conclusao"></textarea>
          </div>

          <div class="botoes" v-if="!salvando">
            <button class="bt-cancelar" type="button" @click="cancelar">
              Cancelar
            </button>
            <button class="bt-salvar" type="submit"><IconSalvar /> Salvar</button>
          </div>
          <AppLoading v-if="salvando" size="small" texto="" />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import AppErroFormulario from "../../../AppErrosFormularios";
import PessoaFisicaAutocomplete from "../../../cadastros_basicos/pessoas_fisicas/PessoaFisicaAutocomplete";

import toDate from "../../../../filters/toDate";

import MeiosContatoApi from "../../../../services/api/MeiosContatoApi";

import { modulePessoaJuridica } from "../../../../store/moduleNames";
import { PESSOA_JURIDICA_EDIT } from "../../../../router/routeNames";
import ContatoRealizado from "../../../../store/models/ContatoRealizado";
import ContatosRealizadosApi from "../../../../services/api/ContatosRealizadosApi";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";
import processarMensagemErro from "../../../../helpers/processarMensagemErro";
import PessoaJuridica from "../../../../store/models/PessoaJuridica";
import IconSalvar from "../../../../assets/images/icon-save.svg"
import IconFechar from "../../../../assets/images/icon-fechar.svg"

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

const isEmpty = require("lodash.isempty");

export default {
  name: "PessoasJuridicasContatoForm",
  components: {
    AppErro,
    AppErroFormulario,
    AppLoading,
    DatePicker,
    PessoaFisicaAutocomplete,
    IconSalvar,
    IconFechar,
  },
  filters: {
    toDate,
  },
  data() {
    return {
      contatoRealizado: {
        pessoa_juridica_id: this.$route.params.id,
        etapa_type: null,
        etapa_id: null,
      },
      salvando: false,
      errosSalvar: null,
      carregandoValores: true,
      erroCarregarValores: false,
      meiosContato: [],
      etapas: [],
    };
  },
  async created() {
    this.meiosContatoApi = new MeiosContatoApi();
    this.contatosRealizadosApi = new ContatosRealizadosApi();

    this.carregarValores();

    if (this.$route.query.action == "edit") {
      if (
        (this.contatoRealizadoPersistido &&
          this.contatoRealizadoPersistido._persistido &&
          !this.contatoRealizadoPersistido._completo) ||
        !this.contatoRealizadoPersistido
      ) {
        await this.carregarContatoRealizado(
          this.$route.query.contato_realizado_id
        );
      } else if (this.contatoRealizadoPersistido) {
        this.contatoRealizado = this.contatoRealizadoToLocalObject(
          this.contatoRealizadoPersistido
        );
      }
    }
  },
  watch: {
    contatoRealizadoPersistido: function(newValue) {
      if (newValue.$toJson()) {
        this.contatoRealizado = this.contatoRealizadoToLocalObject(newValue);
      }
    },
    "contatoRealizado.etapa_type": function(newValue, oldValue) {
      if (oldValue) {
        this.contatoRealizado.etapa_id = null;
      }
    },
    "contatoRealizado.pessoa_entrou_em_contato": function(newValue) {
      if (!newValue || isEmpty(newValue)) {
        this.contatoRealizado.pessoa_entrou_em_contato_id = null;
      } else {
        this.contatoRealizado.pessoa_entrou_em_contato_id = newValue.id;
      }
    },
  },
  methods: {
    async carregarValores() {
      try {
        this.erroCarregarValores = false;
        this.carregandoValores = true;

        const [{ resultados: meiosContato }, etapas] = await Promise.all([
          this.meiosContatoApi.getMeiosContato({ page: 1, perPage: 100 }),
          this.contatosRealizadosApi.getEtapas(),
        ]);
        this.meiosContato = meiosContato;
        this.etapas = etapas;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);
        this.erroCarregarValores = true;
        this.carregandoValores = false;
      }
    },
    async salvar(event) {
      event.preventDefault();

      try {
        this.salvando = true;
        this.errosSalvar = null;

        const contatoRealizado = await this.contatosRealizadosApi.salvarContatoRealizado(
          this.contatoRealizado
        );

        await ContatoRealizado.insertOrUpdate({ data: contatoRealizado });

        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#contatos",
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Contato salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.salvando = false;
      } catch (err) {
        console.log(err);

        this.salvando = false;
        this.errosSalvar = processarMensagemErro(err.response);
      }
    },
    async cancelar() {
      const result = await this.$swal.fire({
        title: "Você deseja fechar essa tela? ",
        text: "As alterações feitas serão descartadas",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#054700",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#contatos",
        });
      }
    },
    contatoRealizadoToLocalObject(contato) {
      return {
        ...contato.$toJson(),
        data_contato: contato.dataContatoToDate,
      };
    },
    ...mapActions(["carregarContatoRealizado"]),
  },
  computed: {
    carregando() {
      //preciso dos dados de lista de atracao de investimento e protocolo de intencao para o select de etapa
      return (
        this.$store.state.entities.contatosRealizados
          .carregandoContatoRealizado ||
        this.$store.state.entities.contatosRealizados
          .carregandoListaContatosRealizados ||
        this.carregandoValores ||
        this.$store.state.entities.atracaoInvestimentos.carregandoLista ||
        this.$store.state.entities.protocolosIntencao
          .carregandoListaProtocolosIntencao
      );
    },
    erro() {
      return (
        this.$store.state.entities.contatosRealizados
          .erroCarregarContatoRealizado ||
        this.$store.state.entities.contatosRealizados
          .erroCarregarListaContatosRealizados ||
        this.erroCarregarValores ||
        this.$store.state.entities.atracaoInvestimentos.erroCarregarLista ||
        this.$store.state.entities.protocolosIntencao
          .erroCarregarListaProtocolosIntencao
      );
    },
    contatoRealizadoPersistido() {
      if (!this.$route.query.contato_realizado_id) return null;

      return ContatoRealizado.query()
        .whereId(parseInt(this.$route.query.contato_realizado_id))
        .withAll()
        .first();
    },
    protocolosIntencao() {
      return ProtocoloIntencao.query()
        .where("pessoa_juridica_id", parseInt(this.$route.params.id))
        .get();
    },
    atracaoInvestimentos() {
      return AtracaoInvestimento.query()
        .where("pessoa_juridica_id", parseInt(this.$route.params.id))
        .get();
    },
    pessoaJuridica() {
      return PessoaJuridica.query()
        .whereId(parseInt(this.$route.params.id))
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
}
.modal-caixa {
  background-color: white;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: scroll;
  position: relative;
  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
  h1 {
    color: var(--laranja-gaia);
  }
  .item {
    display: grid;
    margin: 1rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    textarea {
      height: 5rem;
    }
  }
  .botoes {
    margin: 2rem 0 2rem;
    display: grid;
    gap: 1rem;
    button {
      font-size: 1em;
      height: 2.5rem;
      border-radius: 2px;
      min-width: 15rem;
      cursor: pointer;
    }
    .bt-cancelar {
      background-color: transparent;
      border: var(--vermelho-gaia) solid 0.15rem;
      color: var(--vermelho-gaia);
    }

    .bt-cancelar:hover {
      background-color: var(--vermelho-gaia);
      border: var(--vermelho-gaia) solid 0.15rem;
      color: white;
    }
    .bt-salvar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--verde-escuro-gaia);
      color: white;
      border: none;
      svg {
        height: 1rem;
        width: 2rem;
      }
    }
  }
}
.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: 95vh;
    width: 80vw;
  }
  .row2-1 {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
  .botoes {
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
</style>
