var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-tela"},[_c('div',{staticClass:"modal-caixa"},[_c('router-link',{staticClass:"bt-fechar",attrs:{"to":{
        name: _vm.PESSOA_JURIDICA_SHOW,
        params: { id: _vm.$route.params.id },
        hash: _vm.$route.hash || '#contatos',
      }}},[_c('IconFechar')],1),(_vm.carregando && !_vm.erro)?_c('div',[_c('AppLoading')],1):_vm._e(),(!_vm.carregando && _vm.erro)?_c('div',[_c('AppErro')],1):_vm._e(),(!_vm.carregando && !_vm.erro)?[_c('h1',[_vm._v("Contato Realizado")]),_c('div',[_c('div',{staticClass:"row2-2"},[_c('p',[_c('strong',[_vm._v("Data de contato: ")]),_vm._v(_vm._s(_vm._f("toDate")(_vm.contatoRealizado.data_contato))+" ")]),_c('p',[_c('strong',[_vm._v("Servidor que entrou em contato: ")]),_vm._v(_vm._s(_vm.contatoRealizado.pessoa_entrou_em_contato ? _vm.contatoRealizado.pessoa_entrou_em_contato.nome : "Não informado")+" ")])]),_c('div',{staticClass:"row3"},[_c('p',[_c('strong',[_vm._v("Pessoa contactada: ")]),_vm._v(_vm._s(_vm.contatoRealizado.pessoa_contactada)+" ")]),_c('p',[_c('strong',[_vm._v("Cargo do contato: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.contatoRealizado.cargo_do_contato))+" ")]),_c('p',[_c('strong',[_vm._v("Meio de contato: ")]),_vm._v(_vm._s(_vm.contatoRealizado.meio_contato ? _vm.contatoRealizado.meio_contato.nome : "Não informado")+" ")])]),_c('div',{staticClass:"row3"},[(_vm.contatoRealizado.etapa_type)?_c('p',[_c('strong',[_vm._v("Etapa: ")]),_vm._v(_vm._s(_vm.contatoRealizado.etapa_label)+" ")]):_vm._e(),(
              _vm.contatoRealizado.etapa_type ==
                'SicNegocio::Gaia::Model::ProtocoloIntencao' &&
                _vm.protocoloIntencao
            )?_c('p',[_c('strong',[_vm._v("Protocolo de intenção: ")]),(
                _vm.usuario.funcionalidades.includes(
                  _vm.PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO
                )
              )?_c('router-link',{attrs:{"to":{
                name: _vm.PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
                params: {
                  id: _vm.$route.params.id,
                  protocoloId: _vm.protocoloIntencao.id,
                },
                hash: _vm.$route.hash,
              }}},[_vm._v(" "+_vm._s(_vm.protocoloIntencao.numero)+" - "+_vm._s(_vm._f("toDate")(_vm.protocoloIntencao.data_inicio))+" ("+_vm._s(_vm.protocoloIntencao.status_label)+") ")]):_c('span',[_vm._v(" "+_vm._s(_vm.protocoloIntencao.numero)+" - "+_vm._s(_vm._f("toDate")(_vm.protocoloIntencao.data_inicio))+" ("+_vm._s(_vm.protocoloIntencao.status_label)+") ")])],1):_vm._e(),(
              _vm.contatoRealizado.etapa_type ==
                'SicNegocio::Gaia::Model::AtracaoInvestimento' &&
                _vm.atracaoInvestimento
            )?_c('p',[_c('strong',[_vm._v("Atração de investimento: ")]),(
                _vm.usuario.funcionalidades.includes(
                  _vm.ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO
                )
              )?_c('router-link',{attrs:{"to":{
                name: _vm.PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
                params: {
                  id: _vm.$route.params.id,
                  atracaoId: _vm.atracaoInvestimento.id,
                },
                hash: _vm.$route.hash,
              }}},[_vm._v(" Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio))+" ")]):_c('span',[_vm._v("Iniciada em "+_vm._s(_vm._f("toDate")(_vm.atracaoInvestimento.data_inicio)))])],1):_vm._e()]),_c('p',[_c('strong',[_vm._v("Assunto: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.contatoRealizado.assunto))+" ")]),_c('p',[_c('strong',[_vm._v("Conclusão: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.contatoRealizado.conclusao))+" ")])])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }