<template>
  <div class="beneficios">
    <h4>Benefícios</h4>
    <div>
      <ul v-if="beneficios.length > 0">
        <li v-for="beneficio in beneficios" :key="beneficio.id">
          <span>&#10148;</span>{{ beneficio.nome }}
        </li>
      </ul>
      <p class="sem-conteudo" v-if="beneficios.length === 0">Nenhum benefício cadastrado</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MunicipioBeneficios",
  props: {
    beneficios: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.beneficios{
  h4 {
    margin: 1.5rem 0  0;
    display: table;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  ul{
    list-style: none;
    padding-left: 1rem;
    li{
      span{
        color:  var(--verde-gaia);
        margin-right: .5rem;
      }
    }
  }
  .sem-conteudo{
    font-style: italic;
  }
  ul{
    margin: .3rem;
  }
}
</style>
