import jwt_decode from "jwt-decode";

import AuthApi from "./api/AuthApi";

import { getWithExpiry, setWithExpiry } from "../helpers/localStorage";

import store from '../store';

const get = require("lodash.get");

const TOKEN_KEY = "aBYwLOl3gGO2AqQWdHNVdLqLAkpWBv2S";

export async function login(login, senha) {
  try {
    const authApi = new AuthApi();
    const token = await authApi.autenticar(login, senha);

    const decodedToken = jwt_decode(token);

    setWithExpiry(
      TOKEN_KEY,
      JSON.stringify(token),
      decodedToken.usuario.valido_por * 60000
    );
  } catch (err) {
    console.log(err);
    const mensagem = get(
      err,
      "response.data.mensagem",
      "Houve um erro ao fazer login. Por favor tente novamente"
    );
    throw mensagem;
  }
}

export function logout() {
  try {
    localStorage.removeItem(TOKEN_KEY);
    store.dispatch('entities/deleteAll')
  } catch (err) {
    console.log(err);
    const mensagem = get(
      err,
      "response.data.mensagem",
      "Houve um erro ao fazer logout. Por favor tente novamente"
    );
    throw mensagem;
  }
}

export function isLoggedIn() {
  return !!getUsuario();
}

export function getUsuario() {
  const tokenString = getWithExpiry(TOKEN_KEY);
  if (tokenString) {
    const decodedToken = jwt_decode(tokenString);
    if (decodedToken) {
      return decodedToken.usuario;
    }
    return null;
  }
  return null;
}

export function getToken() {
  return getWithExpiry(TOKEN_KEY);
}

export function setToken(value) {
  if (value) {
    const decodedToken = jwt_decode(value);

    setWithExpiry(
      TOKEN_KEY,
      JSON.stringify(value),
      decodedToken.usuario.valido_por * 60000
    );
  }
}
