var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caixa-form"},[(
      _vm.carregandoListaProtocolosIntencao &&
        !_vm.erroCarregarListaProtocolosIntencao
    )?_c('AppLoading'):_vm._e(),(
      _vm.erroCarregarListaProtocolosIntencao &&
        !_vm.carregandoListaProtocolosIntencao
    )?_c('AppErro'):_vm._e(),(
      !_vm.carregandoListaProtocolosIntencao &&
        !_vm.erroCarregarListaProtocolosIntencao
    )?[_vm._m(0),_vm._l((_vm.protocolosIntencao),function(protocoloIntencao,index){return _c('PessoasJuridicasItemProtocoloIntencao',{key:protocoloIntencao.id,attrs:{"index":_vm.protocolosIntencao.length - index,"protocolo-intencao":protocoloIntencao}})}),(_vm.protocolosIntencao.length === 0)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Nenhum registro encontrado")])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"disp-flex"},[_c('h4',[_vm._v("Protocolo de Intenção")])])}]

export { render, staticRenderFns }