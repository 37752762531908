import PessoasJuridicasApi from "../../../services/api/PessoasJuridicasApi";

import PessoaJuridica from "../../models/PessoaJuridica";


import {
  preencherNomeFantasiaAtual,
  preencherRazaoSocialAtual,
} from "./helpers";

const pessoasJuridicasApi = new PessoasJuridicasApi();

export default {
  async carregarPessoaJuridica(_context, id) {
    PessoaJuridica.commit((state) => {
      state.carregando = true;
      state.erroCarregar = false;
    });

    try {
      const pessoaJuridica = await pessoasJuridicasApi.getPessoaJuridica(id);

      preencherNomeFantasiaAtual(pessoaJuridica);
      preencherRazaoSocialAtual(pessoaJuridica);

      await PessoaJuridica.insert({
        data: pessoaJuridica,
      });

      PessoaJuridica.commit((state) => {
        state.carregando = false;
        state.erroCarregar = false;
      });
    } catch (err) {
      console.log(err);
      PessoaJuridica.commit((state) => {
        state.carregando = false;
        state.erroCarregar = true;
      });
    }
  },
};
