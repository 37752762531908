<template>
  <div>
    <h4>Contatos</h4>

    <div class="caixa-dados">
      <div
        class="grupo-dados"
        v-for="contato in contatosPersistidos"
        :key="contato.id"
      >
        <div class="row2-2">
          <div class="item">
            <strong>Nome: </strong>
            <span>{{ contato.nome }}</span>
          </div>

          <div class="item">
            <strong>Cargo: </strong>
            <span>{{ contato.cargo | naoInformado }}</span>
          </div>
        </div>
        <div class="row2-2">
          <div class="item">
            <strong>Departamento: </strong>
            <span>{{ contato.departamento | naoInformado }}</span>
          </div>
          <div class="item">
            <strong>CPF / CNPJ: </strong>
            <span>{{ contato.cpf_cnpj | cpfCnpj }}</span>
          </div>
          <div class="item">
            <strong>Email: </strong>
            <span>{{ contato.email | naoInformado }}</span>
          </div>
          <div class="item">
            <strong>Telefone: </strong>
            <span>{{ contato.telefone | naoInformado }}</span>
          </div>
        </div>
      </div>
      <p class="nenhum-resultado" v-if="contatosPersistidos.length === 0">
        Nenhum contato cadastrado
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasContatosInfo",
  props: {
    contatos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    contatosPersistidos() {
      return this.contatos.filter((contato) => contato._persistido);
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.grupos {
  margin-bottom: 1.5rem;
}
.caixa-dados {
  background-color: white;
  padding: 0.5rem 0.5rem 0;
  .grupo-dados {
    margin-top: 0.5rem;
    border-bottom: solid 0.1rem var(--cinza-caixa-gaia);
  }
  .item {
    display: grid;
    gap: 0.5rem;
    margin: 0.5rem 0 1.5rem;
    p {
      margin-top: 0;
    }
  }
}
.nenhum-resultado {
  text-align: center;
  font-style: italic;
  padding-bottom: 1.5rem;
}
@media screen and(min-width: '50em') {
  .row2-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0 1rem;
    align-items: baseline;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
}
</style>
