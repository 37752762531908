<template>
  <div class="caixa-dados" v-if="!atracaoInvestimento._destroy">
    <header class="header-caixa">
      <b>{{ index + 1 }}</b> - Iniciada em
      {{ atracaoInvestimento.data_inicio | toDate }}
    </header>

    <div>
      <div class="row3">
        <p class="item">
          <strong>Data de inicio: </strong
          >{{ atracaoInvestimento.data_inicio | toDate }}
        </p>
        <p class="item">
          <strong>Área internacional: </strong
          >{{ atracaoInvestimento.area_internacional ? "Sim" : "Não" }}
        </p>
        <p class="item">
          <strong>Já estabelecida em Goiás: </strong
          >{{ atracaoInvestimento.ja_estabelecida_em_goias ? "Sim" : "Não" }}
        </p>
      </div>
      <div class="row3">
        <p class="item">
          <strong>Quem iniciou o contato: </strong
          >{{ atracaoInvestimento.quem_iniciou_contato_label | naoInformado }}
        </p>

        <p class="item">
          <strong>Empregos previstos: </strong
          >{{ atracaoInvestimento.empregos_previstos | naoInformado }}
        </p>

        <p class="item">
          <strong>Investimentos previstos: </strong
          >{{ atracaoInvestimento.empregos_previstos | naoInformado }}
        </p>
      </div>
    </div>
    <div class="botoes">
      <button type="button" @click="excluir"><DeleteIcon /> Excluir</button>
      <router-link
        :to="{
          name: PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
          query: {
            atracao_investimento_id: atracaoInvestimento.id,
            action: 'edit',
          },
          hash: '#atracao',
        }"
        ><PencilIcon /> Editar</router-link
      >
    </div>
  </div>
  <div class="caixa-dados-2" v-else>
    <header class="header-caixa">
      <b>{{ index + 1 }}</b> - Iniciada em
      {{ atracaoInvestimento.data_inicio | toDate }}
    </header>
    <div class="desfazer-exclusao">
      <p>Atração de investimento será excluida.</p>
      <button @click="desfazerExclusao" type="button">
        Desfazer exclusão
      </button>
    </div>
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";

import PencilIcon from "vue-material-design-icons/Pencil.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";

import { PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO } from "../../../../router/routeNames";

export default {
  name: "PessoasJuridicasAtracaoItem",

  components: {
    PencilIcon,
    DeleteIcon,
  },
  props: {
    atracaoInvestimento: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
    };
  },
  methods: {
    excluir() {
      if (this.atracaoInvestimento._persistido) {
        AtracaoInvestimento.update({
          ...this.atracaoInvestimento,
          _destroy: true,
        });
      } else {
        AtracaoInvestimento.delete(this.atracaoInvestimento.id);
      }
    },
    desfazerExclusao() {
      AtracaoInvestimento.update({
        ...this.atracaoInvestimento,
        _destroy: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color:  var(--verde-gaia);
    padding: 0.5rem .5rem .5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: 'Lato-Bold';
  }
  .item {
    padding: 0 1rem;
  }
  .botoes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      font-size: 1rem;
      border: none;
      color: var(--vermelho-gaia);
      padding: 0.5rem;
      cursor: pointer;
      svg {
        fill: var(--vermelho-gaia);
        margin-right: 0.5rem;
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-decoration: none;
      background-color: var(--verde-escuro-gaia);
      color: white;
      padding: 0.5rem;
      font-family: 'Lato-Bold';
      svg {
        fill: white;
        margin-right: 0.5rem;
      }
    }
  }
}
.caixa-dados-2 {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color:  var(--verde-gaia);
    padding: 0.5rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: 'Lato-Bold';
  }

  p {
    text-align: center;
  }
  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    background-color: transparent;
    border: var(--vermelho-gaia) solid 0.1rem;
    border-radius: 2px;
    color: var(--vermelho-gaia);
    margin: 1rem auto;
    padding: 0.3rem 1rem;
    transition: 0.2s;
    svg {
      fill: var(--vermelho-gaia);
      margin-right: 1rem;
      transition: 0.2s;
    }
  }
  button:hover {
    background-color: var(--vermelho-gaia);
    color: white;
    svg {
      fill: white;
    }
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
  .desfazer-exclusao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    button {
      margin: 0;
    }
  }
}
</style>
