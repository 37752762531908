export default function filterNullsAndEmptyStrings(object) {
    let newObject = {};
  
    Object.keys(object).forEach((key) => {
      if (object[key] != null && object[key] != undefined && object[key] !== "") {
        newObject[key] = object[key];
      }
    });
  
    return newObject;
  }
  