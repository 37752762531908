<template>
  <div>
    <router-link :to="to" title="Editar"
      ><PencilIcon /><span class="nome">Editar</span>
    </router-link>
  </div>
</template>
<script>
import PencilIcon from "vue-material-design-icons/Pencil.vue";

export default {
  name: "BotaoEditarTabela",
  components: {
    PencilIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: grid;
  text-align: center;
  text-decoration: none;
  span {
    font-size: 0.8rem;
    font-family: "lato-bold";
    color: var(--verde-escuro-gaia);
  }
}
svg {
  fill: var(--verde-escuro-gaia);
}
.nome {
  display: none;
  font-family: "lato-bold";
  font-size: 0.8rem;
}

@media screen and (max-width: 64rem) {
  .nome {
    display: inline;
  }
}
</style>