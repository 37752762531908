<script>
export default {
  name: "AppBreadcrumbs",
  render() {
    if (!this.$route || !this.$route.meta || !this.$route.meta.breadcrumb)
      return null;

    const routes = [];
    const allRoutes = this.$router.getRoutes();

    let currentRoute = this.$route;

    while (currentRoute && currentRoute.meta?.breadcrumb) {
      routes.unshift(currentRoute);

      if (currentRoute.meta.breadcrumb.parent) {
        currentRoute = allRoutes.find(
          (route) => route.name == currentRoute.meta.breadcrumb.parent
        );
      } else {
        break;
      }
    }

    if (routes.length < 2) return null;

    return (
      <div class="centro">
        {routes.map((route, index) => {
          if (index + 1 == routes.length)
            return <span class="pg-atual">{route.meta.breadcrumb.label}</span>;

          return (
            <span>
              <router-link
                to={{
                  name: route.name,
                  params: route.params,
                  query: route.query,
                }}
              >
                {route.meta.breadcrumb.label}
              </router-link>
              <span class="separador"> > </span>
            </span>
          );
        })}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.centro {
  margin-top: 2rem;
  font-size: 0.9rem;
  padding: .5rem;
  .separador {
    margin: 0 0.5rem;
  }
  a {
    text-decoration: none;
    color: var(--preto-gaia);
  }
  a:hover{
    text-decoration: underline;
  }
  span {
    color: var(--preto-gaia);
  }
}
</style>
