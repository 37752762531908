import ModelPersistido from "./ModelPersistido";
import PessoaJuridica from "./PessoaJuridica";

export default class HistoricoRazaoSocial extends ModelPersistido {
  static entity = "historicoRazoesSociais";

  static fields() {
    return {
      id: this.number(null).nullable(),
      razao_social: this.string(null).nullable(),
      data_inicio: this.attr(null),
      data_fim: this.attr(null),
      pessoa_juridica_id: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
    };
  }
}
