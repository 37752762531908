<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <h1>Pessoa Jurídica</h1>
      <BotaoEditarShow
        :to="{
          name: PESSOA_JURIDICA_EDIT,
          params: { id: $route.params.id },
          hash: $route.hash,
        }"
        v-if="
          usuario.funcionalidades.includes(PESSOA_JURIDICA_EDICAO_PERMISSAO)
        "
      />
    </div>

    <div>
      <AppLoading
        class="carregando1"
        v-if="carregandoPessoaJuridica && !erroCarregarPessoaJuridica"
      />
      <AppErro v-if="erroCarregarPessoaJuridica && !carregandoPessoaJuridica" />

      <AppTabs
        v-if="!carregandoPessoaJuridica && !erroCarregarPessoaJuridica"
        :tabs="tabs"
        v-model="currentTab"
      >
        <template v-slot:[tabs[0].id]>
          <PessoasJuridicasShowDadosGerais :pessoa-juridica="pessoaJuridica" />
        </template>
        <template v-slot:[tabs[1].id] v-if="pessoaJuridica">
          <PessoasJuridicasListaAtracaoInvestimentos
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>
        <template v-slot:[tabs[2].id] v-if="pessoaJuridica">
          <PessoasJuridicasListaProtocolosIntencao
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>

        <template v-slot:[tabs[3].id] v-if="pessoaJuridica">
          <PessoasJuridicasContatosRealizadosShowLista
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>
      </AppTabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import AppLoading from "../../components/AppLoading";
import AppErro from "../../components/AppErro.vue";
import BotaoEditarShow from "../../components/botoes/BotaoEditarShow.vue";

import { getUsuario } from "../../services/Auth";

import AppTabs from "../../components/AppTabs";
import PessoasJuridicasShowDadosGerais from "../../components/pessoas_juridicas/show/dados_gerais/PessoasJuridicasShowDadosGerais";
import PessoasJuridicasListaAtracaoInvestimentos from "../../components/pessoas_juridicas/show/atracao_investimentos/PessoasJuridicasListaAtracaoInvestimentos";
import PessoasJuridicasListaProtocolosIntencao from "../../components/pessoas_juridicas/show/protocolos_intencao/PessoasJuridicasListaProtocolosIntencao";
import PessoasJuridicasContatosRealizadosShowLista from "../../components/pessoas_juridicas/show/contatos_realizados/PessoasJuridicasContatosRealizadosShowLista";

import {
  PESSOA_JURIDICA_EDIT,
  PESSOA_JURIDICA_SHOW,
  PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
  PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
  PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
} from "../../router/routeNames";
import PessoaJuridica from "../../store/models/PessoaJuridica";

import {
  PESSOA_JURIDICA_EDICAO_PERMISSAO,
  ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO,
  PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO,
  CONTATOS_REALIZADOS_EXIBIR_PERMISSAO,
} from "../../permissoes";

import { modulePessoaJuridica } from "../../store/moduleNames";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

const tabs = [
  {
    id: 1,
    label: "Dados Pessoa Jurídica",
    permissoes: [],
    disabled: false,
    hash: "#geral",
  },
  {
    id: 2,
    label: "Atração de Investimentos",
    permissoes: [ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO],
    disabled: true,
    hash: "#atracao",
  },
  {
    id: 3,
    label: "Protocolos de Intenção",
    permissoes: [PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO],
    disabled: true,
    hash: "#protocolo",
  },
  {
    id: 4,
    label: "Contatos Realizados",
    permissoes: [CONTATOS_REALIZADOS_EXIBIR_PERMISSAO],
    disabled: true,
    hash: "#contatos",
  },
];

export default {
  name: "PessoaJuridicaShow",
  metaInfo: {
    title: "Exibir Pessoa Jurídica",
    titleTemplate: "%s - Gaia",
  },
  data() {
    this.usuario = getUsuario();

    return {
      tabs: this.habilitarDesabilitarTabs(tabs),
      currentTab: this.getCurrentTab(tabs), // pegamos a primeira tab com permissao e que inclua o hash
      PESSOA_JURIDICA_EDIT,
      PESSOA_JURIDICA_EDICAO_PERMISSAO,
    };
  },
  components: {
    AppLoading,
    AppErro,
    AppTabs,
    PessoasJuridicasListaAtracaoInvestimentos,
    PessoasJuridicasShowDadosGerais,
    PessoasJuridicasListaProtocolosIntencao,
    PessoasJuridicasContatosRealizadosShowLista,
    BotaoEditarShow,
  },
  async created() {
    await this.carregarPessoaJuridica(this.$route.params.id);

    if (
      this.usuario.funcionalidades.some((funcionalidade) =>
        [
          ATRACAO_INVESTIMENTO_EXIBIR_PERMISSAO,
          PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO,
        ].includes(funcionalidade)
      )
    ) {
      this.carregarListaAtracaoInvestimentos(this.$route.params.id);
    }

    if (
      this.usuario.funcionalidades.some((funcionalidade) =>
        [PROTOCOLO_INTENCAO_EXIBIR_PERMISSAO].includes(funcionalidade)
      )
    ) {
      this.carregarListaProtocolosIntencao(this.$route.params.id);
    }

    if (
      this.usuario.funcionalidades.some((funcionalidade) =>
        [CONTATOS_REALIZADOS_EXIBIR_PERMISSAO].includes(funcionalidade)
      )
    ) {
      this.carregarListaContatosRealizados(this.$route.params.id);
    }

    this.tabs = this.habilitarDesabilitarTabs(this.tabs);
    this.currentTab = this.getCurrentTab(this.tabs);
    // verificamos se alguem tentou acessar diretamente pela url uma tab que
    //  nao existe para a pessoa juridica especificada.
    if (
      ["#atracao", "#protocolo"].includes(this.currentTab.hash) &&
      this.pessoaJuridica.tipo != "empresa"
    ) {
      this.currentTab = this.tabs[0];
    }

    PessoaJuridica.commit((state) => {
      state.carregando = false;
    });
  },
  methods: {
    habilitarDesabilitarTabs(tabs) {
      return tabs.map((tab) => {
        return {
          ...tab,
          disabled:
            tab.permissoes.length > 0 &&
            (!tab.permissoes.every((t) =>
              this.usuario.funcionalidades.includes(t)
            ) ||
              ![
                PESSOA_JURIDICA_SHOW,
                PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
                PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
                PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
              ].includes(this.$route.name) ||
              (["#protocolo", "#atracao"].includes(tab.hash) &&
                this.pessoaJuridica &&
                this.pessoaJuridica.tipo != "empresa")),
        };
      });
    },
    getCurrentTab(tabs) {
      let currentTab =
        tabs.find((tab) => {
          return (
            tab.permissoes.every((t) =>
              this.usuario.funcionalidades.includes(t)
            ) && tab.hash == this.$route.hash
          );
        }) || tabs[0];

      if (currentTab) {
        if (
          ["#atracao", "#protocolo"].includes(currentTab.hash) &&
          this.pessoaJuridica &&
          this.pessoaJuridica.tipo != "empresa"
        ) {
          currentTab = tabs[0];
        }
      }

      return currentTab;
    },
    ...mapActions([
      "carregarPessoaJuridica",
      "carregarListaAtracaoInvestimentos",
      "carregarListaProtocolosIntencao",
      "carregarListaContatosRealizados",
    ]),
  },
  watch: {
    currentTab: function(newTab) {
      this.$router.push({ name: PESSOA_JURIDICA_SHOW, hash: newTab.hash });
    },
    "$route.hash": function() {
      this.currentTab = this.getCurrentTab(this.tabs);
    },
    // pessoaJuridica: function() {
    //   this.tabs = this.habilitarDesabilitarTabs(this.tabs);
    // },
  },
  computed: {
    carregandoPessoaJuridica() {
      return this.$store.state.entities.pessoasJuridicas.carregando;
    },
    erroCarregarPessoaJuridica() {
      return this.$store.state.entities.pessoasJuridicas.erroCarregar;
    },
    pessoaJuridica() {
      const id = this.$route.params.id;
      return PessoaJuridica.query()
        .whereId(isNaN(id) ? id : parseInt(id))
        .withAllRecursive()
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0rem;
    gap: 1.5rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
}
</style>
