import { parseISO } from "date-fns";

import ModelPersistido from "./ModelPersistido";

import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";
import ProtocoloIntencao from "./ProtocoloIntencao";

export default class OrgaoEnvolvido extends ModelPersistido {
    static entity = 'orgaosEnvolvidos';

    static fields() {
        return {
            id: this.attr(null),
            nome_orgao: this.string(null).nullable(),
            atribuicoes: this.string(null).nullable(),
            protocolo_intencao_id: this.attr(null),
            data_prevista_termino: this.string(null, convertValidDateToString).nullable(),
            protocolo_intencao: this.belongsTo(ProtocoloIntencao, 'protocolo_intencao_id'),
            _destroy: this.boolean(false),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }

    get dataPrevistaTermino() {
        if (!this.data_prevista_termino) return null;

        return parseISO(this.data_prevista_termino);
    }
}