<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <router-link
        class="bt-fechar"
        :to="{
          name: PESSOA_JURIDICA_SHOW,
          params: { id: $route.params.id },
          hash: $route.hash || '#protocolo',
        }"
        ><IconFechar
      /></router-link>

      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Protocolo de Intenção</h1>

        <div>
          <p>
            <strong>Descrição do Projeto: </strong
            >{{ protocoloIntencao.descricao_projeto }}
          </p>
          <div class="row3">
            <p>
              <strong>Número: </strong>
              {{ protocoloIntencao.numero }}
            </p>

            <p>
              <strong>Tipo de Projeto: </strong>
              {{ protocoloIntencao.tipo_projeto_label | naoInformado }}
            </p>

            <p>
              <strong>Status: </strong>
              {{ protocoloIntencao.status_label }}
            </p>
          </div>
          <div class="row3">
            <p>
              <strong>Data de início: </strong>
              {{ protocoloIntencao.data_inicio | toDate | naoInformado }}
            </p>

            <p>
              <strong>Resultado do processo de atração? </strong>
              {{ atracaoInvestimento ? "Sim" : "Não" }}
            </p>

            <template v-if="atracaoInvestimento">
              <p>
                <strong>Atração: </strong>
                <router-link
                  :to="{
                    name: PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
                    params: {
                      id: $route.params.id,
                      atracaoId: atracaoInvestimento.atracao_investimento_id,
                    },
                  }"
                  >Iniciada em
                  {{
                    atracaoInvestimento.atracao_investimento.data_inicio
                      | toDate
                  }}</router-link
                >
              </p>
            </template>
          </div>
          <div class="row3">
            <p>
              <strong>(Previsão) Empregos diretos gerados: </strong>
              {{ protocoloIntencao.empregos_diretos_gerados | naoInformado }}
            </p>

            <p>
              <strong>(Previsão) Empregos indiretos gerados: </strong>
              {{ protocoloIntencao.empregos_indiretos_gerados | naoInformado }}
            </p>

            <p>
              <strong>(Previsão) Investimentos realizados: </strong>
              {{
                protocoloIntencao.investimento_fixo_estimado
                  | currency
                  | naoInformado
              }}
            </p>
          </div>
          <div class="row3">
            <p>
              <strong>Prazo estimado: </strong>
              {{ protocoloIntencao.prazo_estimado_meses | naoInformado }}
            </p>

            <p>
              <strong>Linha de produtos: </strong>
              {{ protocoloIntencao.linhas_produtos | naoInformado }}
            </p>

            <p>
              <strong>Processo SEI: </strong>
              {{ protocoloIntencao.processo_sei | naoInformado }}
            </p>
          </div>
          <div class="row3">
            <p>
              <strong>Minuta: </strong>
              {{ protocoloIntencao.minuta | naoInformado }}
            </p>

            <p>
              <strong>Situação da minuta: </strong>
              {{ protocoloIntencao.situacao_minuta | naoInformado }}
            </p>
          </div>
        </div>
        <div class="row2">
          <div class="grafico">
            <PessoasJuridicasGraficoEmpregos
              :empregos-diretos-gerados="
                protocoloIntencao.empregos_diretos_gerados
              "
              :empregos-indiretos-gerados="
                protocoloIntencao.empregos_indiretos_gerados
              "
              :historico-protocolo="historicoComDadosEmpregos"
              v-if="
                historicoComDadosEmpregos &&
                  historicoComDadosEmpregos.length > 0
              "
            />
          </div>

          <div class="grafico">
            <PessoasJuridicasGraficoInvestimento
              :historico-protocolo="historicoComDadosInvestimento"
              :investimento-fixo-estimado="
                protocoloIntencao.investimento_fixo_estimado
              "
              v-if="
                historicoComDadosInvestimento &&
                  historicoComDadosInvestimento.length > 0
              "
            />
          </div>
        </div>

        <AppTabs :tabs="tabs" v-model="currentTab">
          <template v-slot:[tabs[0].id]>
            <PessoasJuridicasProtocoloListaOrgaosEnvolvidos
              :orgaos-envolvidos="protocoloIntencao.orgaos_envolvidos"
            />
          </template>

          <template v-slot:[tabs[1].id]>
            <PessoasJuridicasProtocoloListaArquivos :arquivos="arquivos" />
          </template>

          <template v-slot:[tabs[2].id]>
            <PessoasJuridicasListaHistorico
              :historico-protocolos-intencao="
                protocoloIntencao.historico_protocolos_intencao
              "
            />
          </template>
        </AppTabs>

        <AppAutores
          :criado-por="protocoloIntencao.criado_por"
          :created-at="protocoloIntencao.created_at"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import AppTabs from "../../../AppTabs";
import {
  PESSOA_JURIDICA_SHOW,
  PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
} from "../../../../router/routeNames";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import { modulePessoaJuridica } from "../../../../store/moduleNames";

import AtracaoInvestimentoProtocoloIntencao from "../../../../store/models/AtracaoInvestimentoProtocoloIntencao";
import Arquivo from "../../../../store/models/Arquivo";

import AppAutores from "../../../AppAutores";
import PessoasJuridicasGraficoEmpregos from "./PessoasJuridicasGraficoEmpregos";
import PessoasJuridicasGraficoInvestimento from "./PessoasJuridicasGraficoInvestimento";
import PessoasJuridicasProtocoloListaArquivos from "./PessoasJuridicasProtocoloListaArquivos";
import PessoasJuridicasProtocoloListaOrgaosEnvolvidos from "./PessoasJuridicasProtocoloListaOrgaosEnvolvidos";
import PessoasJuridicasListaHistorico from "./PessoasJuridicasListaHistorico";
import HistoricoProtocoloIntencao from "../../../../store/models/HistoricoProtocoloIntencao";

import IconFechar from "../../../../assets/images/icon-fechar.svg";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasExibirProtocolo",
  components: {
    AppAutores,
    AppLoading,
    AppErro,
    AppTabs,
    PessoasJuridicasGraficoEmpregos,
    PessoasJuridicasGraficoInvestimento,
    PessoasJuridicasProtocoloListaArquivos,
    PessoasJuridicasProtocoloListaOrgaosEnvolvidos,
    PessoasJuridicasListaHistorico,
    IconFechar,
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: "Órgãos envolvidos",
        permissoes: [],
      },
      {
        id: 2,
        label: "Arquivos",
        permissoes: [],
      },
      {
        id: 3,
        label: "Histórico de andamento",
        permissoes: [],
      },
    ];
    return {
      PESSOA_JURIDICA_SHOW,
      PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
      urlApi: process.env.VUE_APP_BASE_API_URL,
      tabs,
      currentTab: tabs[0],
    };
  },
  created() {
    this.carregarProtocoloIntencao(this.$route.params.protocoloId);
  },
  methods: {
    ...mapActions(["carregarProtocoloIntencao"]),
  },
  computed: {
    carregando() {
      return (
        this.$store.state.entities.protocolosIntencao
          .carregandoProtocoloIntencao ||
        this.$store.state.entities.protocolosIntencao
          .carregandoListaProtocolosIntencao ||
        this.$store.state.entities.atracaoInvestimentos.carregandoLista
      );
    },
    erro() {
      return (
        this.$store.state.entities.protocolosIntencao
          .erroCarregarProtocoloIntencao ||
        this.$store.state.entities.atracaoInvestimentos.erroCarregarLista
      );
    },
    protocoloIntencao() {
      if (!this.$route.params.protocoloId) return null;

      return ProtocoloIntencao.query()
        .whereId(parseInt(this.$route.params.protocoloId))
        .withAllRecursive()
        .with("orgaos_envolvidos", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .first();
    },
    atracaoInvestimento() {
      if (!this.protocoloIntencao) return null;

      return AtracaoInvestimentoProtocoloIntencao.query()
        .where("protocolo_intencao_id", this.protocoloIntencao.id)
        .with("atracao_investimento")
        .first();
    },
    historicoProtocolosIntencao() {
      if (!this.protocoloIntencao) return null;

      return HistoricoProtocoloIntencao.query()
        .where("protocolo_intencao_id", this.protocoloIntencao.id)
        .orderBy("data", "asc")
        .get();
    },
    // historicos que possuem os dados de empregos até o momento
    historicoComDadosEmpregos() {
      return this.historicoProtocolosIntencao.filter(
        (historico) =>
          historico.empregos_diretos_gerados_atual ||
          historico.empregos_indiretos_gerados_atual
      );
    },
    // historicos que possuem os dados de investimento até o momento
    historicoComDadosInvestimento() {
      return this.historicoProtocolosIntencao.filter(
        (historico) => historico.investimento_fixo_atual
      );
    },
    arquivos() {
      if (!this.protocoloIntencao) return null;

      return Arquivo.query()
        .where("arquivavel_id", this.protocoloIntencao.id)
        .where("arquivavel_type", "SicNegocio::Gaia::Model::ProtocoloIntencao")
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
.grafico {
  // height: 40rem;
  width: 30rem;
  max-width: 100%;
}

.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
}

.modal-caixa {
  background-color: white;
  position: relative;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: scroll;

  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
  h1 {
    color: var(--laranja-gaia);
  }

  .row2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    margin: 1rem 0;
    p {
      display: grid;
      gap: 0.5rem;
    }
  }
}
.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: 95vh;
    width: 80vw;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
    }
  }
  .botoes {
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
</style>
