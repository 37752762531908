<template>
  <div>
    <div class="caixa-dados" v-if="!protocoloIntencao._destroy">
      <header class="header-caixa">
        <b>{{ index + 1 }}</b> - {{ protocoloIntencao.numero }} (
        {{ protocoloIntencao.status_label }})
      </header>

      <div>
        <div class="row3">
          <p class="item" v-if="protocoloIntencao.data_inicio">
            <strong>Data de inicio: </strong
            >{{ protocoloIntencao.data_inicio | toDate | naoInformado }}
          </p>
          <p class="item">
            <strong>Tipo de Projeto: </strong
            >{{ protocoloIntencao.tipo_projeto }}
          </p>
          <p class="item">
            <strong>Processo SEI: </strong
            >{{ protocoloIntencao.processo_sei | naoInformado }}
          </p>
        </div>
        <p class="item">
          <strong>Descrição do Projeto: </strong
          >{{ protocoloIntencao.descricao_projeto }}
        </p>
      </div>

      <div class="botoes">
        <button type="button" @click="excluir"><DeleteIcon /> Excluir</button>
        <router-link
          :to="{
            name: PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
            query: {
              protocolo_intencao_id: protocoloIntencao.id,
              action: 'edit',
            },
            hash: '#protocolo',
          }"
          ><PencilIcon /> Editar</router-link
        >
      </div>
    </div>
    <div class="caixa-dados-2" v-else>
      <header class="header-caixa">
        <b>{{ index + 1 }}</b> - {{ protocoloIntencao.numero }} (
        {{ protocoloIntencao.status_label }})
      </header>
      <div class="desfazer-exclusao">
        <p>Protocolo de Intenção será excluido.</p>
        <button @click="desfazerExclusao" type="button">
          Desfazer exclusão
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO } from "../../../../router/routeNames";
import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";

import toDate from "../../../../filters/toDate";

export default {
  name: "PessoasJuridicasProtocolosItem",
  components: {
    PencilIcon,
    DeleteIcon,
  },
  props: {
    protocoloIntencao: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
    };
  },
  methods: {
    excluir() {
      if (this.protocoloIntencao._persistido) {
        ProtocoloIntencao.update({
          ...this.protocoloIntencao,
          _destroy: true,
        });
      } else {
        ProtocoloIntencao.delete(this.protocoloIntencao.id);
      }
    },
    desfazerExclusao() {
      ProtocoloIntencao.update({
        ...this.protocoloIntencao,
        _destroy: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 0.5rem  0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: "Lato-Bold";
  }
  .item {
    padding: 0 1rem;
  }
  .botoes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      font-size: 1rem;
      border: none;
      color: var(--vermelho-gaia);
      padding: 0.5rem;
      cursor: pointer;
      svg {
        fill: var(--vermelho-gaia);
        margin-right: 0.5rem;
      }
    }
    a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: var(--verde-escuro-gaia);
    color: white;
    padding: 0.5rem;
    font-family: 'Lato-Bold';
      svg {
        fill: white;
        margin-right: 0.5rem;
      }
    }
  }
}
.caixa-dados-2 {
  background-color: var(--cinza-caixa-gaia);
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem;
    color: white;
    border-radius: 2px 2px 0 0;
    text-align: center;
    font-family: "Lato-Bold";
  }

  p {
    text-align: center;
  }
  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    background-color: transparent;
    border: var(--vermelho-gaia) solid 0.1rem;
    border-radius: 2px;
    color: var(--vermelho-gaia);
    margin: 1rem auto;
    padding: 0.3rem 1rem;
    transition: 0.2s;
    svg {
      height: 1.3rem;
      width: 2rem;
      fill: var(--vermelho-gaia);
      margin-right: 1rem;
      transition: 0.2s;
    }
  }
  button:hover {
    background-color: var(--vermelho-gaia);
    color: white;
    svg {
      fill: white;
    }
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
  .desfazer-exclusao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    button {
      margin: 0;
    }
  }
}
</style>
