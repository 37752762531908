import { render, staticRenderFns } from "./MunicipioAreasDisponiveisForm.vue?vue&type=template&id=249981f0&scoped=true&"
import script from "./MunicipioAreasDisponiveisForm.vue?vue&type=script&lang=js&"
export * from "./MunicipioAreasDisponiveisForm.vue?vue&type=script&lang=js&"
import style0 from "./MunicipioAreasDisponiveisForm.vue?vue&type=style&index=0&id=249981f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249981f0",
  null
  
)

export default component.exports