<template>
  <div>
    <VueSimpleAutocomplete
      :items="municipiosJuceg"
      :selected-item="municipioJuceg"
      @change="buscarMunicipioJuceg"
      @value-selected="onMunicipioJucegSelecionada"
      :is-loading="carregando"
      :get-display-value="exibirMunicipioJuceg"
      :min-length="2"
      placeholder="Digite o nome do município para pesquisar"
    ></VueSimpleAutocomplete>
  </div>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";

import PessoasJuridicasJucegApi from "../../../services/api/PessoasJuridicasJucegApi";

export default {
  name: "PessoasJuridicasJucegMunicipioAutocomplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "municipioJuceg",
    event: "change",
  },
  props: {
    municipioJuceg: {
      type: Object,
    },
  },
  data() {
    return {
      municipiosJuceg: [],
      carregando: false,
    };
  },
  created() {
    this.pessoasJuridicasJucegApi = new PessoasJuridicasJucegApi();
  },
  methods: {
    async buscarMunicipioJuceg(nome) {
      try {
        this.municipiosJuceg = [];
        this.$emit("change", {});

        if (nome == null || nome.length === 0) {
          return;
        }
        this.carregando = true;
        const data =
          await this.pessoasJuridicasJucegApi.getMunicipioJucegAutocomplete(
            nome
          );
        this.municipiosJuceg = data;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
      }
    },
    onMunicipioJucegSelecionada(municipioJuceg) {
      this.$emit("change", municipioJuceg);
    },
    exibirMunicipioJuceg(municipioJuceg) {
      if (municipioJuceg && municipioJuceg !== "" && municipioJuceg.nome) {
        return `${municipioJuceg.nome}`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
