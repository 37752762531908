import ModelPersistido from "./ModelPersistido";

export default class ContatoPessoaJuridica extends ModelPersistido {
  static entity = "contatosPessoasJuridicas";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.string(null).nullable(),
      telefone: this.string(null).nullable(),
      email: this.string(null).nullable(),
      cargo: this.string(null).nullable(),
      departamento: this.string(null).nullable(),
      cpf_cnpj: this.string(null).nullable(),
      pessoa_juridica_id: this.attr(null),
      _destroy: this.boolean(false)
    };
  }
}
