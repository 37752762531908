import ProtocolosIntencaoApi from "../../../services/api/ProtocolosIntencaoApi";
import ProtocoloIntencao from "../../models/ProtocoloIntencao";
import processarMensagemErro from "../../../helpers/processarMensagemErro";
import Arquivo from "../../models/Arquivo";

const protocolosIntencaoApi = new ProtocolosIntencaoApi();

export default {
    async carregarListaProtocolosIntencao(_context, pessoaJuridicaId) {
        try {
            ProtocoloIntencao.commit((state) => {
                state.carregandoListaProtocolosIntencao = true;
                state.erroCarregarListaProtocolosIntencao = false;
                state.excluindoProtocolosIntencao = false;
                state.errosExcluirProtocolosIntencao = null;
            });

            const { resultados: protocolosIntencao } = await protocolosIntencaoApi.getProtocolosIntencao({ page: 1, perPage: 1000, pessoaJuridicaId, });

            await ProtocoloIntencao.insertOrUpdate({
                data: protocolosIntencao
            });

            ProtocoloIntencao.commit((state) => {
                state.carregandoListaProtocolosIntencao = false;
                state.erroCarregarListaProtocolosIntencao = false;
                state.excluindoProtocolosIntencao = false;
                state.errosExcluirProtocolosIntencao = null;
            });
        } catch (err) {
            console.log(err);
            ProtocoloIntencao.commit((state) => {
                state.carregandoListaProtocolosIntencao = false;
                state.erroCarregarListaProtocolosIntencao = true;
                state.excluindoProtocolosIntencao = false;
                state.errosExcluirProtocolosIntencao = null;
            });
        }
    },

    async carregarProtocoloIntencao(_context, protocoloIntencaoId) {
        try {
            ProtocoloIntencao.commit((state) => {
                state.carregandoProtocoloIntencao = true;
                state.erroCarregarProtocoloIntencao = false;
            });

            const protocoloIntencao = await protocolosIntencaoApi.getProtocoloIntencao(protocoloIntencaoId);
           
            const idsArquivos = Arquivo.query().where("arquivavel_type", "SicNegocio::Gaia::Model::ProtocoloIntencao")
                .where("arquivavel_id", protocoloIntencao.id).get().map((arquivo) => arquivo.id);
            await Arquivo.delete((arquivo) => idsArquivos.includes(arquivo.id));

            await Arquivo.insertOrUpdate({ data: protocoloIntencao.arquivos });

            await ProtocoloIntencao.insertOrUpdate({ data: protocoloIntencao });

            ProtocoloIntencao.commit((state) => {
                state.carregandoProtocoloIntencao = false;
                state.erroCarregarProtocoloIntencao = false;
            });

        } catch (err) {
            console.log(err);
            ProtocoloIntencao.commit((state) => {
                state.carregandoProtocoloIntencao = false;
                state.erroCarregarProtocoloIntencao = true;
            });
        }
    },

    async excluirProtocolosIntencaoBatch(_context, idsProtocolosIntencao) {
        try {
            ProtocoloIntencao.commit((state) => {
                state.carregandoListaProtocolosIntencao = false;
                state.erroCarregarListaProtocolosIntencao = false;
                state.excluindoProtocolosIntencao = true;
                state.errosExcluirProtocolosIntencao = null;
            });

            await protocolosIntencaoApi.excluirBatchProtocolosIntencao(idsProtocolosIntencao);

            await ProtocoloIntencao.delete((atracao) => idsProtocolosIntencao.includes(atracao.id));

            ProtocoloIntencao.commit((state) => {
                state.excluindoProtocolosIntencao = false;
            });
        } catch (err) {
            console.log(err);

            const { response } = err;
            ProtocoloIntencao.commit((state) => {
                state.excluindoProtocolosIntencao = false;
                state.errosExcluirProtocolosIntencao = processarMensagemErro(response);
            });
        }
    }

}