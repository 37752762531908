<template>
  <div>
    <form class="form-pesquisa" @submit="submit">
      <h4>Pesquisar</h4>
      <div>
        <div class="row3-2">
          <div class="item">
            <label>CNPJ:</label>
            <TheMask
              type="text"
              mask="##.###.###/####-##"
              :value="filtro.cnpj"
              @input="(value) => filtroMudou('cnpj', value)"
            />
          </div>
          <div class="item">
            <label>Nome Empresarial:</label>
            <input
              type="text"
              :value="filtro.nome_empresarial"
              @input="
                (evt) => filtroMudou('nome_empresarial', evt.target.value)
              "
            />
          </div>
          <div class="item">
            <label>Município: </label>
            <PessoasJuridicasJucegMunicipioAutocomplete
              v-model="filtro.municipioFiltro"
            ></PessoasJuridicasJucegMunicipioAutocomplete>
          </div>
        </div>
        <div class="row4">
          <div class="item">
            <label>NIRE:</label>
            <input
              type="text"
              :value="filtro.nire"
              @input="(evt) => filtroMudou('nire', evt.target.value)"
            />
          </div>
          <div class="item">
            <label>Situação:</label>
            <input
              type="text"
              :value="filtro.situacao"
              @input="(evt) => filtroMudou('situacao', evt.target.value)"
            />
          </div>
          <div class="item">
            <label>Porte:</label>
            <input
              type="text"
              :value="filtro.porte"
              @input="(evt) => filtroMudou('porte', evt.target.value)"
            />
          </div>
          <div class="item">
            <label>Código CNAE:</label>
            <PessoasJuridicasJucegAtividadeEconomicaAutocomplete
              v-model="filtro.atividadeEconomica"
            ></PessoasJuridicasJucegAtividadeEconomicaAutocomplete>
          </div>
        </div>
        <fieldset>
          <p>Início da Atividade:</p>
          <div class="div-flex">
            <DatePicker
              v-model="filtro.inicio_atividade"
              @change="(value) => filtroMudou('inicio_atividade', value)"
              format="DD/MM/YYYY"
              value-type="format"
            />
            <label>a</label>
            <DatePicker
              v-model="filtro.termino_atividade"
              @change="(value) => filtroMudou('termino_atividade', value)"
              format="DD/MM/YYYY"
              value-type="format"
            />
          </div>
        </fieldset>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar">
          <IconClear /> Limpar campos
        </button>
        <button class="bt-pesquisar" type="submit">Pesquisar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import IconClear from "../../../assets/images/icon-clean.svg";
import DatePicker from "vue2-datepicker";
import PessoasJuridicasJucegMunicipioAutocomplete from "./PessoasJuridicasJucegMunicipioAutocomplete.vue";
import "vue2-datepicker/index.css";
import PessoasJuridicasJucegAtividadeEconomicaAutocomplete from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegAtividadeEconomicaAutocomplete";

export default {
  name: "PessoasJuridicasJucegPesquisaForm",
  model: {
    prop: "filtro",
    event: "update-filtro-pesquisa-pessoa-juridica-juceg",
  },
  props: {
    filtro: {
      type: Object,
    },
  },
  components: {
    TheMask,
    PessoasJuridicasJucegMunicipioAutocomplete,
    DatePicker,
    IconClear,
    PessoasJuridicasJucegAtividadeEconomicaAutocomplete,
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    filtroMudou(chave, valor) {
      this.$emit("update-filtro-pesquisa-pessoa-juridica-juceg", {
        ...this.filtro,
        [chave]: valor,
      });
    },
    limpar() {
      const chaves = Object.keys(this.filtro);
      const filtroLimpo = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
      this.$emit("update-filtro-pesquisa-pessoa-juridica-juceg", filtroLimpo);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
    }
  }
  fieldset {
    margin: .5rem 0;
    p {
      margin: 0 0 1rem;
      font-family: 'Lato-Regular';
    }
  }
  .div-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    label{
      margin: 0 1rem;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color: var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

@media screen and(min-width: '50rem') {
  .row3-2 {
    grid-template-columns: 1fr 3fr 1fr;
    display: grid;
    gap: 1rem;
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
  .form-pesquisa{
    .div-flex{
      flex-direction: row;
    }
  }
}
</style>
