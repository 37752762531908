<template>
  <div class="tela-form">
    <div class="caixa-form">
      <div>
        <router-link
          title="Fechar"
          :to="{
            name: ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
            query: { ...this.$route.query },
          }"
          >&#10005;</router-link
        >
        <h1>
          {{
            $route.name === ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW
              ? "Novo Tipo de Área"
              : "Editar Tipo de Área"
          }}
        </h1>
      </div>
      <div></div>
      <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
      <AppErro v-if="!carregando && erroCarregar" />
      <template v-if="!carregando && !erroCarregar">
        <div id="erros-container">
          <AppErrosFormularios
            v-if="!salvando && erroSalvar && erroSalvar.length > 0"
            :erros="erroSalvar"
          />
        </div>
        <form @submit="salvarDados" v-if="!carregando && !erroCarregar">
          <div>
            <div class="item">
              <label>Nome: <span>*</span></label>
              <input type="text" v-model="tipoArea.nome" />
            </div>
          </div>
          <div class="botao-salvar">
            <AppLoading v-if="salvando" />
            <BotaoSalvar v-if="!salvando" />
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import {
  ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
  ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";
import TiposAreaApi from "../../../../services/api/TiposAreaApi";

import AppErrosFormularios from "../../../../components/AppErrosFormularios.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";

export default {
  name: "TiposAreaForm",
  components: {
    AppLoading,
    AppErro,
    AppErrosFormularios,
    BotaoSalvar,
  },
  metaInfo() {
    return {
      title: `${
        this.$route.name === ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW
          ? "Criar"
          : "Editar"
      } Tipo de Área - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      tipoArea: [],
      page: this.$route.query.page,
      perPage: this.$route.query.per_page,
      carregando: true,
      erroCarregar: false,
      salvando: false,
      erroSalvar: null,
      ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
      ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
    };
  },
  created() {
    this.tiposAreaApi = new TiposAreaApi();
    this.carregandoDados();
  },
  methods: {
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.erroSalvar = null;

        await this.tiposAreaApi.salvarTipoArea(this.tipoArea);

        this.salvando = false;

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
          query: this.$route.query,
        });
      } catch (err) {
        console.log(err);
        this.salvando = false;
        const { response } = err;

        if (response === undefined) {
          this.erroSalvar = ["Houve um erro. Por favor, tente novamente"];
        } else {
          if (response.data) {
            if (Array.isArray(response.data)) {
              this.erroSalvar = response.data;
            } else {
              this.erroSalvar = [response.data];
            }
          } else {
            this.erroSalvar = ["Houve um erro. Por favor, tente novamente"];
          }
        }
        this.$scrollTo("#erros-container");
      }
    },
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        if (this.tiposAreaApi.cancelTokens.getTipoArea) {
          this.tiposAreaApi.cancelTokens.getTipoArea.cancel();
        }

        if (this.$route.params.id) {
          this.tipoArea = await this.tiposAreaApi.getTipoArea(
            this.$route.params.id
          );
        }

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.erroCarregar = true;
        this.carregando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-form {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    min-width: 50%;
    position: relative;
    a {
      text-decoration: none;
      color: silver;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      transition: 0.2s;
    }
    a:hover {
      color: var(--vermelho-gaia);
    }
    h1 {
      color: var(--laranja-gaia);
      margin-top: 2rem;
      margin-bottom: 0;
    }
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
      }
      select {
        height: 1.9rem;
      }
    }
    .botao-salvar {
      text-align: center;
      margin: 1rem 0;
    }
  }
}
@media screen and (min-width: 50em) {
  .row2-2 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
  }
}
</style>
