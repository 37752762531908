import axios from "axios"
import axiosAuthenticated from "./axiosAutenticated"

const queryString = require('query-string')

class PolosIndustriais {
    cancelTokens = {
        getPolosIndustriais: null,
        getPoloIndustrial: null,
        salvarPoloIndustrial: null,
        excluirPoloIndustrial: null
    }

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown)
    }

    async getPolosIndustriais({ page, perPage, nome }) {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.getPolosIndustriais = source;

        const searchString = queryString.stringify({
            page,
            per_page: perPage,
            nome
        })

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/polos_industriais?${searchString}`,
            {
                cancelToken: source.token
            }
        )

        this.cancelTokens.getPolosIndustriais = null
        return data
    }

    async getPoloIndustrial(id) {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.getPoloIndustrial = source

        const data = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/polos_industriais/${id}`,
            {
                cancelToken: source.token
            }
        )
        this.cancelTokens.getPoloIndustrial = null
        return data
    }

    async salvarPoloIndustrial(poloIndustrial) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.salvarPoloIndustrial = source
        let method;
        let url;
        if (poloIndustrial.id) {
            method = axiosAuthenticated.put,
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/polos_industriais/${poloIndustrial.id}`
        } else {
            method = axiosAuthenticated.post,
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/polos_industriais`
        }
        const { data } = await method(url, poloIndustrial,
            {
                cancelToken: source.token
            }
        )
        this.cancelTokens.salvarPoloIndustrial = null
        return data
    }

    async excluirPoloIndustrial({ id }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.excluirPoloIndustrial = source;

        const data = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/polos_industriais/${id}`,
            {
                cancelToken: source.token,
            }
        )
        this.cancelTokens.excluirPoloIndustrial = null

        return data
    }
}

export default PolosIndustriais