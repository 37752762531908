<template>
  <div class="centro form-conteudo">
    <div class="form-header">
      <h1>
        {{
          $route.name == PESSOA_JURIDICA_EDIT
            ? "Editar Pessoa Jurídica"
            : "Nova Pessoa Jurídica"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="
          !carregandoPessoaJuridica &&
          !erroCarregamento &&
          $route.name == PESSOA_JURIDICA_EDIT
        "
        :to="{
          name: PESSOA_JURIDICA_SHOW,
          params: { id: $route.params.id },
          hash: $route.hash,
        }"
      />
    </div>

    <div>
      <AppLoading
        class="carregando1"
        v-if="carregandoPessoaJuridica && !erroCarregamento"
      />
      <div id="erro-container">
        <AppErro v-if="erroCarregamento && !carregandoPessoaJuridica" />
      </div>

      <AppTabs
        :tabs="tabs"
        v-model="currentTab"
        v-if="!carregandoPessoaJuridica && !erroCarregamento"
      >
        <template
          v-slot:[tabs[0].id]
          v-if="pessoaJuridica && pessoaJuridica.id"
        >
          <PessoasJuridicasFormularioDadosGerais
            :pessoa-juridica="pessoaJuridica"
            :tipos="tipos"
            :classificacoes="classificacoes"
            :tipos-enderecos="tiposEnderecos"
            :estados="estados"
            :paises="paises"
            @salvou="fazerCopiaPessoaJuridica"
          />
        </template>
        <template
          v-slot:[tabs[1].id]
          v-if="pessoaJuridica && pessoaJuridica.id"
        >
          <PessoasJuridicasAtracaoInvestimentos
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>
        <template
          v-slot:[tabs[2].id]
          v-if="pessoaJuridica && pessoaJuridica.id"
        >
          <PessoasJuridicasProtocolosIntencao
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>
        <template
          v-slot:[tabs[3].id]
          v-if="pessoaJuridica && pessoaJuridica.id"
        >
          <PessoasJuridicasContatosRealizados
            :pessoa-juridica-id="pessoaJuridica.id"
          />
        </template>
      </AppTabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import PessoasJuridicasApi from "../../services/api/PessoasJuridicasApi";
import EnderecosApi from "../../services/api/EnderecosApi";
import EstadosApi from "../../services/api/EstadosApi";
import PaisesApi from "../../services/api/PaisesApi";

import AppLoading from "../../components/AppLoading";
import AppErro from "../../components/AppErro";
import AppTabs from "../../components/AppTabs";

import { getUsuario } from "../../services/Auth";

import {
  PESSOA_JURIDICA_EDIT,
  PESSOA_JURIDICA_NEW,
  PESSOA_JURIDICA_SHOW,
  PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
  PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
  PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
} from "../../router/routeNames";

import {
  ATRACAO_INVESTIMENTO_CRIAR_EDITAR_EXCLUIR_PERMISSAO,
  PROTOCOLO_INTENCAO_CRIAR_EDITAR_EXCLUIR_PERMISSAO,
  CONTATOS_REALIZADOS_CRIAR_EDITAR_EXCLUIR_PERMISSAO,
} from "../../permissoes";

import PessoaJuridica from "../../store/models/PessoaJuridica";

import PessoasJuridicasFormularioDadosGerais from "../../components/pessoas_juridicas/form/dados_gerais/PessoasJuridicasFormularioDadosGerais";
import PessoasJuridicasAtracaoInvestimentos from "../../components/pessoas_juridicas/form/atracao_investimentos/PessoasJuridicasAtracaoInvestimentos";
import PessoasJuridicasProtocolosIntencao from "../../components/pessoas_juridicas/form/protocolos_intencao/PessoasJuridicasProtocolosIntencao";
import PessoasJuridicasContatosRealizados from "../../components/pessoas_juridicas/form/contatos_realizados/PessoasJuridicasContatosRealizados";

import { modulePessoaJuridica } from "../../store/moduleNames";
import ProtocoloIntencao from "../../store/models/ProtocoloIntencao";
import AtracaoInvestimento from "../../store/models/AtracaoInvestimento";
import ContatoRealizado from "../../store/models/ContatoRealizado";
import BotaoVisualizarForm from "../../components/botoes/BotaoVisualizarForm";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

const cloneDeep = require("lodash.clonedeep");

export default {
  name: "PessoaJuridicaForm",
  metaInfo() {
    return {
      title:
        this.$route.name == PESSOA_JURIDICA_NEW
          ? "Nova Pessoa Jurídica"
          : "Editar Pessoa Jurídica",
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: "Dados Pessoa Jurídica",
        permissoes: [],
        disabled: false,
        hash: "#geral",
        onChange: async () => {
          if (
            this.pessoaJuridica.tipo != this.pessoaJuridicaCopiaInicial.tipo
          ) {
            await this.$swal.fire({
              title: "Salve os dados para mudar de aba",
              text: "Você alterou o tipo de pessoa juridica e não salvou. Salve para poder prosseguir ou desfaça essa alteração.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#054700",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Ok, entendi",
              allowOutsideClick: false,
              // reverseButtons: true
            });
            return false;
          }

          return true;
        },
      },
      {
        id: 2,
        label: "Atração de Investimentos",
        permissoes: [ATRACAO_INVESTIMENTO_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
        disabled: true,
        hash: "#atracao",
        onChange: async () => {
          const atracoes = AtracaoInvestimento.query()
            .where("pessoa_juridica_id", parseInt(this.$route.params.id))
            .where("_destroy", true)
            .exists();

          if (atracoes) {
            const result = await this.$swal.fire({
              title: "Tem certeza que deseja sair dessa tela?",
              text: "Existem alterações não salvas. Você irá perdê-las.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#054700",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
              allowOutsideClick: false,
              // reverseButtons: true
            });

            if (result.isConfirmed) {
              await AtracaoInvestimento.update({
                where: (atracao) => {
                  return atracao._destroy;
                },
                data: { _destroy: false },
              });
              return true;
            }
            return false;
          }
          return true;
        },
      },
      {
        id: 3,
        label: "Protocolos de Intenção",
        permissoes: [PROTOCOLO_INTENCAO_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
        disabled: true,
        hash: "#protocolo",
        onChange: async () => {
          const protocolos = ProtocoloIntencao.query()
            .where("pessoa_juridica_id", parseInt(this.$route.params.id))
            .where("_destroy", true)
            .exists();

          if (protocolos) {
            const result = await this.$swal.fire({
              title: "Tem certeza que deseja sair dessa tela?",
              text: "Existem alterações não salvas. Você irá perdê-las.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#054700",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
              allowOutsideClick: false,
            });

            if (result.isConfirmed) {
              await ProtocoloIntencao.update({
                where: (protocoloIntencao) => {
                  return protocoloIntencao._destroy;
                },
                data: { _destroy: false },
              });
              return true;
            }
            return false;
          }
          return true;
        },
      },
      {
        id: 4,
        label: "Contatos Realizados",
        permissoes: [CONTATOS_REALIZADOS_CRIAR_EDITAR_EXCLUIR_PERMISSAO],
        disabled: true,
        hash: "#contatos",
        onChange: async () => {
          const contatosRealizados = ContatoRealizado.query()
            .where("pessoa_juridica_id", parseInt(this.$route.params.id))
            .where("_destroy", true)
            .exists();

          if (contatosRealizados) {
            const result = await this.$swal.fire({
              title: "Tem certeza que deseja sair dessa tela?",
              text: "Existem alterações não salvas. Você irá perdê-las.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#054700",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
              allowOutsideClick: false,
            });

            if (result.isConfirmed) {
              await ContatoRealizado.update({
                where: (contato) => {
                  return contato._destroy;
                },
                data: { _destroy: false },
              });
              return true;
            }
            return false;
          }
          return true;
        },
      },
    ];

    this.usuario = getUsuario();

    // pegamos a primeira tab com permissao e que inclua o hash
    const currentTab = this.getCurrentTab(tabs);

    return {
      id: null,
      erroCarregandoValores: false,
      carregandoValores: true,
      tipos: [],
      classificacoes: [],
      tiposEnderecos: [],
      estados: [],
      paises: [],
      currentTab: currentTab,
      tabs: this.habilitarDesabilitarTabs(tabs),
      PESSOA_JURIDICA_SHOW,
      PESSOA_JURIDICA_EDIT,
    };
  },
  components: {
    AppLoading,
    AppErro,
    AppTabs,
    BotaoVisualizarForm,
    PessoasJuridicasFormularioDadosGerais,
    PessoasJuridicasAtracaoInvestimentos,
    PessoasJuridicasProtocolosIntencao,
    PessoasJuridicasContatosRealizados,
  },
  watch: {
    currentTab: function (newTab) {
      if (!newTab.disabled) {
        this.$router.replace({ name: this.$route.name, hash: newTab.hash });
      }
    },
    $route: function (newRoute, oldRoute) {
      //Quando muda da rota de new para edit, temos que chamar a funcao de carregar dados novamente
      //para buscar os dados de atracao de investimento, protocolo de intencao e contatos realizados
      if (
        oldRoute.name == PESSOA_JURIDICA_NEW &&
        newRoute.name == PESSOA_JURIDICA_EDIT
      ) {
        this.carregarDados();
        this.tabs = this.habilitarDesabilitarTabs(this.tabs);
      }
    },
    "$route.hash": function () {
      this.currentTab = this.getCurrentTab(this.tabs);
    },
    "pessoaJuridica.tipo": function () {
      this.tabs = this.habilitarDesabilitarTabs(this.tabs);
    },
  },
  async created() {
    this.pessoaJuridicaCopiaInicial = null;

    this.pessoasJuridicasApi = new PessoasJuridicasApi();
    this.enderecosApi = new EnderecosApi();
    this.estadosApi = new EstadosApi();
    this.paisesApi = new PaisesApi();

    PessoaJuridica.commit((state) => {
      state.carregando = true;
      state.erroCarregar = false;
    });

    await PessoaJuridica.deleteAll();

    this.carregarDados();
  },
  methods: {
    getCurrentTab(tabs) {
      let currentTab =
        tabs.find((tab) => {
          return (
            tab.permissoes.every((t) =>
              this.usuario.funcionalidades.includes(t)
            ) && tab.hash == this.$route.hash
          );
        }) || tabs[0];

      if (currentTab) {
        if (
          ["#atracao", "#protocolo"].includes(currentTab.hash) &&
          this.pessoaJuridica &&
          this.pessoaJuridica.tipo != "empresa"
        ) {
          currentTab = tabs[0];
        }
      }

      if(currentTab.disabled) {
        currentTab = tabs[0];
      }

      return currentTab;
    },
    async carregarDados() {
      if (this.$route.name === PESSOA_JURIDICA_NEW) {
        const pessoaJuridica = await PessoaJuridica.new();

        this.id = pessoaJuridica.id;

        PessoaJuridica.commit((state) => {
          state.carregando = false;
        });
      } else {
        this.id = this.$route.params.id;

        //se nao esta carregado, carrega pessoa juridica
        if (!this.pessoaJuridica) {
          await this.carregarPessoaJuridica(this.id);
        }

        // verificamos se alguem tentou acessar diretamente pela url uma tab que
        //  nao existe para a pessoa juridica especificada.
        if (
          ["#atracao", "#protocolo"].includes(this.currentTab.hash) &&
          this.pessoaJuridica.tipo != "empresa"
        ) {
          this.currentTab = this.tabs[0];
        }

        //se a pessoa tem acesso a lista de contatos realizados
        if (
          this.usuario.funcionalidades.some((funcionalidade) =>
            [CONTATOS_REALIZADOS_CRIAR_EDITAR_EXCLUIR_PERMISSAO].includes(
              funcionalidade
            )
          )
        ) {
          this.carregarListaAtracaoInvestimentos(this.id);
          this.carregarListaProtocolosIntencao(this.id);
          this.carregarListaContatosRealizados(this.id);
        } else if (
          this.usuario.funcionalidades.some((funcionalidade) =>
            [PROTOCOLO_INTENCAO_CRIAR_EDITAR_EXCLUIR_PERMISSAO].includes(
              funcionalidade
            )
          )
        ) {
          this.carregarListaAtracaoInvestimentos(this.id);
          this.carregarListaProtocolosIntencao(this.id);
        } else if (
          this.usuario.funcionalidades.some((funcionalidade) =>
            [ATRACAO_INVESTIMENTO_CRIAR_EDITAR_EXCLUIR_PERMISSAO].includes(
              funcionalidade
            )
          )
        ) {
          this.carregarListaAtracaoInvestimentos(this.id);
        }
      }

      this.tabs = this.habilitarDesabilitarTabs(this.tabs);
      this.currentTab = this.getCurrentTab(this.tabs);

      await this.carregarDadosSelects();
      PessoaJuridica.commit((state) => {
        state.carregando = false;
      });
      this.fazerCopiaPessoaJuridica();
    },
    async carregarDadosSelects() {
      try {
        this.erroCarregandoValores = false;
        this.carregandoValores = true;

        const [tipos, classificacoes, tiposEnderecos, estados, paises] =
          await Promise.all([
            this.pessoasJuridicasApi.getTipos(),
            this.pessoasJuridicasApi.getClassificacoes(),
            this.enderecosApi.getTipos(),
            this.estadosApi.getEstados(),
            this.paisesApi.getPaises(),
          ]);

        this.tipos = tipos;
        this.classificacoes = classificacoes;
        this.tiposEnderecos = tiposEnderecos;
        this.estados = estados;
        this.paises = paises;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);
        this.erroCarregandoValores = true;
        this.carregandoValores = false;
      }
    },
    habilitarDesabilitarTabs(tabs) {
      return tabs.map((tab) => {
        return {
          ...tab,
          disabled:
            tab.permissoes.length > 0 &&
            (!tab.permissoes.every((t) =>
              this.usuario.funcionalidades.includes(t)
            ) ||
              ![
                PESSOA_JURIDICA_EDIT,
                PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
                PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
                PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
              ].includes(this.$route.name) ||
              (["#protocolo", "#atracao"].includes(tab.hash) &&
                this.pessoaJuridica &&
                this.pessoaJuridica.tipo != "empresa")),
        };
      });
    },
    fazerCopiaPessoaJuridica() {
      this.pessoaJuridicaCopiaInicial = cloneDeep(this.pessoaJuridica);
    },
    ...mapActions([
      "carregarPessoaJuridica",
      "carregarListaAtracaoInvestimentos",
      "carregarListaProtocolosIntencao",
      "carregarListaContatosRealizados",
    ]),
  },
  computed: {
    erroCarregamento() {
      return this.erroCarregarPessoaJuridica || this.erroCarregandoValores;
    },
    carregandoPessoaJuridica() {
      return (
        this.$store.state.entities.pessoasJuridicas.carregando ||
        this.carregandoValores
      );
    },
    erroCarregarPessoaJuridica() {
      return this.$store.state.entities.pessoasJuridicas.erroCarregar;
    },
    pessoaJuridica() {
      if (!this.id) return null;

      return PessoaJuridica.query()
        .whereId(isNaN(this.id) ? this.id : parseInt(this.id))
        .withAll()
        .with("pessoas_responsaveis.pessoa_fisica")
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: var(--laranja-gaia);
    }
  }
}
</style>
