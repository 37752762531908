import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import TiposAreaIndex from "../../../views/atracao_investimentos/cadastros/tipos_area/TiposAreaIndex";
import TiposAreaForm from "../../../views/atracao_investimentos/cadastros/tipos_area/TiposAreaForm";

const rotasTiposArea = [
    {
        name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
        component: TiposAreaIndex,
        path: "/atracao-investimentos/cadastros/tipos-area",
        meta: {
            requerPermissao: true,
            raiz: true,
            permissoes: [permissoes.TIPOS_AREA_PERMISSAO]
        },
        children: [
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
                component: TiposAreaForm,
                path: "/atracao-investimentos/cadastros/tipos-area/new",
                meta: {
                    requerPermissao: true,
                    raiz: true,
                    permissoes: [permissoes.TIPOS_AREA_PERMISSAO]
                }
            },
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_AREA_EDIT,
                component: TiposAreaForm,
                path: "/atracao-investimentos/cadastros/tipos-area/:id/edit",
                meta: {
                    requerPermissao: true,
                    raiz: true,
                    permissoes: [permissoes.TIPOS_AREA_PERMISSAO]
                }
            }
        ]
    }
]

export default rotasTiposArea;