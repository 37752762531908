<template>
  <div class="container">
    <div class="div-flex-header">
      <h4>Quadro societário / Responsável Legal</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Pessoa Quadro Societário"
      >
        <span>+</span> Adicionar
      </BotaoArredondado>
    </div>
    <template v-for="(pessoaResponsavel, index) in pessoasResponsaveis">
      <div
        class="caixa-form"
        :key="`${pessoaResponsavel.id}_${index}`"
        v-if="!pessoaResponsavel._destroy"
      >
        <button @click="excluir(index)" type="button"><DeleteIcon /></button>
        <PessoasJuridicasFormularioResponsavel
          :pessoa-responsavel="pessoasResponsaveis[index]"
        />
      </div>
    </template>
  </div>
</template>

<script>
import BotaoArredondado from "../../../botoes/BotaoArredondado";
import PessoasJuridicasFormularioResponsavel from "./PessoasJuridicasFormularioResponsavel";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import PessoaResponsavel from "../../../../store/models/PessoaResponsavel";

export default {
  name: "PessoasJuridicasFormularioResponsaveis",
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    BotaoArredondado,
    PessoasJuridicasFormularioResponsavel,
    DeleteIcon,
  },
  methods: {
    adicionar() {
      PessoaResponsavel.insert({
        data: { pessoa_juridica_id: this.pessoaJuridicaId },
      });
    },
    excluir(index) {
      let pessoaResponsavel = this.pessoasResponsaveis[index];

      if (pessoaResponsavel._persistido) {
        PessoaResponsavel.update({ ...pessoaResponsavel, _destroy: true });
      } else {
        PessoaResponsavel.delete(pessoaResponsavel.id);
      }
    },
  },
  computed: {
    pessoasResponsaveis() {
      return PessoaResponsavel.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .with("pessoa_fisica")
        .with("funcao")
        .get();
    },
    exibirMensagemCadastroPessoaFisica() {
      return (
        this.pessoasResponsaveis.filter((pessoa) => !pessoa._destroy).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0 0.5rem;
}
.div-flex-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span {
    font-weight: 800;
    font-size: 1.8rem;
  }
}
.caixa-form {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
  position: relative;
  button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
      width: 100%;
    }
  }
}

@media screen and(min-width: '50em') {
  h4::after {
    min-width: 130%;
    width: 30vw;
  }
}
</style>
