import axios from "axios";
import router from "../../router";

import * as Auth from "../Auth";
import * as RouteNames from "../../router/routeNames";

const instance = axios.create({});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  function(config) {
    if (Auth.getToken()) {
      config.headers["Authorization"] = Auth.getToken();
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.headers.authorization) {
      Auth.setToken(response.headers.authorization);
    }

    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      Auth.logout();

      router.push({ name: RouteNames.LOGIN_ROUTE });
    }

    return Promise.reject(error);
  }
);

export default instance;
