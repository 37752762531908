<template>
  <div class="caixa-form">
    <h4>Arquivos</h4>
    <div class="caixa-item">
      <div class="row4">
        <p class="item" v-for="arquivo in arquivos" :key="arquivo.id">
          Baixar:
          <a target="_blank" :href="urlApi + arquivo.arquivo_url">{{
            arquivo.nome
          }}</a>
        </p>
      </div>

      <p class="text-center" v-if="!arquivos || arquivos.length == 0">
        Nenhum arquivo encontrado.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasProtocoloListaArquivos",
  props: {
    arquivos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      urlApi: process.env.VUE_APP_BASE_API_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
.caixa-form {
  h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  margin-bottom: 2rem;
}
.text-center {
  text-align: center;
}
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    border-radius: 2px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
  }
  .item {
    display: grid;
    gap: .5rem;
    text-align: center;
    a {
      text-decoration: none;
      color:  var(--verde-escuro-gaia);
      border:  var(--verde-escuro-gaia) solid 0.1rem;
      padding: 0.3rem;
      transition: 0.2s;
      margin-top: .5rem;
    }
    a:hover {
      background-color: var(--verde-escuro-gaia);
      color: white;
    }
  }
}
.text-center {
  text-align: center;
}
@media screen and(min-width: '60rem') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
