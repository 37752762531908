import ContatoRealizado from "../../models/ContatoRealizado";
import ContatosRealizadosApi from "../../../services/api/ContatosRealizadosApi";
import processarMensagemErro from '../../../helpers/processarMensagemErro';
import ProtocoloIntencao from "../../models/ProtocoloIntencao";
import AtracaoInvestimento from "../../models/AtracaoInvestimento";

const contatosRealizadosApi = new ContatosRealizadosApi();

export default {
    async carregarListaContatosRealizados(_context, pessoaJuridicaId) {
        try {
            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = true;
                state.erroCarregarListaContatosRealizados = false;
                state.excluindoContatoRealizado = false;
                state.errosExcluirContatoRealizado = null;
            });

            const {
                resultados,
            } = await contatosRealizadosApi.getContatosRealizados({
                pessoaJuridicaId,
                page: 1,
                perPage: 1000,
            });

            await ContatoRealizado.insertOrUpdate({ data: resultados });

            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = false;
            });
        } catch (err) {
            console.log(err);
            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = false;
                state.erroCarregarListaContatosRealizados = true;
            });
        }
    },

    async carregarContatoRealizado(_context, contatoId) {
        try {
            ContatoRealizado.commit((state) => {
                state.carregandoContatoRealizado = true;
                state.erroCarregarContatoRealizado = false;
            });

            const contatoRealizado = await contatosRealizadosApi.getContatoRealizado(contatoId);
            await ContatoRealizado.insertOrUpdate({ data: contatoRealizado });

            //feito em separado pois não tem um relacionamento no model
            if(contatoRealizado && contatoRealizado.protocolo_intencao) {
                await ProtocoloIntencao.insertOrUpdate({ data: contatoRealizado.protocolo_intencao });
            }else if(contatoRealizado && contatoRealizado.atracao_investimento) {
                await AtracaoInvestimento.insertOrUpdate({ data: contatoRealizado.atracao_investimento});
            }

            ContatoRealizado.commit((state) => {
                state.carregandoContatoRealizado = false;
                state.erroCarregarContatoRealizado = false;
            });

        } catch (err) {
            console.log(err);
            ContatoRealizado.commit((state) => {
                state.carregandoContatoRealizado = false;
                state.erroCarregarContatoRealizado = true;
            });
        }
    },

    async excluirBatchContatosRealizados(_context, contatosRealizadosIds) {
        try {
            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = false;
                state.erroCarregarListaContatosRealizados = false;
                state.excluindoContatoRealizado = true;
                state.errosExcluirContatoRealizado = null;
            });

            await contatosRealizadosApi.excluirBatchContatosRealizados(contatosRealizadosIds);

            await ContatoRealizado.delete((contato) => contatosRealizadosIds.includes(contato.id));

            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = false;
                state.erroCarregarListaContatosRealizados = false;
                state.excluindoContatoRealizado = false;
                state.errosExcluirContatoRealizado = null;
            });
        } catch (err) {
            console.log(err);

            const { response } = err;
            ContatoRealizado.commit((state) => {
                state.carregandoListaContatosRealizados = false;
                state.erroCarregarListaContatosRealizados = false;
                state.excluindoContatoRealizado = false;
                state.errosExcluirContatoRealizado = processarMensagemErro(response);
            });
        }
    }
}