import { render, staticRenderFns } from "./PessoasJuridicasFormularioResponsaveis.vue?vue&type=template&id=50b9683f&scoped=true&"
import script from "./PessoasJuridicasFormularioResponsaveis.vue?vue&type=script&lang=js&"
export * from "./PessoasJuridicasFormularioResponsaveis.vue?vue&type=script&lang=js&"
import style0 from "./PessoasJuridicasFormularioResponsaveis.vue?vue&type=style&index=0&id=50b9683f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b9683f",
  null
  
)

export default component.exports