<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Pessoas Jurídicas</h1>
      <BotaoLink
        class="bt-novo"
        :to="{ name: PESSOA_JURIDICA_NEW }"
        v-if="
          usuario.funcionalidades.includes(PESSOA_JURIDICA_EDICAO_PERMISSAO)
        "
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading class="carregando1" v-if="carregandoPessoasJuridicas" />
    <AppOverlayLoading v-if="excluindo">
      <span>Excluindo... Por favor, aguarde.</span>
    </AppOverlayLoading>
    <AppErro
      v-if="erroCarregarPessoasJuridicas && !carregandoPessoasJuridicas"
    />

    <PessoasJuridicasPesquisaForm
      v-model="filtro"
      @submit="buscar"
      v-if="!carregandoPessoasJuridicas && !erroCarregarPessoasJuridicas"
    />

    <div v-if="!carregandoPessoasJuridicas && !erroCarregarPessoasJuridicas">
      <div class="campo-header-resultados">
        <AppTotalResultadosEncontrados :total-resultados="totalResultados" />
        <AppResultadosPorPagina
          :per-page="perPage"
          @per-page-changed="perPageChanged"
        />
      </div>

      <PessoasJuridicasResultados
        :pessoas-juridicas="pessoasJuridicas"
        @excluir-pessoa-juridica="excluirPessoaJuridica"
      />
      <div class="paginacao">
        <Paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="5"
          prev-text="Anterior"
          next-text="Próxima"
          :clickHandler="mudarPagina"
        ></Paginate>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

import BotaoLink from "../../components/botoes/BotaoLink";

import {
  PESSOA_JURIDICA_NEW,
  PESSOAS_JURIDICAS_INDEX,
} from "../../router/routeNames";
import { DEFAULT_PER_PAGE } from "../../config";
import PessoasJuridicasApi from "../../services/api/PessoasJuridicasApi";
import CodigosCnaeApi from "../../services/api/CodigosCnaeApi";

import AppResultadosPorPagina from "../../components/AppResultadosPorPagina";
import AppTotalResultadosEncontrados from "../../components/AppTotalResultadosEncontrados";

import AppLoading from "../../components/AppLoading.vue";
import AppErro from "../../components/AppErro.vue";
import AppOverlayLoading from "../../components/AppOverlayLoading.vue";

import PessoasJuridicasResultados from "../../components/pessoas_juridicas/PessoasJuridicasResultados";
import PessoasJuridicasPesquisaForm from "../../components/pessoas_juridicas/PessoasJuridicasPesquisaForm";
import processarMensagemErro from "../../helpers/processarMensagemErro";

import { getUsuario } from "../../services/Auth";
import { PESSOA_JURIDICA_EDICAO_PERMISSAO } from "../../permissoes";

const isEqual = require("lodash.isequal");
const get = require("lodash.get");

export default {
  name: "PessoasJuridicasIndex",
  metaInfo: {
    title: "Pessoas Jurídicas",
    titleTemplate: "%s - Gaia",
  },
  components: {
    AppLoading,
    AppResultadosPorPagina,
    BotaoLink,
    AppErro,
    AppTotalResultadosEncontrados,
    Paginate,
    PessoasJuridicasResultados,
    PessoasJuridicasPesquisaForm,
    AppOverlayLoading,
  },
  data() {
    return {
      PESSOA_JURIDICA_NEW,
      pessoasJuridicas: [],
      totalResultados: 0,
      carregandoFiltro: true,
      carregandoPessoasJuridicas: true,
      erroCarregarPessoasJuridicas: false,
      excluindo: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      filtro: {
        cnpj: this.$route.query.cnpj,
        razao_social: this.$route.query.razao_social,
        nome_fantasia: this.$route.query.nome_fantasia,
        codigo_cnae: null,
      },
      usuario: getUsuario(),
      PESSOA_JURIDICA_EDICAO_PERMISSAO,
    };
  },
  created() {
    this.pessoasJuridicasApi = new PessoasJuridicasApi();
    this.codigosCnaeApi = new CodigosCnaeApi();

    this.carregarPessoasJuridicas();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == PESSOAS_JURIDICAS_INDEX &&
        !isEqual(to.query, from.query)
      ) {
        this.carregarPessoasJuridicas();
      }
    },
  },
  methods: {
    async carregarPessoasJuridicas() {
      try {
        this.carregandoPessoasJuridicas = true;
        this.erroCarregarPessoasJuridicas = false;

        if (this.$route.query.codigo_cnae) {
          await this.carregarFiltros();
        }

        const data = await this.pessoasJuridicasApi.getPessoasJuridicas({
          ...this.$route.query,
          page: this.$route.query.page || this.page,
          perPage: this.$route.query.perPage || this.perPage,
        });

        this.pessoasJuridicas = data.resultados;
        this.totalResultados = data.total_resultados;

        this.carregandoPessoasJuridicas = false;
        this.carregandoFiltro = false;
      } catch (err) {
        console.log(err);
        this.erroCarregarPessoasJuridicas = true;
        this.carregandoPessoasJuridicas = false;
        this.carregandoFiltro = false;
      }
    },
    async carregarFiltros() {
      this.carregandoFiltro = true;
      const data = await this.codigosCnaeApi.autocomplete(
        null,
        this.$route.query.codigo_cnae
      );

      if (data && data.length > 0) {
        this.filtro.codigo_cnae = data[0];
      }
    },
    async excluirPessoaJuridica(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.excluindo = true;

        await this.pessoasJuridicasApi.excluirPessoaJuridica(id);

        this.excluindo = false;

        if (this.pessoasJuridicas.length == 1 && this.page > 1) {
          this.page--;

          this.$router.push({
            ...this.$route.query,
            perPage: this.$route.query.perPage || this.perPage,
            page: this.page,
          });
        } else {
          this.carregarPessoasJuridicas();
        }
        this.$swal({
          position: "top-right",
          icon: "success",
          title: "Registro excluído com sucesso.",
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      } catch (err) {
        this.excluindo = false;
        let mensagemErro = processarMensagemErro(err.response);

        this.$swal({
          icon: "error",
          title: mensagemErro.join(","),
          showConfirmButton: true,
        });
      }
    },
    buscar(evt) {
      evt.preventDefault();
      this.page = 1;

      const codigoCnaeId = get(this.filtro, "codigo_cnae.id", null);

      this.$router.push({
        name: PESSOAS_JURIDICAS_INDEX,
        query: {
          ...this.filtro,
          codigo_cnae: codigoCnaeId,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: PESSOAS_JURIDICAS_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 1.5rem 0rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}

.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
