<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>
        {{
          $route.name === INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW
            ? "Adicionar Polos Industriais"
            : "Editar Polos Industriais"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="$route.name === INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT"
        :to="{
          name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
          params: { id: $route.params.id },
        }"
      />
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
    <AppErro v-if="!carregando && erroCarregar" />
    <form v-if="!carregando && !erroCarregar" @submit="salvarDados">
      <AppErrosFormularios
        v-if="erroSalvar && erroSalvar.length > 0"
        :erros="erroSalvar"
      />
      <div>
        <div class="caixa-form">
          <div class="row2">
            <div class="item">
              <label>Nome: <span>*</span></label>
              <input type="text" v-model="poloIndustrial.nome" />
            </div>
            <div class="item">
              <label>Principais segmentos:</label>
              <input
                type="text"
                v-model="poloIndustrial.principais_segmentos"
              />
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Categoria:</label>
              <input type="text" v-model="poloIndustrial.categoria" />
            </div>
            <div class="item">
              <label>Município: <span>*</span></label>
              <select v-model="poloIndustrial.municipio_id">
                <option disabled value="">Selecione</option>
                <option
                  v-for="municipio in municipios"
                  :key="municipio.id"
                  :value="municipio.id"
                >
                  {{ municipio.nome }}
                </option>
              </select>
            </div>
            <div class="item">
              <label>Raio de influência: </label>
              <input type="text" v-model="poloIndustrial.raio_influencia" />
            </div>
          </div>
        </div>
        <div>
          <PolosIndustriaisPontosForm v-model="poloIndustrial.pontos" />
        </div>
        <div class="botao-salvar">
          <BotaoSalvar v-if="!salvando" />
          <AppLoading v-if="salvando" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW,
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";
import PolosIndustriaisApi from "../../../services/api/PolosIndustriaisApi";
import MunicipiosGoiasApi from "../../../services/api/MunicipiosGoiasApi";

import PolosIndustriaisPontosForm from "../../../components/investe_goias/polos_industriais/PolosIndustriaisPontosForm.vue";
import AppErro from "../../../components/AppErro.vue";
import AppLoading from "../../../components/AppLoading.vue";
import AppErrosFormularios from "../../../components/AppErrosFormularios";
import BotaoSalvar from "../../../components/botoes/BotaoSalvar.vue";
import BotaoVisualizarForm from "../../../components/botoes/BotaoVisualizarForm.vue";
export default {
  name: "PolosIndustriaisForm",
  components: {
    BotaoSalvar,
    AppErro,
    AppLoading,
    AppErrosFormularios,
    PolosIndustriaisPontosForm,
    BotaoVisualizarForm,
  },
  metaInfo: {
    title: "Editar Polo Industrial - Investe Goiás",
    titleTemplate: "%s - Gaia",
  },
  data() {
    return {
      poloIndustrial: {
        nome: "",
        categoria: "",
        principais_segmentos: "",
        municipio_id: "",
        raio_influencia: "",
        pontos: [],
      },
      municipios: [],
      carregando: true,
      erroCarregar: false,
      salvando: false,
      erroSalvar: [],
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW,
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_EDIT,
    };
  },
  async created() {
    this.polosIndustriaisApi = new PolosIndustriaisApi();
    this.municipiosGoiasApi = new MunicipiosGoiasApi();

    try {
      this.municipios = await this.municipiosGoiasApi.getMunicipiosGoias();
    } catch (err) {
      console.log(err);
      this.erroCarregar = true;
    }
    if (this.$route.params.id) {
      this.carregandoDados();
    } else {
      this.poloIndustrial = {
        id: "",
        nome: "",
        categoria: "",
        principais_segmentos: "",
        municipio_id: "",
        raio_influencia: "",
        pontos: [],
      };
      this.carregando = false;
    }
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        const data = await this.polosIndustriaisApi.getPoloIndustrial(
          this.$route.params.id
        );
        this.poloIndustrial = {
          id: data.data.id,
          nome: data.data.nome,
          categoria: data.data.categoria,
          principais_segmentos: data.data.principais_segmentos,
          municipio_id: data.data.municipio_id,
          raio_influencia: data.data.raio_influencia,
          pontos: data.data.pontos,
        };
        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregando = false;
        this.erroCarregar = false;
      }
    },
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.erroSalvar = [];

        const poloIndustrial = {
          id: this.poloIndustrial.id,
          nome: this.poloIndustrial.nome,
          categoria: this.poloIndustrial.categoria,
          principais_segmentos: this.poloIndustrial.principais_segmentos,
          municipio_id: this.poloIndustrial.municipio_id,
          raio_influencia: this.poloIndustrial.raio_influencia,
          pontos_attributes: this.poloIndustrial.pontos,
        };

        this.poloIndustrial = await this.polosIndustriaisApi.salvarPoloIndustrial(
          poloIndustrial
        );

        this.salvando = false;
        this.$router.push({
          name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
          params: { id: this.poloIndustrial.id },
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Polo industrial salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);
        this.salvando = false;
        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.erroSalvar = response.data;
          } else {
            this.erroSalvar = [response.data];
          }
        } else {
          this.erroSalvar = ["Houve um erro. Por favor, tente novamente"];
        }
        console.log(this.erroSalvar);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: var(--laranja-gaia);
    }
  }
  .caixa-form {
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
      select {
        height: 2rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
    }
  }
  .botao-salvar {
    text-align: center;
    margin: 2rem 0 3rem;
  }
}

@media screen and (min-width: 50rem) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
