<template>
  <div class="caixa-show">
    <div class="grupos grupo1">
      <h4>Dados básicos</h4>

      <div class="caixa-dados">
        <div class="row2-2">
          <div class="item">
            <strong>CNPJ: </strong>
            <span>{{ pessoaJuridica.cnpj | cpfCnpj }}</span>
          </div>

          <div class="item">
            <strong>Razão Social: </strong>
            <span>{{
              pessoaJuridica.razao_social_atual
                ? pessoaJuridica.razao_social_atual.razao_social
                : ""
            }}</span>
          </div>
        </div>
        <div class="row3">
          <div class="item">
            <strong>Nome Fantasia: </strong>
            <span>{{
              pessoaJuridica.nome_fantasia_atual &&
                pessoaJuridica.nome_fantasia_atual.nome_fantasia | naoInformado
            }}</span>
          </div>

          <div class="item">
            <strong>Tipo: </strong>
            <span>{{ pessoaJuridica.label_tipo }}</span>
          </div>
        </div>

        <div class="row3">
          <div class="item" v-if="pessoaJuridica.tipo == 'empresa'">
            <strong>CNPJ empresa matriz: </strong>
            <span>{{
              pessoaJuridica.cnpj_empresa_matriz | cpfCnpj | naoInformado
            }}</span>
          </div>

          <div class="item" v-if="pessoaJuridica.tipo == 'empresa'">
            <strong>Data de constituição: </strong>
            <span>{{
              pessoaJuridica.data_constituicao | toDate | naoInformado
            }}</span>
          </div>

          <div class="item" v-if="pessoaJuridica.tipo == 'empresa'">
            <strong>Classificação: </strong>
            <span>{{ pessoaJuridica.label_classificacao | naoInformado }}</span>
          </div>
        </div>

        <div class="row2-2">
          <div class="item">
            <strong>Registro junta comércial: </strong>
            <span>{{ pessoaJuridica.reg_junta_comercial | naoInformado }}</span>
          </div>

          <div class="item" v-if="pessoaJuridica.tipo == 'empresa'">
            <strong>Objetivo Social: </strong>
            <span>{{ pessoaJuridica.objetivo_social | naoInformado }}</span>
          </div>
        </div>

        <div class="row2-2">
          <div class="item" v-if="pessoaJuridica.tipo == 'empresa'">
            <strong>Classificação CNAE: </strong>
            <span class="codigo-cnae">{{ codigoCnae | naoInformado }}</span>
          </div>
        </div>

        <div class="row3">
          <div class="item">
            <strong>Site: </strong>
            <span>{{ pessoaJuridica.site | naoInformado }}</span>
          </div>
          <div class="item">
            <strong>E-mail: </strong>
            <span>{{ pessoaJuridica.email | naoInformado }}</span>
          </div>
        </div>

        <div class="item" v-if="pessoaJuridica.observacao">
          <strong>Observação</strong>
          <p>{{ pessoaJuridica.observacao }}</p>
        </div>
      </div>
    </div>

    <div class="grupos grupo2">
      <h4>Dados adicionais</h4>
      <div class="caixa-dados dados-adicionais">
        <div class="row2">
          <div class="item">
            <strong>Atua na área internacional: </strong>
            <span>{{
              pessoaJuridica.atua_area_internacional ? "Sim" : "Não"
            }}</span>
          </div>

          <div class="item" v-if="pessoaJuridica.atua_area_internacional">
            <strong>Data início área internacional: </strong>
            <span>{{
              pessoaJuridica.data_inicio_area_internacional
                | toDate
                | naoInformado
            }}</span>
          </div>
        </div>

        <div class="row2">
          <div class="item" v-if="pessoaJuridica.faturamento">
            <strong>Faturamento: </strong>
            <span>{{ pessoaJuridica.faturamento | currency }}</span>
          </div>

          <div class="item" v-if="pessoaJuridica.num_funcionarios">
            <strong>Número de funcionários: </strong>
            <span>{{ pessoaJuridica.num_funcionarios }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="grupos grupo2">
      <h4>Quadro societário / Responsável Legal</h4>
      <div class="caixa-dados">
        <div
          class="grupo-dados"
          v-for="pessoaResponsavel in pessoasResponsaveis"
          :key="pessoaResponsavel.id"
        >
          <div class="row3-2">
            <div class="item">
              <strong>Nome: </strong>
              <span>{{ pessoaResponsavel.pessoa_fisica.nome }}</span>
            </div>

            <div class="item">
              <strong>CPF: </strong>
              <span>{{ pessoaResponsavel.pessoa_fisica.cpf | cpfCnpj }}</span>
            </div>

            <div class="item">
              <strong>Função: </strong>
              <span>{{ pessoaResponsavel.funcao }} </span>
            </div>
          </div>
        </div>
        <div v-if="pessoasResponsaveis.length === 0">
          <p class="nenhum-resultado">Nenhuma pessoa responsável cadastrada</p>
        </div>
      </div>
    </div>
    <PessoasJuridicasContatosInfo
      :contatos="pessoaJuridica.contatos_pessoas_juridicas"
    />
    <PessoasJuridicasEnderecoInfo :enderecos="pessoaJuridica.enderecos" />
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";
import displayCodigoCnae from "../../../../helpers/displayCodigoCnae";
import PessoasJuridicasEnderecoInfo from "./PessoasJuridicasEnderecoInfo";
import PessoasJuridicasContatosInfo from "./PessoasJuridicasContatosInfo";

export default {
  name: "PessoasJuridicasShowDadosGerais",
  components: {
    PessoasJuridicasEnderecoInfo,
    PessoasJuridicasContatosInfo,
  },
  props: {
    pessoaJuridica: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toDate(value) {
      if (!value) return "Não informado";
      return toDate(value);
    },
  },
  methods: {
    displayCodigoCnae,
  },
  computed: {
    codigoCnae() {
      return this.pessoaJuridica.codigos_cnae_pessoas_juridicas
        .map((cod) => displayCodigoCnae(cod.codigo_cnae))
        .join("\n");
    },
    pessoasResponsaveis() {
      return this.pessoaJuridica.pessoas_responsaveis.filter(
        (pessoa) => pessoa._persistido
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.codigo-cnae {
  white-space: pre-line;
}
.caixa-show {
  margin-bottom: 5rem;
  h4 {
    margin: 0.5rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .grupos {
    margin-bottom: 1.5rem;
  }
  .caixa-dados {
    background-color: white;
    padding: 0.5rem 0.5rem 0;
    .grupo-dados {
      margin-top: 0.5rem;
      border-bottom: solid 0.1rem var(--cinza-caixa-gaia);
    }
    .item {
      display: grid;
      gap: 0.5rem;
      margin: 0.5rem 0 1.5rem;
      p {
        margin-top: 0;
      }
    }
    .nenhum-resultado {
      text-align: center;
      padding-bottom: 1.5rem;
      font-style: italic;
    }
  }
}
.dados-adicionais {
  padding-bottom: 0.01rem !important;
}
@media screen and(min-width: '50em') {
  .row2-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    align-items: baseline;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
  .row3-2 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
  }
}
</style>
