<template>
  <div class="row2-2">
    <div class="item">
      <label>CPF: </label>
      <div class="disp-flex">
        <TheMask type="text" mask="###.###.###-##" v-model="cpf" />
        <AppLoading v-if="buscando" texto="" size="small" />
      </div>
    </div>

    <div class="row2" v-if="!cpfInvalido && pessoaFisica">
      <div class="item">
        <label
          >Nome:
          <span v-if="!(pessoaFisica && pessoaFisica._persistido)"
            >*</span
          ></label
        >
        <span v-if="pessoaFisica && pessoaFisica._persistido">
          {{ pessoaFisica.nome }}
        </span>
        <input
          type="text"
          :value="pessoaFisica.nome"
          required
          @input="(e) => alterouPessoaFisica(e.target.value)"
          v-else
        />
      </div>

      <div class="item" v-if="pessoaResponsavel.pessoa_fisica">
        <label>Função: </label>
        <input
          type="text"
          @input="(e) => alterouFuncao(e.target.value)"
          :value="pessoaResponsavel.funcao"
        />
      </div>
    </div>

    <div class="nao-encontrado" v-if="cpfInvalido">
      CPF inválido
    </div>

    <div class="nao-encontrado" v-if="erroBuscar">
      Houve um erro ao buscar o CPF.
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import AppLoading from "../../../AppLoading";
import PessoasFisicasApi from "../../../../services/api/PessoasFisicasApi";
import PessoaResponsavel from "../../../../store/models/PessoaResponsavel";
import PessoaFisica from "../../../../store/models/PessoaFisica";

import validaCpf from "../../../../helpers/validaCpf";

export default {
  name: "PessoasJuridicasFormularioResponsavel",
  props: {
    pessoaResponsavel: {
      type: Object,
      required: true,
    },
  },
  components: {
    TheMask,
    AppLoading,
  },
  data() {
    return {
      cpf:
        this.pessoaResponsavel && this.pessoaResponsavel.pessoa_fisica
          ? this.pessoaResponsavel.pessoa_fisica.cpf
          : "",
      buscando: false,
      erroBuscar: null,
      cpfInvalido: false,
    };
  },
  watch: {
    cpf: function(newValue) {
      this.cpfInvalido = false;

      if (newValue.length !== 11) {
        this.desvincularPessoaFisica();
        return null;
      }

      if (validaCpf(newValue)) {
        this.buscar();
      } else {
        this.cpfInvalido = true;
        if (this.pessoaFisica) {
          this.desvincularPessoaFisica();
        }
      }
    },
    pessoaFisica: function(newValue) {
      if (newValue && newValue.cpf) {
        this.cpf = newValue.cpf;
      }
    },
  },
  created() {
    this.pessoasFisicasApi = new PessoasFisicasApi();
  },
  methods: {
    async buscar() {
      try {
        this.buscando = true;
        this.erroBuscar = null;

        const { resultados } = await this.pessoasFisicasApi.getPessoasFisicas({
          page: 1,
          termo: this.cpf,
        });

        if (resultados && resultados.length > 0) {
          PessoaResponsavel.insertOrUpdate({
            data: {
              ...this.pessoaResponsavel,
              pessoa_fisica: resultados[0],
              pessoa_fisica_id: resultados[0].id,
            },
          });
        } else {
          const valorInserido = await PessoaFisica.insert({
            data: { cpf: this.cpf },
          });

          PessoaResponsavel.insertOrUpdate({
            data: {
              ...this.pessoaResponsavel,
              pessoa_fisica_id: valorInserido?.pessoasFisicas[0]?.id,
              funcao: null,
            },
          });
        }

        this.buscando = false;
      } catch (err) {
        console.log(err, err.response);
        this.desvincularPessoaFisica();
        this.buscando = false;
        this.erroBuscar = "Houve um erro ao buscar a pessoa fisica";
      }
    },
    alterouFuncao(value) {
      PessoaResponsavel.update({
        where: this.pessoaResponsavel.id,
        data: {
          funcao: value,
        },
      });
    },
    alterouPessoaFisica(value) {
      PessoaFisica.update({
        where: this.pessoaResponsavel.pessoa_fisica_id,
        data: {
          nome: value,
        },
      });
    },
    desvincularPessoaFisica() {
      PessoaResponsavel.update({
        where: this.pessoaResponsavel.id,
        data: {
          pessoa_fisica: null,
          pessoa_fisica_id: null,
        },
      });
    },
  },
  computed: {
    pessoaFisica() {
      return PessoaFisica.query()
        .where("id", this.pessoaResponsavel.pessoa_fisica_id)
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 0.5rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  select {
    height: 1.9rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  .disp-flex {
    display: flex;
    flex-wrap: wrap;
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      svg {
        width: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: "50rem") {
  .row2-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    span {
      display: grid;
      align-items: flex-end;
      margin-bottom: 1rem;
      b {
        margin-right: 0.5rem;
      }
    }
    .item {
      span {
        display: inline;
      }
    }
    .nao-encontrado {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1rem;
      font-style: italic;
    }
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
