<template>
  <div class="tela-login">
    <div class="marcas">
      <img
        src="../assets/images/logo-gaia-branco.png"
        class="logo-gaia"
        alt="Logo do Sistema Gaia"
      />
    </div>
    <form @submit="doLogin" class="formulario-login">
      <div>
        <h1>Login</h1>
        <div v-show="erro" class="msg-erro">
          {{ this.erroMsg }}
        </div>
        <div class="item">
          <span class="label-info">
            <label for="user">Usuário:</label>
            <Tooltip
              >Usuário e senha são os mesmos utilizados para acessar o seu
              computador</Tooltip
            >
          </span>
          <input type="text" v-model="login" id="user" />
        </div>

        <div class="item">
          <label for="password">Senha:</label>
          <PasswordTextField v-model="password" id="password" />
        </div>

        <div class="botao-entrar">
          <AppLoading size="small" v-if="loading" texto="" />
          <button type="submit" :disabled="loading" v-else>Entrar</button>
        </div>
      </div>
    </form>
    <div class="marca-goias-mobile">
    </div>
  </div>
</template>

<script>
import AppLoading from "../components/AppLoading";
import PasswordTextField from "../components/inputs/PasswordTextField";
import Tooltip from "../components/inputs/Tooltip";
import getRotaInicial from "../helpers/getRotaInicial";
import * as Auth from "../services/Auth";

export default {
  name: "Login",
  metaInfo: {
    title: "Entrar",
    titleTemplate: "%s - Gaia",
  },
  components: {
    AppLoading,
    PasswordTextField,
    Tooltip,
  },
  data() {
    return {
      login: "",
      password: "",
      loading: false,
      erro: false,
      erroMsg: "",
    };
  },
  methods: {
    async doLogin(event) {
      try {
        event.preventDefault();

        this.erro = false;
        this.erroMsg = "";
        this.loading = true;
        await Auth.login(this.login, this.password);
        this.loading = false;

        const redirectTo = this.$route.query.redirect;

        if (redirectTo && redirectTo != "") {
          this.$router.push(redirectTo);
        } else {
          this.$router.push({
            name: getRotaInicial().name,
          });
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.erro = true;

        this.erroMsg =
          err ||
          "Houve um erro ao efetuar o login. Por favor, tente novamente.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-login {
  background-color: var(--verde-gaia);
  background-image: url("../assets/images/background-login.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  .marcas {
    text-align: center;
    margin-bottom: 1rem;
    .logo-sic {
      display: none;
    }
    .logo-gaia {
      height: 7rem;
    }
  }
  .formulario-login {
    display: block;
    background-color: white;
    padding: 1rem;
    margin: 0.5rem auto;
    max-width: 26rem;
    width: 80%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.438);
    .msg-erro {
      background-color: #e20000;
      padding: 0.2rem;
      text-align: center;
      color: white;
      font-family: "Lato-Regular";
      margin-top: -2rem;
      font-size: 0.9rem;
    }
    h1 {
      margin: 0;
      color: var(--laranja-gaia);
      font-family: "Lato-Bolder";
      margin-bottom: 3rem;
    }
    .item {
      display: grid;
      margin: 1rem 0rem;
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .is-mobile {
          div {
            display: none;
          }
        }
      }

      .border {
        position: absolute;
        height: 1rem;
        width: 1rem;
        top: 0;
        background-color: red;
        display: block;
      }
      label {
        font-family: "Lato-Regular";
        color: black;
      }
      input {
        height: 2rem;
        padding-left: 0.5rem;
        font-size: 1rem;
        border-radius: 2px;
        border: silver 0.1rem solid;
        background-color: transparent;
        font-family: "Lato-Regular";
      }
    }
    .botao-entrar {
      text-align: center;
      margin-top: 2rem;
      button {
        background-color: var(--verde-escuro-gaia);
        color: white;
        padding: 0.5rem 3rem;
        border: none;
        border-radius: 2px;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  .marca-goias-mobile {
    text-align: center;
    margin-top: 3rem;
    .logo-sic {
      height: 4rem;
    }
  }
}
@media screen and (min-width: "70rem") {
  .tela-login {
    flex-direction: row;
    background-position: left;
    justify-content: space-between;
    .marcas {
      margin: 0 auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-gaia {
        height: 10rem;
        margin: 0.5rem auto;
      }
      .logo-sic {
        display: block;
        height: 5rem;
        margin-top: 5rem;
      }
    }
    .formulario-login {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      max-width: 20rem;
      padding: 2rem 4rem;
      h1 {
        color: var(--laranja-gaia);
        margin-bottom: 3rem;
        margin-top: -5rem;
      }
    }
    .marca-goias-mobile {
      display: none;
    }
  }
}
</style>
