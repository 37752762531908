import { getUsuario } from "../services/Auth";
import rotasPrivadas from "../router/rotas_privadas"; 

// recebe apenas o nome da rota que se deseja verificar permissao de acesso.
// retorna true caso usuario tenha permissao de acesso 
export default function podeAcessarRota(routeName) {
   const usuario = getUsuario();

   if(!usuario) return false;

   return rotasPrivadas.some((route) => {
     if(route.name != routeName) return false;

     if(!route.meta?.requerPermissao) return true;

     if(route.meta?.exigirTodasPermissoes) {
        return route.meta.permissoes.every((permissao) => usuario.funcionalidades.includes(permissao))
     }else {
        return route.meta.permissoes.some((permissao) => usuario.funcionalidades.includes(permissao))
     }
   });
}