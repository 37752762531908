<template>
  <div class="caixa-form">
    <AppLoading
      v-if="
        carregandoListaProtocolosIntencao &&
          !erroCarregarListaProtocolosIntencao
      "
    />
    <AppErro
      v-if="
        erroCarregarListaProtocolosIntencao &&
          !carregandoListaProtocolosIntencao
      "
    />
    <template
      v-if="
        !carregandoListaProtocolosIntencao &&
          !erroCarregarListaProtocolosIntencao
      "
    >
      <div class="disp-flex">
        <h4>Protocolo de Intenção</h4>
      </div>

      <PessoasJuridicasItemProtocoloIntencao
        v-for="(protocoloIntencao, index) in protocolosIntencao"
        :index="protocolosIntencao.length - index"
        :protocolo-intencao="protocoloIntencao"
        :key="protocoloIntencao.id"
      />

      <div class="text-center" v-if="protocolosIntencao.length === 0">
        <p>Nenhum registro encontrado</p>
      </div>
    </template>
  </div>
</template>

<script>
import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";

import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";
import PessoasJuridicasItemProtocoloIntencao from "./PessoasJuridicasItemProtocoloIntencao";

export default {
  name: "PessoasJuridicasListaProtocolosIntencao",
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    AppErro,
    AppLoading,
    PessoasJuridicasItemProtocoloIntencao,
  },
  computed: {
    protocolosIntencao() {
      return ProtocoloIntencao.query()
        .where(
          "pessoa_juridica_id",
          isNaN(this.pessoaJuridicaId)
            ? this.pessoaJuridicaId
            : parseInt(this.pessoaJuridicaId)
        )
        .orderBy("data_inicio", "desc")
        .get();
    },
    erroCarregarListaProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .erroCarregarListaProtocolosIntencao;
    },
    carregandoListaProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .carregandoListaProtocolosIntencao;
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  margin-bottom: 2rem;
}
.text-center {
  text-align: center;
}
</style>
