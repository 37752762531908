import AtracaoInvestimentosApi from "../../../services/api/AtracaoInvestimentosApi";
import AtracaoInvestimento from "../../models/AtracaoInvestimento";

import processarMensagemErro from "../../../helpers/processarMensagemErro";


const atracaoInvestimentosApi = new AtracaoInvestimentosApi();

export default {
    async carregarListaAtracaoInvestimentos(_context, pessoaJuridicaId) {
        try {
            AtracaoInvestimento.commit((state) => {
                state.carregandoLista = true;
                state.erroCarregarLista = false;
                state.excluindo = false;
                state.errosExcluir = null;
            });

            const {
                resultados,
            } = await atracaoInvestimentosApi.getAtracaoInvestimentos({
                pessoaJuridicaId,
                page: 1,
                perPage: 1000,
            });

            await AtracaoInvestimento.insertOrUpdate({ data: resultados });

            AtracaoInvestimento.commit((state) => {
                state.carregandoLista = false;
                state.erroCarregarLista = false;
                state.excluindo = false;
                state.errosExcluir = null;
            });
        } catch (err) {
            console.log(err);
            AtracaoInvestimento.commit((state) => {
                state.carregandoLista = false;
                state.erroCarregarLista = true;
                state.excluindo = false;
                state.errosExcluir = null;
            });
        }
    },

    async carregarAtracaoInvestimento(_context, atracaoInvestimentoId) {
        try {
            AtracaoInvestimento.commit((state) => {
                state.carregandoAtracaoInvestimento = true;
                state.erroCarregarAtracaoInvestimento = false;
            });

            const atracaoInvestimento = await atracaoInvestimentosApi.getAtracaoInvestimento(atracaoInvestimentoId);
            await AtracaoInvestimento.insertOrUpdate({ data: atracaoInvestimento });

            AtracaoInvestimento.commit((state) => {
                state.carregandoAtracaoInvestimento = false;
                state.erroCarregarAtracaoInvestimento = false;
            });

        } catch (err) {
            console.log(err);
            AtracaoInvestimento.commit((state) => {
                state.carregandoAtracaoInvestimento = false;
                state.erroCarregarAtracaoInvestimento = true;
            });
        }
    },

    async excluirAtracaoInvestimento(_context, atracaoInvestimentoId) {
        try {
            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = true;
                state.errosExcluir = null;
            });

            const atracaoInvestimento = AtracaoInvestimento.query().whereId(atracaoInvestimentoId).first();

            if (atracaoInvestimento._persistido) {
                await atracaoInvestimentosApi.excluirAtracaoInvestimento(atracaoInvestimentoId);
            }

            AtracaoInvestimento.delete(atracaoInvestimento.id);

            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = false;
                state.errosExcluir = null;
            });
        } catch (err) {
            console.log(err);

            const { response } = err;
            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = false;
                state.errosExcluir = processarMensagemErro(response);
            });
        }
    },

    async excluirAtracaoInvestimentoBatch(_context, atracaoInvestimentosIds) {
        try {
            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = true;
                state.errosExcluir = null;
            });

            await atracaoInvestimentosApi.excluirBatchAtracaoInvestimentos(atracaoInvestimentosIds);

            await AtracaoInvestimento.delete((atracao) => atracaoInvestimentosIds.includes(atracao.id));

            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = false;
                state.errosExcluir = null;
            });
        } catch (err) {
            console.log(err);

            const { response } = err;
            AtracaoInvestimento.commit((state) => {
                state.salvandoLista = false;
                state.errosSalvarLista = false;
                state.excluindo = false;
                state.errosExcluir = processarMensagemErro(response);
            });
        }
    }
}

