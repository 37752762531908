<template>
  <div class="caixa-form">
    <h4>Órgãos envolvidos</h4>
    <!-- varios órgãos -->
    <div class="caixa-item">
      <div v-for="orgao in orgaosEnvolvidos" :key="orgao.id">
        <div class="row3">
          <p>
            <strong>Nome do órgão: </strong>
            {{ orgao.nome_orgao }}
          </p>
          <p>
            <strong>Data prevista de termino: </strong>
            {{ orgao.data_prevista_termino | toDate | naoInformado }}
          </p>
          <p>
            <strong>Atribuições: </strong>
            {{ orgao.atribuicoes | naoInformado }}
          </p>
        </div>
      </div>
      <p
        class="text-center"
        v-if="!orgaosEnvolvidos || orgaosEnvolvidos.length == 0"
      >
        Nenhum órgão envolvido
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasProtocoloListaOrgaosEnvolvidos",
  props: {
    orgaosEnvolvidos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-form {
  h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  margin-bottom: 2rem;
}
.text-center {
  text-align: center;
}
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    padding: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
  }
}
.text-center {
  text-align: center;
}
@media screen and (min-width: "60em") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
}
</style>
