<template>
  <div class="numeros-resultados">
    <h4>
      {{
        totalResultados == 1
          ? "1 resultado encontrado"
          : `${totalResultados} resultados encontrados`
      }}
    </h4>
  </div>
</template>

<script>
export default {
  name: "AppTotalResultadosEncontrados",
  props: {
    totalResultados: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.2rem;
  font-family: "Lato";
}
</style>
