<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <h1>Exibir Pessoa Física</h1>
      <BotaoEditarShow
        :to="{
          name: CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
          params: { id: $route.params.id },
        }"
      />
    </div>

    <div>
      <AppLoading v-if="carregandoPessoaFisica && !erroCarregarPessoaFisica" />
      <AppErro v-if="!carregandoPessoaFisica && erroCarregarPessoaFisica" />

      <AppTabs
        v-if="!carregandoPessoaFisica && !erroCarregarPessoaFisica"
        v-model="currentTab"
        :tabs="tabs"
        :keep-alive="true"
      >
        <template v-slot:[tabs[0].id]>
          <PessoasFisicasShowTabGeral :pessoa-fisica="pessoaFisica" />
        </template>
        <template v-slot:[tabs[1].id]>
          <PessoasFisicasShowTabFuncoes />
        </template>
      </AppTabs>
    </div>
  </div>
</template>

<script>
import {
  CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import AppTabs from "../../../components/AppTabs";
import PessoasFisicasApi from "../../../services/api/PessoasFisicasApi";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import PessoasFisicasShowTabGeral from "../../../components/cadastros_basicos/pessoas_fisicas/PessoasFisicasShowTabGeral";
import PessoasFisicasShowTabFuncoes from "../../../components/cadastros_basicos/pessoas_fisicas/PessoasFisicasShowTabFuncoes";

import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";

export default {
  name: "PessoasFisicasShow",
  components: {
    AppTabs,
    AppErro,
    AppLoading,
    PessoasFisicasShowTabGeral,
    PessoasFisicasShowTabFuncoes,
    BotaoEditarShow,
  },
  metaInfo: {
    title: "Exibir Pessoa Física",
    titleTemplate: "%s - Gaia",
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: "Geral",
        permissoes: [],
      },
      {
        id: 2,
        label: "Funções/Cargos",
        permissoes: [],
      },
    ];

    return {
      pessoaFisica: null,
      carregandoPessoaFisica: true,
      erroCarregarPessoaFisica: false,
      tabs,
      currentTab: tabs[0],
      CADASTROS_BASICOS_PESSOAS_FISICAS_EDIT,
    };
  },
  created() {
    this.pessoasFisicasApi = new PessoasFisicasApi();

    this.carregarPessoaFisica();
  },
  methods: {
    async carregarPessoaFisica() {
      try {
        this.carregandoPessoaFisica = true;
        this.erroCarregarPessoaFisica = false;

        this.pessoaFisica = await this.pessoasFisicasApi.getPessoaFisica(
          this.$route.params.id
        );

        this.carregandoPessoaFisica = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        this.carregandoPessoaFisica = false;
        this.erroCarregarPessoaFisica = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0rem 1rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
}
</style>
