import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

export default class ContatosRealizadosApi {
  cancelTokens = {
    getContatosRealizados: null,
    getContatoRealizado: null,
    salvarContatoRealizado: null,
    getEtapas: null,
    excluirBatchContatosRealizados: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getContatosRealizados({
    page = 1,
    perPage,
    pessoaJuridicaId,
    periodoInicioContato,
    periodoFimContato,
  }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getContatosRealizados = source;

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
      pessoa_juridica_id: pessoaJuridicaId,
      periodo_inicio_contato: periodoInicioContato,
      periodo_fim_contato: periodoFimContato,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getContatosRealizados = null;

    return data;
  }

  async getContatoRealizado(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getContatoRealizado = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getContatoRealizado = null;

    return { ...data, _completo: true };
  }

  async salvarContatoRealizado(contatoRealizado) {
    let method;
    let url;
    if (contatoRealizado.id) {
      method = axiosAuthenticated.put;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados/${contatoRealizado.id}`;
    } else {
      method = axiosAuthenticated.post;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados`;
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarContatoRealizado = source;
    const { data } = await method(
      url,
      {
        ...contatoRealizado
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarContatoRealizado = null;

    return data;
  }

  async excluirBatchContatosRealizados(ids) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.excluirBatchContatosRealizados = source;

    const searchString = ids.map((id) => `ids[]=${id}`).join("&");

    const { data } = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados/destroy_batch?${searchString}`
    );

    this.cancelTokens.excluirBatchContatosRealizados = null;

    return data;
  }

  async getEtapas() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getEtapas = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/contatos_realizados/etapas`
    );

    this.cancelTokens.getEtapas = null;

    return data;
  }
}
