import Mesorregiao from "./Mesorregiao";
import ModelPersistido from "./ModelPersistido";

export default class Microrregiao extends ModelPersistido {
  static entity = "microrregioes";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.string(null).nullable(),
      mesorregiao_id: this.attr(null),
      mesorregiao: this.belongsTo(Mesorregiao, "mesorregiao_id"),
    };
  }
}
