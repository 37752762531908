import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

import buildFormData from "../../helpers/buildFormData";

const queryString = require("query-string");

export default class ProtocolosIntencaoApi {
    cancelTokens = {
        getProtocolosIntencao: null,
        salvarProtocoloIntencao: null,
        excluirBatchProtocolosIntencao: null,
        getTiposProjeto: null,
        getStatuses: null,
        getProtocoloIntencao: null,
    }

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown)
    }

    async getProtocolosIntencao({
        page = 1,
        perPage,
        pessoaJuridicaId,
        processoSei,
        tipoProjeto,
        numero,
        status
    }) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getProtocolosIntencao = source;

        const searchString = queryString.stringify({
            page,
            per_page: perPage,
            pessoa_juridica_id: pessoaJuridicaId,
            processo_sei: processoSei,
            tipo_projeto: tipoProjeto,
            numero,
            status
        });

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao?${searchString}`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getProtocolosIntencao = null;

        return data;
    }

    async getProtocoloIntencao(id) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.getProtocoloIntencao = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao/${id}`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getProtocoloIntencao = null;

        return { ...data, _completo: true };
    }

    async salvarProtocoloIntencao(protocoloIntencao) {
        let method;
        let url;
        if (protocoloIntencao.id) {
            method = axiosAuthenticated.put;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao/${protocoloIntencao.id}`;
        } else {
            method = axiosAuthenticated.post;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao`;
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.salvarProtocoloIntencao = source;
        const { data } = await method(
            url,
            buildFormData(protocoloIntencao),
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                cancelToken: source.token,
            }
        );

        this.cancelTokens.salvarProtocoloIntencao = null;

        return data;
    }

    async excluirBatchProtocolosIntencao(ids) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.excluirBatchProtocolosIntencao = source;

        const searchString = ids.map((id) => `ids[]=${id}`).join('&')

        const { data } = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao/destroy_batch?${searchString}`
        );

        this.cancelTokens.excluirBatchProtocolosIntencao = null;

        return data;
    }

    async getTiposProjeto() {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getTiposProjeto = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao/tipos_projeto`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getTiposProjeto = null;

        return data;
    }

    async getStatuses() {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();

        this.cancelTokens.getStatuses = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/protocolos_intencao/statuses`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getStatuses = null;

        return data;
    }
}