<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Nome da tradução</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="tipoInvestimento in tiposInvestimento"
          :key="tipoInvestimento.id"
        >
          <td class="topo-card">{{ tipoInvestimento.tipo_investimento_traducao_padrao.nome }}</td>
          <td class="acoes">
            <BotaoExibirTabela
              :to="{
                name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
                params: {
                  id: tipoInvestimento.id,
                },
              }"
            />
            <BotaoEditarTabela
              :to="{
                name: INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
                params: {
                  id: tipoInvestimento.id,
                },
              }"
            />
            <BotaoDeletarTabela @click="deletar(tipoInvestimento.id)" />
          </td>
        </tr>
        <tr v-if="tiposInvestimento.length == 0">
          <td class="text-center">Nenhum resultado encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BotaoExibirTabela from "../../botoes/botoes_tabelas_resultados/BotaoExibirTabela.vue";
import BotaoEditarTabela from "../../botoes/botoes_tabelas_resultados/BotaoEditarTabela.vue";
import BotaoDeletarTabela from "../../botoes/botoes_tabelas_resultados/BotaoDeletarTabela.vue";

import {
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
  INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
} from "../../../router/routeNames";
export default {
  name: "TiposInvestimentoResultados",
  components: {
    BotaoExibirTabela,
    BotaoEditarTabela,
    BotaoDeletarTabela,
  },
  props: {
    tiposInvestimento: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
      INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
    };
  },
  methods: {
    deletar(id) {
      this.$emit("removerTipoInvestimento", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.resultados {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
    thead {
      background-color: var(--verde-gaia);
      color: white;

      th {
        text-align: left;
        padding: 1.5rem 1rem;
      }
      .th-maior {
        min-width: 10rem;
      }
      .text-center {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 1rem 1rem;
      }
      tr:nth-child(even) {
        background-color: var(--cinza-caixa-gaia);
      }
      .text-center {
        text-align: center;
      }
      .acoes {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          svg {
            fill: var(--vermelho-gaia);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        // margin: .5rem;
        display: block;
        background-color: var(--cinza-caixa-gaia);
        width: 100% !important;
        border-radius: 2px;
        overflow: hidden;
        padding: 0 0 1rem;
        td {
          padding: 0rem;
          min-height: 1.94rem;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acoes {
          display: flex;
          justify-content: space-evenly;
        }
        .acoes::before{
          display: none;
        }
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        color: var(--verde-escuro-gaia);
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color:  var(--verde-gaia);
        font-family: 'lato-bold';
        text-transform: uppercase;
        margin: 0rem 0rem 1rem 0rem;
        padding: .5rem;
        font-size: 0.9rem;
        color: white;
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 50rem) {
  tbody {
    grid-template-columns: 1fr !important;
  }
}
</style>