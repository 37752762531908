<template>
  <router-link class="bt-header" :to="to"> <EyeIcon /> Exibir </router-link>
</template>

<script>
import EyeIcon from "vue-material-design-icons/Eye.vue";
export default {
  name: "BotaoVisualizarIndex",
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  components: {
    EyeIcon,
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color:  var(--verde-escuro-gaia);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0rem;
  padding: 0rem 1.5rem;
  border:  var(--verde-escuro-gaia) solid 0.15rem;
  transition: linear 0.2s;
  -moz-transition: linear 0.2s;
  -webkit-transition: linear 0.2s;
  height: 2rem;
  svg {
    width: 1.3rem;
    margin-right: 1rem;
    fill:  var(--verde-escuro-gaia);
    -moz-transition: linear 0.2s;
    -webkit-transition: linear 0.2s;
  }
}
a:hover {
  background-color:  var(--verde-escuro-gaia);
  color: white;
  svg {
    fill: white;
  }
}
</style>