<template>
  <div>
<img src="./../assets/images/nao-encontrado.png" alt=" ">
    <h1>Página não encontrada</h1>
  </div>
</template>

<script>
export default {
  name: "NaoEncontrado",
  metaInfo: {
    title: "Não encontrado",
    titleTemplate: "%s - Gaia",
  },
};
</script>

<style lang="scss" scoped>
div{
  text-align: center;
  margin-top: 1rem;
  h1{
    color: var(--vermelho-gaia);
  }
}
img{
  max-width: 20rem;
  width: 80%;
}

</style>
