<template>
  <div class="modal-tela">
    <div class="modal-caixa">
      <a class="bt-fechar" @click="cancelar">
        <b><IconFechar /></b>
      </a>

      <div v-if="carregando && !erro">
        <AppLoading />
      </div>

      <div v-if="!carregando && erro">
        <AppErro />
      </div>

      <template v-if="!carregando && !erro">
        <h1>Atração de Investimento</h1>

        <div v-if="!salvando && errosSalvar">
          <AppErroFormulario :erros="errosSalvar" />
        </div>
        <form @submit="salvar">
          <div class="row3">
            <div class="item">
              <label>Data de inicio: <span>*</span></label>
              <DatePicker
                v-model="atracaoInvestimento.data_inicio"
                format="DD/MM/YYYY"
                :input-attr="{ required: true }"
              />
            </div>

            <div class="item">
              <label>Área internacional: </label>
              <div class="disp-flex">
                <label>
                  <input
                    type="radio"
                    :value="true"
                    name="area_internacional"
                    v-model="atracaoInvestimento.area_internacional"
                  />
                  <span>Sim</span>
                </label>

                <label>
                  <input
                    type="radio"
                    :value="false"
                    name="area_internacional"
                    v-model="atracaoInvestimento.area_internacional"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>

            <div class="item">
              <label>Ja estabelecida em Goiás: </label>
              <div class="disp-flex">
                <label>
                  <input
                    type="radio"
                    :value="true"
                    name="ja_estabelecida_em_goias"
                    v-model="atracaoInvestimento.ja_estabelecida_em_goias"
                  />
                  <span>Sim</span>
                </label>

                <label>
                  <input
                    type="radio"
                    :value="false"
                    name="ja_estabelecida_em_goias"
                    v-model="atracaoInvestimento.ja_estabelecida_em_goias"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Tipo de Projeto: </label>
              <select v-model="atracaoInvestimento.tipo_projeto">
                <option :value="null">Nenhum</option>
                <option
                  v-for="tipo in tiposProjeto"
                  :key="tipo.value"
                  :value="tipo.value"
                >
                  {{ tipo.label }}
                </option>
              </select>
            </div>

            <div class="item">
              <label>Quem iniciou o contato: <span>*</span></label>
              <select
                v-model="atracaoInvestimento.quem_iniciou_contato"
                required
              >
                <option :value="undefined" disabled>Selecione</option>
                <option
                  v-for="ente in quemIniciouContato"
                  :key="ente.value"
                  :value="ente.value"
                >
                  {{ ente.label }}
                </option>
              </select>
            </div>

            <div
              class="item"
              v-if="atracaoInvestimento.quem_iniciou_contato == 'outro'"
            >
              <label>Nome de quem iniciou o contato: </label>
              <input
                v-model="atracaoInvestimento.nome_quem_iniciou_contato"
                type="text"
              />
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Empregos previstos: </label>
              <input
                type="number"
                v-model="atracaoInvestimento.empregos_previstos"
              />
            </div>

            <div class="item">
              <label>Investimentos Previstos: </label>
              <CurrencyInput
                v-model="atracaoInvestimento.investimentos_previstos"
              />
            </div>

            <div class="item">
              <label>Empresa precisa de fomento: </label>
              <div class="disp-flex">
                <label>
                  <input
                    type="radio"
                    :value="true"
                    name="empresa_precisa_de_fomento"
                    v-model="atracaoInvestimento.empresa_precisa_de_fomento"
                  />
                  <span>Sim</span>
                </label>

                <label>
                  <input
                    type="radio"
                    :value="false"
                    name="empresa_precisa_de_fomento"
                    v-model="atracaoInvestimento.empresa_precisa_de_fomento"
                  />
                  <span>Não</span>
                </label>
              </div>
            </div>
          </div>
          <div class="item">
            <label>Observação: </label>
            <textarea v-model="atracaoInvestimento.observacao"></textarea>
          </div>

          <AppTabs :tabs="tabs" v-model="currentTab">
            <template v-slot:[tabs[0].id]>
              <PessoasJuridicasSolicitacoes
                :tipos-solicitacao="tiposSolicitacao"
                v-model="
                  atracaoInvestimento.solicitacoes_empresa_atracao_investimento
                "
              />
            </template>

            <template v-slot:[tabs[1].id]>
              <PessoasJuridicasMunicipiosAlvo
                :municipios="municipios"
                :tipos-area="tiposArea"
                v-model="atracaoInvestimento.municipios_alvos"
              />
            </template>
          </AppTabs>

          <div class="botoes" v-if="!salvando">
            <button class="bt-cancelar" type="button" @click="cancelar">
              Cancelar
            </button>
            <button class="bt-salvar" type="submit"><IconSave /> Salvar</button>
          </div>
          <AppLoading v-if="salvando" size="small" texto="" />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import AppErroFormulario from "../../../AppErrosFormularios";
import AppTabs from "../../../AppTabs";

import AtracaoInvestimentoApi from "../../../../services/api/AtracaoInvestimentosApi";
import MunicipiosGoiasApi from "../../../../services/api/MunicipiosGoiasApi";
import TiposSolicitacaoEmpresaAtracaoApi from "../../../../services/api/TiposSolicitacaoEmpresaAtracaoApi";
import TiposAreaApi from "../../../../services/api/TiposAreaApi";
import { PESSOA_JURIDICA_EDIT } from "../../../../router/routeNames";
import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";
import SolicitacaoEmpresaAtracaoInvestimento from "../../../../store/models/SolicitacaoEmpresaAtracaoInvestimento";

import { modulePessoaJuridica } from "../../../../store/moduleNames";

import processarMensagemErro from "../../../../helpers/processarMensagemErro";

import PessoasJuridicasSolicitacoes from "./PessoasJuridicasSolicitacoes";
import PessoasJuridicasMunicipiosAlvo from "./PessoasJuridicasMunicipiosAlvo";
import MunicipioAlvo from "../../../../store/models/MunicipioAlvo";

import IconSave from "../..//../../assets/images/icon-save.svg";
import IconFechar from "../..//../../assets/images/icon-fechar.svg";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasAtracaoInvestimentoForm",
  data() {
    const tabs = [
      {
        id: 1,
        label: " Solicitações",
        permissoes: [],
      },
      {
        id: 2,
        label: "Municípios de Interesse",
        permissoes: [],
      },
    ];
    return {
      atracaoInvestimento: {
        pessoa_juridica_id: this.$route.params.id,
        area_internacional: false,
        ja_estabelecida_em_goias: false,
        empresa_precisa_de_fomento: false,
        solicitacoes_empresa_atracao_investimento: [],
        municipios_alvos: [],
      },
      quemIniciouContato: [],
      tiposProjeto: [],
      municipios: [],
      tiposSolicitacao: [],
      tiposArea: [],
      carregandoValores: true,
      erroCarregandoValores: false,
      salvando: false,
      errosSalvar: null,
      tabs,
      currentTab: tabs[0],
      PESSOA_JURIDICA_EDIT,
    };
  },
  components: {
    AppErro,
    AppErroFormulario,
    AppLoading,
    AppTabs,
    DatePicker,
    PessoasJuridicasSolicitacoes,
    PessoasJuridicasMunicipiosAlvo,
    IconSave,
    IconFechar,
  },
  async created() {
    this.atracaoInvestimentoApi = new AtracaoInvestimentoApi();
    this.municipiosGoiasApi = new MunicipiosGoiasApi();
    this.tiposSolicitacaoEmpresaAtracaoApi = new TiposSolicitacaoEmpresaAtracaoApi();
    this.tiposAreaApi = new TiposAreaApi();

    this.carregarValores();
    if (this.$route.query.action == "edit") {
      if (
        (this.atracaoInvestimentoPersistido &&
          this.atracaoInvestimentoPersistido._persistido &&
          !this.atracaoInvestimentoPersistido._completo) ||
        !this.atracaoInvestimentoPersistido
      ) {
        await this.carregarAtracaoInvestimento(
          this.$route.query.atracao_investimento_id
        );
      } else if (this.atracaoInvestimentoPersistido) {
        this.atracaoInvestimento = this.atracaoInvestimentoToLocalObject(
          this.atracaoInvestimentoPersistido
        );
      }
    }
  },
  watch: {
    atracaoInvestimentoPersistido: function(newValue) {
      if (newValue.$toJson()) {
        this.atracaoInvestimento = this.atracaoInvestimentoToLocalObject(
          newValue
        );
      }
    },
  },
  methods: {
    async carregarValores() {
      //carrega valores para os combo boxes
      try {
        this.carregandoValores = true;
        this.erroCarregandoValores = false;

        const [
          quemIniciouContato,
          tiposProjeto,
          municipios,
          { resultados: tiposSolicitacao },
          { resultados: tiposArea },
        ] = await Promise.all([
          this.atracaoInvestimentoApi.getQuemIniciouContato(),
          this.atracaoInvestimentoApi.getTiposProjeto(),
          this.municipiosGoiasApi.getMunicipiosGoias(),
          this.tiposSolicitacaoEmpresaAtracaoApi.getTiposSolicitacao({
            page: 1,
            perPage: 100,
          }),
          this.tiposAreaApi.getTiposArea({
            page: 1,
            perPage: 100,
          }),
        ]);
        this.quemIniciouContato = quemIniciouContato;
        this.tiposProjeto = tiposProjeto;
        this.municipios = municipios;
        this.tiposSolicitacao = tiposSolicitacao;
        this.tiposArea = tiposArea;

        this.carregandoValores = false;
        this.erroCarregandoValores = false;
      } catch (err) {
        console.log(err);
        this.carregandoValores = false;
        this.erroCarregandoValores = true;
      }
    },
    async salvar(event) {
      try {
        event.preventDefault();
        this.salvando = true;
        this.errosSalvar = null;
        const atracao = await this.atracaoInvestimentoApi.salvarAtracaoInvestimento(
          {
            ...this.atracaoInvestimento,
            solicitacoes_empresa_atracao_investimento_attributes: this
              .atracaoInvestimento.solicitacoes_empresa_atracao_investimento,
            municipios_alvos_attributes: this.atracaoInvestimento
              .municipios_alvos,
          }
        );

        //precisamos fazer refresh nas associacioes.
        const solicitacoesIds = SolicitacaoEmpresaAtracaoInvestimento.query()
          .where("atracao_investimento_id", atracao.id)
          .get()
          .map((solicitacao) => solicitacao.id);
        await SolicitacaoEmpresaAtracaoInvestimento.delete((solicitacao) =>
          solicitacoesIds.includes(solicitacao.id)
        );

        const municipiosIds = MunicipioAlvo.query()
          .where("atracao_investimento_id", atracao.id)
          .get()
          .map((municipioAlvo) => municipioAlvo.id);
        await MunicipioAlvo.delete((municipioAlvo) =>
          municipiosIds.includes(municipioAlvo.id)
        );

        //fim refresh associacoes

        await AtracaoInvestimento.insertOrUpdate({ data: atracao });
        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#atracao",
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Atração de investimento salva com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.salvando = false;
      } catch (err) {
        console.log(err);
        this.salvando = false;
        this.errosSalvar = processarMensagemErro(err.response);
      }
    },
    async cancelar() {
      const result = await this.$swal.fire({
        title: "Você deseja fechar essa tela? ",
        text: "As alterações feitas serão descartadas",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#054700",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        this.$router.push({
          name: PESSOA_JURIDICA_EDIT,
          params: { id: this.$route.params.id },
          hash: "#atracao",
        });
      }
    },
    atracaoInvestimentoToLocalObject(atracao) {
      return {
        ...atracao.$toJson(),
        data_inicio: atracao.dataInicioToDate,
      };
    },
    ...mapActions(["carregarAtracaoInvestimento"]),
  },
  computed: {
    carregando() {
      return (
        this.$store.state.entities.atracaoInvestimentos
          .carregandoAtracaoInvestimento ||
        this.carregandoValores ||
        this.$store.state.entities.atracaoInvestimentos.carregandoLista
      );
    },
    erro() {
      return (
        this.$store.state.entities.atracaoInvestimentos
          .erroCarregarAtracaoInvestimento || this.erroCarregandoValores
      );
    },
    atracaoInvestimentoPersistido() {
      if (!this.$route.query.atracao_investimento_id) return null;

      return AtracaoInvestimento.query()
        .whereId(parseInt(this.$route.query.atracao_investimento_id))
        .withAll()
        .with("solicitacoes_empresa_atracao_investimento", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .with("municipios_alvos", (query) => {
          query.orderBy("updated_at", "desc");
        })
        .first();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-tela {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
  .bt-fechar {
    margin-left: auto;
    display: inline-block;
  }
}

.modal-caixa {
  background-color: white;
  padding: 0.5rem 0.5rem 0rem;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  overflow-y: scroll;
  position: relative;
  .bt-fechar {
    position: absolute;
    text-decoration: none;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    svg {
      height: 1.1rem;
      width: 1.1rem;
      fill: var(--vermelho-gaia);
    }
  }
  h1 {
    color: var(--laranja-gaia);
  }

  .item {
    display: grid;
    margin: 1rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    textarea {
      height: 5rem;
    }
    .disp-flex {
      display: flex;
      flex-wrap: wrap;
      label {
        display: flex;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;
        margin: 0 1rem 0 0;
        input {
          position: absolute;
          left: -9999px;
          &:checked + span {
            background-color: #efefef;
            &:before {
              box-shadow: inset 0 0 0 0.4375em var(--verde-gaia);
            }
          }
        }
        span {
          display: flex;
          align-items: center;
          padding: 0.375em 0.75em 0.375em 0.375em;
          border-radius: 99em;
          transition: 0.25s ease;
          color: black;
          &:hover {
            background-color: #efefef;
          }
          &:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            background-color: #fff;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #efefef;
          }
        }
      }
    }
  }
  .botoes {
    margin: 2rem 0 2rem;
    display: grid;
    gap: 1rem;
    button {
      font-size: 1em;
      height: 2.5rem;
      border-radius: 2px;
      min-width: 15rem;
      cursor: pointer;
    }
    .bt-cancelar {
      background-color: transparent;
      border: var(--vermelho-gaia) solid 0.15rem;
      color: var(--vermelho-gaia);
    }

    .bt-cancelar:hover {
      background-color: var(--vermelho-gaia);
      border: var(--vermelho-gaia) solid 0.15rem;
      color: white;
    }
    .bt-salvar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--verde-escuro-gaia);
      color: white;
      border: none;
      svg {
        height: 1rem;
        width: 2rem;
      }
    }
  }
}
.modal-caixa::-webkit-scrollbar {
  width: 10px;
}
.modal-caixa::-webkit-scrollbar-track {
  background-color: silver;
}
.modal-caixa::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}
@media screen and (min-width: "60em") {
  .modal-caixa {
    padding: 1rem;
    height: 95vh;
    width: 80vw;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .botoes {
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
</style>
