import ModelPersistido from "./ModelPersistido";
import PessoaFisica from "./PessoaFisica";
import PessoaJuridica from "./PessoaJuridica";

export default class PessoaResponsavel extends ModelPersistido {
  static entity = "pessoasResponsaveis";

  static fields() {
    return {
      id: this.attr(null),
      pessoa_fisica_id: this.attr(null).nullable(),
      pessoa_juridica_id: this.attr(null),
      funcao: this.attr(null),
      pessoa_fisica: this.belongsTo(PessoaFisica, "pessoa_fisica_id"),
      pessoa_juridica: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
      _destroy: this.boolean(false)
    };
  }
}
