<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>
        {{ "Realizar Mapeamento de Oportunidades" }}
      </h1>
      <BotaoVisualizarForm
        v-if="
          $route.name === ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT
        "
        :to="{
          name: ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
          params: { id: $route.params.id },
        }"
      />
    </div>
    <div>
      <AppLoading v-if="carregandoDados && !erroCarregandoDados" />
      <AppErro v-if="!carregandoDados && erroCarregandoDados" />
      <form
        v-if="!carregandoDados && !erroCarregandoDados"
        @submit="salvarDados"
      >
        <AppErrosFormularios
          v-if="errosSalvando && errosSalvando.length > 0"
          :erros="errosSalvando"
        />
        <div class="caixa-form">
          <div class="row2">
            <div class="item">
              <div class="checkbox">
                <label class="check">
                  <input
                    type="checkbox"
                    name="possui_distrito_industrial"
                    v-model="mapeamentoOportunidade.possui_distrito_industrial"
                    :checked="mapeamentoOportunidade.possui_distrito_industrial"
                    :true-value="true"
                    :false-value="false"
                    disabled="true"
                  />
                  <svg viewBox="0 0 21 21">
                    <path
                      d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                    ></path>
                  </svg>
                  <span>Possui distrito industrial</span>
                </label>
              </div>
              <div class="checkbox">
                <label class="check" for="checkbox">
                  <input
                    type="checkbox"
                    name="emite_licenca_ambiental"
                    v-model="mapeamentoOportunidade.emite_licenca_ambiental"
                    :checked="mapeamentoOportunidade.emite_licenca_ambiental"
                    :true-value="true"
                    :false-value="false"
                    disabled="true"
                  />
                  <svg viewBox="0 0 21 21">
                    <path
                      d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                    ></path>
                  </svg>
                  <span>Emite Licença ambiental:</span></label
                >
              </div>
            </div>

            <div class="item">
              <label>Tipo de distrito industrial: </label>
              <select disabled="true">
                <option>
                  {{ mapeamentoOportunidade.tipo_distrito_industrial }}
                </option>
              </select>
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>Potencial turistico:<span>*</span> </label>
              <textarea
                v-model="mapeamentoOportunidade.potencial_turistico"
                disabled="true"
              ></textarea>
            </div>

            <div class="item">
              <label>Informações adicionais:<span>*</span> </label>
              <textarea
                v-model="mapeamentoOportunidade.informacoes_adicionais"
                disabled="true"
              ></textarea>
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Status: <span>*</span> </label>
              <select v-model="mapeamentoOportunidade.status">
                <option
                  v-for="stat in listaStatus"
                  :key="stat.value"
                  :value="stat.value"
                  :selected="stat.value === mapeamentoOportunidade.status"
                >
                  {{ stat.value }}
                </option>
              </select>
            </div>

            <p class="item">
              <label>Município:<span>*</span> </label>
              <input
                type="text"
                v-model="mapeamentoOportunidade.municipio.nome"
                disabled="true"
              />
            </p>
            <div class="item">
              <label>Nome de quem respondeu:<span>*</span></label>
              <input
                type="text"
                v-model="mapeamentoOportunidade.nome_pessoa_resposta"
                disabled="true"
              />
            </div>
          </div>
          <div class="row3">
            <div class="item">
              <label>Email de quem respondeu: </label>
              <input
                type="text"
                v-model="mapeamentoOportunidade.email_pessoa_resposta"
                disabled="true"
              />
            </div>
            <div class="item">
              <label>Data da resposta: </label>
              <input
                type="text"
                :value="mapeamentoOportunidade.created_at | toDate"
                disabled="true"
              />
            </div>

            <div class="item">
              <label>Data da atualização:</label>
              <input
                type="text"
                :value="mapeamentoOportunidade.updated_at | toDate"
                disabled="true"
              />
            </div>
          </div>
        </div>

        <div class="container">
          <h4>Áreas disponíveis</h4>
          <template
            v-for="(area, index) in mapeamentoOportunidade.areas_disponiveis"
          >
            <div class="caixa-form" :key="`${area.id}`">
              <div class="row2-2">
                <div class="item">
                  <label>Area m²: </label>
                  <input type="text" :value="area.area_m2" disabled="true" />
                </div>

                <div class="item">
                  <label>Descrição:</label>
                  <input type="text" :value="area.descricao" disabled="true" />
                </div>
              </div>
              <hr
                v-if="
                  index + 1 < mapeamentoOportunidade.areas_disponiveis.length
                "
              />
            </div>
          </template>
          <p v-if="mapeamentoOportunidade.areas_disponiveis.length == 0">
            Nenhuma área cadastrada
          </p>
        </div>

        <div class="container">
          <h4>Incentivos Municipais</h4>
          <template
            v-for="(incentivo,
            index) in mapeamentoOportunidade.incentivos_municipios"
          >
            <div class="caixa-form" :key="`${incentivo.id}`">
              <div class="row3-2">
                <div class="item">
                  <label>Nome do incentivo:</label>
                  <input
                    type="text"
                    :value="incentivo.formulario_incentivo_municipal.nome"
                    disabled="true"
                    v-if="incentivo.formulario_incentivo_municipal"
                  />
                  <input
                    type="text"
                    :value="incentivo.outros"
                    disabled="true"
                    v-else
                  />
                </div>

                <div class="item">
                  <label>Legislação:</label>
                  <input
                    type="text"
                    :value="incentivo.legislacao"
                    disabled="true"
                  />
                </div>
              </div>
              <hr
                v-if="
                  index + 1 <
                    mapeamentoOportunidade.incentivos_municipios.length
                "
              />
            </div>
          </template>
          <p v-if="mapeamentoOportunidade.incentivos_municipios.length == 0">
            Nenhum incentivo cadastrado
          </p>
        </div>

        <div class="container">
          <div class="div-flex-header">
            <h4>Instituições de capacitação</h4>
          </div>
          <template
            v-for="(form_instituicao,
            index) in mapeamentoOportunidade.formulario_instituicoes_capacitacao_municipios"
          >
            <div class="caixa-form" :key="`${form_instituicao.id}`">
              <div class="row2">
                <div class="item">
                  <label>Nome do instituição:</label>
                  <input
                    type="text"
                    :value="form_instituicao.instituicao_capacitacao.nome"
                    disabled="true"
                    v-if="form_instituicao.instituicao_capacitacao"
                  />
                  <input
                    type="text"
                    :value="form_instituicao.outros"
                    disabled="true"
                    v-else
                  />
                </div>
              </div>

              <hr
                v-if="
                  index + 1 <
                    mapeamentoOportunidade
                      .formulario_instituicoes_capacitacao_municipios.length
                "
              />
            </div>
          </template>
          <p
            v-if="
              mapeamentoOportunidade
                .formulario_instituicoes_capacitacao_municipios.length == 0
            "
          >
            Nenhuma instituição cadastrada
          </p>
        </div>

        <div class="container">
          <div class="div-flex-header">
            <h4>Potenciais econômicos</h4>
          </div>
          <template
            v-for="potenciais_economicos in mapeamentoOportunidade.formulario_municipios_potenciais_economicos"
          >
            <div class="caixa-form" :key="`${potenciais_economicos.id}`">
              <div class="row2">
                <div class="item">
                  <label>Categoria industrial:</label>
                  <input
                    type="text"
                    :value="potenciais_economicos.categoria_industrial.nome"
                    disabled="true"
                    v-if="potenciais_economicos.categoria_industrial"
                  />
                  <input
                    type="text"
                    :value="potenciais_economicos.outros"
                    disabled="true"
                    v-else
                  />
                </div>
              </div>
              <hr
                v-if="
                  index + 1 <
                    mapeamentoOportunidade
                      .formulario_municipios_potenciais_economicos.length
                "
              />
            </div>
          </template>
          <p
            v-if="
              mapeamentoOportunidade
                .formulario_instituicoes_capacitacao_municipios.length == 0
            "
          >
            Nenhum potencial cadastrado
          </p>
        </div>

        <div class="botao-salvar">
          <AppLoading size="small" v-if="salvando" texto="" />
          <BotaoSalvar v-if="!salvando" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import MapeamentoOportunidadesApi from "../../../../services/api/MapeamentoOportunidadesApi";
import {
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";

import BotaoVisualizarForm from "../../../../components/botoes/BotaoVisualizarForm.vue";
import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import AppErrosFormularios from "../../../../components/AppErrosFormularios.vue";

export default {
  name: "MapeamentoOportunidadesForm",
  metaInfo() {
    return {
      title: `${
        this.$route.name === ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT
          ? "Editar"
          : "Criar"
      } Mapeamento Oportunidades - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  components: {
    AppLoading,
    BotaoSalvar,
    AppErro,
    AppErrosFormularios,
    BotaoVisualizarForm,
  },
  data() {
    return {
      carregandoDados: true,
      erroCarregandoDados: false,
      erroCarregar: false,
      page: this.$route.query.page,
      perPage: this.$route.query.per_page,
      mapeamentoOportunidade: [],
      listaStatus: [],
      salvando: false,
      errosSalvando: [],
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_SHOW,
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
    };
  },
  created() {
    this.mapeamentoOportunidadesApi = new MapeamentoOportunidadesApi();
    this.carregarDados();
  },
  methods: {
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.errosSalvando = [];

        await this.mapeamentoOportunidadesApi.salvarMapeamentoOportunidade(
          this.mapeamentoOportunidade
        );
        this.salvando = false;

        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
          query: this.$route.query,
        });

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }

        this.salvando = false;
      }
    },
    async carregarDados() {
      try {
        this.carregandoDados = true;
        this.erroCarregar = false;

        if (
          this.mapeamentoOportunidadesApi.cancelTokens.getMapeamentoOportunidade
        ) {
          this.mapeamentoOportunidadesApi.cancelTokens.getMapeamentoOportunidade.cancel();
        }

        if (this.$route.params.id) {
          this.mapeamentoOportunidade = await this.mapeamentoOportunidadesApi.getMapeamentoOportunidade(
            this.$route.params.id
          );
        }
        this.carregandoDados = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregandoDados = false;
        this.erroCarregar = true;
      }
      await this.carregarDadosSelects();
    },
    async carregarDadosSelects() {
      try {
        this.erroCarregandoValores = false;
        this.carregandoValores = true;

        const [listaStatus] = await Promise.all([
          this.mapeamentoOportunidadesApi.getListaStatus(),
        ]);
        this.listaStatus = listaStatus;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);
        this.erroCarregandoValores = true;
        this.carregandoValores = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 3rem 0 0;
  display: table;
  font-size: 1.4rem;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 130%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    gap: 1.5rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
  }
  .caixa-form {
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
      select {
        height: 2rem;
        font-size: 1rem;
        padding-left: 0.5rem;
      }
      textarea {
        padding-left: 0.5rem;
        height: 5rem;
      }
      .checkbox {
        margin: 0.5rem 0;
        input {
          width: auto;
        }
        .check {
          --background: #fff;
          --border: #d1d6ee;
          --border-hover: #bbc1e1;
          --border-active: grey;
          --tick: #fff;
          position: relative;
          cursor: pointer;
          input,
          svg {
            width: 21px;
            height: 21px;
            display: inline-block;
          }
          input {
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            outline: none;
            background: var(--background);
            border: none;
            margin: 0;
            transform: translateY(5px);
            padding: 0;
            cursor: pointer;
            border-radius: 4px;
            transition: box-shadow 0.3s;
            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
            &:hover {
              --s: 2px;
              --b: var(--border-hover);
            }
            &:checked {
              --b: var(--border-active);
            }
          }
          svg {
            pointer-events: none;
            fill: none;
            stroke-width: 2px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: var(--stroke, var(--border-active));
            position: absolute;
            top: 0;
            left: 0;
            width: 21px;
            height: 21px;
            transform: scale(var(--scale, 1)) translateZ(1);
            margin-top: 0rem;
          }
          span {
            transform: translateY(-5px);
            display: inline;
            color: var(--preto-gaia);
            margin-left: 0.5rem;
          }
          &.path {
            input {
              &:checked {
                --s: 2px;
                transition-delay: 0.4s;
                & + svg {
                  --a: 16.1 86.12;
                  --o: 102.22;
                }
              }
            }
            svg {
              stroke-dasharray: var(--a, 86.12);
              stroke-dashoffset: var(--o, 86.12);
              transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
            }
          }
        }
      }
    }
  }
  .botao-salvar {
    text-align: center;
    margin: 2rem 0 3rem;
  }
}
@media screen and (min-width: 50em) {
  .row2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .row2-2 {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 1rem;
  }
  .row3-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    gap: 1rem;
  }
}
</style>
