import { parseISO } from "date-fns";

import ModelPersistido from "./ModelPersistido";
import MunicipioAlvo from "./MunicipioAlvo";
import SolicitacaoEmpresaAtracaoInvestimento from "./SolicitacaoEmpresaAtracaoInvestimento";

import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";

export default class AtracaoInvestimento extends ModelPersistido {
  static entity = "atracaoInvestimentos";

  static state() {
    return {
      carregandoLista: true,
      erroCarregarLista: false,
      excluindo: false,
      errosExcluir: null,
      carregandoAtracaoInvestimento: false,
      erroCarregarAtracaoInvestimento: false
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      pessoa_juridica_id: this.attr(null),
      data_inicio: this.attr(null, convertValidDateToString),
      ja_estabelecida_em_goias: this.boolean(false),
      quem_iniciou_contato: this.string(null).nullable(),
      quem_iniciou_contato_label: this.string(null).nullable(),
      empregos_previstos: this.number(null).nullable(),
      investimentos_previstos: this.number(null).nullable(),
      descricao: this.string(null).nullable(),
      tipo_projeto: this.string(null).nullable(),
      tipo_projeto_label: this.string(null).nullable(),
      area_internacional: this.boolean(false),
      observacao: this.string(null).nullable(),
      nome_quem_iniciou_contato: this.string(null).nullable(),
      empresa_precisa_de_fomento: this.boolean(false),
      ultimo_contato_realizado_id: this.attr(null),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      criado_por: this.string(null).nullable(),
      atualizado_por: this.string(null).nullable(),
      municipios_alvos: this.hasMany(MunicipioAlvo, "atracao_investimento_id"),
      solicitacoes_empresa_atracao_investimento: this.hasMany(
        SolicitacaoEmpresaAtracaoInvestimento,
        "atracao_investimento_id"
      ),
      _destroy: this.boolean(false),
      _completo: this.boolean(false), //indica se o registro foi carregado por completo
    };
  }

  get dataInicioToDate() {
    if (!this.data_inicio) return null;

    return parseISO(this.data_inicio);
  }
}
