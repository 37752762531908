export default function cpfCnpj(value) {
    if(!value || value.length == 0) return null;

    let v = value;
    if(typeof value == 'number') {
        v = value.toString();
    }

    if(v.length == 11) {
        return v.replace(
            /([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/,
            "$1.$2.$3-$4"
        );
    } else if(v.length == 14) {
        return v.replace(
            /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/,
            "$1.$2.$3/$4-$5"
        );
    }

    return value;
}
