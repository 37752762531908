import { render, staticRenderFns } from "./MunicipioAreasDisponiveis.vue?vue&type=template&id=3617398a&scoped=true&"
import script from "./MunicipioAreasDisponiveis.vue?vue&type=script&lang=js&"
export * from "./MunicipioAreasDisponiveis.vue?vue&type=script&lang=js&"
import style0 from "./MunicipioAreasDisponiveis.vue?vue&type=style&index=0&id=3617398a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3617398a",
  null
  
)

export default component.exports