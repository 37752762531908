var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caixa-form"},[(
      _vm.carregandoListaProtocolosIntencao &&
      !_vm.erroCarregarListaProtocolosIntencao
    )?_c('AppLoading'):_vm._e(),(
      _vm.erroCarregarListaProtocolosIntencao &&
      !_vm.carregandoListaProtocolosIntencao
    )?_c('AppErro'):_vm._e(),(
      !_vm.carregandoListaProtocolosIntencao &&
      !_vm.erroCarregarListaProtocolosIntencao
    )?[(_vm.errosExcluirProtocolosIntencao && !_vm.excluindoProtocolosIntencao)?_c('div',[_c('ul',_vm._l((_vm.errosExcluirProtocolosIntencao),function(erro,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(erro)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"disp-flex"},[_c('h4',[_vm._v("Protocolo de Intenção")]),_c('BotaoArredondado',{attrs:{"type":"button","title":"Adicionar Protocolo de Intenção "},on:{"click":_vm.adicionar}},[_c('span',[_vm._v("+")]),_vm._v(" Adicionar ")])],1),_vm._l((_vm.protocolosIntencao),function(protocoloIntencao,index){return _c('PessoasJuridicasProtocolosItem',{key:protocoloIntencao.id,attrs:{"index":index,"protocolo-intencao":protocoloIntencao}})}),(_vm.protocolosIntencao.length === 0)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Nenhum registro encontrado")])]):_vm._e(),(_vm.excluindoProtocolosIntencao)?_c('AppOverlayLoading',[_c('span',[_vm._v("Excluindo... Aguarde por favor.")])]):_vm._e(),_c('div',{staticClass:"botao-salvar"},[(_vm.protocolosIntencao.length > 0)?_c('BotaoSalvar',{attrs:{"texto":"Confirmar exclusões","disabled":!_vm.possuiAtracaoInvestimentoParaExcluir},on:{"click":_vm.excluirBatch}}):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }