import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";
import CodigoMunicipioMapeamentoOportunidadeIndex from "../../../views/atracao_investimentos/cadastros/codigo_municipio_oportunidade_mapeamento/CodigoMunicipioMapeamentoOportunidadeIndex";
import CodigoMunicipioMapeamentoOportunidadeForm from "../../../views/atracao_investimentos/cadastros/codigo_municipio_oportunidade_mapeamento/CodigoMunicipioMapeamentoOportunidadeForm";

const rotasCodigoMunicipioMapeamentoOportunidade = [
  {
    name: routeNames.CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_INDEX,
    component: CodigoMunicipioMapeamentoOportunidadeIndex,
    path: "/atracao-investimentos/cadastros/codigo-municipio-mapeamento-oportunidade",
    meta: {
      requerPermissao: true,
      permissoes: [permissoes.CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE],
      raiz: true,
    },
    children: [
      {
        name: routeNames.CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE_EDIT,
        component: CodigoMunicipioMapeamentoOportunidadeForm,
        path: "/atracao-investimentos/cadastros/codigo-municipio-mapeamento-oportunidade/:id/edit",
        meta: {
          requerPermissao: true,
          permissoes: [permissoes.CODIGO_MUNICIPIO_MAPEAMENTO_OPORTUNIDADE],
        }
      }
    ]
  }
];

export default rotasCodigoMunicipioMapeamentoOportunidade;
