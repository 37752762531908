<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <div class="left">
        <h1 v-if="municipio">{{ municipio.nome }}</h1>
      </div>
      <BotaoEditarShow
        v-if="municipio"
        :to="{
          name: ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
          params: { id: municipio.id },
        }"
      />
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erro" />
    <AppErro v-if="!carregando && erro" />
    <template v-if="!carregando && !erro">
      <div class="caixa-show">
        <div class="row4">
          <p><b>Estado:</b> {{ municipio.estado.nome }}</p>
          <p>
            <b>Região de Planejamento:</b>
            {{
              municipio.regiao_planejamento
                ? municipio.regiao_planejamento.nome
                : "Não informada"
            }}
          </p>
          <p>
            <b>Microrregião:</b>
            {{
              municipio.microrregiao
                ? municipio.microrregiao.nome
                : "Não informada"
            }}
          </p>
          <p>
            <b>Mesorregião:</b>
            {{
              municipio.microrregiao && municipio.microrregiao.mesorregiao
                ? municipio.microrregiao.mesorregiao.nome
                : "Não informada"
            }}
          </p>
        </div>
      </div>
      <div>
        <MunicipioBeneficios :beneficios="municipio.beneficios" />
        <MunicipioAreasDisponiveis
          :areas-disponiveis="municipio.areas_disponiveis"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";
import MunicipiosApi from "../../../services/api/MunicipiosApi";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import MunicipioBeneficios from "../../../components/atracao_investimentos/municipios/MunicipioBeneficios";
import MunicipioAreasDisponiveis from "../../../components/atracao_investimentos/municipios/MunicipioAreasDisponiveis";
import BotaoEditarShow from "../../../components/botoes/BotaoEditarShow.vue";

export default {
  name: "MunicipioShow",
  metaInfo() {
    return {
      title: `Exibir Município - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      municipio: null,
      carregando: true,
      erro: false,
      ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
      ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
    };
  },
  components: {
    AppLoading,
    AppErro,
    MunicipioBeneficios,
    MunicipioAreasDisponiveis,
    BotaoEditarShow,
  },
  created() {
    this.municipiosApi = new MunicipiosApi();

    this.carregarDados();
  },
  watch: {
    $route: function (to, from) {
      if (
        to.params.id !== from.params.id &&
        to.name === ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW
      ) {
        this.carregarDados();
      }
    },
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erro = false;

        this.municipio = await this.municipiosApi.getMunicipio(
          this.$route.params.id
        );

        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregando = false;
        this.erro = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0rem 1rem;
    .left {
      h1 {
        color: var(--laranja-gaia);
        margin: 0;
      }
    }
  }
  .caixa-show {
    border-radius: 2px;
    p {
      margin: 1rem 0;
    }
  }
}
@media screen and(min-width: '50em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
