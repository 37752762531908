<script>
import { Line } from "vue-chartjs";
import { format } from "date-fns";

export default {
  extends: Line,
  props: {
    investimentoFixoEstimado: {
      type: Number,
    },
    historicoProtocolo: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const options = {
      title: {
        display: true,
        text: "Evolução do investimento realizado",
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem) => {
            return `Total investido: ${this.$options.filters.currency(
              tooltipItem.value
            )}`;
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value) => {
                return this.$options.filters.currency(value);
              },
            },
          },
        ],
      },
    };
    this.renderChart(this.chartData, { ...options, ...this.options });
  },
  computed: {
    chartData() {
      return {
        labels: this.historicoProtocolo.map((historico) =>
          format(historico.dataToDate, "dd/MM/yyyy")
        ),
        datasets: [
          {
            label: `Total investido ${
              this.in
                ? "(Meta de " +
                  this.$options.filters.currency(
                    this.investimentoFixoEstimado
                  ) +
                  ")"
                : ""
            }`,
            data: this.historicoProtocolo.map(
              (historico) => historico.investimento_fixo_atual
            ),
            color: "#FBB03B",
            fill: false,
            backgroundColor: "#FBB03B",
            borderColor: "#FBB03B",
          },
        ],
      };
    },
  },
};
</script>
