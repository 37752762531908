<template>
  <div class="centro conteudo-show">
    <div class="header-show">
      <h1>Pessoa Jurídica JUCEG</h1>
      <div class="disp-flex" v-if="!carregando && !erroCarregando">
        <button class="bt-atualizar" type="button" @click="atualizar">
          Atualizar
        </button>
        <button class="bt-pdf" type="button" @click="gerarPDF">
          <img src="../../../assets/images/icon-pdf.png" alt="" />
          Gerar PDF
        </button>
      </div>
    </div>
    <div>
      <AppLoading class="carregando1" v-if="carregando && !erroCarregando" />
      <AppOverlayLoading
        class="carregando1"
        v-if="atualizando && !erroCarregando"
      >
        <span>Aguarde... Isso pode demorar um pouco</span>
      </AppOverlayLoading>
      <AppErro v-if="erroCarregando && !carregando" />
      <AppTabs
        v-if="!carregando && !erroCarregando"
        :tabs="tabs"
        v-model="currentTab"
      >
        <template v-slot:[tabs[0].id]>
          <PessoasJuridicasJucegShowDadosGerais
            :pessoa-juridica-juceg="pessoaJuridicaJuceg"
          />
        </template>
        <template v-slot:[tabs[1].id]>
          <PessoasJuridicasJucegShowListaAtividadesEconomicas
            :pessoa-juridica-juceg="pessoaJuridicaJuceg"
          />
        </template>
        <template v-slot:[tabs[2].id]>
          <PessoasJuridicasJucegShowListaQuadrosSocietarios
            :pessoa-juridica-juceg="pessoaJuridicaJuceg"
          />
        </template>
      </AppTabs>
    </div>
  </div>
</template>

<script>
import PessoasJuridicasJucegApi from "../../../services/api/PessoasJuridicasJucegApi";
import AppLoading from "../../../components/AppLoading";
import AppOverlayLoading from "../../../components/AppOverlayLoading.vue";
import AppErro from "../../../components/AppErro.vue";

import AppTabs from "../../../components/AppTabs";
import PessoasJuridicasJucegShowDadosGerais from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegShowDadosGerais.vue";
import PessoasJuridicasJucegShowListaAtividadesEconomicas from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegShowListaAtividadesEconomicas.vue";
import PessoasJuridicasJucegShowListaQuadrosSocietarios from "../../../components/cadastros_juceg/pessoas_juridicas_juceg/PessoasJuridicasJucegShowListaQuadrosSocietarios.vue";

export default {
  name: "PessoaJuridicaJucegShow",
  metaInfo: {
    title: "Exibir Pessoa Jurídica JUCEG",
    titleTemplate: "%s - Gaia",
  },
  components: {
    AppLoading,
    AppTabs,
    AppOverlayLoading,
    AppErro,
    PessoasJuridicasJucegShowDadosGerais,
    PessoasJuridicasJucegShowListaAtividadesEconomicas,
    PessoasJuridicasJucegShowListaQuadrosSocietarios,
  },
  data() {
    const tabs = [
      {
        id: 1,
        label: "Dados Gerais",
        permissoes: [],
      },
      {
        id: 2,
        label: "Atividades Econômicas",
        permissoes: [],
      },
      {
        id: 3,
        label: "Quadros Societários",
        permissoes: [],
      },
    ];
    return {
      pessoaJuridicaJuceg: null,
      carregando: true,
      atualizando: false,
      erroCarregando: false,
      tabs,
      currentTab: tabs[0],
    };
  },
  created() {
    this.pessoasJuridicasJucegApi = new PessoasJuridicasJucegApi();

    this.carregarPessoaJuridica();
  },

  methods: {
    async atualizar() {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.atualizando = true;
        this.erroCarregando = false;

        await this.pessoasJuridicasJucegApi.atualizaPessoaJuridicaPorCnpj(
          this.pessoaJuridicaJuceg.cnpj
        );

        this.atualizando = false;

        this.carregarPessoaJuridica();

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Pessoa atualizada com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);
        this.atualizando = false;
        this.erroCarregando = false;

        if (err.response) {
          this.$swal({
            position: "center",
            icon: "error",
            title: err.response.data,
            showConfirmButton: true,
          });
        } else {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Não foi possível atualizar. Por favor, tente novamente.",
            showConfirmButton: true,
          });
        }
      }
    },

    async gerarPDF() {
      try {
        this.carregando = true;

        await this.pessoasJuridicasJucegApi.getPessoaJuridicaPdf(
          this.$route.params.id
        );

        this.carregando = false;
        this.carregandoFiltro = false;
      } catch (err) {
        if (err.response) {
          this.$swal({
            position: "center",
            icon: "error",
            title: err.response.data.mensagem,
            showConfirmButton: true,
          });
        } else {
          this.$swal({
            position: "center",
            icon: "error",
            title:
              "Não foi possível gerar o arquivo. Por favor, tente novamente.",
            showConfirmButton: true,
          });
        }
        console.log(err);
        this.carregando = false;
      }
    },
    async carregarPessoaJuridica() {
      try {
        this.carregando = true;
        this.erroCarregando = false;

        const data = await this.pessoasJuridicasJucegApi.getPessoaJuridicaJuceg(
          this.$route.params.id
        );

        this.pessoaJuridicaJuceg = data;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregando = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteudo-show {
  .header-show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0rem 1rem;
    gap: 1rem;
    h1 {
      color: var(--laranja-gaia);
      margin: 0;
    }
    .disp-flex {
      display: flex;
      gap: 1rem;
    }
    .bt-pdf {
      font-size: 1rem;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: var(--vermelho-gaia) solid 0.15rem;
      color: var(--vermelho-gaia);
      padding: 0.2rem 1rem;
      border-radius: 1.5rem;
      cursor: pointer;
      transition: 0.2s;
    }
    .bt-pdf:hover {
      background-color: rgb(196, 0, 0);
      color: white;
    }
    .bt-atualizar {
      font-size: 1rem;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: var(--verde-escuro-gaia) solid 0.15rem;
      color: var(--verde-escuro-gaia);
      padding: 0.2rem 1rem;
      border-radius: 1.5rem;
      cursor: pointer;
      transition: 0.2s;
    }
    .bt-atualizar:hover {
      background-color: var(--verde-escuro-gaia);
      color: white;
    }
  }
}
</style>
