var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.contatoRealizado._destroy)?_c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - "+_vm._s(_vm._f("toDate")(_vm.contatoRealizado.data_contato))+" ")]),_c('div',[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Assunto:")]),_vm._v(" "+_vm._s(_vm.contatoRealizado.assunto)+" ")]),_c('div',{staticClass:"row2"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Pessoa que entrou em contato:")]),_vm._v(" "+_vm._s(_vm.contatoRealizado.pessoa_entrou_em_contato ? _vm.contatoRealizado.pessoa_entrou_em_contato.nome : "Não informado")+" ")]),(
            _vm.contatoRealizado.etapa_label && _vm.pessoaJuridica.tipo == 'empresa'
          )?_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Etapa: ")]),_vm._v(_vm._s(_vm.contatoRealizado.etapa_label)+" ")]):_vm._e()])]),_c('div',{staticClass:"botoes"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.excluir}},[_c('DeleteIcon'),_vm._v(" Excluir")],1),_c('router-link',{attrs:{"to":{
          name: _vm.PESSOA_JURIDICA_EDIT_CONTATO_REALIZADO,
          query: {
            contato_realizado_id: _vm.contatoRealizado.id,
            action: 'edit',
          },
          hash: '#contatos',
        }}},[_c('PencilIcon'),_vm._v(" Editar")],1)],1)]):_c('div',{staticClass:"caixa-dados-2"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" - "+_vm._s(_vm._f("toDate")(_vm.contatoRealizado.data_contato))+" ")]),_c('div',{staticClass:"desfazer-exclusao"},[_c('p',[_vm._v("Contato realizado será excluido.")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.desfazerExclusao}},[_vm._v("Desfazer exclusão")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }