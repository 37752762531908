import ModelPersistido from "./ModelPersistido";

export default class Arquivo extends ModelPersistido {
    static entity = "arquivos";

    static fields() {
        return {
            id: this.attr(null),
            nome: this.string(null).nullable(),
            arquivo_url: this.string(null).nullable(),
            arquivavel_id: this.attr(null),
            arquivavel_type: this.string(null).nullable(),
            created_at: this.string(null).nullable(),
            updated_at: this.string(null).nullable(),
            criado_por: this.string(null).nullable(),
            atualizado_por: this.string(null).nullable(),
            _destroy: this.boolean(false)
        }
    }
}