<template>
  <div class="tela-form">
    <div class="caixa-form">
      <router-link
        title="Fechar"
        :to="{
          name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
          query: { ...this.$route.query },
        }"
        >&#10005;</router-link
      >
      <h1>
        {{
          $route.name === ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW
            ? "Novo Meio de Contato"
            : "Editar Meio de Contato"
        }}
      </h1>

      <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
      <AppErro v-if="!carregando && erroCarregar" />
      <div v-if="!carregando && !erroCarregar">
        <form @submit="salvarDados">
          <AppErrosFormularios
            v-if="erroSalvar && erroSalvar.length > 0"
            :erros="erroSalvar"
          />
          <div>
            <div class="item">
              <label>Nome: <span>*</span></label>
              <input type="text" v-model="meioContato.nome" />
            </div>
            <div class="botao-salvar">
              <AppLoading v-if="salvando" />
              <BotaoSalvar v-if="!salvando" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MeiosContatoApi from "../../../../services/api/MeiosContatoApi";
import {
  ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
  ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
  NAO_ENCONTRADO,
} from "../../../../router/routeNames";

import BotaoSalvar from "../../../../components/botoes/BotaoSalvar.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import AppErrosFormularios from "../../../../components/AppErrosFormularios.vue";

export default {
  name: "MeiosContatoForm",
  components: {
    BotaoSalvar,
    AppLoading,
    AppErro,
    AppErrosFormularios,
  },
  metaInfo() {
    return {
      title: `${
        this.$route.name === ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW
          ? "Criar"
          : "Editar"
      } Meio de Contato - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      carregando: true,
      erroCarregar: false,
      page: this.$route.query.page,
      perPage: this.$route.query.per_page,
      meioContato: [],
      salvando: false,
      erroSalvar: [],
      ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
      ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
    };
  },
  created() {
    this.meiosContatoApi = new MeiosContatoApi();
    this.carregandoDados();
  },
  methods: {
    async salvarDados(event) {
      event.preventDefault();
      try {
        this.salvando = true;
        this.erroSalvar = [];

        await this.meiosContatoApi.salvarMeioContato(this.meioContato);
        this.salvando = false;

        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
          query: this.$route.query,
        });

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro salvo com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.erroSalvar = response.data;
          } else {
            this.erroSalvar = [response.data];
          }
        } else {
          this.erroSalvar = ["Houve um erro. Por favor, tente novamente"];
        }

        this.salvando = false;
      }
    },
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        if (this.meiosContatoApi.cancelTokens.getMeioContato) {
          this.meiosContatoApi.cancelTokens.getMeioContato.cancel();
        }

        if (this.$route.params.id) {
          this.meioContato = await this.meiosContatoApi.getMeioContato(
            this.$route.params.id
          );
        }
        this.carregando = false;
      } catch (err) {
        console.log(err);
        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }

        this.carregando = false;
        this.erroCarregar = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tela-form {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  .caixa-form {
    background-color: white;
    padding: 1rem;
    min-width: 50%;
    margin: 0.5rem;
    position: relative;
    a {
      text-decoration: none;
      color: silver;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      transition: 0.2s;
    }
    a:hover {
      color: var(--vermelho-gaia);
    }
    h1 {
      color: var(--laranja-gaia);
      margin-top: 2.5rem;
    }
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
      }
    }
    .botao-salvar {
      text-align: center;
      margin: 2rem 0;
    }
  }
}
</style>
