<template>
  <div class="caixa-show">
    <div
      class="caixa-dados"
      v-for="pessoaJuceg in pessoaJuridicaJuceg.pessoas_juceg"
      :key="pessoaJuceg.id"
    >
      <div>
        <h4>{{ pessoaJuceg.nome | naoInformado }}</h4>
        <div class="row3">
          <p>
            <strong>CPF: </strong>
            <span>{{ pessoaJuceg.cpf | naoInformado }}</span>
          </p>
          <p>
            <strong>Estado Civil: </strong>
            <span>{{
              pessoaJuceg.estado_civil | naoInformado
            }}</span>
          </p>
          <p>
            <strong>Regime de Bens: </strong>
            <span>{{
              pessoaJuceg.regime_bens | naoInformado
            }}</span>
          </p>
        </div>
      </div>
      
      <div class="caixa-branco" v-for="quadroSocietario in pessoaJuceg.quadros_societarios_juceg" :key="quadroSocietario.id">
        <div class="row3">
          <p>
            <strong>Condição: </strong>
            <span>{{ quadroSocietario.condicao | naoInformado }}</span>
          </p>
          <p>
            <strong>Código da Condição: </strong>
            <span>{{ quadroSocietario.codigo_condicao | naoInformado }}</span>
          </p>
          <p>
            <strong>Entrada da Sociedade: </strong>
            <span>{{
              quadroSocietario.entrada_sociedade | data | naoInformado
            }}</span>
          </p>
          <p>
            <strong>Saída da Sociedade: </strong>
            <span>{{
              quadroSocietario.saida_sociedade | data | naoInformado
            }}</span>
          </p>
          <p>
            <strong>Participação: </strong>
            <span>{{ quadroSocietario.participacao | naoInformado }}</span>
          </p>
          <p>
            <strong>Representante Legal: </strong>
            <span>{{
              quadroSocietario.representante_legal | naoInformado
            }}</span>
          </p>
        </div>
      </div>
     
    </div>
    <div v-if="pessoaJuridicaJuceg.pessoas_juceg.length < 1">
      <p class="nenhum-resultado">Nenhum dado encontrado</p>
    </div>
  </div>
</template>

<script>
import toDate from "../../../filters/toDate";
export default {
  name: "PessoasJuridicasJucegShowListaQuadrosSocietarios",
  components: {},
  props: {
    pessoaJuridicaJuceg: {
      type: Object,
      required: true,
    },
  },
  filters: {
    data: toDate,
  },
  methods: {
    pessoaJuceg(quadroSocietario) {
      return quadroSocietario.pessoa_juceg || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-show {
  h4 {
    margin: 0rem 0 1rem;
    font-size: 1.2rem;
    text-transform: capitalize;
    color: var(--verde-escuro-gaia);
  }
  .grupos {
    margin-bottom: 1.5rem;
  }
  .caixa-dados {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem;
    margin: 0rem 0 1.5rem 0;
    .caixa-branco {
      margin: 1rem 0;
      background-color: white;
      padding: .1rem 1rem;
      .row3{
        margin: 1rem 0;
        display: grid;
        gap: 1rem;
      }
      p {
        margin: 0;
      }
    }
    .item {
      display: grid;
      margin: 0.5rem 0 1.5rem;
    }
  }
}
.nenhum-resultado {
  text-align: center;
  padding-bottom: 1.5rem;
  font-style: italic;
}
.dados-adicionais {
  padding-bottom: 0.01rem !important;
}
@media screen and(min-width: '50em') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
    p {
      display: grid;
    }
  }
}

</style>
