<template>
  <div class="caixa-show">
    <h4>Dados Básicos</h4>
    <div class="caixa-dados">
      <div class="row3-2">
        <div class="item"><b>Nome: </b> {{ pessoaFisica.nome }}</div>

        <div class="item"><b>CPF: </b> {{ pessoaFisica.cpf | cpfCnpj }}</div>

        <div class="item">
          <b>Servidor da SIC: </b>
          {{ pessoaFisica.servidor_sic ? "Sim" : "Não" }}
        </div>
      </div>
    </div>

    <AppAutores
      :criado-por="pessoaFisica.criado_por"
      :created-at="pessoaFisica.created_at"
      :atualizado-por="pessoaFisica.atualizado_por"
      :updated-at="pessoaFisica.updated_at"
    />
  </div>
</template>

<script>
import AppAutores from "../../AppAutores";

export default {
  name: "PessoasFisicasShowTabGeral",
  props: {
    pessoaFisica: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppAutores,
  },
};
</script>

<style lang="scss" scoped>
.caixa-show {
  h4 {
    margin: 2rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 0rem;
  }
  .grupos {
    padding-bottom: 0.5rem;
    ol {
      list-style-type: decimal;
      padding-left: 1.5rem;
    }
  }
  .caixa-dados {
    .item {
      display: grid;
      margin: 0.5rem 0 1.5rem;
      gap: 0.5rem;
    }
  }
}
@media screen and(min-width: '50em') {
  .row3-2 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }
}
</style>
