import VuexORM from "@vuex-orm/core";

import Arquivo from "./models/Arquivo";
import AtracaoInvestimento from "./models/AtracaoInvestimento";
import AtracaoInvestimentoProtocoloIntencao from "./models/AtracaoInvestimentoProtocoloIntencao";
import CodigoCnae from "./models/CodigoCnae";
import CodigoCnaePessoaJuridica from "./models/CodigoCnaePessoaJuridica";
import ContatoPessoaJuridica from "./models/ContatoPessoaJuridica";
import ContatoRealizado from "./models/ContatoRealizado";

import Endereco from "./models/Endereco";
import Estado from "./models/Estado";
import HistoricoNomeFantasia from "./models/HistoricoNomeFantasia";
import HistoricoRazaoSocial from "./models/HistoricoRazaoSocial";
import HistoricoProtocoloIntencao from "./models/HistoricoProtocoloIntencao";
import MeioContato from "./models/MeioContato";
import Mesorregiao from "./models/Mesorregiao";
import Microrregiao from "./models/Microrregiao";
import Municipio from "./models/Municipio";
import MunicipioAlvo from "./models/MunicipioAlvo";
import OrgaoEnvolvido from "./models/OrgaoEnvolvido";
import Pais from "./models/Pais";
import PessoaFisica from "./models/PessoaFisica";
import PessoaJuridica from "./models/PessoaJuridica";
import PessoaResponsavel from "./models/PessoaResponsavel";
import ProtocoloIntencao from "./models/ProtocoloIntencao";
import RegiaoPlanejamento from "./models/RegiaoPlanejamento";
import SolicitacaoEmpresaAtracaoInvestimento from "./models/SolicitacaoEmpresaAtracaoInvestimento";
import TipoArea from "./models/TipoArea";
import TipoSolicitacaoEmpresaAtracao from "./models/TipoSolicitacaoEmpresaAtracao";

const database = new VuexORM.Database();

database.register(Arquivo);
database.register(AtracaoInvestimento);
database.register(AtracaoInvestimentoProtocoloIntencao);
database.register(HistoricoProtocoloIntencao);
database.register(CodigoCnae);
database.register(CodigoCnaePessoaJuridica);
database.register(ContatoPessoaJuridica);
database.register(ContatoRealizado);
database.register(Endereco);
database.register(Estado);
database.register(HistoricoNomeFantasia);
database.register(HistoricoRazaoSocial);
database.register(Mesorregiao);
database.register(MeioContato);
database.register(Microrregiao);
database.register(Municipio);
database.register(MunicipioAlvo);
database.register(OrgaoEnvolvido);
database.register(Pais);
database.register(PessoaFisica);
database.register(PessoaJuridica);
database.register(PessoaResponsavel);
database.register(ProtocoloIntencao);
database.register(RegiaoPlanejamento);
database.register(SolicitacaoEmpresaAtracaoInvestimento);
database.register(TipoArea);
database.register(TipoSolicitacaoEmpresaAtracao);

export default database;
