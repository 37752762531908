<template>
  <VueSimpleAutocomplete
    :items="codigosCnae"
    :selected-item="codigoCnae"
    @change="searchCodigos"
    @value-selected="onCodigoSelected"
    :is-loading="loadingCodigoCnae"
    :get-display-value="displayCodigoCnae"
    :min-length="2"
    placeholder="Digite para pesquisar"
  ></VueSimpleAutocomplete>
</template>

<script>
import VueSimpleAutocomplete from "vue-simple-autocomplete";

import CodigosCnaeApi from "../../services/api/CodigosCnaeApi";
import displayCodigoCnae from "../../helpers/displayCodigoCnae";

export default {
  name: "CodigosCnaeAutocomplete",
  components: {
    VueSimpleAutocomplete,
  },
  model: {
    prop: "codigoCnae",
    event: "change",
  },
  props: {
    codigoCnae: Object,
  },
  data() {
    return {
      codigosCnae: [],
      loadingCodigoCnae: false,
    };
  },
  created() {
    this.codigosCnaeApi = new CodigosCnaeApi();
  },
  methods: {
    async searchCodigos(term) {
      try {
        if (this.codigosCnaeApi.cancelTokens.autocomplete) {
          this.codigosCnaeApi.cancelTokens.autocomplete.cancel(
            "Usuário fez outra busca"
          );
        }

        this.codigosCnae = [];
        this.$emit("change", {});

        if (!term || term.length === 0) {
          return;
        }
        this.loadingCodigoCnae = true;
        this.codigosCnae = await this.codigosCnaeApi.autocomplete(term);
        this.loadingCodigoCnae = false;
      } catch (err) {
        if (!this.codigosCnaeApi.isCancel(err)) {
          this.loadingCodigoCnae = false;
        }
      }
    },
    onCodigoSelected(codigo) {
      this.$emit("change", codigo);
    },
    displayCodigoCnae,
  },
};
</script>

<style lang="scss" scoped></style>
