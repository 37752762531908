<template>
  <form @submit="submit">
    <div>
      <div class="botao-adicionar">
        <h4>Traduções:</h4>
        <BotaoArredondado
          @click="adicionar"
          type="button"
          title="Adicionar Tipo de Investimento"
        >
          <span>+</span> Adicionar
        </BotaoArredondado>
      </div>
      <template v-for="(traducoes, index) in tipoInvestimento">
        <div
          class="caixa-form"
          :key="traducoes.id + '-' + index"
          v-if="!traducoes._destroy"
        >
          <div class="row2">
            <div class="item">
              <label>Nome: <span>*</span></label>
              <input
                type="text"
                :value="traducoes.nome"
                @input="updateValue('nome', $event.target.value, index)"
              />
            </div>
            <div class="item">
              <label>Selecione a Linguagem:</label>
              <select
                @input="updateValue('linguagem_id', $event.target.value, index)"
              >
                <option value="">Selecione uma linguagem</option>
                <option
                  v-for="linguagem in filtro.linguagens"
                  :key="linguagem.id"
                  :value="linguagem.id"
                  :selected="traducoes.linguagem_id == linguagem.id"
                >
                  {{ linguagem.nome }}
                  {{ linguagem.linguagem_padrao ? "(Padrão)" : "" }}
                </option>
              </select>
            </div>
          </div>
          <button @click="excluir(index)" type="button">
            <DeleteIcon title="Deletar Tipo de Investimento" />
          </button>
        </div>
      </template>
    </div>
    <div class="botao-salvar">
      <BotaoSalvar v-if="!salvando" />
      <AppLoading v-if="salvando" texto="" size="small" />
    </div>
  </form>
</template>

<script>
import AppLoading from "../../../AppLoading.vue";
import BotaoSalvar from "../../../botoes/BotaoSalvar.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import BotaoArredondado from "../../../botoes/BotaoArredondado";

const cloneDeep = require("lodash.clonedeep");
export default {
  name: "TraducoesTipoInvestimento",
  model: {
    prop: "tipoInvestimento",
    event: "input",
  },
  components: {
    AppLoading,
    BotaoSalvar,
    DeleteIcon,
    BotaoArredondado,
  },
  props: {
    tipoInvestimento: {
      type: Array,
      required: true,
    },
    filtro: {
      type: Object,
      required: true,
    },
    salvando: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(key, value, index) {
      const traducao = cloneDeep(this.tipoInvestimento);

      traducao[index][key] = value;
      this.$emit("input", traducao);
    },
    submit(event) {
      this.$emit("submit", event);
    },
    adicionar() {
      let tipoInvestimento = cloneDeep(this.tipoInvestimento);
      tipoInvestimento = tipoInvestimento.concat({
        nome: "",
        linguagem_id: null,
      });
      this.$emit("input", tipoInvestimento);
    },
    excluir(index) {
      let tipoInvestimento = cloneDeep(this.tipoInvestimento);

      if (tipoInvestimento[index].id) {
        this.$set(tipoInvestimento[index], "_destroy", true);
      } else {
        tipoInvestimento.splice(index, 1);
      }

      this.$emit("input", tipoInvestimento);
    },
  },
};
</script>

<style lang="scss" scoped>
.botao-adicionar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  h4 {
    margin: 0.5rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
}
.caixa-form {
  background-color: var(--cinza-caixa-gaia);
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 2px;
  button {
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    position: absolute;
    top: 0rem;
    right: 0.5rem;
    cursor: pointer;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
}
.item {
  display: grid;
  margin: 0.5rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
  }
  select {
    height: 2rem;
    padding-left: 0.5rem;
  }
}
.botao-salvar {
  margin: 1rem 0 3rem;
  text-align: center;
}
@media screen and (min-width: "40em") {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
