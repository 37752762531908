import { parseISO } from "date-fns";

import AtracaoInvestimento from "./AtracaoInvestimento";
import ModelPersistido from "./ModelPersistido";
import TipoSolicitacaoEmpresaAtracao from "./TipoSolicitacaoEmpresaAtracao";

import { convertValidDateToString } from "../modules/pessoa_juridica/helpers";

export default class SolicitacaoEmpresaAtracaoInvestimento extends ModelPersistido {
  static entity = "solicitacoesEmpresaAtracaoInvestimentos";

  static fields() {
    return {
      id: this.attr(null),
      tipo_solicitacao_empresa_atracao_id: this.attr(null),
      atracao_investimento_id: this.attr(null),
      observacao: this.string(null).nullable(),
      concluida: this.boolean(false),
      data_conclusao: this.attr(null, convertValidDateToString).nullable(),
      descricao_conclusao: this.string(null).nullable(),
      atracao_investimento: this.belongsTo(
        AtracaoInvestimento,
        "atracao_investimento_id"
      ),
      tipo_solicitacao_empresa_atracao: this.belongsTo(
        TipoSolicitacaoEmpresaAtracao,
        "tipo_solicitacao_empresa_atracao_id"
      ),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    };
  }

  get dataConclusaoToDate() {
    if (!this.data_conclusao) return null;

    return parseISO(this.data_conclusao);
  }
}
