<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Polos Industriais</h1>
      <BotaoLink class="bt-novo" :to="{ name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW }" >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
    <AppErro v-if="!carregando && erroCarregar" />
    <template v-if="!carregando && !erroCarregar">
      <div>
        <form
          class="form-pesquisa"
          @submit="buscar"
          v-if="!errocarregandoFiltro && !carregandoFiltro"
        >
          <h4>Pesquisar</h4>
          <div class="item">
            <label>Nome: </label>
            <input type="text" v-model="filtro.nome" />
          </div>
          <div class="botoes-pesquisa">
            <button class="bt-limpar" @click="limpar" type="button">
              <IconClear /> Limpar campos
            </button>
            <button class="bt-pesquisar" type="submit">Pesquisar</button>
          </div>
        </form>
        <div>
          <div class="campo-header-resultados">
            <AppTotalResultadosEncontrados
              :total-resultados="totalResultados"
            />
            <AppResultadosPorPagina
              :per-page="perPage"
              @per-page-changed="perPageChanged"
            />
          </div>
          <div>
            <PolosIndustriaisResultados
              :polos-industriais="polosIndustriais"
              @excluirPoloIndustrial="excluirPoloIndustrial"
            />
          </div>
        </div>
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PolosIndustriaisApi from "../../../services/api/PolosIndustriaisApi";
import {
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW,
  INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
} from "../../../router/routeNames";
import { DEFAULT_PER_PAGE } from "../../../config";

import filterNullsAndEmptyStrings from "../../../helpers/filterNullsAndEmptyStrings";

import AppErro from "../../../components/AppErro.vue";
import Paginate from "vuejs-paginate";
import BotaoLink from "../../../components/botoes/BotaoLink.vue";
import AppResultadosPorPagina from "../../../components/AppResultadosPorPagina.vue";
import PolosIndustriaisResultados from "../../../components/investe_goias/polos_industriais/PolosIndustriaisResultados.vue";
import AppLoading from "../../../components/AppLoading.vue";
import AppTotalResultadosEncontrados from "../../../components/AppTotalResultadosEncontrados.vue";

import IconClear from "../../../assets/images/icon-clean.svg";

export default {
  name: "PolosIndustriaisIndex",
  metaInfo: {
    title: "Polos Industriais - Investe Goiás",
    titleTemplate: "%s - Gaia",
  },
  components: {
    BotaoLink,
    AppResultadosPorPagina,
    PolosIndustriaisResultados,
    Paginate,
    AppLoading,
    AppErro,
    AppTotalResultadosEncontrados,
    IconClear,
  },
  data() {
    return {
      polosIndustriais: [],
      carregando: true,
      erroCarregar: false,
      totalResultados: 0,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      INVESTE_GOIAS_POLOS_INDUSTRIAIS_NEW,
      filtro: {
        nome: this.$route.query.nome || "",
      },
      carregandoFiltro: false,
      errocarregandoFiltro: false,
    };
  },
  created() {
    this.polosIndustriaisApi = new PolosIndustriaisApi();
    this.carregandoDados();
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregandoDados();
      }
    },
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        this.filtro = {
          nome: this.$route.query.nome || "",
        };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;

        const data = await this.polosIndustriaisApi.getPolosIndustriais({
          ...this.filtro,
          page: this.page,
          perPage: this.perPage,
        });

        this.totalResultados = data.total_resultados;
        this.polosIndustriais = data.resultados;
        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
        query: filterNullsAndEmptyStrings({
          page: 1,
          per_page: this.perPage,
          nome: this.filtro.nome,
        }),
      });
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    limpar() {
      this.filtro = { nome: "" };
    },
    async excluirPoloIndustrial(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }
        this.carregando = true;
        await this.polosIndustriaisApi.excluirPoloIndustrial({ id: id });

        if (
          this.polosIndustriais &&
          this.polosIndustriais.length == 1 &&
          this.page > 1
        ) {
          this.page--;
          this.$router.push({
            name: INVESTE_GOIAS_POLOS_INDUSTRIAIS_SHOW,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregandoDados();
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.carregando = false;
      } catch (err) {
        console.log(err, err.response);
        this.$swal({
          position: "center",
          icon: "error",
          title:
            "Não foi possível excluir o registro. Por favor tente novamente.",
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
  },
  computed: {
    pageCount() {
      console.log(this.perPage);
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  h1 {
    margin: 0;
    color:  var(--laranja-gaia);
  }
}
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
  }
  .form-pesquisa {
    background-color: #e6e6e6;
    padding: 1rem;
    border-radius: 2px;
    h4 {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }
  p {
    margin: 0;
  }
  .div-flex {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color:  var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
