import AtracaoInvestimento from "./AtracaoInvestimento";
import ModelPersistido from "./ModelPersistido";

import Municipio from "./Municipio";
import TipoArea from "./TipoArea";

export default class MunicipioAlvo extends ModelPersistido {
  static entity = "municipiosAlvos";

  static fields() {
    return {
      id: this.attr(null),
      prefeito: this.string(null).nullable(),
      municipio_id: this.attr(null),
      atracao_investimento_id: this.attr(null),
      precisa_de_area: this.boolean(false),
      tamanho_area: this.number(null).nullable(),
      tipo_area_id: this.number(null).nullable(),
      municipio: this.belongsTo(Municipio, "municipio_id"),
      atracao_investimento: this.belongsTo(
        AtracaoInvestimento,
        "atracao_investimento_id"
      ),
      tipo_area: this.belongsTo(TipoArea, "tipo_area_id"),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    };
  }
}
