<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Meios de Contato</h1>
      <BotaoLink
        class="bt-novo"
        :to="{
          name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
          query: { ...this.$route.query },
        }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <div>
      <div>
        <form class="form-pesquisa" @submit="buscar">
          <h4>Pesquisar</h4>
          <div>
            <div class="item">
              <label>Nome:</label>
              <input type="text" v-model="filtro.nome" />
            </div>
            <div class="botoes-pesquisa">
              <button class="bt-limpar" type="button" @click="limpar">
                <IconClear /> Limpar campos
              </button>
              <button class="bt-pesquisar" type="submit">Pesquisar</button>
            </div>
          </div>
        </form>
      </div>
      <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
      <AppErro v-if="!carregando && erroCarregar" />
      <template v-if="!carregando && !erroCarregar">
        <div class="campo-header-resultados">
          <AppTotalResultadosEncontrados :totalResultados="totalResultados" />
          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <div>
          <MeiosContatoResultados
            :meiosContato="meiosContato"
            @excluirMeioContato="excluirMeioContato"
          />
        </div>
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          />
        </div>
      </template>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { DEFAULT_PER_PAGE } from "../../../../config";
import {
  ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
  ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
} from "../../../../router/routeNames";
import MeiosContatoApi from "../../../../services/api/MeiosContatoApi";

import MeiosContatoResultados from "../../../../components/atracao_investimentos/meios_contato/MeiosContatoResultados.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import Paginate from "vuejs-paginate";
import AppResultadosPorPagina from "../../../../components/AppResultadosPorPagina";
import BotaoLink from "../../../../components/botoes/BotaoLink.vue";
import filterNullsAndEmptyStrings from "../../../../helpers/filterNullsAndEmptyStrings";
import AppTotalResultadosEncontrados from "../../../../components/AppTotalResultadosEncontrados.vue";
import IconClear from "../../../../assets/images/icon-clean.svg";

export default {
  name: "MeiosContatoIndex",
  metaInfo: {
    title: "Meios de Contato - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  components: {
    MeiosContatoResultados,
    AppLoading,
    AppErro,
    BotaoLink,
    Paginate,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    IconClear,
  },
  data() {
    return {
      meiosContato: [],
      nome: "",
      carregando: true,
      erroCarregarFiltro: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      totalResultados: 0,
      ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
      filtro: {
        nome: this.$route.query.nome || "",
      },
    };
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
  },
  watch: {
    $route: function (to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregandoDados();
      }
    },
  },
  created() {
    this.meiosContatoApi = new MeiosContatoApi();
    this.carregandoDados();
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        this.filtro = {
          nome: this.$route.query.nome || "",
        };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.meiosContatoApi.getMeiosContato({
          nome: this.filtro.nome,
          perPage: this.perPage,
          page: this.page,
        });

        this.carregando = false;
        this.meiosContato = data.resultados;
        this.totalResultados = data.total_resultados;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async excluirMeioContato(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.carregando = true;
        this.erroCarregar = false;

        await this.meiosContatoApi.removerMeioContato(id);

        if (
          this.meiosContato &&
          this.meiosContato.length == 1 &&
          this.page > 1
        ) {
          this.page--;
          this.$router.push({
            name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregandoDados();
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.carregando = false;
      } catch (err) {
        console.log(err);

        let msgErro =
          "Não foi possível excluir o registro. Por favor tente novamente.";
        if (err && err.response && err.response.data) {
          if (Array.isArray(err.response.data)) {
            msgErro = err.response.data.join(", ");
          } else {
            msgErro = err.response.data;
          }
        }

        this.$swal({
          position: "center",
          icon: "error",
          title: msgErro,
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
        query: {
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
        query: filterNullsAndEmptyStrings({
          nome: this.filtro.nome,
          page: this.page,
          per_page: this.perPage,
        }),
      });
    },
    limpar() {
      this.filtro = {
        nome: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  h1 {
    margin: 0;
    color:  var(--laranja-gaia);
  }
}
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
  }
  .form-pesquisa {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem;
    border-radius: 2px;
    h4 {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }
  p {
    margin: 0;
  }
  .div-flex {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color:  var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  gap: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
@media screen and(min-width: '50rem') {
  .div-flex {
    display: flex;
    justify-content: center !important;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: auto !important;
    }
  }
}
</style>
