var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index))]),_vm._v(" - "+_vm._s(_vm._f("toDate")(_vm.contatoRealizado.data_contato))+" ")]),_c('div',[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Assunto:")]),_vm._v(" "+_vm._s(_vm.contatoRealizado.assunto)+" ")]),_c('div',{staticClass:"row2"},[_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Pessoa que entrou em contato:")]),_vm._v(" "+_vm._s(_vm.contatoRealizado.pessoa_entrou_em_contato ? _vm.contatoRealizado.pessoa_entrou_em_contato.nome : "Não informado")+" ")]),(_vm.contatoRealizado.etapa_label)?_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Etapa: ")]),_vm._v(_vm._s(_vm.contatoRealizado.etapa_label)+" ")]):_vm._e()])]),_c('router-link',{staticClass:"bt-show-modal",attrs:{"to":{
        name: _vm.PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
        params: {
          id: this.$route.params.id,
          contatoId: this.contatoRealizado.id,
        },
        hash: '#contatos',
      }}},[_c('EyeIcon'),_vm._v(" Visualizar")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }