import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

class CodigosCnaeApi {
  cancelTokens = {
    autocomplete: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async autocomplete(term, id = null) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.autocomplete = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/codigos_cnae/autocomplete?termo=${term}&codigo_cnae_id=${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.autocomplete = null;

    return data;
  }
}

export default CodigosCnaeApi;
