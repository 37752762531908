import ModelPersistido from "./ModelPersistido";

export default class Mesorregiao extends ModelPersistido {
    static entity = "mesorregioes"

    static fields() {
        return {
            id: this.attr(null),
            nome: this.string(null).nullable(),
        }
    }
}