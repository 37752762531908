<template>
  <div class="caixa-show">
    <div class="grupos grupo1">
      <h4>Dados Gerais</h4>
      <div class="caixa-dados">
        <p>
          <b>Nome Empresarial: </b>
          <span>{{ pessoaJuridicaJuceg.nome_empresarial | naoInformado }}</span>
        </p>
        <div class="row2">
          <p>
            <b>Natureza Jurídica: </b>
            <span>{{
              pessoaJuridicaJuceg.natureza_juridica | naoInformado
            }}</span>
          </p>
          <p>
            <b>Situação: </b>
            <span>{{ pessoaJuridicaJuceg.situacao | naoInformado }}</span>
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Código da Natureza Jurídica: </b>
            <span>{{
              pessoaJuridicaJuceg.codigo_natureza_juridica | naoInformado
            }}</span>
          </p>
          <p>
            <b>NIRE: </b>
            <span>{{ pessoaJuridicaJuceg.nire | naoInformado }}</span>
          </p>
          <p>
            <b>CNPJ: </b>
            <span>{{ pessoaJuridicaJuceg.cnpj | naoInformado }}</span>
          </p>
        </div>
        <div class="row3">
          <p>
            <b>MEI: </b>
            <span>{{ pessoaJuridicaJuceg.mei | naoInformado }}</span>
          </p>
          <p>
            <b>Constituição: </b>
            <span>{{
              pessoaJuridicaJuceg.constituicao | date | naoInformado
            }}</span>
          </p>
          <p>
            <b>Início de Atividade: </b>
            <span>{{
              pessoaJuridicaJuceg.inicio_atividade | date | naoInformado
            }}</span>
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Término de Atividade: </b>
            <span>{{
              pessoaJuridicaJuceg.termino_atividade | date | naoInformado
            }}</span>
          </p>
          <p>
            <b>Código da Situação: </b>
            <span>{{
              pessoaJuridicaJuceg.codigo_situacao | naoInformado
            }}</span>
          </p>
          <p>
            <b>Porte: </b>
            <span>{{ pessoaJuridicaJuceg.porte | naoInformado }}</span>
          </p>
        </div>
      </div>
      <h4>Endereço</h4>
      <div class="caixa-dados">
        <div class="row2-2">
          <p>
            <b>Logradouro:</b>
            <span
              >{{
                enderecoJuceg.logradouro | naoInformado
              }}
            </span>
          </p>
          <p>
            <b>Número:</b>
            <span
              >{{ enderecoJuceg.numero | naoInformado }}
            </span>
          </p>
        </div>
        <div class="row3">
          <p>
            <b>Complemento:</b>
            <span
              >{{
                enderecoJuceg.complemento | naoInformado
              }}
            </span>
          </p>
          <p>
            <b>Bairro:</b>
            <span
              >{{ enderecoJuceg.bairro | naoInformado }}
            </span>
          </p>
          <p>
            <b>CEP:</b>
            <span
              >{{ enderecoJuceg.cep | naoInformado }}
            </span>
          </p>
        </div>
        <h4>Município</h4>
        <div class="grupo-dados row3">
          <p>
            <strong>Nome: </strong>
            <span
              v-if="
                municipioJuceg.nome ||
                municipioJuceg.uf
              "
              >{{ municipioJuceg.nome }}-{{
                municipioJuceg.uf
              }}
            </span>
            <span v-else> Não informado(a) </span>
          </p>
          <p>
            <b>Código IGBE:</b>
            <span
              >{{
                municipioJuceg.codigo_ibge
                  | naoInformado
              }}
            </span>
          </p>
          <p>
            <b>Código IGBE Completo:</b>
            <span
              >{{
                municipioJuceg.codigo_ibge_completo | naoInformado
              }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toDate from "../../../filters/toDate";
export default {
  name: "PessoasJuridicasJucegShowDadosGerais",
  components: {},
  props: {
    pessoaJuridicaJuceg: {
      type: Object,
      required: true,
    },
  },
  filters: {
    date: toDate,
  },
  computed: {
    municipioJuceg() {
      return this.pessoaJuridicaJuceg.endereco_juceg?.municipio_juceg || {};
    },
    enderecoJuceg() {
      return this.pessoaJuridicaJuceg.endereco_juceg || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.caixa-show {
  margin-bottom: 5rem;
  h4 {
    margin: 1.5rem 0  0;
    display: table;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .caixa-dados {
    p{
      display: grid;
    }
    background-color: white;
    .grupo-dados {
      p {
        margin: 0.5rem 0;
      }
    }
    .item {
      display: grid;
      margin: 0.5rem 0 1.5rem;
      p {
        margin-top: 0;
      }
    }
    .nenhum-resultado {
      text-align: center;
      padding-bottom: 1.5rem;
      font-style: italic;
    }
  }
}
.dados-adicionais {
  padding-bottom: 0.01rem !important;
}
@media screen and(min-width: '50em') {
  .row2-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    align-items: baseline;
    p {
      display: grid;
    }
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
    p {
      display: grid;
    }
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
    p {
      display: grid;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
    p {
      display: grid;
    }
  }
}
</style>
