<template>
  <div class="container">
    <div class="div-flex-header">
      <h4>Endereços</h4>
      <BotaoArredondado
        type="button"
        title="Adicionar Endereço"
        @click="adicionar"
      >
        <span>+</span>Adicionar
      </BotaoArredondado>
    </div>
    <template>
      <template v-for="(endereco, index) in enderecos">
        <div
          class="caixa-form"
          v-if="!endereco._destroy"
          :key="`${endereco.id}_${index}`"
        >
          <button type="button" @click="excluir(index)"><DeleteIcon /></button>
          <div class="row3-2">
            <div class="item">
              <label>Tipo de endereço: <span>*</span></label>
              <select
                @change="
                  (event) => updateValue(index, 'tipo', event.target.value)
                "
              >
                <option
                  v-for="tipo in tipos"
                  :key="tipo.value"
                  :value="tipo.value"
                  :selected="tipo.value == endereco.tipo"
                  >{{ tipo.label }}</option
                >
              </select>
            </div>
            <div class="item">
              <label>Logradouro: <span>*</span> </label>
              <input
                type="text"
                :value="endereco.logradouro"
                @input="
                  (event) =>
                    updateValue(index, 'logradouro', event.target.value)
                "
              />
            </div>
            <div class="item">
              <label>Número: </label>
              <input
                type="text"
                :value="endereco.numero"
                @input="
                  (event) => updateValue(index, 'numero', event.target.value)
                "
              />
            </div>
          </div>
          <div class="row3-3">
            <div class="item">
              <label>Complemento: </label>
              <input
                type="text"
                :value="endereco.complemento"
                @input="
                  (event) =>
                    updateValue(index, 'complemento', event.target.value)
                "
              />
            </div>
            <div class="item">
              <label>Caixa Postal: </label>
              <input
                type="text"
                :value="endereco.caixa_postal"
                @input="
                  (event) =>
                    updateValue(index, 'caixa_postal', event.target.value)
                "
              />
            </div>

            <div class="item">
              <label>Bairro: </label>
              <input
                type="text"
                :value="endereco.bairro"
                @input="
                  (event) => updateValue(index, 'bairro', event.target.value)
                "
              />
            </div>
            <div class="item">
              <label>CEP: <span>*</span></label>
              <input
                type="text"
                :value="endereco.cep"
                @input="
                  (event) => updateValue(index, 'cep', event.target.value)
                "
              />
            </div>
          </div>
          <PessoasJuridicasFormularioMunicipioSelect
            :estados="estados"
            :paises="paises"
            :endereco="enderecos[index]"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import BotaoArredondado from "../../../botoes/BotaoArredondado";
import EstadosApi from "../../../../services/api/EstadosApi";
import DeleteIcon from "vue-material-design-icons/Delete.vue";

import PessoasJuridicasFormularioMunicipioSelect from "./PessoasJuridicasFormularioMunicipioSelect";
import Endereco from "../../../../store/models/Endereco";

export default {
  name: "PessoasJuridicasFormularioEnderecos",
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
    tipos: {
      type: Array,
      required: true,
    },
    estados: {
      type: Array,
      required: true,
    },
    paises: {
      type: Array,
      required: true,
    },
  },
  components: {
    BotaoArredondado,
    PessoasJuridicasFormularioMunicipioSelect,
    DeleteIcon,
  },
  created() {
    this.estadosApi = new EstadosApi();
  },
  methods: {
    adicionar() {
      Endereco.insert({
        data: {
          pessoa_juridica_id: this.pessoaJuridicaId,
          tipo: this.tipos[0].value,
        },
      });
    },
    excluir(index) {
      const endereco = this.enderecos[index];
      if (endereco.id) {
        Endereco.update({
          ...endereco,
          _destroy: true,
        });
      } else {
        Endereco.delete(endereco.id);
      }
    },
    updateValue(index, key, value) {
      const endereco = this.enderecos[index];

      Endereco.update({
        ...endereco,
        [key]: value,
      });
    },
  },
  computed: {
    enderecos() {
      return Endereco.query()
        .where((endereco) => {
          return endereco.pessoa_juridica_id == this.pessoaJuridicaId;
        })
        .withAllRecursive()
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0 0.5rem;
}
.div-flex-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span {
    font-weight: 800;
    font-size: 1.8rem;
  }
}

.caixa-form {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
  position: relative;
  button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 1.9rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }
}
@media screen and (min-width: "50rem") {
  .row3-2 {
    display: grid;
    grid-template-columns: 1fr 3.5fr 0.1fr;
    gap: 1rem;
  }
  .row3-3 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
