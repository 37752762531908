<template>
  <div class="caixa-form">
    <div class="header-caixa">
      <h4>Municípios de Interesse</h4>
    </div>
    <div v-for="(municipio, index) in municipiosAlvos" :key="index">
      <div class="caixa-item">
        <PessoasJuridicasItemMunicipioAlvo
          :municipio-alvo="municipiosAlvos[index]"
          :index="municipiosAlvos.length - index"
        />
      </div>
    </div>
    <div v-if="municipiosAlvos.length === 0">
      <p>Nenhum municipío alvo encontrado</p>
    </div>
  </div>
</template>

<script>
import PessoasJuridicasItemMunicipioAlvo from "./PessoasJuridicasItemMunicipioAlvo";

export default {
  name: "PessoasJuridicasListaMunicipiosAlvos",
  props: {
    municipiosAlvos: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PessoasJuridicasItemMunicipioAlvo,
  },
};
</script>

<style lang="scss" scoped>
.caixa-form {
  .header-caixa {
    display: flex;
    align-items: center;
    h4 {
      margin: 0;
      font-size: 1.4rem;
      display: table;
      color: var(--verde-escuro-gaia);
    }
    h4::after {
      content: "";
      display: block;
      height: 2px;
      min-width: 100%;
      width: 30vw;
      max-width: 80vw;
      background-color: var(--verde-escuro-gaia);
      margin: 0.5rem 0 1rem;
    }
  }
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    position: relative;
  }
}
p {
  text-align: center;
  gap: 0.5rem;
}
.text-center {
  text-align: center;
}
</style>
