<template>
  <div class="header">
    <div class="barra-sup">
      <div class="nav centro">
        <div>
          <span>v{{ version }}</span>
        </div>
        <div class="direita">
          <span>{{ nome }}</span>
          <a @click="logout" class="cursor-pointer"><IconSair />Sair</a>
        </div>
      </div>
    </div>
    <div class="barra-menu">
      <div class="centro">
        <a href="/" class="link-gaia">
          <img
            src="../../assets/images/logo-gaia-color.png"
            class="logo-gaia"
            alt="Logo do Sistema Gaia"
          />
        </a>
      </div>
      <div class="lista-menu">
        <div class="centro">
          <ul>
            <li
              class="li-no-hover"
              v-for="menuItem in menu"
              :key="menuItem.label"
            >
              <template v-if="menuItem.routeName">
                <router-link :to="{ name: menuItem.routeName }">
                  {{ menuItem.label }}
                </router-link>
              </template>
              <template v-else>
                <a class="a-menu">
                  {{ menuItem.label }}
                  <SetaMenu class="seta-menu" />
                </a>
                <ol class="submenu">
                  <li
                    class="li-no-hover"
                    v-for="subItem in menuItem.routeList"
                    :key="subItem.label"
                  >
                    <template v-if="subItem.routeName">
                      <router-link :to="{ name: subItem.routeName }">
                        <component :is="subItem.icon" v-if="subItem.icon" />
                        {{ subItem.label }}</router-link
                      >
                    </template>
                    <template v-else>
                      <a
                        ><component :is="subItem.icon" v-if="subItem.icon" />{{
                          subItem.label
                        }}
                        <SetaMenu class="seta-menu" />
                      </a>
                      <ol class="sub-submenu">
                        <li v-for="item in subItem.routeList" :key="item.label">
                          <router-link :to="{ name: item.routeName }">{{
                            item.label
                          }}</router-link>
                        </li>
                      </ol>
                    </template>
                  </li>
                </ol>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSair from "../../assets/images/icon-exit.svg";
import SetaMenu from "../../assets/images/icon-seta-menu.svg";

const $ = require("jquery");

export default {
  name: "TheMenuDesk",
  components: {
    IconSair,
    SetaMenu,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    nome: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
  },
  mounted() {
    $(".submenu li").on("mouseenter mouseleave", function() {
      if ($("ol", this).length) {
        var elm = $("ol:first", this);
        var off = elm.offset();
        var l = off.left;
        var w = elm.width();

        var docW = $("body").width();

        var isEntirelyVisible = l + w <= docW;

        if (!isEntirelyVisible) {
          $("ol.sub-submenu").addClass("edge");
        } else {
          $("ol.sub-submenu").removeClass("edge");
        }
      }
    });
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .barra-sup {
    background-color: white;
    width: 100%;
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
      .direita {
        display: flex;
        align-items: center;
        span {
          font-family: "lato-regular";
        }
        a {
          display: flex;
          align-items: center;
          font-family: "Lato-bold";
          color: var(--verde-escuro-gaia);
        }
        svg {
          height: 1.3rem;
          width: 1.5rem;
          fill: var(--verde-escuro-gaia);
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .barra-menu {
    .logo-gaia {
      height: 5rem;
    }
    .link-gaia {
      display: table;
    }
    .lista-menu {
      background-color: var(--verde-gaia);
      margin-top: 0.5rem;
      svg {
        display: none;
      }
      ul,
      ol {
        list-style: none;
      }
      ul {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        li {
          position: relative;
          a {
            display: block;
            cursor: pointer;
            padding: 0.5rem 1rem;
            color: white;
            text-decoration: none;
          }
          ol {
            position: absolute;
            height: 0;
            overflow: hidden;
            background-color: white;
            padding: 0;
            margin: 0;
            min-width: 100%;
            width: 15rem;
            li {
              a {
                color: var(--verde-escuro-gaia);
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
        .sub-submenu {
          position: absolute;
          left: 100%;
          z-index: 1000;
        }
        .edge {
          left: -100% !important;
        }
        li:hover {
          .submenu {
            height: auto;
            border-bottom: #ef9b07 solid 0.2rem;
            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
            overflow: visible;
            li {
              .seta-menu {
                display: block;
                height: 0.8rem;
                width: 0.6rem;
                fill: var(--verde-escuro-gaia);
                transform: rotate(-90deg);
              }
            }
            li:hover {
              background-color: #ef9b07;
              ol {
                height: auto;
                margin-top: -2.2rem;
                box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
                border-bottom: #ef9b07 solid 0.2rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
