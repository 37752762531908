<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Tipos de área</h1>
      <BotaoLink
        class="bt-novo"
        :to="{
          name: ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
          query: { ...this.$route.query },
        }"
      >
        <span>+</span>Novo</BotaoLink
      >
    </div>
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
    <AppErro v-if="!carregando && erroCarregar" />

    <template v-if="paginaCarregou && !paginaComErro">
      <div>
        <div class="campo-header-resultados">
          <AppTotalResultadosEncontrados :totalResultados="totalResultados" />

          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <div>
          <TiposAreaResultados
            :tiposArea="tiposArea"
            @removerTipoArea="removerTipoArea"
          />
        </div>
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          >
          </Paginate>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import { DEFAULT_PER_PAGE } from "../../../../config";
import TiposAreaApi from "../../../../services/api/TiposAreaApi";
import {
  ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
  ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
} from "../../../../router/routeNames";

import Paginate from "vuejs-paginate";
import BotaoLink from "../../../../components/botoes/BotaoLink.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import TiposAreaResultados from "../../../../components/atracao_investimentos/tipos_area/TiposAreaResultados.vue";
import AppResultadosPorPagina from "../../../../components/AppResultadosPorPagina.vue";
import AppTotalResultadosEncontrados from "../../../../components/AppTotalResultadosEncontrados.vue";
export default {
  name: "TiposAreaIndex",
  metaInfo: {
    title: "Tipos de Área - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  components: {
    AppLoading,
    AppErro,
    TiposAreaResultados,
    AppResultadosPorPagina,
    Paginate,
    BotaoLink,
    AppTotalResultadosEncontrados,
  },
  data() {
    return {
      tiposArea: [],
      totalResultados: 0,
      carregando: true,
      erroCarregar: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      ATRACAO_INVESTIMENTOS_TIPOS_AREA_NEW,
    };
  },
  created() {
    this.tiposAreaApi = new TiposAreaApi();
    this.carregandoDados();
  },
  methods: {
    async carregandoDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.tiposAreaApi.getTiposArea({
          page: this.page,
          perPage: this.perPage,
        });

        this.tiposArea = data.resultados;
        this.totalResultados = data.total_resultados;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.erroCarregar = true;
        this.carregando = false;
      }
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
        query: {
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    async removerTipoArea(id) {
      try {
        const result = await this.$swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá desfazer essa ação",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (!result.isConfirmed) {
          return;
        }

        this.carregando = true;

        await this.tiposAreaApi.removerTipoArea(id);

        if (this.tiposArea && this.tiposArea.length == 1 && this.page > 1) {
          this.page--;
          this.$router.push({
            name: ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX,
            query: { ...this.$route.query, page: this.page },
          });
        } else {
          this.carregandoDados();
        }

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Registro excluido com sucesso.",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });

        this.carregando = false;
      } catch (err) {
        console.log(err, err.response);

        let msgErro =
          "Não foi possível excluir o registro. Por favor tente novamente.";
        if (err && err.response && err.response.data) {
          if (Array.isArray(err.response.data)) {
            msgErro = err.response.data.join(", ");
          } else {
            msgErro = err.response.data;
          }
        }

        this.$swal({
          position: "center",
          icon: "error",
          title: msgErro,
          showConfirmButton: true,
        });

        this.carregando = false;
      }
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    paginaCarregou() {
      return !this.carregando;
    },
    paginaComErro() {
      return this.erroCarregar;
    },
  },
  watch: {
    $route: function (to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_TIPOS_AREA_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregandoDados();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  h1 {
    margin: 0;
    color:  var(--laranja-gaia);
  }
}

.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@media screen and(min-width: '30rem') {
  .campo-header-resultados {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
