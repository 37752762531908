import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import TiposSolicitacaoIndex from '../../../views/atracao_investimentos/cadastros/tipos_solicitacao_empresa/TiposSolicitacaoEmpresaIndex.vue'
import TiposSolicitacaoForm from '../../../views/atracao_investimentos/cadastros/tipos_solicitacao_empresa/TiposSolicitacaoEmpresaForm.vue'
const rotasTiposSolicitacaoEmpresa = [
    {
        name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_INDEX,
        path: "/atracao-investimentos/cadastros/tipos-solicitacao-empresa",
        component: TiposSolicitacaoIndex,
        meta: {
            raiz: true,
            requerPermissao: true,
            permissoes: [permissoes.TIPOS_SOLICITACAO_EMPRESA_PERMISSAO]
        },
        children: [
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_NEW,
                path: "/atracao-investimentos/cadastros/tipos-solicitacao-empresa/new",
                component: TiposSolicitacaoForm,
                meta: {
                    raiz: false,
                    requerPermissao: true,
                    permissoes: [permissoes.TIPOS_SOLICITACAO_EMPRESA_PERMISSAO]
                }
            },
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_TIPOS_SOLICITACAO_EMPRESAS_EDIT,
                path: "/atracao-investimentos/cadastros/tipos-solicitacao-empresa/:id/edit",
                component: TiposSolicitacaoForm,
                meta: {
                    raiz: false,
                    requerPermissao: true,
                    permissoes: [permissoes.TIPOS_SOLICITACAO_EMPRESA_PERMISSAO],
                }
            }
        ]
    }
]

export default rotasTiposSolicitacaoEmpresa