import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

class PaisesApi {
  cancelTokens = {
    getPaises: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getPaises() {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getPaises = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/paises`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPaises = null;

    return data;
  }
}

export default PaisesApi;
