<template>
  <div class="form-conteudo centro">
    <div class="form-header">
      <h1>
        {{
          $route.name == ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW
            ? "Novo município"
            : "Editar município"
        }}
      </h1>
      <BotaoVisualizarForm
        v-if="$route.name === ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT"
        :to="{
          name: ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
          params: { id: $route.params.id },
        }"
      />
    </div>
    <div>
      <AppLoading
        class="carregando1"
        v-if="carregandoDados && !erroCarregandoDados"
      />
      <AppErro v-if="!carregandoDados && erroCarregandoDados" />
      <form
        v-if="!carregandoDados && !erroCarregandoDados"
        @submit="salvarDados"
      >
        <AppErrosFormularios
          v-if="errosSalvando && errosSalvando.length > 0"
          :erros="errosSalvando"
        />
        <div class="caixa-form">
          <div class="row4">
            <div class="item">
              <label>Nome: <span>*</span></label>
              <input type="text" v-model="municipio.nome" />
            </div>

            <div class="item">
              <label>Estado: <span>*</span></label>
              <select v-model="municipio.estado_id">
                <option disabled :value="undefined">Selecione</option>
                <option
                  v-for="estado in estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.nome }}
                </option>
              </select>
            </div>

            <div class="item">
              <label>Microrregião:</label>
              <select v-model="municipio.microrregiao_id">
                <option
                  v-for="microrregiao in microrregioes"
                  :key="microrregiao.id"
                  :value="microrregiao.id"
                >
                  {{ microrregiao.nome }}
                </option>
              </select>
            </div>
            <div class="item">
              <label>Região de Planejamento:</label>
              <select v-model="municipio.regiao_planejamento_id">
                <option
                  v-for="regiao in regioesPlanejamento"
                  :key="regiao.id"
                  :value="regiao.id"
                >
                  {{ regiao.nome }}
                </option>
              </select>
            </div>
          </div>
          <MunicipioBeneficiosForm
            :beneficios="beneficios"
            v-model="municipio.beneficios_municipios"
          />
          <MunicipioAreasDisponiveisForm
            v-model="municipio.areas_disponiveis"
          />
        </div>
        <div class="botao-salvar">
          <AppLoading size="small" v-if="salvando" texto="" />
          <BotaoSalvar v-if="!salvando" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
  ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
  NAO_ENCONTRADO,
} from "../../../router/routeNames";

import MunicipiosApi from "../../../services/api/MunicipiosApi";
import EstadosApi from "../../../services/api/EstadosApi";
import MicrorregioesApi from "../../../services/api/MicrorregioesApi";
import RegioesPlanejamentoApi from "../../../services/api/RegioesPlanejamentoApi";
import BeneficiosApi from "../../../services/api/BeneficiosApi";

import AppLoading from "../../../components/AppLoading";
import AppErro from "../../../components/AppErro";
import MunicipioBeneficiosForm from "../../../components/atracao_investimentos/municipios/MunicipioBeneficiosForm";
import MunicipioAreasDisponiveisForm from "../../../components/atracao_investimentos/municipios/MunicipioAreasDisponiveisForm";
import AppErrosFormularios from "../../../components/AppErrosFormularios";

import BotaoVisualizarForm from "../../../components/botoes/BotaoVisualizarForm.vue";
import BotaoSalvar from "../../../components/botoes/BotaoSalvar.vue";

export default {
  name: "MunicipioForm",
  metaInfo() {
    return {
      title: `${
        this.$route.name === ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT
          ? "Editar"
          : "Criar"
      } Município - Atração de Investimentos`,
      titleTemplate: "%s - Gaia",
    };
  },
  data() {
    return {
      municipio: {
        beneficios_municipios: [],
      },
      estados: [],
      microrregioes: [],
      regioesPlanejamento: [],
      beneficios: [],
      carregandoDados: true,
      erroCarregandoDados: false,
      salvando: false,
      errosSalvando: [],
      ATRACAO_INVESTIMENTOS_MUNICIPIO_NEW,
      ATRACAO_INVESTIMENTOS_MUNICIPIOS_INDEX,
      ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
      ATRACAO_INVESTIMENTOS_MUNICIPIO_EDIT,
    };
  },
  components: {
    AppLoading,
    AppErro,
    MunicipioBeneficiosForm,
    AppErrosFormularios,
    MunicipioAreasDisponiveisForm,
    BotaoVisualizarForm,
    BotaoSalvar,
  },
  created() {
    this.municipiosApi = new MunicipiosApi();
    this.estadosApi = new EstadosApi();
    this.microrregioesApi = new MicrorregioesApi();
    this.regioesPlanejamentoApi = new RegioesPlanejamentoApi();
    this.beneficiosApi = new BeneficiosApi();

    this.carregarDados();
  },
  methods: {
    async carregarDados() {
      try {
        this.carregandoDados = true;
        this.erroCarregandoDados = false;

        if (this.municipiosApi.cancelTokens.getMunicipio) {
          this.municipiosApi.cancelTokens.getMunicipio.cancel();
        }

        if (this.$route.params.id) {
          this.municipio = await this.municipiosApi.getMunicipio(
            this.$route.params.id
          );
        }

        const [
          estados,
          microrregioes,
          regioesPlanejamento,
          resultadosBeneficios,
        ] = await Promise.all([
          this.estadosApi.getEstados(),
          this.microrregioesApi.getMicrorregioes(),
          this.regioesPlanejamentoApi.getRegioesPlanejamento(),
          this.beneficiosApi.getBeneficios({
            page: 1,
            perPage: 1000,
          }),
        ]);

        this.estados = estados;
        this.microrregioes = microrregioes;
        this.regioesPlanejamento = regioesPlanejamento;
        this.beneficios = resultadosBeneficios.resultados;

        this.carregandoDados = false;
      } catch (err) {
        if (this.municipiosApi.isCancel(err)) {
          return;
        }

        if (err && err.response && err.response.status == 404) {
          this.$router.push({ name: NAO_ENCONTRADO });
        }
        console.log(err);
        this.carregandoDados = false;
        this.erroCarregandoDados = true;
      }
    },
    async salvarDados(event) {
      event.preventDefault();

      try {
        this.salvando = true;
        this.errosSalvando = [];

        const municipio = await this.municipiosApi.salvarMunicipio(
          this.municipio
        );
        this.salvando = false;
        this.$router.push({
          name: ATRACAO_INVESTIMENTOS_MUNICIPIO_SHOW,
          params: { id: municipio.id },
        });

        this.$swal({
          icon: "success",
          position: "top-end",
          title: "Município salvo com sucesso!",
          timer: 3000,
          showConfirmButton: false,
          toast: true,
        });
      } catch (err) {
        console.log(err);

        const { response } = err;
        if (response.data) {
          if (Array.isArray(response.data)) {
            this.errosSalvando = response.data;
          } else {
            this.errosSalvando = [response.data];
          }
        } else {
          this.errosSalvando = ["Houve um erro. Por favor, tente novamente"];
        }
        this.salvando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-conteudo {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: var(--laranja-gaia);
    }
  }
  .caixa-form {
    .item {
      display: grid;
      margin: 0.5rem 0;
      input {
        height: 1.5rem;
      }
      select {
        height: 2rem;
      }
    }
  }
  .botao-salvar {
    text-align: center;
    margin: 2rem 0 3rem;
  }
}
@media screen and(min-width: '40em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }
}
@media screen and(min-width: '60em') {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
