<template>
  <div>
    <div class="caixa-dados">
      <header class="header-caixa">
        <b>{{ index }}</b> - {{ contatoRealizado.data_contato | toDate }}
      </header>
      <div>
        <p class="item">
          <strong>Assunto:</strong> {{ contatoRealizado.assunto }}
        </p>
        <div class="row2">
          <p class="item">
            <strong>Pessoa que entrou em contato:</strong>
            {{
              contatoRealizado.pessoa_entrou_em_contato
                ? contatoRealizado.pessoa_entrou_em_contato.nome
                : "Não informado"
            }}
          </p>
          <p class="item" v-if="contatoRealizado.etapa_label">
            <strong>Etapa: </strong>{{ contatoRealizado.etapa_label }}
          </p>
        </div>
      </div>

      <router-link
        class="bt-show-modal"
        :to="{
          name: PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
          params: {
            id: this.$route.params.id,
            contatoId: this.contatoRealizado.id,
          },
          hash: '#contatos',
        }"
        ><EyeIcon /> Visualizar</router-link
      >
    </div>
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";

import { PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO } from "../../../../router/routeNames";

import EyeIcon from "vue-material-design-icons/Eye.vue";

export default {
  name: "PessoasJuridicasContatoRealizadoShowItem",
  components: {
    EyeIcon,
  },
  props: {
    contatoRealizado: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_SHOW_CONTATO_REALIZADO,
    };
  },
};
</script>

<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: "Lato-Bold";
  }
  .item {
    padding: 0 1rem;
  }
  .bt-show-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--verde-escuro-gaia);
    border-radius: 2px;
    font-family: "lato-regular";
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem 2rem;
    width: 7rem;
    margin: 0 auto;
    font-family: "Lato-Regular";
    svg {
      fill: white;
      margin-right: 0.5rem;
    }
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
    gap: 0.5rem;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
}
</style>
