<template>
  <div class="caixa-form">
    <AppLoading
      v-if="
        carregandoListaProtocolosIntencao &&
        !erroCarregarListaProtocolosIntencao
      "
    />
    <AppErro
      v-if="
        erroCarregarListaProtocolosIntencao &&
        !carregandoListaProtocolosIntencao
      "
    />
    <template
      v-if="
        !carregandoListaProtocolosIntencao &&
        !erroCarregarListaProtocolosIntencao
      "
    >
      <div
        v-if="errosExcluirProtocolosIntencao && !excluindoProtocolosIntencao"
      >
        <ul>
          <li
            v-for="(erro, index) in errosExcluirProtocolosIntencao"
            :key="index"
          >
            {{ erro }}
          </li>
        </ul>
      </div>
      <div class="disp-flex">
        <h4>Protocolo de Intenção</h4>
        <BotaoArredondado
          @click="adicionar"
          type="button"
          title="Adicionar Protocolo de Intenção "
        >
          <span>+</span> Adicionar
        </BotaoArredondado>
      </div>

      <PessoasJuridicasProtocolosItem
        v-for="(protocoloIntencao, index) in protocolosIntencao"
        :index="index"
        :protocolo-intencao="protocoloIntencao"
        :key="protocoloIntencao.id"
      />

      <div class="text-center" v-if="protocolosIntencao.length === 0">
        <p>Nenhum registro encontrado</p>
      </div>

      <AppOverlayLoading v-if="excluindoProtocolosIntencao">
        <span>Excluindo... Aguarde por favor.</span>
      </AppOverlayLoading>
      <div class="botao-salvar">
        <BotaoSalvar
          texto="Confirmar exclusões"
          v-if="protocolosIntencao.length > 0"
          :disabled="!possuiAtracaoInvestimentoParaExcluir"
          @click="excluirBatch"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import AppLoading from "../../../AppLoading";
import AppErro from "../../../AppErro";
import BotaoSalvar from "../../../botoes/BotaoSalvar";
import AppOverlayLoading from "../../../AppOverlayLoading";

import ProtocoloIntencao from "../../../../store/models/ProtocoloIntencao";

import { modulePessoaJuridica } from "../../../../store/moduleNames";
import PessoasJuridicasProtocolosItem from "./PessoasJuridicasProtocolosItem";
import { PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO } from "../../../../router/routeNames";
import BotaoArredondado from "../../../botoes/BotaoArredondado.vue";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasProtocolosIntencao",
  components: {
    AppErro,
    AppLoading,
    AppOverlayLoading,
    BotaoSalvar,
    PessoasJuridicasProtocolosItem,
    BotaoArredondado,
  },
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    adicionar() {
      this.$router.push({
        name: PESSOA_JURIDICA_EDIT_PROTOCOLO_INTENCAO,
        query: { action: "new" },
        hash: this.$route.hash,
      });
    },
    async excluirBatch() {
      const result = await this.$swal.fire({
        title:
          "Você tem certeza que deseja excluir os registros selecionados? ",
        text: "Os registros não poderão ser recuperados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#054700",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        const protocolosParaExcluir = ProtocoloIntencao.query()
          .where("pessoa_juridica_id", this.pessoaJuridicaId)
          .where("_destroy", true)
          .get();

        const idsParaExcluir = protocolosParaExcluir.map(
          (atracao) => atracao.id
        );

        await this.excluirProtocolosIntencaoBatch(idsParaExcluir);
      }
    },
    ...mapActions(["excluirProtocolosIntencaoBatch"]),
  },
  computed: {
    protocolosIntencao() {
      return ProtocoloIntencao.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .orderBy("data_inicio", "desc")
        .get();
    },
    possuiAtracaoInvestimentoParaExcluir() {
      return ProtocoloIntencao.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .where("_destroy", true)
        .exists();
    },
    erroCarregarListaProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .erroCarregarListaProtocolosIntencao;
    },
    carregandoListaProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .carregandoListaProtocolosIntencao;
    },
    excluindoProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .excluindoProtocolosIntencao;
    },
    errosExcluirProtocolosIntencao() {
      return this.$store.state.entities.protocolosIntencao
        .errosExcluirProtocolosIntencao;
    },
  },
};
</script>

<style lang="scss" scoped>

h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  padding: 0rem .5rem;
  margin-bottom: 2rem;
}
.disp-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.botao-salvar{
  text-align: center;
  margin-top: 1rem;
}
.text-center{
  text-align: center;
}
</style>
