<template>
  <div class="caixa-form">
    <div class="header-caixa">
      <h4>Solicitações</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Solicitação"
      >
        <span>+</span>Adicionar
      </BotaoArredondado>
    </div>
    <div
      class="caixa-item"
      v-for="(solicitacao, index) in solicitacoesEmpresa"
      :key="index"
    >
      <template v-if="!solicitacao._destroy">
        <button class="bt-delete" type="button" @click="excluir(index)">
          <DeleteIcon />
        </button>
        <PessoasJuridicasSolicitacao
          v-model="solicitacoesEmpresa[index]"
          :tipos-solicitacao="tiposSolicitacao"
          :index="solicitacoesEmpresa.length - index"
        />
      </template>
      <template v-else>
        <div class="campo-desfazer">
          <p>O registro será excluído.</p>
          <button
            class="bt-desfazer"
            @click="desfazerExclusao(index)"
            type="button"
          >
            <IconDesfazer /> Desfazer
          </button>
        </div>
      </template>
    </div>

    <div class="text-center" v-if="solicitacoesEmpresa.length === 0">
      <p>Nenhuma solicitação encontrada</p>
    </div>
  </div>
</template>

<script>
import PessoasJuridicasSolicitacao from "./PessoasJuridicasSolicitacao";
import BotaoArredondado from "../../../botoes/BotaoArredondado.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import IconDesfazer from "../../../../assets/images/icon-return.svg";

const cloneDeep = require("lodash.clonedeep");

export default {
  name: "PessoasJuridicasSolicitacoes",
  model: {
    prop: "solicitacoesEmpresa",
    event: "change",
  },
  props: {
    tiposSolicitacao: {
      type: Array,
      required: true,
    },
    solicitacoesEmpresa: {
      type: Array,
    },
  },
  components: {
    PessoasJuridicasSolicitacao,
    BotaoArredondado,
    DeleteIcon,
    IconDesfazer,
  },
  methods: {
    adicionar() {
      const solicitacoes = cloneDeep(this.solicitacoesEmpresa);
      solicitacoes.unshift({ concluida: false });
      this.$emit("change", solicitacoes);
    },
    excluir(index) {
      const solicitacoes = cloneDeep(this.solicitacoesEmpresa);
      if (!solicitacoes[index].id) {
        //nao esta salvo no banco
        solicitacoes.splice(index, 1);
      } else {
        solicitacoes[index]._destroy = true;
      }
      this.$emit("change", solicitacoes);
    },
    desfazerExclusao(index) {
      const solicitacoes = cloneDeep(this.solicitacoesEmpresa);

      solicitacoes[index]._destroy = false;

      this.$emit("change", solicitacoes);
    },
    updateValue(index, solicitacao) {
      const solicitacoes = cloneDeep(this.solicitacoesEmpresa);

      solicitacoes[index] = solicitacao;

      this.$emit("change", solicitacoes);
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-form {
  padding: 0 .5rem;

.header-caixa {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  span{
    font-weight: 800;
    font-size: 1.8rem;
  }
}
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem .5rem .5rem;
    margin-bottom: 1rem;
    position: relative;

  }
}
.bt-delete {
  position: absolute;
  right: .5rem;
  top: .5rem;
  background-color: transparent;
  padding: 0;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  svg {
    fill: var(--vermelho-gaia);
  }
}
p {
  text-align: center;
}
.bt-desfazer {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: var(--vermelho-gaia);
  margin: 1rem auto;
  padding: 0.3rem 1rem;
  transition: 0.2s;
  svg {
    height: 1.3rem;
    width: 2rem;
    fill: var(--vermelho-gaia);
    margin-right: 1rem;
    transition: 0.2s;
  }
}
.text-center {
  text-align: center;
}
@media screen and (min-width: "60em") {
  .campo-desfazer {
    display: flex;
    align-items: center;
    justify-content: center;
    .bt-desfazer {
      margin: 0;
    }
  }
}
</style>
