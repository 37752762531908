var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caixa-dados"},[_c('header',{staticClass:"header-caixa"},[_c('b',[_vm._v(_vm._s(_vm.index))]),_vm._v(" - "+_vm._s(_vm.protocoloIntencao.numero)+" ( "+_vm._s(_vm.protocoloIntencao.status_label)+") ")]),_c('div',[_c('div',{staticClass:"row3"},[(_vm.protocoloIntencao.data_inicio)?_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Data de inicio: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm._f("toDate")(_vm.protocoloIntencao.data_inicio)))+" ")]):_vm._e(),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Tipo de Projeto: ")]),_vm._v(_vm._s(_vm.protocoloIntencao.tipo_projeto)+" ")]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Processo SEI: ")]),_vm._v(_vm._s(_vm._f("naoInformado")(_vm.protocoloIntencao.processo_sei))+" ")])])]),_c('p',{staticClass:"item"},[_c('strong',[_vm._v("Descrição do Projeto: ")]),_vm._v(_vm._s(_vm.protocoloIntencao.descricao_projeto)+" ")]),_c('router-link',{staticClass:"bt-show-modal",attrs:{"to":{
      name: _vm.PESSOA_JURIDICA_SHOW_PROTOCOLO_INTENCAO,
      params: {
        id: _vm.$route.params.id,
        protocoloId: _vm.protocoloIntencao.id,
      },
      hash: '#protocolo',
    }}},[_c('EyeIcon'),_vm._v(" Visualizar")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }