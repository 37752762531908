<template>
  <div class="caixa-dados">
    <header class="header-caixa">
      <b>{{ index }}</b> - Iniciada em
      {{ atracaoInvestimento.data_inicio | toDate }}
    </header>

    <div>
      <div class="row3">
        <p class="item">
          <strong>Data de inicio: </strong
          >{{ atracaoInvestimento.data_inicio | toDate }}
        </p>
        <p class="item">
          <strong>Área internacional: </strong
          >{{ atracaoInvestimento.area_internacional ? "Sim" : "Não" }}
        </p>
        <p class="item">
          <strong>Já estabelecida em Goiás: </strong
          >{{ atracaoInvestimento.ja_estabelecida_em_goias ? "Sim" : "Não" }}
        </p>
      </div>
      <div class="row3">
        <p class="item">
          <strong>Quem iniciou o contato: </strong
          >{{ atracaoInvestimento.quem_iniciou_contato_label }}
        </p>

        <p class="item">
          <strong>Empregos previstos: </strong
          >{{ atracaoInvestimento.empregos_previstos | naoInformado }}
        </p>

        <p class="item">
          <strong>Investimentos previstos: </strong
          >{{ atracaoInvestimento.empregos_previstos | naoInformado }}
        </p>
      </div>
    </div>

    <router-link
      class="bt-show-modal"
      :to="{
        name: PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
        params: {
          id: this.$route.params.id,
          atracaoId: this.atracaoInvestimento.id,
        },
        hash: '#atracao',
      }"
      ><EyeIcon /> Visualizar</router-link
    >
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";
import { PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO } from "../../../../router/routeNames";
import EyeIcon from "vue-material-design-icons/Eye.vue";
export default {
  name: "PessoasJuridicasItemAtracaoInvestimento",
  components: {
    EyeIcon,
  },
  props: {
    atracaoInvestimento: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    toDate,
  },
  data() {
    return {
      PESSOA_JURIDICA_SHOW_ATRACAO_INVESTIMENTO,
    };
  },
};
</script>

<style lang="scss" scoped>
.caixa-dados {
  background-color: var(--cinza-caixa-gaia);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    font-family: 'Lato-Bold';
  }
  .item {
    padding: 0 1rem;
  }
  .bt-show-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color:  var(--verde-escuro-gaia);
    border-radius: 2px;
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem 2rem;
    width: 7rem;
    margin: 0 auto;
    font-family: 'Lato-Regular';
    svg {
      fill: white;
      margin-right: 0.5rem;
    }
  }
}
.caixa-dados-2 {
  background-color: white;
  margin-top: 1rem;
  border-radius: 2px;
  overflow: hidden;
  .header-caixa {
    background-color: var(--verde-gaia);
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2px 2px 0 0;
    text-align: center;
    font-family: 'Lato-Bold';
  }

  p {
    text-align: center;
  }
}
@media screen and(min-width: '45em') {
  p {
    display: grid;
      gap: .5rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .caixa-dados {
    .botoes {
      display: flex;
      justify-content: center;
      margin: 1rem 0 0.5rem;
      button {
        width: 10rem;
      }
      a {
        width: 10rem;
        border-radius: 2px;
      }
    }
  }
  .desfazer-exclusao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    button {
      margin: 0;
    }
  }
}
</style>
