import * as routeNames from "../../routeNames";
import * as permissoes from "../../../permissoes";

import MeiosContatoIndex from "../../../views/atracao_investimentos/cadastros/meios_contato/MeiosContatoIndex";
import MeiosContatoForm from "../../../views/atracao_investimentos/cadastros/meios_contato/MeiosContatoForm";

const rotasMeiosContato = [
    {
        name: routeNames.ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_INDEX,
        component: MeiosContatoIndex,
        path: "/atracao-investimentos/cadastros/meios-contato",
        meta: {
            requerPermissao: true,
            raiz: true,
            permissoes: [permissoes.MEIOS_CONTATO_PERMISSOES]
        },
        children: [
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_NEW,
                component: MeiosContatoForm,
                path: "/atracao-investimentos/cadastros/meios-contato/new",
                meta: {
                    requerPermissao: true,
                    raiz: true,
                    permissoes: [permissoes.MEIOS_CONTATO_PERMISSOES]
                }
            },
            {
                name: routeNames.ATRACAO_INVESTIMENTOS_MEIOS_CONTATO_EDIT,
                component: MeiosContatoForm,
                path: "/atracao-investimentos/cadastros/meios-contato/:id/edit",
                meta: {
                    requerPermissao: true,
                    raiz: true,
                    permissoes: [permissoes.MEIOS_CONTATO_PERMISSOES]
                }
            }
        ]
    }
]

export default rotasMeiosContato;