import cpfCnpj from "./cpfCnpj";
import naoInformado from "./naoInformado";
import toDate from "./toDate";

export default {
    install(Vue) {
        Vue.filter('cpfCnpj', cpfCnpj);
        Vue.filter('naoInformado', naoInformado);
        Vue.filter('toDate', toDate);
    }
}