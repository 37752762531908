<template>
  <div>
    <div class="div-flex-header">
      <h4>Classificação CNAE</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar CNAE"
        alt="Adicionar CNAE"
      >
        <span>+</span> Adicionar
      </BotaoArredondado>
    </div>

    <div class="row2">
      <template v-for="(codigoPessoa, index) in codigosCnaePessoasJuridicas">
        <div
          class="item"
          :key="codigoPessoa.codigo_cnae_id + '-' + index"
          v-if="!codigoPessoa._destroy"
        >
          <label>Código CNAE:</label>
          <div class="div-flex">
            <CodigosCnaeAutocomplete
              :codigo-cnae="codigosCnaePessoasJuridicas[index].codigo_cnae"
              @change="(codigo) => updateCodigo(index, codigo)"
            />
            <button @click="excluir(index)" type="button">
              <DeleteIcon />
            </button>
          </div>
        </div>
      </template>
    </div>
    <p class="nao-possui" v-if="classificacoes.length == 0">
      Nenhuma classificação selecionada
    </p>
  </div>
</template>

<script>
import CodigosCnaeAutocomplete from "../../../codigos_cnae/CodigosCnaeAutocomplete";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import BotaoArredondado from "../../../botoes/BotaoArredondado";

import CodigoCnaePessoaJuridica from "../../../../store/models/CodigoCnaePessoaJuridica";

export default {
  name: "PessoasJuridicasFormularioCodigoCnae",
  props: {
    pessoaJuridicaId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    BotaoArredondado,
    CodigosCnaeAutocomplete,
    DeleteIcon,
  },
  methods: {
    updateCodigo(index, codigo) {
      const codigoCnaePessoaJuridica = this.codigosCnaePessoasJuridicas[index];

      let data;
      if (codigoCnaePessoaJuridica && codigo && codigo.id) {
        data = {
          codigo_cnae_id: codigo.id,
          codigo_cnae: codigo,
        };
      } else {
        data = {
          codigo_cnae_id: null,
          codigo_cnae: null,
        };
      }

      CodigoCnaePessoaJuridica.insertOrUpdate({
        data: { ...codigoCnaePessoaJuridica, ...data },
      });
    },
    adicionar() {
      CodigoCnaePessoaJuridica.insert({
        data: {
          pessoa_juridica_id: this.pessoaJuridicaId,
        },
      });
    },
    excluir(index) {
      const codigoCnaePessoaJuridica = this.codigosCnaePessoasJuridicas[index];
      if (codigoCnaePessoaJuridica._persistido) {
        CodigoCnaePessoaJuridica.update({
          where: codigoCnaePessoaJuridica.id,
          data: {
            ...codigoCnaePessoaJuridica,
            _destroy: true,
          },
        });
      } else {
        CodigoCnaePessoaJuridica.delete(codigoCnaePessoaJuridica.id);
      }
    },
  },
  computed: {
    classificacoes() {
      return this.codigosCnaePessoasJuridicas.filter((cod) => !cod._destroy);
    },
    codigosCnaePessoasJuridicas() {
      return CodigoCnaePessoaJuridica.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .with("codigo_cnae")
        .get();
    },
  },
};
</script>
<style lang="scss" scoped>
.div-flex-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.5rem 0 1rem;
  h4 {
    margin: 0;
    font-size: 1.4rem;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 130%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
}
.div-flex {
  display: flex;
  margin-bottom: 0.5rem;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
}
.item{
  label{
    display: block;
    margin-bottom: .5rem;
  }
}
.nao-possui {
  font-style: italic;
  text-align: center;
}
@media screen and(min-width: '50em') {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
</style>
