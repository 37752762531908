<template>
  <div>
    <form class="form-pesquisa" @submit="submit">
      <h4>Pesquisar</h4>
      <div class="row2">
        <div class="item">
          <label>CNPJ:</label>
          <TheMask
            type="text"
            mask="##.###.###/####-##"
            :value="filtro.cnpj"
            @input="(value) => filtroMudou('cnpj', value)"
          />
        </div>
        <div class="item">
          <label>Código CNAE:</label>
          <CodigosCnaeAutocomplete v-model="filtro.codigo_cnae" />
        </div>
        <div class="item">
          <label>Nome Fantasia:</label>
          <input
            type="text"
            :value="filtro.nome_fantasia"
            @input="(evt) => filtroMudou('nome_fantasia', evt.target.value)"
          />
        </div>
        <div class="item">
          <label>Razão Social:</label>
          <input
            type="text"
            :value="filtro.razao_social"
            @input="(evt) => filtroMudou('razao_social', evt.target.value)"
          />
        </div>
      </div>
      <div class="botoes-pesquisa">
        <button class="bt-limpar" type="button" @click="limpar">
          <IconClear /> Limpar campos
        </button>
        <button class="bt-pesquisar" type="submit">Pesquisar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

import CodigosCnaeAutocomplete from "../codigos_cnae/CodigosCnaeAutocomplete";

import IconClear from "../../assets/images/icon-clean.svg";

export default {
  name: "PessoasJuridicasPesquisaForm",
  model: {
    prop: "filtro",
    event: "update-filtro-pesquisa-pessoa-juridica",
  },
  props: {
    filtro: {
      type: Object,
    },
  },
  components: {
    TheMask,
    CodigosCnaeAutocomplete,
    IconClear,
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
    filtroMudou(chave, valor) {
      this.$emit("update-filtro-pesquisa-pessoa-juridica", {
        ...this.filtro,
        [chave]: valor,
      });
    },
    limpar() {
      const chaves = Object.keys(this.filtro);
      const filtroLimpo = chaves.reduce((acc, chave) => {
        acc[chave] = null;
        return acc;
      }, {});
      this.$emit("update-filtro-pesquisa-pessoa-juridica", filtroLimpo);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      padding-left: 0.5rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
    }
  }
  .form-pesquisa {
    padding: 1rem;
    border-radius: 2px;
    h4 {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }
  p {
    margin: 0;
  }
  .div-flex {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    .area-de {
      margin-right: 1rem;
    }
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color:  var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color: var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

@media screen and(min-width: '50rem') {
  .row2 {
    grid-template-columns: 1fr 2fr;
    display: grid;
    gap: 0 1rem;
  }
}
</style>
