import ModelPersistido from "./ModelPersistido";
import AtracaoInvestimento from "./AtracaoInvestimento";
import ProtocoloIntencao from "./ProtocoloIntencao";

export default class AtracaoInvestimentoProtocoloIntencao extends ModelPersistido {
    static entity = 'atracaoInvestimentosProtocolosIntencao';

    static fields() {
        return {
            id: this.attr(null),
            atracao_investimento_id: this.attr(null),
            protocolo_intencao_id: this.attr(null),
            atracao_investimento: this.belongsTo(AtracaoInvestimento, 'atracao_investimento_id'),
            protocolo_intencao: this.belongsTo(ProtocoloIntencao, 'protocolo_intencao_id'),
            _destroy: this.boolean(false),
        }
    }
}