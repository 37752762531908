import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class MunicipiosApi {
  cancelTokens = {
    getMunicipios: null,
    getMunicipio: null,
    salvarMunicipio: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getMunicipios({
    page = 1,
    perPage,
    nome,
    estadoId,
    microrregiaoId,
    mesorregiaoId,
    possuiIncentivo,
    areaMinima,
    areaMaxima,
  }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
      nome,
      estado_id: estadoId,
      microrregiao_id: microrregiaoId,
      mesorregiao_id: mesorregiaoId,
      possui_incentivo: possuiIncentivo,
      area_m2_minima: areaMinima,
      area_m2_maxima: areaMaxima,
    });

    this.cancelTokens.getMunicipios = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/municipios?${searchString}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMunicipios = null;

    return data;
  }

  async getMunicipio(id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMunicipio = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/municipios/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMunicipio = null;

    return data;
  }

  async salvarMunicipio({
    id,
    nome,
    estado_id,
    beneficios_municipios,
    microrregiao_id,
    regiao_planejamento_id,
    areas_disponiveis,
  }) {
    let method;
    let url;
    if (id) {
      //update
      method = axiosAuthenticated.put;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/municipios/${id}`;
    } else {
      //create
      method = axiosAuthenticated.post;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/municipios`;
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarMunicipio = source;

    const { data } = await method(
      url,
      {
        id,
        nome,
        estado_id,
        beneficios_municipios_attributes: beneficios_municipios,
        microrregiao_id,
        regiao_planejamento_id,
        areas_disponiveis_attributes: areas_disponiveis,
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarMunicipio = null;

    return data;
  }
}

export default MunicipiosApi;
