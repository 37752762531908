var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.tiposInvestimento),function(tipoInvestimento){return _c('tr',{key:tipoInvestimento.id},[_c('td',{staticClass:"topo-card"},[_vm._v(_vm._s(tipoInvestimento.tipo_investimento_traducao_padrao.nome))]),_c('td',{staticClass:"acoes"},[_c('BotaoExibirTabela',{attrs:{"to":{
              name: _vm.INVESTE_GOIAS_TIPOS_INVESTIMENTO_SHOW,
              params: {
                id: tipoInvestimento.id,
              },
            }}}),_c('BotaoEditarTabela',{attrs:{"to":{
              name: _vm.INVESTE_GOIAS_TIPOS_INVESTIMENTO_EDIT,
              params: {
                id: tipoInvestimento.id,
              },
            }}}),_c('BotaoDeletarTabela',{on:{"click":function($event){return _vm.deletar(tipoInvestimento.id)}}})],1)])}),(_vm.tiposInvestimento.length == 0)?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Nenhum resultado encontrado")])]):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nome da tradução")]),_c('th')])])}]

export { render, staticRenderFns }