<template>
  <button
    type="submit"
    @click="click"
    title="Salvar"
    :disabled="disabled"
    :class="{ disabled: disabled }"
  >
    <span><SaveIcon /> {{ texto }}</span>
  </button>
</template>

<script>
import SaveIcon from 'vue-material-design-icons/ContentSave.vue';

export default {
  name: "BotaoSalvar",
  props: {
    texto: {
      type: String,
      default: "Salvar",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
  components: {
    SaveIcon,
  },
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  background-color:  var(--verde-escuro-gaia);
  color: white;
  padding: 0rem 4rem;
  cursor: pointer;
  transition: 0.2s linear;
  border-radius: 2px;
  justify-content: center;
  svg {
    height: 39px;
    margin-right: 1rem;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Lato-bold' !important;
  }
}

button.disabled {
  //colocar css de botao disabled
  background-color: gray;
}

button:hover {
  box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.521);
}
</style>
