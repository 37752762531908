<template>
  <div class="caixa-form">
    <h4>Histórico de andamento</h4>

    <div>
      <div
        class="caixa-item"
        v-for="andamento in historicoProtocolosIntencaoInvertido"
        :key="andamento.id"
      >
          <p>
            <strong>Data: </strong>
            {{ andamento.data | toDate }}
          </p>
        <div class="row3">
          <p>
            <strong>Empregos diretos gerados até o momento: </strong>
            {{ andamento.empregos_diretos_gerados_atual | naoInformado }}
          </p>
          <p>
            <strong>Empregos indiretos gerados até o momento: </strong>
            {{ andamento.empregos_indiretos_gerados_atual | naoInformado }}
          </p>
          <p>
            <strong>Investimento até o momento: </strong>
            <span
              v-if="
                andamento.investimento_fixo_atual != null &&
                  andamento.investimento_fixo_atual != undefined
              "
              >{{ andamento.investimento_fixo_atual | currency }}</span
            >
            <span v-else>Não informado(a)</span>
          </p>
        </div>
          <p>
            <strong>Observação: </strong>
            {{ andamento.observacao }}
          </p>
      </div>
      <div
        class="caixa-item"
        v-if="
          !historicoProtocolosIntencao ||
            historicoProtocolosIntencao.length == 0
        "
      >
        <p class="text-center">
          Nenhum andamento cadastrado
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasListaHistorico",
  props: {
    historicoProtocolosIntencao: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    historicoProtocolosIntencaoInvertido() {
      return this.historicoProtocolosIntencao.slice().reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.caixa-form {
  h4 {
    margin: 0.5rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
  .caixa-form {
    margin-bottom: 2rem;
  }
  .text-center {
    text-align: center;
  }
  .caixa-item {
    background-color: var(--cinza-caixa-gaia);
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
  }
}
.text-center {
  text-align: center;
}
@media screen and (min-width: "60em") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: 0.5rem;
    }
  }
}
</style>
