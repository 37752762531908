<template>
  <button
    :type="type"
    @click="click"
    :title="title"
    :alt="alt"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BotaoArredondado",
  props: {
    texto: {
      type: String,
      default: "Adicionar",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
    },
    alt: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  color: var(--verde-gaia);
  border-radius: 1rem;
  transition: 0.2s;
  span {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    font-family: 'lato-regular';
  }
}
</style>
