import axios from 'axios'
import axiosAuthenticated from './axiosAutenticated'

const queryString = require('query-string')

class TiposInvestimentoApi {
    cancelTokens = {
        getTiposInvestimento: null,
        getTipoInvestimento: null,
        removerTipoInvestimento: null,
        salvarTipoInvestimento: null,
        getLinguagens: null
    }

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown)
    }

    async getTiposInvestimento({
        page = 1,
        perPage
    }) {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.getTiposInvestimento = source
        const searchString = queryString.stringify({
            page,
            per_page: perPage,
        })

        const {
            data
        } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/tipos_investimento?${searchString}`,
            {
                cancelToken: source.token,
            }
        )
        this.cancelTokens.getTiposInvestimento = null
        return data
    }

    async getTipoInvestimento(id) {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.getTipoInvestimento = source
        const {
            data
        } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/tipos_investimento/${id}`,
            {
                cancelToken: source.token,
            }
        )
        this.cancelTokens.getTipoInvestimento = null
        return data
    }

    async salvarTipoInvestimento(tipoInvestimento) {
        let method;
        let url;
        if (tipoInvestimento.id != null) {
            method = axiosAuthenticated.put,
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/tipos_investimento/${tipoInvestimento.id}`;
        } else {
            method = axiosAuthenticated.post,
                url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/tipos_investimento/`;
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.salvarTipoInvestimento = source


        

        const { data } = await method(
            url,
            {
                tipos_investimento_traducao_attributes: [
                    ...tipoInvestimento.traducoes
                ] 
            },
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.salvarTipoInvestimento = null;

        return data;
    }

    async removerTipoInvestimento(id) {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.removerTipoInvestimento = source

        const data = await axiosAuthenticated.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/tipos_investimento/${id}`,
            {
                cancelToken: source.token,
            }
        )

        this.cancelTokens.removerTipoInvestimento = null

        return data
    }

    async getLinguagens() {
        const CancelTokens = axios.CancelToken
        const source = CancelTokens.source()

        this.cancelTokens.getLinguagens = source

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens`,
            {
                cancelToken: source.token,
            }
        )

        this.cancelTokens.getLinguagens = null

        return data
    }



}

export default TiposInvestimentoApi