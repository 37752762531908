<template>
  <div class="caixa-form">
    <AppLoading class="carregando1" v-if="carregando && !erroCarregar" />
    <AppErro v-if="erroCarregar && !carregando" />
    <template v-if="!carregando && !erroCarregar">
      <div v-if="errosExcluir && !excluindo">
        <ul>
          <li v-for="(erro, index) in errosExcluir" :key="index">{{ erro }}</li>
        </ul>
      </div>
      <div class="disp-flex">
        <h4>Atração de Investimentos</h4>
        <BotaoArredondado
          @click="adicionar"
          type="button"
          title="Adicionar Atração de Investimento"
        >
          <span>+</span> Adicionar
        </BotaoArredondado>
      </div>
      <PessoasJuridicasAtracaoItem
        v-for="(atracaoInvestimento, index) in atracaoInvestimentos"
        :index="index"
        :atracao-investimento="atracaoInvestimento"
        :key="atracaoInvestimento.id"
      />
      <div class="text-center" v-if="atracaoInvestimentos.length === 0">
        <p>Nenhum registro encontrado</p>
      </div>

      <AppOverlayLoading v-if="excluindo">
        <span>Excluindo... Aguarde por favor.</span>
      </AppOverlayLoading>
      <div class="botao-salvar">
        <BotaoSalvar
          texto="Confirmar exclusões"
          v-if="atracaoInvestimentos.length > 0"
          :disabled="!possuiAtracaoInvestimentoParaExcluir"
          @click="excluirBatch"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import AtracaoInvestimento from "../../../../store/models/AtracaoInvestimento";

import AppErro from "../../../AppErro";
import AppLoading from "../../../AppLoading";
import AppOverlayLoading from "../../../AppOverlayLoading";
import BotaoSalvar from "../../../botoes/BotaoSalvar";
import BotaoArredondado from "../../../botoes/BotaoArredondado.vue";

import { PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO } from "../../../../router/routeNames";

import PessoasJuridicasAtracaoItem from "./PessoasJuridicasAtracaoItem";

import { modulePessoaJuridica } from "../../../../store/moduleNames";

const { mapActions } = createNamespacedHelpers(modulePessoaJuridica);

export default {
  name: "PessoasJuridicasAtracaoInvestimentos",
  props: {
    pessoaJuridicaId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    AppErro,
    AppLoading,
    AppOverlayLoading,
    BotaoSalvar,
    BotaoArredondado,
    PessoasJuridicasAtracaoItem,
  },
  methods: {
    adicionar() {
      this.$router.push({
        name: PESSOA_JURIDICA_EDIT_ATRACAO_INVESTIMENTO,
        query: { action: "new" },
        hash: this.$route.hash,
      });
    },
    async excluirBatch() {
      const result = await this.$swal.fire({
        title:
          "Você tem certeza que deseja excluir os registros selecionados? ",
        text: "Os registros não poderão ser recuperados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#054700",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        const atracoesParaExcluir = AtracaoInvestimento.query()
          .where("pessoa_juridica_id", this.pessoaJuridicaId)
          .where("_destroy", true)
          .get();

        const idsParaExcluir = atracoesParaExcluir.map((atracao) => atracao.id);

        await this.excluirAtracaoInvestimentoBatch(idsParaExcluir);
      }
    },
    ...mapActions(["excluirAtracaoInvestimentoBatch"]),
  },
  computed: {
    carregando() {
      return this.$store.state.entities.atracaoInvestimentos.carregandoLista;
    },
    erroCarregar() {
      return this.$store.state.entities.atracaoInvestimentos.erroCarregarLista;
    },
    excluindo() {
      return this.$store.state.entities.atracaoInvestimentos.excluindo;
    },
    errosExcluir() {
      return this.$store.state.entities.atracaoInvestimentos.errosExcluir;
    },
    atracaoInvestimentos() {
      return AtracaoInvestimento.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .orderBy("data_inicio", "desc")
        .get();
    },
    possuiAtracaoInvestimentoParaExcluir() {
      return AtracaoInvestimento.query()
        .where("pessoa_juridica_id", this.pessoaJuridicaId)
        .where("_destroy", true)
        .exists();
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.caixa-form {
  padding: 0rem;
  border-radius: 2px;
  margin-bottom: 2rem;
}
.disp-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
}
.botao-salvar {
  text-align: center;
  margin-top: 1rem;
}
.text-center {
  text-align: center;
}
@media screen and(min-width: '50em') {
  .caixa-form {
    padding: 0 0.5rem;
  }
}
</style>
