import PessoaJuridica from "./PessoaJuridica";
import Municipio from "./Municipio";
import ModelPersistido from "./ModelPersistido";
import Pais from "./Pais";

export default class Endereco extends ModelPersistido {
  static entity = "enderecos";

  static fields() {
    return {
      id: this.attr(null),
      logradouro: this.string(null).nullable(),
      numero: this.string(null).nullable(),
      complemento: this.string(null).nullable(),
      cep: this.string(null).nullable(),
      caixa_postal: this.string(null).nullable(),
      bairro: this.string(null).nullable(),
      municipio_id: this.number(null).nullable(),
      pessoa_juridica_id: this.attr(null),
      tipo: this.string(null).nullable(),
      label_tipo: this.string(null).nullable(),
      pais_id: this.attr(null),
      pessoa_juridica: this.belongsTo(PessoaJuridica, "pessoa_juridica_id"),
      municipio: this.belongsTo(Municipio, "municipio_id"),
      pais: this.belongsTo(Pais, "pais_id"),
      cidade_estado: this.string(null).nullable(),
      _destroy: this.boolean(false)
    };
  }
}
