import axios from 'axios'
import axiosAuthenticated from './axiosAutenticated'

const queryString = require('query-string')

class LinguagensApi {
  cancelTokens = {
    getLinguagens: null,
    getLimguagem: null,
    salvarLinguagem: null,
    removerLinguagem: null,
  }

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown)
  }

  async getLinguagens({ page = 1, perPage }) {
    const CancelTokens = axios.CancelToken
    const source = CancelTokens.source()

    this.cancelTokens.getLinguagens = source

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
    })

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens?${searchString}`,
      {
        cancelToken: source.token,
      }
    )

    this.cancelTokens.getLinguagens = null

    return data
  }

  async getLinguagem(id) {
    const CancelTokens = axios.CancelToken
    const source = CancelTokens.source()

    this.cancelTokens.getLimguagem = source

    const data = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens/${id}`,
      {
        cancelToken: source.token,
      }
    )
    this.cancelTokens.getLimguagem = null

    return data
  }

  async salvarLinguagem({ id, nome, codigo, linguagem_padrao}) {
    let method;
    let url;
    if(id) {
      method = axiosAuthenticated.put,
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens/${id}`;
    } else {
      method = axiosAuthenticated.post,
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens`;
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { data } = await method(
      url,
      {
        id,
        nome,
        codigo,
        linguagem_padrao
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarLinguagem = null;

    return data;
  }

  async removerLinguagem({id}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.removerLinguagem = source;

    const data = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/investe_goias/linguagens/${id}`,
      {
        cancelToken: source.token,
      }
    )
    this.cancelTokens.removerLinguagem = null

    return data

  }
}

export default LinguagensApi
