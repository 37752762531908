<template>
  <div>
    <div class="row4">
      <div class="item">
        <label>Data: <span>*</span> </label>
        <DatePicker
          v-model="data"
          @input="(value) => updateValue('data', value)"
          format="DD/MM/YYYY"
          :input-attr="{ required: true }"
        />
      </div>

      <div class="item">
        <label>Empregos diretos gerados até o momento:</label>
        <input
          type="number"
          @input="
            (evt) =>
              updateValue('empregos_diretos_gerados_atual', evt.target.value)
          "
          :value="historicoProtocoloIntencao.empregos_diretos_gerados_atual"
        />
      </div>
      <div class="item">
        <label>Empregos indiretos gerados até o momento:</label>
        <input
          type="number"
          @input="
            (evt) =>
              updateValue('empregos_indiretos_gerados_atual', evt.target.value)
          "
          :value="historicoProtocoloIntencao.empregos_indiretos_gerados_atual"
        />
      </div>

      <div class="item">
        <label>Investimento até o momento: </label>
        <CurrencyInput
          @input="(value) => updateValue('investimento_fixo_atual', value)"
          :value="historicoProtocoloIntencao.investimento_fixo_atual"
        />
      </div>
    </div>
    <div class="item">
      <label>Observação: </label>
      <textarea
        :value="historicoProtocoloIntencao.observacao"
        @input="(evt) => updateValue('observacao', evt.target.value)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { parseISO } from "date-fns";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "PessoasJuridicasProtocoloHistoricoItem",
  model: {
    prop: "historicoProtocoloIntencao",
    event: "change",
  },
  props: {
    historicoProtocoloIntencao: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      data: null,
    };
  },
  watch: {
    "historicoProtocoloIntencao.data": {
      immediate: true,
      handler: function(newDate) {
        if (newDate && typeof newDate == "string") {
          this.data = parseISO(newDate);
        } else {
          this.data = newDate;
        }
      },
    },
  },
  methods: {
    updateValue(key, value) {
      this.$emit("change", {
        ...this.historicoProtocoloIntencao,
        [key]: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0;
  input {
    height: 1.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
  textarea{
    height: 7rem;
  }
  .mx-datepicker {
    width: 100%;
  }
}
@media screen and (min-width: "60rem") {
  .row4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    .item {
      margin: 0;
    }
  }
  .mx-datepicker{
    align-self: flex-end;
    height: 2rem;
  }
}
</style>
