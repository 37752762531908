<template>
  <div>
    <div class="header-item-form">
      <h4>Pontos</h4>
      <BotaoArredondado
        @click="adicionar"
        type="button"
        title="Adicionar Ponto"
      >
        <span>+</span> Adicionar
      </BotaoArredondado>
    </div>
    <div class="row3">
      <template v-for="(ponto, index) in pontos">
        <div
          class="caixa-form"
          v-if="!ponto._destroy"
          :key="`${ponto.id}_${index}`"
        >
          <button type="button" @click="excluir(index)" title="Excluir">
            <DeleteIcon />
          </button>
          <div class="item">
            <label>Latitude:</label>
            <input type="text" v-model="ponto.lat" />
          </div>
          <div class="item">
            <label>Longitude:</label>
            <input type="text" v-model="ponto.long" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const cloneDeep = require("lodash.clonedeep");
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import BotaoArredondado from "../../botoes/BotaoArredondado.vue";

export default {
  name: "PolosIndustriaisPontosForm",
  components: {
    DeleteIcon,
    BotaoArredondado,
  },
  model: {
    prop: "pontos",
    event: "update-pontos",
  },
  props: {
    pontos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    adicionar() {
      let pontos = cloneDeep(this.pontos);
      pontos = pontos.concat({
        lat: "",
        long: "",
      });
      this.$emit("update-pontos", pontos);
    },
    excluir(index) {
      let pontos = cloneDeep(this.pontos);
      if (pontos[index].id) {
        this.$set(pontos[index], "_destroy", true);
      } else {
        pontos.splice(index, 1);
      }

      this.$emit("update-pontos", pontos);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-item-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: baseline;
  margin: 3rem 0 1rem;
  h4 {
    margin: 0.5rem 0 0;
    font-size: 1.4rem;
    display: table;
    color: var(--verde-escuro-gaia);
  }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    min-width: 100%;
    width: 30vw;
    max-width: 80vw;
    background-color: var(--verde-escuro-gaia);
    margin: 0.5rem 0 1rem;
  }
}
.caixa-form {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  position: relative;
  border-radius: 2px;
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.5rem;
      font-size: 1rem;
      padding-left: 0.5rem;
    }
    select {
      height: 2rem;
      font-size: 1rem;
      padding-left: 0.5rem;
    }
  }
  button {
    background-color: transparent;
    font-size: 1em;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    svg {
      fill: var(--vermelho-gaia);
    }
  }
}
.row3 {
  display: grid;
  gap: 1rem;
}

@media screen and(min-width: '38em') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media screen and(min-width: '50em') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>