import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

export default class MapeamentoOportunidadesApi {
    cancelTokens = {
        getMapeamentoOportunidades: null,
        getMapeamentoOportunidade: null,
        salvarMapeamentoOportunidade: null,
        getListaStatus: null,
    };

    async getMapeamentoOportunidades({ page, perPage, ...filtro }) {
        const CancelTokens = axios.CancelToken;
        const source = CancelTokens.source();
        this.cancelTokens.getMapeamentoOportunidades = source;

        const query = queryString.stringify({ page, per_page: perPage, ...filtro });

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/mapeamento_oportunidades?$${query}`,
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.getMapeamentoOportunidades = null;
        return data;
    }

    async getMapeamentoOportunidade( id ) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.getMapeamentoOportunidade = source;

        const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/mapeamento_oportunidades/${id}`,
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.getMapeamentoOportunidade = null;
        return data;
    }

    async salvarMapeamentoOportunidade({ id, status }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelTokens.salvarMapeamentoOportunidade = source;

        let method;
        let url;

        if (id) {
            method = axiosAuthenticated.put;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/mapeamento_oportunidades/${id}`;
        }
        else {
            method = axiosAuthenticated.post;
            url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/mapeamento_oportunidades`
        }

        const { data } = await method(url, { id, status },
            {
                cancelToken: source.token
            }
        );
        this.cancelTokens.salvarMapeamentoOportunidade = null;
        return data;
    }

    async getListaStatus() {
        try {
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
    
          this.cancelTokens.getListaStatus = source;
    
          const { data } = await axiosAuthenticated.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/mapeamento_oportunidades/lista_status`,
            {
              cancelToken: source.token,
            }
          );
    
          this.cancelTokens.getListaStatus = null;
    
          return data;
        } catch (err) {
          this.cancelTokens.getListaStatus = null;
    
          throw err;
        }
      }
}

