import ModelPersistido from "./ModelPersistido";

export default class Pais extends ModelPersistido {
  static entity = "paises";

  static fields() {
    return {
      id: this.attr(null),
      nome: this.attr(null),
      nome_pt: this.attr(null),
      sigla: this.attr(null),
      bacen: this.attr(null),
      padrao: this.attr(null),
    };
  }
}
