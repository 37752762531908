export default function displayCodigoCnae(codigo) {
  if (!codigo) return "";

  function printCodigo(cod) {
    if (cod && cod.length > 0) {
      return `${cod} - ${codigo.denominacao}`;
    }
    return "";
  }

  let result;

  const keys = ["subclasse", "classe", "grupo", "divisao", "secao"];

  for (let i = 0; i < keys.length && !result; i++) {
    result = printCodigo(codigo[keys[i]]);
  }
  return result;
}
