<template>
  <div class="centro">
    <div class="topo-title">
      <h1>Mapeamento Oportunidades</h1>
    </div>
    <div>
      <div v-if="!carregandoTela">
        <form class="form-pesquisa" @submit="buscar">
          <h4>Pesquisar</h4>
          <div>
            <div class="row3">
              <div class="item">
                <label>Município:</label>
                <select v-model="filtro.municipio">
                  <option value="">Todos</option>
                  <option
                    v-for="municipio in listaMunicipiosGoias"
                    :key="municipio.nome"
                    :value="municipio.nome"
                  >
                    {{ municipio.nome }}
                  </option>
                </select>
              </div>
              <div class="item">
                <label> Status :</label>
                <select v-model="filtro.status">
                  <option value="">Todos</option>
                  <option
                    v-for="stat in listaStatus"
                    :key="stat.value"
                    :value="stat.value"
                  >
                    {{ stat.value }}
                  </option>
                </select>
              </div>
              <div class="item">
                <label>Possui distrito industrial?</label>
                <select v-model="filtro.possui_distrito_industrial">
                  <option :value="null">Todos</option>
                  <option :value="true">Sim</option>
                  <option :value="false">Não</option>
                </select>
              </div>
            </div>
            <fieldset>
              <p>Data da resposta:</p>
              <div class="div-flex">
                <div class="item">
                  <label>De</label>
                  <date-picker
                    v-model="filtro.inicio"
                    format="DD/MM/YYYY"
                    value-type="format"
                  ></date-picker>
                </div>
                <div class="item">
                  <label>Até</label>
                  <date-picker
                    v-model="filtro.fim"
                    format="DD/MM/YYYY"
                    value-type="format"
                  ></date-picker>
                </div>
              </div>
            </fieldset>
            <div class="botoes-pesquisa">
              <button class="bt-limpar" type="button" @click="limpar">
                <IconClear /> Limpar
              </button>
              <button class="bt-pesquisar" type="submit">Pesquisar</button>
            </div>
          </div>
        </form>
      </div>
      <AppLoading v-if="carregandoTela" />
      <AppErro v-if="!carregandoTela && erroCarregarTela" />
      <template v-if="!carregandoTela && !erroCarregarTela">
        <div class="campo-header-resultados">
          <AppTotalResultadosEncontrados :totalResultados="totalResultados" />
          <AppResultadosPorPagina
            :per-page="perPage"
            @per-page-changed="perPageChanged"
          />
        </div>
        <div>
          <MapeamentoOportunidadesResultados
            :mapeamentoOportunidades="mapeamentoOportunidades"
          />
        </div>
        <div class="paginacao">
          <Paginate
            v-model="page"
            :page-count="pageCount"
            :page-range="5"
            prev-text="Anterior"
            next-text="Próxima"
            :clickHandler="mudarPagina"
          />
        </div>
      </template>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { DEFAULT_PER_PAGE } from "../../../../config";
import {
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
  ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
} from "../../../../router/routeNames";
import MapeamentoOportunidadesApi from "../../../../services/api/MapeamentoOportunidadesApi";
import MunicipiosGoiasApi from "../../../../services/api/MunicipiosGoiasApi";

import MapeamentoOportunidadesResultados from "../../../../components/atracao_investimentos/mapeamento_oportunidades/MapeamentoOportunidadesResultados.vue";
import AppLoading from "../../../../components/AppLoading.vue";
import AppErro from "../../../../components/AppErro.vue";
import Paginate from "vuejs-paginate";
import AppResultadosPorPagina from "../../../../components/AppResultadosPorPagina";
import filterNullsAndEmptyStrings from "../../../../helpers/filterNullsAndEmptyStrings";
import AppTotalResultadosEncontrados from "../../../../components/AppTotalResultadosEncontrados.vue";
import IconClear from "../../../../assets/images/icon-clean.svg";

export default {
  name: "MapeamentoOportunidadesIndex",
  metaInfo: {
    title: "Mapeamento Oportunidades - Atração de Investimentos",
    titleTemplate: "%s - Gaia",
  },
  components: {
    MapeamentoOportunidadesResultados,
    AppLoading,
    AppErro,
    Paginate,
    DatePicker,
    AppResultadosPorPagina,
    AppTotalResultadosEncontrados,
    IconClear,
  },
  data() {
    return {
      mapeamentoOportunidades: [],
      listaStatus: [],
      listaMunicipiosGoias: [],
      carregando: true,
      erroCarregarFiltro: false,
      carregandoValores: true,
      erroCarregar: false,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page || DEFAULT_PER_PAGE,
      totalResultados: 0,
      ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_EDIT,
      filtro: {
        status: this.$route.query.status || "",
        municipio: this.$route.query.municipio || "",
        inicio: this.$route.query.inicio || "",
        fim: this.$route.query.fim || "",
        possui_distrito_industrial:
          this.$route.query.possui_distrito_industrial === undefined
            ? null
            : this.$route.query.possui_distrito_industrial,
      },
    };
  },
  watch: {
    $route: function(to, from) {
      if (
        to.name == ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX ||
        to.query.page != from.query.page ||
        to.query.per_page != from.query.per_page
      ) {
        this.carregarDados();
      }
    },
  },
  created() {
    this.mapeamentoOportunidadesApi = new MapeamentoOportunidadesApi();
    this.municipiosGoiasApi = new MunicipiosGoiasApi();
    this.carregarDados();
    this.carregarDadosSelects();
  },
  methods: {
    async carregarDados() {
      try {
        this.carregando = true;
        this.erroCarregar = false;

        this.filtro = {
          status: this.$route.query.status || "",
          municipio: this.$route.query.municipio || "",
          inicio: this.$route.query.inicio || "",
          fim: this.$route.query.fim || "",
          possui_distrito_industrial:
            this.$route.query.possui_distrito_industrial === undefined
              ? null
              : this.$route.query.possui_distrito_industrial,
        };

        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page || DEFAULT_PER_PAGE;

        const data = await this.mapeamentoOportunidadesApi.getMapeamentoOportunidades(
          {
            ...this.filtro,
            perPage: this.perPage,
            page: this.page,
          }
        );

        this.carregando = false;
        this.mapeamentoOportunidades = data.resultados;
        this.totalResultados = data.total_resultados;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erroCarregar = true;
      }
    },
    async carregarDadosSelects() {
      try {
        this.erroCarregarFiltro = false;
        this.carregandoValores = true;

        const [listaStatus, listaMunicipiosGoias] = await Promise.all([
          this.mapeamentoOportunidadesApi.getListaStatus(),
          this.municipiosGoiasApi.getMunicipiosGoias(),
        ]);
        this.listaStatus = listaStatus;
        this.listaMunicipiosGoias = listaMunicipiosGoias;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);
        this.erroCarregarFiltro = true;
        this.carregandoValores = false;
      }
    },

    mudarPagina(pageNum) {
      this.page = pageNum;
      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
        query: {
          ...this.$route.query,
          page: this.page,
          per_page: this.perPage,
        },
      });
    },
    perPageChanged(newValuePerPage) {
      if (newValuePerPage < this.perPage) {
        this.perPage = newValuePerPage;
        this.mudarPagina(this.page);
      } else {
        this.perPage = newValuePerPage;
        this.mudarPagina(1);
      }
    },
    buscar(event) {
      event.preventDefault();

      this.$router.push({
        name: ATRACAO_INVESTIMENTOS_MAPEAMENTO_OPORTUNIDADES_INDEX,
        query: filterNullsAndEmptyStrings({
          status: this.filtro.status,
          municipio: this.filtro.municipio,
          inicio: this.filtro.inicio,
          fim: this.filtro.fim,
          possui_distrito_industrial: this.filtro.possui_distrito_industrial,
          page: this.page,
          per_page: this.perPage,
        }),
      });
    },
    limpar() {
      this.filtro = {
        status: "",
        municipio: "",
        inicio: "",
        fim: "",
        possui_distrito_industrial: null,
      };
    },
  },
  computed: {
    pageCount() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.totalResultados > 0
      ) {
        return Math.ceil(this.totalResultados / this.perPage);
      }
      return 0;
    },
    carregandoTela() {
      return this.carregando || this.carregandoValores;
    },
    erroCarregarTela() {
      return this.erroCarregarFiltro || this.erroCarregar;
    },
  },
};
</script>

<style lang="scss" scoped>
.topo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
  h1 {
    margin: 0;
    color: var(--laranja-gaia);
  }
}
.form-pesquisa {
  background-color: var(--cinza-caixa-gaia);
  padding: 1rem;
  border-radius: 2px;
  h4 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
  }
  .item {
    display: grid;
    margin: 0.5rem 0;
    input {
      height: 1.6rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
    select {
      height: 2rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }
  fieldset {
    p {
      margin: 0;
      font-family: "Lato-Regular";
    }
  }
  .div-flex {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    justify-content: center;
    input {
      height: 1.5rem;
      margin-left: 0.1rem;
      padding-left: 0.5rem;
      width: 4rem;
    }
    .mx-datepicker {
      width: auto;
    }
  }
  .botoes-pesquisa {
    text-align: center;
    margin-top: 2rem;
    .bt-limpar {
      border: none;
      color: var(--verde-escuro-gaia);
      font-size: 1rem;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 1rem;
      svg {
        height: 0.9rem;
        width: 1.1rem;
      }
    }
    .bt-pesquisar {
      border: none;
      border-radius: 2px;
      background-color: var(--verde-escuro-gaia);
      color: white;
      font-size: 1rem;
      padding: 0.5rem 3rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
.campo-header-resultados {
  margin-bottom: 1rem;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: "30em") {
  .campo-header-resultados {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    h4 {
      margin-bottom: 1.5rem;
    }
  }
  fieldset {
    .div-flex {
      .item {
        display: flex;
        align-items: center;
        label {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
