import { render, staticRenderFns } from "./PessoasJuridicasProtocoloOrgaoEnvolvido.vue?vue&type=template&id=e34d12cc&scoped=true&"
import script from "./PessoasJuridicasProtocoloOrgaoEnvolvido.vue?vue&type=script&lang=js&"
export * from "./PessoasJuridicasProtocoloOrgaoEnvolvido.vue?vue&type=script&lang=js&"
import style0 from "./PessoasJuridicasProtocoloOrgaoEnvolvido.vue?vue&type=style&index=0&id=e34d12cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e34d12cc",
  null
  
)

export default component.exports