<template>
  <div class="">
    <h4>Endereços</h4>
    <div class="caixa-dados">
      <div
        class="grupo-dados"
        v-for="endereco in enderecosPersistidos"
        :key="endereco.id"
      >
        <div class="row3">
          <div class="item">
            <strong>Tipo de endereço: </strong>
            <span>{{ endereco.label_tipo }}</span>
          </div>

          <div class="item">
            <strong>Logradouro: </strong>
            <span>{{ endereco.logradouro }}</span>
          </div>

          <div class="item">
            <strong>Número: </strong>
            <span>{{ endereco.numero | naoInformado }}</span>
          </div>
        </div>
        <div class="row3">
          <div class="item">
            <strong>Complemento: </strong>
            <span>{{ endereco.complemento | naoInformado }}</span>
          </div>

          <div class="item">
            <strong>Caixa Postal: </strong>
            <span>{{ endereco.caixa_posta | naoInformado }}</span>
          </div>

          <div class="item">
            <strong>Bairro: </strong>
            <span>{{ endereco.bairro | naoInformado }}</span>
          </div>

          <div class="item">
            <strong>CEP: </strong>
            <span>{{ endereco.cep }}</span>
          </div>
        </div>
        <div class="row3">
          <div class="item">
            <strong>País: </strong>
            <span>{{ endereco.pais.nome }}</span>
          </div>

          <div class="item" v-if="endereco.municipio">
            <strong>Municipio/Estado: </strong>
            <span
              >{{ endereco.municipio && endereco.municipio.nome }} -
              {{ endereco.municipio.estado.nome }}</span
            >
          </div>

          <div class="item" v-if="endereco.cidade_estado">
            <strong>Cidade/Estado: </strong>
            <span>{{ endereco.cidade_estado }}</span>
          </div>
        </div>
      </div>

      <p class="nenhum-resultado" v-if="enderecosPersistidos.length === 0">
        Nenhum endereço cadastrado
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PessoasJuridicasEnderecoInfo",
  props: {
    enderecos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    enderecosPersistidos() {
      return this.enderecos.filter((endereco) => endereco._persistido);
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  display: table;
  color: var(--verde-escuro-gaia);
}
h4::after {
  content: "";
  display: block;
  height: 2px;
  min-width: 100%;
  width: 30vw;
  max-width: 80vw;
  background-color: var(--verde-escuro-gaia);
  margin: 0.5rem 0 1rem;
}
.grupos {
  margin-bottom: 1.5rem;
}
.caixa-dados {
  background-color: white;
  padding: 0.5rem 0.5rem 0;
  .grupo-dados {
    margin-top: 0.5rem;
    border-bottom: solid 0.1rem var(--cinza-caixa-gaia);
  }
  .item {
    display: grid;
    gap: 0.5rem;
    margin: 0.5rem 0 1.5rem;
    p {
      margin-top: 0;
    }
  }
}
.nenhum-resultado {
  text-align: center;
  font-style: italic;
  padding-bottom: 1.5rem;
}
@media screen and(min-width: '50em') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 1rem;
    align-items: baseline;
  }
}
</style>
