<template>
  <div>
    <div>
      <div class="row3">
        <p>
          <strong>Tipo de Solicitação: </strong
          >{{
            solicitacao.tipo_solicitacao_empresa_atracao
              ? solicitacao.tipo_solicitacao_empresa_atracao.nome
              : "Não informado"
          }}
        </p>

        <p>
          <strong>Concluída: </strong>
          {{ solicitacao.concluida ? "Sim" : "Não" }}
        </p>

        <p>
          <strong>Data de conclusão: </strong
          >{{ solicitacao.data_conclusao | toDate | naoInformado }}
        </p>
      </div>
      <p>
        <strong>Observação: </strong>
        {{ solicitacao.observacao | naoInformado }}
      </p>

      <template v-if="solicitacao.concluida">
        <p>
          <strong>Detalhes da conclusão: </strong
          >{{ solicitacao.descricao_conclusao | naoInformado }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import toDate from "../../../../filters/toDate";

export default {
  name: "PessoasJuridicasItemSolicitacao",
  props: {
    index: {
      type: Number,
      required: true,
    },
    solicitacao: {
      type: Object,
      required: true,
    },
  },
  filters: {
    toDate,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: "60em") {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    p {
      display: grid;
      gap: .5rem;
    }
  }
}
</style>
