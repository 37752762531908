import axios from "axios";
import axiosAuthenticated from "./axiosAutenticated";

const queryString = require("query-string");

class TiposSolicitacaoEmpresaApi {
  cancelTokens = {
    getTiposSolicitacaoEmpresa: null,
    removerTipoSolicitacaoEmpresa: null,
    getTipoSolicitacaoEmpresa: null,
    salvarDados: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getTiposSolicitacaoEmpresa({ page = 1, perPage }) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getTiposSolicitacaoEmpresa = source;

    const searchString = queryString.stringify({
      page,
      per_page: perPage,
    });

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao?${searchString}`,
      {
        cancelToken: source.token,
      }
    );
    this.cancelTokens.getTiposSolicitacaoEmpresa = null;

    return data;
  }
  async removerTipoSolicitacaoEmpresa(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();
    this.cancelTokens.removerTipoSolicitacaoEmpresa = source;

    const data = await axiosAuthenticated.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.removerTipoSolicitacaoEmpresa = null;

    return data;
  }

  async getTipoSolicitacaoEmpresa(id) {
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.getTipoSolicitacaoEmpresa = source;

    const { data } = await axiosAuthenticated.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao/${id}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipoSolicitacaoEmpresa = null;
    return data;
  }

  async salvarDados(tipoSolicitacaoEmpresa) {
    let method;
    let url;
    const CancelTokens = axios.CancelToken;
    const source = CancelTokens.source();

    this.cancelTokens.salvarDados = source;

    if (tipoSolicitacaoEmpresa.id) {
      method = axiosAuthenticated.put;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao/${tipoSolicitacaoEmpresa.id}`;
    } else {
      method = axiosAuthenticated.post;
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/atracao_apoio_investidor/tipos_solicitacao_empresa_atracao/`;
    }

    const { data } = await method(url, tipoSolicitacaoEmpresa, {
      cancelToken: source.token,
    });

    this.cancelTokens.salvarDados = null;
    return data;
  }
}

export default TiposSolicitacaoEmpresaApi;
